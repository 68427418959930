export const riscuriDefaults = [
    {
        label: 'infectare',
        value: false,
        required: true
    },
    {
        label: 'electrocutare',
        value: false,
        required: true
    },
    {
        label: 'inalta tensiune',
        value: false,
        required: true
    },
    {
        label: 'joasa, medie tensiune',
        value: false,
        required: true
    },
    {
        label: 'inecare',
        value: false,
        required: true
    },
    {
        label: 'asfixiere',
        value: false,
        required: true
    },
    {
        label: 'blocare',
        value: false,
        required: true
    },
    {
        label: 'microtraumatisme repetate',
        value: false,
        required: true
    },
    {
        label: 'lovire',
        value: false,
        required: true
    },
    {
        label: 'muscatura',
        value: false,
        required: true
    },
    {
        label: 'zgariere',
        value: false,
        required: true
    },
    {
        label: 'strivire',
        value: false,
        required: true
    },
    {
        label: 'taiere',
        value: false,
        required: true
    },
    {
        label: 'intepare',
        value: false,
        required: true
    },
    {
        label: 'impuscare',
        value: false,
        required: true
    },
    {
        label: 'ardere',
        value: false,
        required: true
    },
    {
        label: 'oparire',
        value: false,
        required: true
    },
    {
        label: 'degerare',
        value: false,
        required: true
    },
    {
        label: 'miscari repetitive',
        value: false,
        required: true
    }
];

export const conditiiMuncaDefaults = [
    {
        label: 'In conditii de izolare',
        value: false,
        required: true
    },
    {
        label: 'La inaltime',
        value: false,
        required: true
    },
    {
        label: 'La altitudine',
        value: false,
        required: true
    },
    {
        label: 'In miscare',
        value: false,
        required: true
    },
    {
        label: 'Pe sol',
        value: false,
        required: true
    },
    {
        label: 'In aer',
        value: false,
        required: true
    },
    {
        label: 'Pe apa',
        value: false,
        required: true
    },
    {
        label: 'Sub apa',
        value: false,
        required: true
    },
    {
        label: 'Nisa',
        value: false,
        required: true
    },
    {
        label: 'Cabina etansa',
        value: false,
        required: true
    },
    {
        label: 'Aer liber',
        value: false,
        required: true
    },
];

export const default_risk_file = {
    "naveta": null,
    "deplasariInteresServiciu": null,
    "muncaEchipa": true,
    "nrOrePeZi": 8,
    "nrSchimburiDeLucru": 1,
    "schimbNoapte": null,
    "pauzeOrganizate": true,
    "bandaRulanta": false,
    "riscuri": [
        {
            "label": "infectare",
            "value": false,
            "required": true
        },
        {
            "label": "electrocutare",
            "value": false,
            "required": true
        },
        {
            "label": "inalta tensiune",
            "value": false,
            "required": true
        },
        {
            "label": "joasa, medie tensiune",
            "value": false,
            "required": true
        },
        {
            "label": "inecare",
            "value": false,
            "required": true
        },
        {
            "label": "asfixiere",
            "value": false,
            "required": true
        },
        {
            "label": "blocare",
            "value": false,
            "required": true
        },
        {
            "label": "microtraumatisme repetate",
            "value": false,
            "required": true
        },
        {
            "label": "lovire",
            "value": false,
            "required": true
        },
        {
            "label": "muscatura",
            "value": false,
            "required": true
        },
        {
            "label": "zgariere",
            "value": false,
            "required": true
        },
        {
            "label": "strivire",
            "value": false,
            "required": true
        },
        {
            "label": "taiere",
            "value": false,
            "required": true
        },
        {
            "label": "intepare",
            "value": false,
            "required": true
        },
        {
            "label": "impuscare",
            "value": false,
            "required": true
        },
        {
            "label": "ardere",
            "value": false,
            "required": true
        },
        {
            "label": "oparire",
            "value": false,
            "required": true
        },
        {
            "label": "degerare",
            "value": false,
            "required": true
        },
        {
            "label": "miscari repetitive",
            "value": false,
            "required": true
        }
    ],
    "alteRiscuri": "",
    "conduceMasinaInstitutiei": null,
    "conduceNumaiIntrauzinal": false,
    "inConditiiDeosebite": false,
    "inConditiiSpeciale": false,
    "sectorAlimentar": false,
    "portArma": false,
    "operatiuniProcesTehnologic": "",
    "suprafataLucruVerticala": false,
    "suprafataLucruOrizontala": false,
    "suprafataLucruOblica": false,
    "dimensiuneIncapereL": "",
    "dimensiuneIncaperel": "",
    "dimensiuneIncapereH": "",
    "conditiiMunca": [
        {
            "label": "In conditii de izolare",
            "value": false,
            "required": true
        },
        {
            "label": "La inaltime",
            "value": false,
            "required": true
        },
        {
            "label": "La altitudine",
            "value": false,
            "required": true
        },
        {
            "label": "In miscare",
            "value": false,
            "required": true
        },
        {
            "label": "Pe sol",
            "value": false,
            "required": true
        },
        {
            "label": "In aer",
            "value": false,
            "required": true
        },
        {
            "label": "Pe apa",
            "value": false,
            "required": true
        },
        {
            "label": "Sub apa",
            "value": false,
            "required": true
        },
        {
            "label": "Nisa",
            "value": false,
            "required": true
        },
        {
            "label": "Cabina etansa",
            "value": false,
            "required": true
        },
        {
            "label": "Aer liber",
            "value": false,
            "required": true
        }
    ],
    "conditiiMuncaAltele": "",
    "efortFizicMic": false,
    "efortFizicMediu": false,
    "efortFizicMare": false,
    "efortFizicFoarteMare": false,
    "pozitiePreponderentOrtostatica": false,
    "pozitiePreponderentAsezat": false,
    "pozitiePreponderentAplecat": false,
    "pozitiePreponderentMixta": false,
    "pozitiiFortate": null,
    "gesturiProfesionale": "",
    "suprasolicitariVizuale": false,
    "suprasolicitariAuditive": false,
    "suprasolicitariNeuropsihosenzoriale": false,
    "suprasolicitariNeuroMentale": false,
    "suprasolicitariNeuroEmotionale": false,
    "suprasolicitariNeuroAltele": false,
    "miscariFortate": false,
    "miscariRepetitive": false,
    "miscariRepetitive_cervical": false,
    "miscariRepetitive_toracal": false,
    "miscariRepetitive_lombar": false,
    "miscariRepetitive_umar": false,
    "miscariRepetitive_cot": false,
    "miscariRepetitive_pumn": false,
    "miscariRepetitive_sold": false,
    "miscariRepetitive_genunchi": false,
    "miscariRepetitive_glezna": false,
    "manipulareaManualaAMaselor": null,
    "manipulareManualaGreutateMaxima": "",
    "manipulareManualaRidicare": false,
    "manipulareManualaCoborare": false,
    "manipulareManualaImpingere": false,
    "manipulareManualaTragere": false,
    "manipulareManualaPurtare": false,
    "manipulareManualaDeplasare": false,
    "agentiChimici": false,
    "agentiChimiciTabelData": [
        {
            "columns": [
                "",
                "",
                "",
                "",
                "",
                ""
            ]
        }
    ],
    "agentiBiologici": "",
    "agentiBiologiciGrupa": "",
    "agentiCancerigeni": "",
    "pulberiProfesionale": false,
    "pulberiProfesionaleTableData": [
        {
            "columns": [
                "",
                "",
                ""
            ]
        }
    ],
    "zgomotProfesionalLessVLE": false,
    "zgomotProfesionalMoreVLE": false,
    "zgomoteImpulsive": false,
    "vibratiiMecaniceLessVLE": false,
    "vibratiiMecaniceMoreVLE": false,
    "vibratiiMecaniceVertebrala": false,
    "vibratiiMecaniceMembreSuperioare": false,
    "vibratiiMecaniceActiuneAsupraIntreguluiCorp": false,
    "temperaturaAer": "",
    "temperaturaRepetata": false,
    "presiuneAer": "",
    "umiditateRelativa": "",
    "iluminatSuficient": false,
    "iluminatInsuficient": false,
    "iluminatNatural": false,
    "iluminatArtificial": false,
    "iluminatMixt": false,
    "mijloaceProtectieColectiva": "",
    "mijloaceProtectieIndividuala": "",
    "echipamentDeLucru": "",
    "anexeIgenicoVestiar": false,
    "anexeIgenicoChiuveta": false,
    "anexeIgenicoWC": false,
    "anexeIgenicoDus": false,
    "anexeIgenicoSalaDeMese": false,
    "anexeIgenicoSpatiuRecreere": false,
    "anexeIgenicoAltele": "",
    "observatii": "",
    "radiatii": false,
    "radiatiiIonizateDataIntrariiMediuExpunereProfesionala": "",
    "radiatiiIonizateGrupaA": false,
    "radiatiiIonizateGrupaB": false,
    "radiatiiIonizateAparaturaFolosita": "",
    "radiatiiIonizateProcesTehnologic": "",
    "radiatiiIonizateOperatiuniIndeplinite": "",
    "radiatiiIonizateSurseDeschise": false,
    "radiatiiIonizateSurseInchise": false,
    "radiatiiIonizateTipExpunere_X_externa": false,
    "radiatiiIonizateTipExpunere_gamma_externa": false,
    "radiatiiIonizateTipExpunere_interna": false,
    "radiatiiIonizateTipExpunere_interna_externa": false,
    "radiatiiIonizateTipExpunere_masuriprotectieindividuala": "",
    "radiatiiIonizate_expunere_anterioara_perioada": "",
    "radiatiiIonizate_expunere_anterioara_nr_ani": 0,
    "radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa": 0,
    "radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna": 0,
    "radiatiiIonizate_expunere_anterioara_doza_totala": 0,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii": "",
    "radiatiiNeionizateTipul": "",
    "locMunca": "",
    "sectieDepartament": ""
}

export const default_risks_macheta = {
    "deplasariInteresServiciu": null,
    "muncaEchipa": true,
    "nrOrePeZi": 8,
    "nrSchimburiDeLucru": 1,
    "schimbNoapte": null,
    "pauzeOrganizate": true,
    "bandaRulanta": false,
    "riscuri": [
      {
        "label": "infectare",
        "value": false,
        "required": true
      },
      {
        "label": "electrocutare",
        "value": true,
        "required": true
      },
      {
        "label": "inalta tensiune",
        "value": false,
        "required": true
      },
      {
        "label": "joasa, medie tensiune",
        "value": false,
        "required": true
      },
      {
        "label": "inecare",
        "value": false,
        "required": true
      },
      {
        "label": "asfixiere",
        "value": false,
        "required": true
      },
      {
        "label": "blocare",
        "value": false,
        "required": true
      },
      {
        "label": "microtraumatisme repetate",
        "value": false,
        "required": true
      },
      {
        "label": "lovire",
        "value": true,
        "required": true
      },
      {
        "label": "muscatura",
        "value": false,
        "required": true
      },
      {
        "label": "zgariere",
        "value": true,
        "required": true
      },
      {
        "label": "strivire",
        "value": true,
        "required": true
      },
      {
        "label": "taiere",
        "value": true,
        "required": true
      },
      {
        "label": "intepare",
        "value": true,
        "required": true
      },
      {
        "label": "impuscare",
        "value": false,
        "required": true
      },
      {
        "label": "ardere",
        "value": false,
        "required": true
      },
      {
        "label": "oparire",
        "value": false,
        "required": true
      },
      {
        "label": "degerare",
        "value": false,
        "required": true
      },
      {
        "label": "miscari repetitive",
        "value": false,
        "required": true
      }
    ],
    "alteRiscuri": "alunecare, impiedicare, dezechilibru, cadere",
    "conduceMasinaInstitutiei": null,
    "conduceNumaiIntrauzinal": false,
    "inConditiiDeosebite": false,
    "inConditiiSpeciale": false,
    "sectorAlimentar": false,
    "portArma": false,
    "operatiuniProcesTehnologic": "",
    "suprafataLucruVerticala": false,
    "suprafataLucruOrizontala": true,
    "suprafataLucruOblica": false,
    "dimensiuneIncapereL": "",
    "dimensiuneIncaperel": "",
    "dimensiuneIncapereH": "",
    "dimensiuneObservatii": "birou",
    "conditiiMunca": [
      {
        "label": "In conditii de izolare",
        "value": false,
        "required": true
      },
      {
        "label": "La inaltime",
        "value": false,
        "required": true
      },
      {
        "label": "La altitudine",
        "value": false,
        "required": true
      },
      {
        "label": "In miscare",
        "value": true,
        "required": true
      },
      {
        "label": "Pe sol",
        "value": true,
        "required": true
      },
      {
        "label": "In aer",
        "value": false,
        "required": true
      },
      {
        "label": "Pe apa",
        "value": false,
        "required": true
      },
      {
        "label": "Sub apa",
        "value": false,
        "required": true
      },
      {
        "label": "Nisa",
        "value": false,
        "required": true
      },
      {
        "label": "Cabina etansa",
        "value": false,
        "required": true
      },
      {
        "label": "Aer liber",
        "value": false,
        "required": true
      }
    ],
    "conditiiMuncaAltele": "",
    "efortFizicMic": true,
    "efortFizicMediu": false,
    "efortFizicMare": false,
    "efortFizicFoarteMare": false,
    "pozitiePreponderentOrtostatica": true,
    "pozitiePreponderentAsezat": true,
    "pozitiePreponderentAplecat": false,
    "pozitiePreponderentMixta": false,
    "pozitiiFortate": null,
    "gesturiProfesionale": "",
    "suprasolicitariVizuale": false,
    "suprasolicitariAuditive": false,
    "suprasolicitariNeuropsihosenzoriale": false,
    "suprasolicitariNeuroMentale": false,
    "suprasolicitariNeuroEmotionale": false,
    "suprasolicitariNeuroAltele": false,
    "miscariFortate": false,
    "miscariRepetitive": false,
    "miscariRepetitive_cervical": false,
    "miscariRepetitive_toracal": false,
    "miscariRepetitive_lombar": false,
    "miscariRepetitive_umar": false,
    "miscariRepetitive_cot": false,
    "miscariRepetitive_pumn": false,
    "miscariRepetitive_sold": false,
    "miscariRepetitive_genunchi": false,
    "miscariRepetitive_glezna": false,
    "manipulareaManualaAMaselor": "documente",
    "manipulareManualaGreutateMaxima": "<V.M.A.",
    "manipulareManualaRidicare": true,
    "manipulareManualaCoborare": true,
    "manipulareManualaImpingere": true,
    "manipulareManualaTragere": true,
    "manipulareManualaPurtare": true,
    "manipulareManualaDeplasare": true,
    "agentiChimici": false,
    "agentiChimiciTabelData": [
      {
        "columns": [
          "",
          "",
          "",
          "",
          "",
          ""
        ]
      }
    ],
    "agentiBiologici": "",
    "agentiBiologiciGrupa": "",
    "agentiCancerigeni": "",
    "pulberiProfesionale": false,
    "pulberiProfesionaleTableData": [
      {
        "columns": [
          "",
          "",
          ""
        ]
      }
    ],
    "zgomotProfesionalLessVLE": true,
    "zgomotProfesionalMoreVLE": false,
    "zgomoteImpulsive": false,
    "vibratiiMecaniceLessVLE": false,
    "vibratiiMecaniceMoreVLE": false,
    "vibratiiMecaniceVertebrala": false,
    "vibratiiMecaniceMembreSuperioare": false,
    "vibratiiMecaniceActiuneAsupraIntreguluiCorp": false,
    "temperaturaAer": "NORMALA",
    "temperaturaRepetata": false,
    "presiuneAer": "NORMALA",
    "umiditateRelativa": "NORMALA",
    "iluminatSuficient": true,
    "iluminatInsuficient": false,
    "iluminatNatural": false,
    "iluminatArtificial": false,
    "iluminatMixt": true,
    "mijloaceProtectieColectiva": "INCALAZIRE TERMICA, VENTILATIE",
    "mijloaceProtectieIndividuala": "CONFORM NORMATIV INTERN",
    "echipamentDeLucru": "CONFORM NORMATIV INTERN",
    "anexeIgenicoVestiar": true,
    "anexeIgenicoChiuveta": true,
    "anexeIgenicoWC": false,
    "anexeIgenicoDus": false,
    "anexeIgenicoSalaDeMese": true,
    "anexeIgenicoSpatiuRecreere": true,
    "anexeIgenicoAltele": "",
    "observatii": "",
    "radiatii": false,
    "radiatiiIonizateDataIntrariiMediuExpunereProfesionala": "",
    "radiatiiIonizateGrupaA": false,
    "radiatiiIonizateGrupaB": false,
    "radiatiiIonizateAparaturaFolosita": "",
    "radiatiiIonizateProcesTehnologic": "",
    "radiatiiIonizateOperatiuniIndeplinite": "",
    "radiatiiIonizateSurseDeschise": false,
    "radiatiiIonizateSurseInchise": false,
    "radiatiiIonizateTipExpunere_X_externa": false,
    "radiatiiIonizateTipExpunere_gamma_externa": false,
    "radiatiiIonizateTipExpunere_interna": false,
    "radiatiiIonizateTipExpunere_interna_externa": false,
    "radiatiiIonizateTipExpunere_masuriprotectieindividuala": "",
    "radiatiiIonizate_expunere_anterioara_perioada": "",
    "radiatiiIonizate_expunere_anterioara_nr_ani": 0,
    "radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa": 0,
    "radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna": 0,
    "radiatiiIonizate_expunere_anterioara_doza_totala": 0,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa": false,
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza": "",
    "radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii": "",
    "radiatiiNeionizateTipul": ""
};