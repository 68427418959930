import React, { useEffect, useRef, useState } from 'react';
import { getAccessToken } from 'shared/dist/components/api-authorization/AuthService';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import {get_ajax, post, parseTrueOrErrorMessage} from 'shared/dist/utils/DataProvider';
import AOS from 'aos';
import "aos/dist/aos.css";
import {CdnResourceDownloadBtn, RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {formatBytes} from 'shared/dist/utils/Utils';
import {FileTypeIcon, MediaPreviewIcon} from 'shared/dist/components/generic-building-blocks/FileTypeIcon';
import {CdnImage} from 'shared/dist/components/generic-building-blocks/CdnImage';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {DeleteConfirmationDialog} from 'shared/dist/components/generic-building-blocks/UserDialogs';

export const MediaLibrary = () =>{
    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(12);

    useEffect(()=>{
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [stats, setStats] = useState(undefined);

    useEffect(()=>{     
        AOS.init();   
        get_stats();             
    }, []);

    const loadData = (searchBy, page) => {
        get_ajax("ExpertSSM/MediaLibrary/GetMediaResources?search=" + searchBy + "&page=" + page, (response) =>{
            setRecords(response);
            setIsLoading(false);
        });
    }

    const get_stats = () => {
        get_ajax("ExpertSSM/MediaLibrary/GetResourceStats", (response) =>{
            setStats(response);
        });
    }

    const [selectedResourceId, setSelectedResourceId] = useState(undefined);
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(undefined);

    return (
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Arhiva media</h4>
                        <br/>
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </LayoutCard>
                    {isLoading && (<LoadingCircleComponent/>)}
                    {!isLoading && (
                        <>
                            <div className='row'>
                                {records.map((rec, i)=> 
                                    <div className='col-md-3' key={i}>
                                        <div className='maxHeight' style={{paddingBottom: '25px'}}>
                                        <LayoutCard 
                                            className="layout_card1 shadow-sm no_padding maxHeight top_bottom"
                                            onClick={()=>{setSelectedResourceId(rec.id)}}
                                        >
                                            <MediaPreviewIcon
                                                size={52} 
                                                style={{margin: 'auto', display: 'block', marginTop: '40px'}}
                                                blobName={rec.blobName}
                                                fileName={rec.originalName}
                                                has_video_controls={false}
                                            />
                                            <p 
                                                style={{
                                                    textAlign: 'center',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    marginBottom: '0'
                                                }}
                                                className='padded_bottom'
                                            >
                                                <b>{rec.originalName}</b>
                                                <br/>
                                                <small>
                                                    {formatBytes(rec.fileSize)}
                                                </small>
                                            </p>
                                        </LayoutCard>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {records.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista resurse.</p><br/></>
                            )}
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={records.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                <div className='col-md-4'>
                    {stats === undefined && (<LoadingCircleComponent/>)}
                    {stats !== undefined && (
                        <LayoutCard
                            className={"layout_card_25bottom shadow-sm"}
                        >            
                            <label>Utilizare memorie</label>
                            <br/>
                            <h4 style={{marginBottom: '0', display: 'inline'}}>
                                {formatBytes(stats.space_used)}
                            </h4>     
                            <p style={{display: 'inline'}}>/ {stats.max_space != "" ? formatBytes(stats.max_space) : <>&#8734;</>}</p> 
                        </LayoutCard>
                    )}
                    <RoundButton
                        className={"custom-btn-primary"}
                        text={'Incarca fisier'}
                        onClickHandler={()=>{setIsUploadDialogOpen(true)}}
                    />
                </div>
            </div>

            <MediaRecordDetailsDialog
                isOpen={selectedResourceId !== undefined}
                toggle={()=>{setSelectedResourceId(undefined)}}
                resourceId={selectedResourceId}
                reload={()=>{loadData();get_stats();}}
            /> 
            <UploadFileDialog
                isOpen={isUploadDialogOpen}
                toggle={()=>{setIsUploadDialogOpen(false)}}
                reload={()=>{loadData();get_stats();}}
            />
        </>
    );
}

const MediaRecordDetailsDialog = ({isOpen, toggle, resourceId, reload}) => {
    const [recordData, setRecordData] = useState(undefined);
    useEffect(()=>{
        if(resourceId === undefined) return;
        get_ajax("ExpertSSM/MediaLibrary/GetResourceDetails?resourceId="+resourceId, (data) => {
            setRecordData(data);
            console.log(data);
        })
    }, [resourceId]);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    return (
        <>
            <Modal isOpen={isOpen && !isDeleteDialogOpen} toggle={toggle} size='lg'>
                <ModalHeader>Detalii</ModalHeader>
                <ModalBody>
                    {isOpen && (
                        <>
                            {recordData === undefined && (<LoadingCircleComponent/>)}
                                {recordData !== undefined && (
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <div style={{minHeight: '15vh'}}>
                                                <MediaPreviewIcon
                                                    size={52} 
                                                    style={{margin: 'auto', display: 'block', marginTop: '40px'}}
                                                    blobName={recordData.blobName}
                                                    fileName={recordData.originalFileName}
                                                    preview_style={{maxWidth: '100%', maxHeight: '100%', display: 'block', margin: 'auto'}}
                                                    has_video_controls={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='maxHeight top_bottom'>
                                                <label>
                                                    {recordData.originalFileName}
                                                    <br/>
                                                    <small>{formatBytes(recordData.fileSize)}</small>
                                                    <br/><br/>
                                                    {recordData.uploadedBy}
                                                    <br/>
                                                    <small>{recordData.uploadedAt}</small>
                                                </label>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <RoundButton
                                                            className="btn-danger"
                                                            text="Sterge"
                                                            onClickHandler={()=>{setIsDeleteDialogOpen(true)}}
                                                        />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <CdnResourceDownloadBtn
                                                            className="custom-btn-primary"
                                                            text="Descarca"
                                                            blobName={recordData.blobName}
                                                            originalName={recordData.originalFileName}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                </ModalBody>
            </Modal>
            <DeleteConfirmationDialog
                isOpen={isDeleteDialogOpen}
                toggle={()=>{setIsDeleteDialogOpen(false)}}
                text="Sunteti sigur ca doriti sa continuati? Aceasta resursa nu va mai fi disponibila in niciun material din platforma."
                onConfirm={()=>{
                    post('ExpertSSM/MediaLibrary/RemoveResource', 
                        {"resourceId": resourceId}, (result)=> {
                            parseTrueOrErrorMessage(result, (result) => {
                                toggle();
                                reload();
                            });
                    });
                }}    
            />
        </>
    );
}

const UploadFileDialog = ({isOpen, toggle, reload}) => {
    const [file,setFile] = useState(undefined);

    const onDropHandler = (ev) => {
        ev.preventDefault();
        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    setFile(file);
                    break;
                }
            }
        } else {
            if(ev.dataTransfer.files.length > 0)
                setFile(ev.dataTransfer.files[0]);            
        }
    }

    const uploadFile = async () => {
        var data = new FormData();
        data.encoding = "multipart/form-data";
        data.append("file", file)
        var request = new XMLHttpRequest();

        request.upload.addEventListener('progress', function (e) {
            setUploadFileProgress(e.loaded);
            setUploadFileProgressTotal(e.total);
            if (e.total === e.loaded) {
                setTimeout(()=>{
                    reload();
                    toggle();         
                    setFile(undefined);
                    setUploadFileProgress(0);
                    setUploadFileProgressTotal(0);
                }, 1000);
            }
        }.bind(this), false);

        request.open('POST', 'ExpertSSM/MediaLibrary/UploadNewResource');
        const token = getAccessToken();
        request.setRequestHeader('Authorization', token);
        request.send(data);
    }
    const [uploadFileProgress, setUploadFileProgress] = useState(0);
    const [uploadFileProgressTotal, setUploadFileProgressTotal] = useState(0);
    let perc = ((uploadFileProgress * 100) / uploadFileProgressTotal).toFixed(2);

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalHeader>Incarca fisier</ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-md-7'>
                        <div 
                            className='drop_zone'
                            onDrop={onDropHandler}
                            onDragOver={(ev)=>{ ev.preventDefault();}}
                        >
                            <p style={{marginBottom: '0'}}>
                                {file === undefined ? "Trage fisierele aici" : file.name}
                            </p>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'></div>
                            <div className='col-md-4'>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={'Alege fisier'}
                                    onClickHandler={()=>{document.getElementById("add_resource_input").click();}}
                                />
                            </div>
                        </div>
                        <input type="file" id="add_resource_input" hidden onChange={(e) => {
                            if(e.target.files.length > 0)
                                setFile(e.target.files[0])
                        }}/>
                    </div>
                    <div className='col-md-5'>
                        <RoundButton
                            disabled={file === undefined}
                            className={"custom-btn-primary"}
                            text={'Incarca fisier'}
                            onClickHandler={uploadFile}
                        />
                        {uploadFileProgressTotal != 0 && (
                            <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                                <br />
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={perc}
                                        aria-valuemin="0" aria-valuemax="100" style={{ width: perc + "%" }}>
                                        {perc + "%"}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={toggle}>Anuleaza</Button>                
            </ModalFooter>
        </Modal>
    )
}