import React, {useEffect, useState} from 'react';
import { Link, Redirect } from 'react-router-dom';
import {FormGroup} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {SearchBox} from 'shared/dist/components/generic-building-blocks/SearchBox';
import {RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { get_ajax, post, parseTrueOrErrorMessage } from 'shared/dist/utils/DataProvider';
import { EmployeeNew_FactoriRisc } from './Workers';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {DeleteConfirmationDialog, UpdateResultSuccessDialog, CreateResourceDialog, EditResourceDialog, CustomResourceModal} from 'shared/dist/components/generic-building-blocks/UserDialogs';

import AOS from 'aos';
import "aos/dist/aos.css";
import { FisaFactoriRisc } from './shared/MedicalControlRequestRender';
import { default_risks_macheta } from './defaultOptions';
import {ToggleViewSelector} from 'shared/dist/components/generic-building-blocks/ToggleViewSelector';

import {BsFillGrid3X2GapFill, BsFillFolderFill} from 'react-icons/bs';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function isEmptyOrSpaces(str){
    try { return str === null || str.match(/^ *$/) !== null; }
    catch { return true; }
}

export const ConfiguratorMachete = () => {

    const [riskPresetsLoaded, setRiskPresetsLoaded] = useState(false);
    const [riskPresets, setRiskPresets] = useState([]);

    const [riskStatistics, setRiskStatistics] = useState(undefined);

    useEffect(()=>{   
        AOS.init();             
        loadMachete(-1);
        loadFolders(-1);
        get_ajax('EmployeeManagement/RiskPresets/GetRiskStatistics', (response) => {
            setRiskStatistics(response);
        });         
    }, []);

    const loadMachete = (type, text, page) =>{        
        get_ajax('EmployeeManagement/RiskPresets/GetRiskPresets?type='+type + "&search=" + text + "&page=" + page, (response)=> {
            setRiskPresets(response.objects);
            setPageSize(response.objects_per_page);
            setRiskPresetsLoaded(true);                        
        });
    }

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(0);

    useEffect(()=>{
        setSearchBoxValue('');
        loadMachete(-1, '', currentPage);
        loadFolders(-1, '', currentPage);
    }, [currentPage]);

    const [useGridView, setUseGridView] = useState(localStorage.getItem("useGridView") === 'true' ? false : true);
    useEffect(()=>{
        localStorage.setItem("useGridView", useGridView ? "false" : "true");
        loadFolders(-1);
    }, [useGridView]);
    const loadFolders = (type, text, page) => {
        if(useGridView === true) return;
        get_ajax('EmployeeManagement/RiskPresets/GetRiskPresetsFolders?type='+type + "&search=" + text + "&page=" + page, (response)=> {
            setMacheteFolders(response.objects);
        });
    }
    const [macheteFolders, setMacheteFolders] = useState([]);
    const [selectedMacheteFolder, setSelectedMacheteFolder] = useState(undefined);
    return(
        <>
            <br/>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm noselect">
            <div className='row'>
                <div className='col-md-9'>
                    <h4>Machete</h4>
                </div>
                <div className='col-md-3'>
                    <div className='row'>
                        <div className='col-md-6'></div>
                        <div className='col-md-6'>
                            <ToggleViewSelector
                                icon1={<BsFillGrid3X2GapFill/>}
                                icon2={<BsFillFolderFill/>}
                                firstOptionActive={useGridView}
                                optionChanged={(active)=>{ setUseGridView(active); }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-6">
                    <SearchBox 
                        placeholder="Cauta"
                        value={searchBoxValue}
                        onChange={(e)=>{ loadMachete(-1, e.target.value); loadFolders(-1, e.target.value); setSearchBoxValue(e.target.value); }}
                    />
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <RoundRedirectButton 
                        className="custom-btn-primary"
                        text="Macheta noua"
                        redirectUrl="/settings/medical-control/configure_machete/new"
                    />
                </div>
            </div>
            </LayoutCard>                
            <div className="row">
                <div className="col-md-8">
                    {useGridView && (
                        <>
                            {riskPresetsLoaded && (
                                <>
                                    <div className="row">
                                        {riskPresets.map((e, i) =>
                                            <div className="col-md-4" key={i}>
                                                <Link to = {"/settings/medical-control/configure_machete/details/" + e.id}>
                                                    <LayoutCard
                                                        className={e.type == "Compartiment" ? "layout_card2" : e.type == "Loc de munca" ? "layout_card3" : "custom-btn-primary"}
                                                    >
                                                        <div style={{textAlign: 'center'}}>
                                                            <br/>
                                                            <h6>{e.displayName}</h6>
                                                            <label style={{display: 'block'}}>Macheta riscuri</label>                                            
                                                            <label>{e.type}</label>
                                                            <br/><br/> 
                                                        </div>
                                                    </LayoutCard>
                                                </Link>                                    
                                            </div>
                                        )}
                                    </div>
                                    {riskPresets.length == 0 && (
                                        <><br/><p style={{textAlign: 'center'}}>Nu exista machete.</p></>
                                    )}
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <button 
                                                disabled={currentPage <= 0} 
                                                className="page-link btn no-outline" 
                                                onClick={()=>{setCurrentPage(currentPage-1)}}
                                                style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                            >
                                                Inapoi
                                            </button>
                                        </li>
                                        <li className="page-item">
                                            <button 
                                                className="page-link btn no-outline"
                                                style={{cursor: "auto", borderRadius: 0}}
                                            >
                                                {currentPage+1}
                                            </button>
                                        </li>
                                        <li className="page-item">
                                            <button 
                                                disabled={riskPresets.length < pageSize} 
                                                className="page-link btn no-outline" 
                                                onClick={()=>{setCurrentPage(currentPage+1)}}
                                                style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                            >
                                                Inainte
                                            </button>
                                        </li>
                                    </ul>
                                </>
                            )}    
                            {!riskPresetsLoaded && (
                                <>
                                    <br/>
                                    <LoadingCircleComponent/>
                                </>
                            )}  
                        </>
                    )}   
                    {!useGridView && (
                        <>
                            <div className="row">
                                {macheteFolders.map((e, i) =>
                                    <div className="col-md-4" key={i}>
                                        {/* <Link to = {"/settings/medical-control/configure_machete/details/" + e.id}> */}
                                            <LayoutCard
                                                onClick={()=>{
                                                    setSelectedMacheteFolder(e.key);
                                                }}
                                                className={e.type == "Compartiment" ? "layout_card2" : e.type == "Loc de munca" ? "layout_card3" : "custom-btn-primary scale_svg"}
                                            >
                                                <div style={{textAlign: 'center'}}>
                                                    <br/>
                                                    <BsFillFolderFill
                                                        size={24}
                                                    />
                                                    <br/><br/> 
                                                    <h6>{e.key}</h6>
                                                    <small>{e.value} machete</small>
                                                    <br/>
                                                </div>
                                            </LayoutCard>
                                        {/* </Link>                                     */}
                                        </div>
                                )}
                            </div>
                            {riskPresets.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista machete.</p></>
                            )}
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={riskPresets.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}               
                </div>
                <div className="col-md-4">
                    <h5>Statistica machete</h5>
                    <br/>
                    {riskStatistics != undefined && (
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    data-aos="flip-up"
                                >
                                    <LayoutCard
                                        className="layout_card1 noselect"
                                        onClick={()=>{loadMachete(-1); loadFolders(-1); }}
                                    >                            
                                        <label>Total</label>
                                        <br/>
                                        <h4 style={{marginBottom: '0', display: 'inline'}}>
                                            {riskStatistics.compartimentCount+riskStatistics.locMuncaCount+riskStatistics.compartimentLocMuncaCount}
                                        </h4>                                               
                                    </LayoutCard>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    data-aos="flip-up"
                                >
                                    <LayoutCard
                                        className="layout_card2 noselect"
                                        onClick={()=>{loadMachete(0); loadFolders(0)}}
                                    >                            
                                        <label>Compartimente</label>
                                        <br/>
                                        <h4 style={{marginBottom: '0', display: 'inline'}}>
                                            {riskStatistics.compartimentCount}
                                        </h4>   
                                        <p style={{display: 'inline'}}>/ {riskStatistics.compartimentTotal}</p>  
                                    </LayoutCard>
                                </div>                                
                            </div>
                            <div className="col-md-6">
                                <div
                                    data-aos="flip-up"
                                    data-aos-delay="50"
                                >                                    
                                    <LayoutCard
                                        className="layout_card3 noselect"
                                        onClick={()=>{loadMachete(1); loadFolders(1)}}
                                    >                            
                                        <label>Locuri de munca</label>
                                        <br/>
                                        <h4 style={{marginBottom: '0', display: 'inline'}}>
                                            {riskStatistics.locMuncaCount}
                                        </h4>     
                                        <p style={{display: 'inline'}}>/ {riskStatistics.locMuncaTotal}</p>
                                    </LayoutCard>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div
                                    data-aos="flip-up"
                                >
                                    <LayoutCard
                                        className="custom-btn-primary noselect"
                                        onClick={()=>{loadMachete(2); loadFolders(2);}}
                                    >                            
                                        <label>Compartiment si loc de munca</label>
                                        <br/>
                                        <h4 style={{marginBottom: '0', display: 'inline'}}>
                                            {riskStatistics.compartimentLocMuncaCount}
                                        </h4>   
                                    </LayoutCard>
                                </div>                                
                            </div>
                        </div>
                    )}
                </div>
            </div>   
            <MacheteFolderViewer
                toggle={()=>{setSelectedMacheteFolder(undefined)}}
                folder_filter={selectedMacheteFolder}
            />         
        </>
    );
}

const MacheteFolderViewer = ({toggle, folder_filter}) => { 
    useEffect(()=>{
        if(folder_filter === undefined) return;        
        loadMachete(-1);
    }, [folder_filter]);

    const [riskPresetsLoaded, setRiskPresetsLoaded] = useState(false);
    const [riskPresets, setRiskPresets] = useState([]);

    const loadMachete = (type, text, page) =>{  
        if(folder_filter === undefined) return;              
        get_ajax('EmployeeManagement/RiskPresets/GetRiskPresets?type='+type + "&search=" + text + "&folder_search="+folder_filter+"&page=" + page, (response)=> {
            setRiskPresets(response.objects);
            setPageSize(response.objects_per_page);
            setRiskPresetsLoaded(true);                        
        });
    }

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(0);

    useEffect(()=>{
        if(folder_filter === undefined) return;        
        setSearchBoxValue('');
        loadMachete(-1, '', currentPage);
    }, [currentPage]);

    return(
        <Modal isOpen={folder_filter !== undefined} toggle={toggle} size={'xl'}>
            <ModalHeader>{`Machete - ${folder_filter !== undefined ? folder_filter : '...'}`}</ModalHeader>
            <ModalBody>
                <SearchBox 
                    placeholder="Cauta"
                    value={searchBoxValue}
                    onChange={(e)=>{ loadMachete(-1, e.target.value); setSearchBoxValue(e.target.value); }}
                />
                <br/>
                {riskPresetsLoaded && (
                    <>
                        <div className="row">
                            {riskPresets.map((e, i) =>
                                <div className="col-md-4" key={i}>
                                    <Link to = {"/settings/medical-control/configure_machete/details/" + e.id}>
                                        <LayoutCard
                                            className={e.type == "Compartiment" ? "layout_card2" : e.type == "Loc de munca" ? "layout_card3" : "custom-btn-primary"}
                                        >
                                            <div style={{textAlign: 'center'}}>
                                                <br/>
                                                <h6>{e.displayName}</h6>
                                                <label style={{display: 'block'}}>Macheta riscuri</label>                                            
                                                <label>{e.type}</label>
                                                <br/><br/> 
                                            </div>
                                        </LayoutCard>
                                    </Link>                                    
                                </div>
                            )}
                        </div>
                            {riskPresets.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista machete.</p></>
                            )}
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={riskPresets.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}    
                    {!riskPresetsLoaded && (
                    <>
                        <br/>
                        <LoadingCircleComponent/>
                    </>
                )}  
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={toggle}>Inchide</Button>                
            </ModalFooter>
        </Modal>
    );
}

export const ConfiguratorMacheteNew = () => {    
    const [selectedMachetaType, setSelectedMachetaType] = useState(0);
    const [selectedLocDeMunca, setSelectedLocDeMunca] = useState('');
    const [selectedCompartiment, setSelectedCompartiment] = useState(-1);

    const [compartimenteOptions, setCompartimenteOptions] = useState([]);

    useEffect(()=>{
        if(selectedMachetaType != 0) return;
        get_ajax("EmployeeManagement/Employees/GetCompartimente", (response) => {
            setCompartimenteOptions(response);
            setSelectedCompartiment(response[0].id);
        });        
    }, [selectedMachetaType]);    

    const [fisaRiscuriData, setFisaRiscuriData] = useState(default_risks_macheta);    
    const updatefisaRiscuriData = (field, newValue) =>{
        const _newDataCpy = fisaRiscuriData;
        _newDataCpy[field] = newValue;
        setFisaRiscuriData(_newDataCpy);        
    }

    const [displayRiskConfig, setDisplayRiskConfig] = useState(undefined);

    //Verificare compartiment
    useEffect(()=>{        
        if(selectedMachetaType !== 0 && selectedMachetaType !== '0') return;          
        post("EmployeeManagement/RiskPresets/IsCompartimentRiskPresetConfigured", {
            'compartimentId': selectedCompartiment
        }, (response) => {
            if(response == false){
                setDisplayRiskConfig(true);
            }
            else{
                setDisplayRiskConfig("Compartimentul selectat este deja configurat");
            }
        });
    }, [selectedCompartiment, selectedMachetaType]);

    //Verificare loc munca
    useEffect(()=>{        
        if(selectedMachetaType !== 1 && selectedMachetaType !== '1') return;     
        if(isEmptyOrSpaces(selectedLocDeMunca)) {setDisplayRiskConfig(undefined); return;}        
        post("EmployeeManagement/RiskPresets/IsLocDeMuncaRiskPresetConfigured", {
            'locMunca': selectedLocDeMunca
        }, (response) => {
            if(response == false){
                setDisplayRiskConfig(true);
            }
            else{
                setDisplayRiskConfig("Locul de munca selectat este deja configurat");
            }
        });        
    }, [selectedLocDeMunca, selectedMachetaType]);

    //Verificare compartiment & loc munca combo
    useEffect(()=>{
        if(selectedMachetaType !== 2 && selectedMachetaType !== '2') return;       
        if(isEmptyOrSpaces(selectedLocDeMunca)) {setDisplayRiskConfig(undefined); return;}         
        post("EmployeeManagement/RiskPresets/IsCompartimentLocMuncaPresetConfigured", {
            'compartimentId': selectedCompartiment,
            'locMunca': selectedLocDeMunca
        }, (response) => {
            if(response == false){
                setDisplayRiskConfig(true);
            }
            else{
                setDisplayRiskConfig("Compartimentul selectat este deja configurat");
            }
        });
    }, [selectedLocDeMunca, selectedCompartiment, selectedMachetaType])

    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

    return(
        <>
            <h4><Link className="redirectNavigationLink" to='/settings/medical-control/configure_machete'>Machete</Link> &gt; Macheta noua</h4>
            <br/>
            <h5>Selectie tip macheta</h5>
            <br/>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Tip</label>
                        <select 
                            className="form-control no-outline rounded_input" 
                            value={selectedMachetaType}
                            onChange={(e)=>{setSelectedMachetaType(e.target.value);}}
                        >
                            <option value={0}>Compartiment</option>
                            <option value={1}>Loc de munca</option>
                            <option value={2}>Compartiment si loc de munca</option>
                        </select>
                    </div>
                    {selectedMachetaType == 0 && (
                        <div className="form-group">
                            <label>Compartiment</label>
                            <select 
                                className="form-control no-outline rounded_input"
                                value={selectedCompartiment}
                                onChange={(e)=>{setSelectedCompartiment(e.target.value);}}
                            >
                                {compartimenteOptions.map((s, i) =>
                                    <option value={s.id} key={i}>{s.nume}</option>
                                )}
                            </select>
                        </div>
                    )}               
                    {selectedMachetaType == 1 && (
                        <FormGroup 
                            label={'Denumirea postului si a locului de munca'}
                            value={selectedLocDeMunca}      
                            onChange={(e) =>{ 
                                if(e.target.value.includes('#')) return;
                                setSelectedLocDeMunca(e.target.value); 
                            }}                                      
                        />
                    )}    
                    {selectedMachetaType == 2 && (
                        <>
                            <div className="form-group">
                                <label>Compartiment</label>
                                <select 
                                    className="form-control no-outline rounded_input"
                                    value={selectedCompartiment}
                                    onChange={(e)=>{setSelectedCompartiment(e.target.value);}}
                                >
                                    {compartimenteOptions.map((s, i) =>
                                        <option value={s.id} key={i}>{s.nume}</option>
                                    )}
                                </select>
                            </div>
                            <FormGroup 
                                label={'Denumirea postului si a locului de munca'}
                                value={selectedLocDeMunca}      
                                onChange={(e) =>{ setSelectedLocDeMunca(e.target.value); }}                                      
                            />
                        </>
                    )} 
                    <br/>
                    {displayRiskConfig == true && (
                        <>
                            <EmployeeNew_FactoriRisc
                                hide_save_as_macheta={true}
                                configMode={true}
                                newUserData={fisaRiscuriData}
                                updateNewUserData={updatefisaRiscuriData}                        
                            />
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <RoundButton 
                                        className="custom-btn-primary"
                                        text="Salveaza"                        
                                        onClickHandler={()=>{      
                                            if(selectedMachetaType != 0 && isEmptyOrSpaces(selectedLocDeMunca))
                                            {
                                                alert("Completati un loc de munca");
                                                return;
                                            }                                      
                                            post("EmployeeManagement/RiskPresets/AddNewRiskPreset", {
                                                'type': selectedMachetaType, 
                                                'targetObjectId': selectedMachetaType == 0 ? selectedCompartiment : selectedMachetaType == 1 ? selectedLocDeMunca : `${selectedCompartiment}#${selectedLocDeMunca}`, 
                                                'presetJSON': JSON.stringify(fisaRiscuriData)
                                            }, (response) => {
                                                parseTrueOrErrorMessage(response, (response)=>{
                                                    window.location.href="/settings/medical-control/configure_machete";
                                                });                                                
                                            });
                                        }}
                                    />
                                </div>
                            </div>                            
                        </>
                    )}
                    {(displayRiskConfig != true && displayRiskConfig != undefined) && (
                        <p>{displayRiskConfig}</p>
                    )}            
                    <br/>  
                    {savedSuccessfully && (
                        <Redirect to="/settings/medical-control/configure_machete"/>
                    )}      
                </div>
                {displayRiskConfig && (
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='col-md-6'></div>
                            <div className='col-md-6'>
                                <RoundButton 
                                    className="custom-btn-primary"
                                    text="Previzualizeaza"                        
                                    onClickHandler={()=>{ setIsPreviewDialogOpen(true); }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <CustomResourceModal
                size={'xl'}
                isOpen={isPreviewDialogOpen}
                toggle={() => setIsPreviewDialogOpen(false)}
                title={"Previzualizeaza"}
            >
                <FisaFactoriRisc
                    employeeData={{'unitate': ''}}
                    medicalRequest={undefined}
                    risksFile={fisaRiscuriData}
                    medicalRequestDate={''}
                    risksFileCreatedBy={''}
                    risksFileSignature={undefined}
                    medicalRequestSignature={undefined}
                />  
            </CustomResourceModal>
        </>
    );
}

export const ConfiguratorMachetaDetails = (props) =>{

    const [isLoaded, setIsLoaded] = useState(false);

    const [machetaDisplayName, setMachetaDisplayName] = useState('...');
    const [selectedMachetaType, setSelectedMachetaType] = useState(0);
    const [selectedLocDeMunca, setSelectedLocDeMunca] = useState('');
    const [selectedCompartiment, setSelectedCompartiment] = useState(-1);

    const [compartimenteOptions, setCompartimenteOptions] = useState([]);

    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

    useEffect(()=>{
        loadData();
    }, []);

    const loadData =  () =>{
        if (props.match.params.macheta_id != undefined) {
            get_ajax("EmployeeManagement/Employees/GetCompartimente?all=true", (response) => {
                setCompartimenteOptions(response);                                   
                
            }); 

            get_ajax('EmployeeManagement/RiskPresets/GetRiskPreset?id=' + props.match.params.macheta_id, (response)=> {
                setMachetaDisplayName(response.displayName);
                setSelectedMachetaType(response.presetType);
                if(response.presetType == 2)
                {
                    setSelectedLocDeMunca(response.targetObjectLocMunca);
                    setSelectedCompartiment(response.targetObjectIdCompartiment);
                }
                else
                {
                    setSelectedLocDeMunca(response.targetObjectId);
                    setSelectedCompartiment(response.targetObjectId);
                }
                setFisaRiscuriData(JSON.parse(response.presetJSON));
                setIsLoaded(true);
            });
        }
    }

    const [fisaRiscuriData, setFisaRiscuriData] = useState({});    
    const updatefisaRiscuriData = (field, newValue) =>{
        const _newDataCpy = fisaRiscuriData;
        _newDataCpy[field] = newValue;
        setFisaRiscuriData(_newDataCpy);        
    }    

    const [savedSuccessfully, setSavedSuccessfully] = useState(false);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [redirectToResource, setRedirectToResource] = useState('');

    return(
        <>
            <h4><Link className="redirectNavigationLink" to='/settings/medical-control/configure_machete'>Machete</Link> &gt; {machetaDisplayName}</h4>
            <br/>
            {isLoaded == false && (
                <>
                    <br/>
                    <LoadingCircleComponent/>
                </>
            )}
            {isLoaded && (
                <>
                    <h5>Selectie tip macheta</h5>
                    <br/>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Tip</label>
                                <select 
                                    disabled={true}
                                    className="form-control no-outline rounded_input" 
                                    value={selectedMachetaType}
                                    onChange={(e)=>{setSelectedMachetaType(e.target.value);}}
                                >
                                    <option value={0}>Compartiment</option>
                                    <option value={1}>Loc de munca</option>
                                    <option value={2}>Compartiment si loc de munca</option>
                                </select>
                            </div>
                            {selectedMachetaType == 0 && (
                                <div className="form-group">
                                    <label>Compartiment</label>
                                    <select 
                                        disabled={true}
                                        className="form-control no-outline rounded_input"
                                        value={selectedCompartiment}
                                        onChange={(e)=>{setSelectedCompartiment(e.target.value);}}
                                    >
                                        {compartimenteOptions.map((s, i) =>
                                            <option value={s.id} key={i}>{s.nume}</option>
                                        )}
                                    </select>
                                </div>
                            )}               
                            {selectedMachetaType == 1 && (
                                <FormGroup 
                                    label={'Denumirea postului si a locului de munca'}
                                    value={selectedLocDeMunca}      
                                    disabled={true}
                                    onChange={(e) =>{ setSelectedLocDeMunca(e.target.value); }}                                      
                                />
                            )}   
                            {selectedMachetaType == 2 && (
                                <>
                                    <div className="form-group">
                                        <label>Compartiment</label>
                                        <select 
                                            disabled={true}
                                            className="form-control no-outline rounded_input"
                                            value={selectedCompartiment}
                                            onChange={(e)=>{setSelectedCompartiment(e.target.value);}}
                                        >
                                            {compartimenteOptions.map((s, i) =>
                                                <option value={s.id} key={i}>{s.nume}</option>
                                            )}
                                        </select>
                                    </div>
                                    <FormGroup 
                                        label={'Denumirea postului si a locului de munca'}
                                        value={selectedLocDeMunca}      
                                        disabled={true}
                                        onChange={(e) =>{ setSelectedLocDeMunca(e.target.value); }}                                      
                                    />
                                </>
                            )}  
                            <br/>
                            <>
                                <EmployeeNew_FactoriRisc
                                    hide_save_as_macheta={true}
                                    configMode={true}
                                    newUserData={fisaRiscuriData}
                                    updateNewUserData={updatefisaRiscuriData}                        
                                />
                                <div className="row">
                                    <div className="col-md-6">
                                        <RoundButton                                        
                                            className={"btn-danger"}
                                            text="Sterge"
                                            onClickHandler={()=>{setIsDeleteDialogOpen(true);}}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <RoundButton 
                                            className="custom-btn-primary"
                                            text="Salveaza"                        
                                            onClickHandler={()=>{                                            
                                                post("EmployeeManagement/RiskPresets/UpdateRiskPreset", {
                                                    'id': props.match.params.macheta_id,
                                                    'presetJSON': JSON.stringify(fisaRiscuriData)
                                                }, (response) => {
                                                    parseTrueOrErrorMessage(response, (response) => {
                                                        setSavedSuccessfully(true);
                                                    });
                                                });
                                            }}
                                        />
                                    </div>
                                </div>                            
                            </>          
                            <br/>                              
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'></div>
                                <div className='col-md-6'>
                                    <RoundButton 
                                        className="custom-btn-primary"
                                        text="Previzualizeaza"                        
                                        onClickHandler={()=>{ setIsPreviewDialogOpen(true); }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <>                
                <DeleteConfirmationDialog
                    isOpen={isDeleteDialogOpen}
                    toggle={()=>{setIsDeleteDialogOpen(!isDeleteDialogOpen)}}
                    onConfirm={()=>{
                        post('EmployeeManagement/RiskPresets/DeleteRiskPreset', {
                            "id": props.match.params.macheta_id
                        }, (response)=>{
                            parseTrueOrErrorMessage(response, (response) => {
                                setRedirectToResource("/settings/medical-control/configure_machete");
                            });
                        });
                    }}
                />
                <UpdateResultSuccessDialog
                    isOpen={savedSuccessfully}
                    toggle={()=>{setSavedSuccessfully(!savedSuccessfully)}}
                    onConfirm={loadData}
                />
                {redirectToResource != '' && (
                    <Redirect to={redirectToResource}/>
                )}
                <CustomResourceModal
                    size={'xl'}
                    isOpen={isPreviewDialogOpen}
                    toggle={() => setIsPreviewDialogOpen(false)}
                    title={"Previzualizeaza"}
                >
                    <FisaFactoriRisc
                        employeeData={{'unitate': ''}}
                        medicalRequest={undefined}
                        risksFile={fisaRiscuriData}
                        medicalRequestDate={''}
                        risksFileCreatedBy={''}
                        risksFileSignature={undefined}
                        medicalRequestSignature={undefined}
                    />  
                </CustomResourceModal>
            </>
        </>
    );
}
