import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { get_ajax, post, parseTrueOrErrorMessage } from 'shared/dist/utils/DataProvider';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import {FaUser}from 'react-icons/fa';
import {FiEdit2} from 'react-icons/fi';
import {Link, Redirect} from 'react-router-dom';
import { RoundButton, RoundRedirectButton } from 'shared/dist/components/generic-building-blocks/RoundButton';
import { Checked, FisaFactoriRisc, FisaSolicitare, UnChecked } from '../medical-control/shared/MedicalControlRequestRender';
import { Checkbox } from 'shared/dist/components/generic-building-blocks/Checkbox';
import { FormGroup, FormGroupArea } from 'shared/dist/components/generic-building-blocks/FormGroup';
import { DynamicTable } from 'shared/dist/components/generic-building-blocks/DynamicTable';
import { YesNoDetails, YesNoDetailsArea } from 'shared/dist/components/generic-building-blocks/YesNoDetails';
import { ConfirmationDialog, CustomResourceModal, TempConfirmationDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import { ExamenMedicalRenderer } from './MedicalFolder';
import { NullOrUndefined } from 'shared/dist/utils/Utils';
import { printPage } from 'shared/dist/utils/PdfBuilder';
import {Modal, ModalBody, ModalFooter, ModalHeader, Button} from 'reactstrap';
import { MedicalAptitudiniFilePreview, MedicalAptitudiniFileViewer, MedicalAptitudiniFileViewerManual } from '../medical-control/shared/MedicalAptitudiniFileViewer';

function get_today_date(){
    var today = new Date(); 
    var dd = today.getDate(); 
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear(); 
    return `${dd}-${mm}-${yyyy}`;
}

function calculate_age(dob) {     
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms); 
  
    return Math.abs(age_dt.getUTCFullYear() - 1970);
}
function parse_server_date(date_string){    
    const parts = date_string.split('-');
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
}

export const MedicalExamRequestsContainer = (props) =>{
    const [isLoading, setIsLoading] = useState(true);
    const [medicalExams, setMedicalExams] = useState([]);        

    const [clients, setClients] = useState([]);

    useEffect(()=>{
        AOS.init();  
    }, []);

    const [clientSearchBy, setClientSearchBy] = useState('');
    useEffect(()=>{
        get_ajax('MedicSSM/General/GetClients?searchBy=' + clientSearchBy, (response) => {
            setClients(response);            
        });
    }, [clientSearchBy]);

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(12);

    useEffect(()=>{
        setPageSize(12);
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const loadData = (searchBy, page) => {
        get_ajax("MedicSSM/MedicalExamRequest/GetMedicalExamRequests?searchBy=" + searchBy + "&page=" + page + "&angajatorId=" + selectedClientId, (response) =>{
            setMedicalExams(response);
            setIsLoading(false);
        });
    }    

    useEffect(()=>{
        if(props.match.params.selected_client_id !== undefined)
            setSelectedClientId(props.match.params.selected_client_id);
    }, [props.match.params.selected_client_id]);
    const [selectedClientId, setSelectedClientId] = useState(undefined);    
    const [exameneMedicaleTitleLabel, setExameneMedicaleTitleLabel]= useState('Cereri examen medical');
    
    useEffect(()=>{        
        if(selectedClientId == undefined || clients.length === 0)
            setExameneMedicaleTitleLabel("Cereri examen medical"); 
        else
            setExameneMedicaleTitleLabel(`Cereri examen medical ${clients.filter(c => c.id == selectedClientId)[0].nume}`);
        if(currentPage != 0) {setCurrentPage(0); return; }
        setSearchBoxValue(''); 
        loadData('', 0);
    }, [selectedClientId, clients]);

    return(
        <>
            <br/>
            <div className="row">
                <div className="col-md-8">
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>{exameneMedicaleTitleLabel}</h4>                                              
                        <br/>
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </LayoutCard>
                    {isLoading && (
                        <>
                            <br/>
                            <LoadingCircleComponent/>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            {medicalExams.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista cereri de examen medical.</p></>
                            )}   
                            {medicalExams.length != 0 && (
                                <div className="row">
                                    {medicalExams.map((e, i) => 
                                        <div className="col-md-4" key={i}>
                                            <Link to={`/medic/medical_exam_requests/details/${e.id}`}>                                            
                                                <LayoutCard                                        
                                                    className="layout_card1" key={i} 
                                                    onClick={()=>{

                                                    }}
                                                >
                                                    <div style={{textAlign: 'center'}}>                                                    
                                                        <FaUser size={56} className="avatar_container"/>
                                                        <h6>{`${e.nume} ${e.prenume}`}</h6>
                                                        <label>
                                                            {e.locMunca}
                                                        </label>
                                                        <br/>
                                                        <label>Adaugat la: <b>{e.createdAtStr}</b></label>                                          
                                                    </div>
                                                </LayoutCard>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            )}                                                                                            
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={medicalExams.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                <div className="col-md-4">
                    {clients.map((client, i) =>
                        <LayoutCard 
                            className={client.id == selectedClientId ? "layout_card1 layout_card_active shadow-sm" : "layout_card1 shadow-sm"}
                            key={i}
                            onClick={()=>{
                                if(selectedClientId != client.id)
                                    setSelectedClientId(client.id);
                                else
                                    setSelectedClientId(undefined);
                            }}
                        >
                            <h5>{client.nume}</h5>
                            <label style={{marginBottom: '0'}}>{client.adresa}</label>
                        </LayoutCard>
                    )}
                    {clients.length == 0 && (
                        <><br/><p style={{textAlign: 'center'}}>Nu exista clienti.</p></>
                    )}
                </div>
            </div>
        </>
    );
}

export const MedicalExamRequestsDetails = (props) => {
    const [medicalRequestId, setMedicalRequestId] = useState(undefined);
    const [signatureDetails, setSignatureDetails] = useState(undefined);

    useEffect(()=>{
        AOS.init();  
        get_ajax('MedicSSM/MedicalExamRequest/GetMedicSignatureDetails', (response) => {
            setSignatureDetails(response);
        })
    }, []);

    useEffect(()=>{
        if(props.match.params.exam_request_id != undefined)
            setMedicalRequestId(props.match.params.exam_request_id);
    }, [props.match.params.exam_request_id]);

    const [employeeId, setEmployeeId] = useState(undefined);

    useEffect(()=>{
        if(medicalRequestId === undefined) return;

        get_ajax("MedicSSM/MedicalExamRequest/GetMedicalRequestDetails?id="+medicalRequestId, (response) =>{                        
            setEmployeeData(JSON.parse(response.employeeData));
            setMedicalRequest(JSON.parse(response.medicalRequest));
            setRisksFile(JSON.parse(response.risksFile));
            setMedicalRequestDate(response.medicalRequestDate);
            setRisksFileCreatedBy(response.risksFileCreatedBy);
            setAngajatorId(response.angajatorId);
            setRisksFileSignature(response.risksFileSignature);
            setMedicalRequestSignature(response.medicalRequestSignature);
            setEmployeeId(response.employeeId);
        });       
        loadDosarMedical();   
        loadTempExamination();

    }, [medicalRequestId]);

    const loadDosarMedical = () =>{
        get_ajax("MedicSSM/MedicalExamRequest/GetDosarMedicalData?id="+medicalRequestId, (response) =>{                        
            if(response !== undefined && response != null)
                setDosarMedicalData(JSON.parse(response));            
        }); 
    }

    const loadTempExamination = () => {
        get_ajax("MedicSSM/MedicalExamRequest/LoadTempExamination?id="+medicalRequestId, (response) =>{                        
            setExamenMedicalData(JSON.parse(response));   
        });
    }

    const [angajatorId, setAngajatorId] = useState(undefined);
    useEffect(()=>{
        if(angajatorId === undefined) return;
        loadInvestigatiiDisponibile('');
    }, [angajatorId]);
    
    const [employeeData, setEmployeeData] = useState(undefined);
    const [medicalRequest, setMedicalRequest] = useState(undefined);
    const [risksFile, setRisksFile] = useState(undefined);
    const [medicalRequestDate, setMedicalRequestDate] = useState(undefined);
    const [risksFileCreatedBy, setRisksFileCreatedBy] = useState(undefined);   
    const [risksFileSignature, setRisksFileSignature] = useState(undefined);
    const [medicalRequestSignature, setMedicalRequestSignature] = useState(undefined);

    const [dosarMedicalData, setDosarMedicalData] = useState(undefined);

    const [investigatiiDisponibileIsLoading, setInvestigatiiDisponibileIsLoading] = useState(true);
    const [investigatiiDisponibile, setInvestigatiiDisponibile] = useState([]);
    const [investigatiiSearchBy, setInvestigatiiSearchBy] = useState('');    

    const [checkedInvestigations, setCheckedInvestigations] = useState([]);

    const loadInvestigatiiDisponibile = (searchBy) => {
        get_ajax("MedicSSM/Investigations/GetInvestigationsEmployeeConfiguredOnly?searchBy=" + searchBy + "&angajatorId=" + angajatorId, (response) =>{
            setInvestigatiiDisponibile(response);
            setInvestigatiiDisponibileIsLoading(false);
        });
    }

    const [examenMedicalData, setExamenMedicalData] = useState(false);  
    const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
    const [redirectToResource, setRedirectToResource] = useState(undefined);

    const [isHistoryDialogViewer, setIsHistoryDialogOpen] = useState(false);
    
    const [downloadFileModalInformation, setDownloadFileModalInformation] = useState(undefined);

    return(
        <>
            <div className='row'>
                <div className='col-md-3'>
                    <RoundRedirectButton 
                        className="btn-secondary"
                        text="Inapoi"
                        redirectUrl="/medic/medical_exam_requests"
                    />                        
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-4'>
                    <RoundButton 
                        className="custom-btn-side-menu" 
                        text="Istoric"
                        onClickHandler={() =>{setIsHistoryDialogOpen(true);}}
                    />                        
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-3'>
                    <RoundButton 
                        className="custom-btn-primary"
                        disabled={dosarMedicalData === undefined}
                        text="Finalizeaza"
                        onClickHandler={() =>{setConfirmationDialogVisible(true);}}
                    />                        
                </div>
            </div>     
            <br/>           
            <div className='row'>
                <div className='col-md-6'>                                                                
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Cerere examen medical</h4>                                              
                        <br/>
                        {((employeeData == undefined || medicalRequest == undefined || risksFile == undefined || medicalRequestDate == undefined) && (
                            <LoadingCircleComponent/>
                        ))}
                        {(!(employeeData == undefined || medicalRequest == undefined || risksFile == undefined || medicalRequestDate == undefined) && (
                            <FisaSolicitare
                                employeeData={employeeData}
                                medicalRequest={medicalRequest}
                                risksFile={risksFile}
                                medicalRequestDate={medicalRequestDate}
                                medicalRequestSignature={medicalRequestSignature}
                            />
                        ))}
                    </LayoutCard>    
                    
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Fisa riscuri</h4>                                              
                        <br/>
                        {((employeeData == undefined || medicalRequest == undefined || risksFile == undefined || medicalRequestDate == undefined || risksFileCreatedBy == undefined) && (
                            <LoadingCircleComponent/>
                        ))}
                        {(!(employeeData == undefined || medicalRequest == undefined || risksFile == undefined || medicalRequestDate == undefined || risksFileCreatedBy == undefined) && (
                            <FisaFactoriRisc
                                risksFileSignature={risksFileSignature}
                                employeeData={employeeData}
                                medicalRequest={medicalRequest}
                                risksFile={risksFile}
                                medicalRequestDate={medicalRequestDate}
                                risksFileCreatedBy={risksFileCreatedBy}
                                medicalRequestSignature={medicalRequestSignature}
                            />
                        ))}
                    </LayoutCard>                

                </div>
                <div className='col-md-6'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div className='row'>
                            <div className='col-md-8'>
                                <h4>Investigatii efectuate</h4>
                            </div>
                            <div className='col-md-4'>
                                <SearchBox                             
                                    placeholder="Cauta"   
                                    value={investigatiiSearchBy}                       
                                    onChange={(e)=>{loadInvestigatiiDisponibile(e.target.value); setInvestigatiiSearchBy(e.target.value)}}
                                />
                            </div>
                        </div>
                        <br/>                                                        
                        {investigatiiDisponibileIsLoading && (<LoadingCircleComponent/>)}
                        {!investigatiiDisponibileIsLoading && (
                            <>
                                {investigatiiDisponibile.length == 0 && (
                                    <><p style={{textAlign: 'center'}}>Nu exista investigatii.</p></>
                                )}
                                <div className='row'>
                                    {investigatiiDisponibile.map((e, i) => 
                                        <div className='col-md-6' key={i}>
                                            <Checkbox 
                                                checkLabel={e.investigationName}
                                                value={checkedInvestigations.includes(e.id)}
                                                setValue={(new_value) => {
                                                    const _checkedInvestigations = [...checkedInvestigations];
                                                    if(new_value === false)
                                                        _checkedInvestigations.splice(_checkedInvestigations.indexOf(e.id), 1);
                                                    else
                                                        _checkedInvestigations.push(e.id);
                                                    setCheckedInvestigations(_checkedInvestigations);
                                                }}
                                            />                                    
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </LayoutCard>
                                        
                    <DosarMedicalElement
                        employeeData={employeeData}
                        medicalRequest={medicalRequest}
                        dosarMedical={dosarMedicalData}
                        medicalRequestId={medicalRequestId}
                        triggerDosarReload={()=>{loadDosarMedical()}}
                        signatureDetails={signatureDetails}
                    />
                    
                    {dosarMedicalData !== undefined && examenMedicalData !== false && (
                        <ExamenMedical     
                            employeeData={employeeData}                   
                            medicalRequest={medicalRequest}    
                            signatureDetails={signatureDetails}   
                            examenMedicalData={examenMedicalData}   
                            setExamenMedicalData={setExamenMedicalData}                                  
                            risksFile={risksFile}
                        />
                    )}
                </div>
            </div>

            <div className='row'>
                <div className='col-md-3'></div>
                <div className='col-md-6'></div>
                <div className='col-md-3'>
                    <RoundButton 
                        className="custom-btn-primary"
                        disabled={dosarMedicalData === undefined}
                        text="Finalizeaza"
                        onClickHandler={() =>{setConfirmationDialogVisible(true);}}
                    />  
                    <br/>                      
                </div>
            </div>  

            <TempConfirmationDialog
                size='md'
                isOpen={confirmationDialogVisible}
                toggle={() =>{setConfirmationDialogVisible(false)}}
                onConfirm={()=>{    
                    if(examenMedicalData.dataExamenUrmator === undefined ||
                        examenMedicalData.dataExamenUrmator === null ||
                        examenMedicalData.dataExamenUrmator === '' )
                        {
                            alert("Alegeti data urmatorului examen");
                            return;
                        }    
                    if(examenMedicalData.numarFisaAptitudini === undefined ||
                        examenMedicalData.numarFisaAptitudini === null ||
                        examenMedicalData.numarFisaAptitudini === '')
                        {
                            alert("Completati numar de inregistrare");
                            return;
                        }    
                    if(checkedInvestigations.length === 0)
                    {
                        alert("Alegeti investigatiile efectuate");
                        return;
                    }
                    post('MedicSSM/MedicalExamRequest/CompleteMedicalRequest', 
                    {
                        id: medicalRequestId,
                        examResultJSON: JSON.stringify(examenMedicalData),
                        investigatiiJSON: JSON.stringify(checkedInvestigations)
                    }, (response)=>{
                        if(response == false){ alert("Eroare la salvare."); return;}
                        setConfirmationDialogVisible(false);
                        setDownloadFileModalInformation(response);
                    })                                        
                }}
                onConfirmTemp={()=>{
                    post("MedicSSM/MedicalExamRequest/StoreExamResultJSONTemp", {
                        "id": medicalRequestId,
                        "tempJSON": JSON.stringify(examenMedicalData)
                    }, (response)=>{
                        if(response == false){ alert("Eroare la salvare."); return;}
                        setConfirmationDialogVisible(false);
                    })
                }}
                label='Salveaza'
                text='Confirmati salvarea? Actiunea va fi inregistrata in istoricul angajatului si va solutiona cererea angajatorului. Actiunea nu este reversibila.'
            />
            {redirectToResource != undefined && (
                <Redirect to={redirectToResource}/>
            )}
            {employeeId !== undefined && (
                <MedicalExamHistoryViewer
                    isOpen={isHistoryDialogViewer}
                    toggle={() => { setIsHistoryDialogOpen(!isHistoryDialogViewer)}}
                    workerId={employeeId}
                />
            )}
            <DownloadFisaAptitudiniModal
                isOpen={downloadFileModalInformation !== undefined}
                id = {downloadFileModalInformation}
                toggle={()=>{setDownloadFileModalInformation(undefined); }}
            />
        </>        
    );
}

const DownloadFisaAptitudiniModal = ({isOpen, toggle, id}) => {
    const [redirectToResource, setRedirectToResource] = useState(undefined);
    const downloadFisaAptitudini = () => {
        printPage(`/Utils/PdfGenerator/FisaAptitudini?aptitudiniFileId=${id}`);
    }
    return (
        <>
        <Modal isOpen={isOpen} toggle={toggle} size={'md'}>
            <ModalHeader>Examen finalizat cu succes</ModalHeader>
            <ModalBody>
                <p><b>Examenul a fost finalizat cu succes!</b></p>
                <p>Pentru a descarca fisa de aptitudini apasati <a onClick={()=>{downloadFisaAptitudini();}}>aici</a></p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className="no-outline" onClick={() => { 
                    downloadFisaAptitudini();
                }}>Descarca fisa de aptitudini</Button>
                <Button color="primary" className="no-outline" onClick={() => { setRedirectToResource("/medic/medical_exam_requests"); toggle(); }}>Inapoi la solicitari</Button>
            </ModalFooter>
        </Modal>
        {redirectToResource != undefined && (
            <Redirect to={redirectToResource}/>
        )}
        </>
    )
}

const DosarMedicalElement = ({employeeData, medicalRequest, editMode, dosarMedical, medicalRequestId, triggerDosarReload, signatureDetails}) => {
    const [isEditMode, setIsEditMode] = useState(false);

    const [dosarMedicalData, setDosarMedicalData] = useState({});    

    useEffect(()=>{
        if(editMode === undefined) return;        
        setIsEditMode(editMode);
    }, [editMode]);
    
    useEffect(()=>{
        if(isEditMode === false)
            setDosarMedicalData(dosarMedical);
    }, [isEditMode]);

    useEffect(()=>{
        if(dosarMedical === undefined)
            return;
        setDosarMedicalData(dosarMedical);
    }, [dosarMedical]);

    const [isSaveConfirmationDialogOpen, setIsSaveConversationDialogOpen] = useState(false);

    return (
        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
            <div className='row'>
                <div className='col-md-7'>
                    <h4 style={{ display: 'inline' }}>Dosar medical</h4>
                </div>
                <div className='col-md-5'>
                    {!isEditMode && (
                        <div className="float-right">
                            <FiEdit2 onClick={()=>{setIsEditMode(true);}}/>                                
                        </div>
                    )}  
                    {isEditMode && (
                        <div className='row'>
                            <div className='col-md-6'>
                                <RoundButton
                                    className="btn-secondary"
                                    onClickHandler={()=>{ setIsEditMode(false); }}
                                    text="Anuleaza"
                                />
                            </div>
                            <div className='col-md-6'>
                                <RoundButton
                                    className="custom-btn-primary"
                                    onClickHandler={()=>{                                        
                                        setIsSaveConversationDialogOpen(true);
                                        //Confirmation dialog then send to server :)
                                        //Call loadDosarMedical pe componentul parinte
                                    }}
                                    text="Salveaza"
                                />
                            </div>
                        </div>
                    )}                                      
                </div>
            </div>
            <br />
            {(employeeData === undefined || medicalRequest === undefined || signatureDetails === undefined) && (
                <LoadingCircleComponent />
            )}
            {(employeeData !== undefined && medicalRequest !== undefined && signatureDetails !== undefined) && (
                <>
                    {!isEditMode && (
                        <div
                            data-aos="fade-up"
                        >
                            <DosarMedicalRender
                                employeeData={employeeData}
                                medicalRequest={medicalRequest}
                                dosarMedical={dosarMedicalData}
                                signatureDetails={signatureDetails}
                            />
                        </div>
                    )}
                    {isEditMode && (
                        <div
                            data-aos="fade-up"
                        >
                            <DosarMedicalEditor
                                employeeData={employeeData}
                                medicalRequest={medicalRequest}
                                dosarMedical={dosarMedicalData === undefined ? {} : dosarMedicalData}
                                updateDosarMedical={(newDosarMedical)=>{                                    
                                    setDosarMedicalData(newDosarMedical);
                                }}
                            />
                        </div>
                    )}
                </>
            )}
            <div className='row'>
                <div className='col-md-7'></div>
                <div className='col-md-5'>
                    {isEditMode && (
                        <div className='row'>
                            <div className='col-md-6'>
                                
                            </div>
                            <div className='col-md-6'>
                                <RoundButton
                                    className="custom-btn-primary"
                                    onClickHandler={()=>{                                        
                                        setIsSaveConversationDialogOpen(true);
                                        //Confirmation dialog then send to server :)
                                        //Call loadDosarMedical pe componentul parinte
                                    }}
                                    text="Salveaza"
                                />
                            </div>
                        </div>
                    )}                                      
                </div>
            </div>
            <ConfirmationDialog
                isOpen={isSaveConfirmationDialogOpen}
                toggle={() =>{setIsSaveConversationDialogOpen(false)}}
                onConfirm={()=>{
                    post('MedicSSM/MedicalExamRequest/UpdateDosarMedicalDataFromMedicalRequest', 
                    {
                        id: medicalRequestId,
                        json: JSON.stringify(dosarMedicalData)
                    }, (response)=>{
                        parseTrueOrErrorMessage(response, (response) => {
                            setIsEditMode(false);
                            triggerDosarReload();                        
                        });
                    })                                        
                }}
                label='Salveaza'
                text='Confirmati salvarea? Actiunea va fi inregistrata in istoricul angajatului.'
            />
        </LayoutCard>
    );
}

export const DosarMedicalRender = ({ employeeData, medicalRequest, dosarMedical, signatureDetails }) => {   
    if(dosarMedical === undefined){
        return (
            <p style={{textAlign: 'center'}}>Completeaza dosarul medical...</p>
        )
    }
    if(dosarMedical !== undefined) 
        return (
            <>
                {signatureDetails !== undefined && (
                    <div className='row'>
                        <div className='col-md-6'>
                            <label>Unitatea medicala: <b>{signatureDetails.numeUnitateMedicala}</b></label>
                        </div>
                        <div className='col-md-6'>
                            <label>
                                Adresa: <b>{signatureDetails.adresaUnitateMedicala}</b><br/>
                                Tel: <b>{signatureDetails.telefonUnitateMedicala}</b><br/>
                            </label>
                        </div>
                    </div>
                )}            
                <hr/>
                <h5 style={{ textAlign: 'center' }}>DOSAR MEDICAL Nr. <b>{dosarMedical.numarDosarMedical}</b></h5>
                <br />
                <p>Numele <b>{employeeData.nume}</b>, prenumele <b>{employeeData.prenume}</b><br />
                    Sex: M {parseInt(employeeData.cnp[0]) % 2 == 1 ? <Checked /> : <UnChecked />} F {parseInt(employeeData.cnp[0]) % 2 == 0 ? <Checked /> : <UnChecked />} &nbsp;
                    varsta <b>{calculate_age(new Date(parse_server_date(employeeData.data_nasterii)))} ani</b>, data nasterii: <b>{employeeData.data_nasterii}</b><br />
                    CNP <b>{employeeData.cnp}</b><br />
                    Adresa: <b>{employeeData.adresa}</b><br />
                    Ocupatia/Functia: <b>{medicalRequest.solicitare_functie}</b><br />
                    Formarea profesionala: <b>{dosarMedical.formareProfesionala}</b><br />
                    Ruta profesionala<br />
                </p>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Postul si locul de munca</th>
                            <th scope="col">Perioada</th>
                            <th scope="col">Ocupatia/Functia</th>
                            <th scope="col">Noxe</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dosarMedical.profesionalRouteData !== undefined && (
                            <>
                                {dosarMedical.profesionalRouteData.map((row, i) =>
                                    <tr key={i}>
                                        {row.columns.map((col, j) =>
                                        <td key={j}>{col}</td>
                                        )}                        
                                    </tr>
                                )}                    
                            </>
                        )}
                        
                    </tbody>
                </table>
                <p>Activitati indeplinite la actualul loc de munca: <br/> <b style={{whiteSpace: 'pre-wrap'}}>{dosarMedical.activitatiLocActualDeMunca}</b></p>
                <p>
                    Boli profesionale da {NullOrUndefined(dosarMedical.boliProfesionale) ? <UnChecked/> : <Checked/>} nu {NullOrUndefined(dosarMedical.boliProfesionale) ? <Checked/> : <UnChecked/>} <br/> <b style={{whiteSpace: 'pre-wrap'}}>{dosarMedical.boliProfesionale}</b> <br /><br/>
                    Accidente de munca da {NullOrUndefined(dosarMedical.accidenteMunca) ? <UnChecked/> : <Checked/>} nu {NullOrUndefined(dosarMedical.accidenteMunca) ? <Checked/> : <UnChecked/>} <br/> <b style={{whiteSpace: 'pre-wrap'}}>{dosarMedical.accidenteMunca}</b>
                </p>
                <p>
                    Medic de familie <b>{dosarMedical.medicFamilie}</b> tel. <b>{dosarMedical.medicFamilieTelefon}</b>
                </p>
                <p>
                    Declar pe propria raspundere ca nu sunt in evidenta cu epilepsie, boli psihice, boli neurologice si nu sunt sub tratament pentru boli neuropsihice,
                    diabet: <br /> {<b>{employeeData.nume} {employeeData.prenume}</b>} <br />
                </p>
                <p>
                    ANTECEDENTE HEREDOCOLATERALE <br/> <b style={{whiteSpace: 'pre-wrap'}}>{dosarMedical.antecedenteHeredocolaterale}</b> <br /><br/>
                    ANTECEDENTE PERSONALE FIZIOLOGICE SI PATOLOGICE/vaccinari/droguri <br /><br/>
                    UM: <b>{dosarMedical.antecedentePersonaleUM}</b>, nasteri <b>{dosarMedical.antecedentePersonaleNasteri}</b> <br />
                    Fumat: 0 {dosarMedical.fumatZeroChecked ? <Checked/> : <UnChecked/>} de la <b>{dosarMedical.fumatAni}</b> ani, in medie <b>{dosarMedical.fumatTigariPerZi}</b> tigari/zi <br />
                    Alcool: 0 {dosarMedical.alcoolZeroChecked ? <Checked/> : <UnChecked/>} / ocazional {dosarMedical.alcoolOcazionalChecked ? <Checked/> : <UnChecked/>}
                </p>
            </>
        );
}

export const DosarMedicalEditor = ({ employeeData, medicalRequest, dosarMedical, updateDosarMedical }) => {
    
    const [numarDosarMedical, setNumarDosarMedical] = useState(dosarMedical !== {} ? dosarMedical.numarDosarMedical : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["numarDosarMedical"] = numarDosarMedical;
        updateDosarMedical(dosar_medical);
    }, [numarDosarMedical]);

    const [formareProfesionala, setFormareProfesionala] = useState(dosarMedical !== {} ? dosarMedical.formareProfesionala : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["formareProfesionala"] = formareProfesionala;
        updateDosarMedical(dosar_medical);
    }, [formareProfesionala]);

    const [profesionalRouteData, setProfesionalRouteData] = useState(dosarMedical !== {} ? dosarMedical.profesionalRouteData : [{columns: ['','','','']}]);
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["profesionalRouteData"] = profesionalRouteData;
        updateDosarMedical(dosar_medical);
    }, [profesionalRouteData]);    

    const [activitatiLocActualDeMunca, setActivitatiLocActualDeMunca] = useState(dosarMedical !== {} ? dosarMedical.activitatiLocActualDeMunca : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["activitatiLocActualDeMunca"] = activitatiLocActualDeMunca;
        updateDosarMedical(dosar_medical);
    }, [activitatiLocActualDeMunca]);

    const [boliProfesionale, setBoliProfesionale] = useState(dosarMedical !== {} ? dosarMedical.boliProfesionale : null);
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["boliProfesionale"] = boliProfesionale;
        updateDosarMedical(dosar_medical);
    }, [boliProfesionale]);
    
    const [accidenteMunca, setAccidenteMunca] = useState(dosarMedical !== {} ? dosarMedical.accidenteMunca : null);
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["accidenteMunca"] = accidenteMunca;
        updateDosarMedical(dosar_medical);
    }, [accidenteMunca]);

    const [medicFamilie, setMedicFamilie] = useState(dosarMedical !== {} ? dosarMedical.medicFamilie : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["medicFamilie"] = medicFamilie;
        updateDosarMedical(dosar_medical);
    }, [medicFamilie]);

    const [medicFamilieTelefon, setMedicFamilieTelefon] = useState(dosarMedical !== {} ? dosarMedical.medicFamilieTelefon : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["medicFamilieTelefon"] = medicFamilieTelefon;
        updateDosarMedical(dosar_medical);
    }, [medicFamilieTelefon]);

    const [antecedenteHeredocolaterale, setAntecedenteHeredocolaterale] = useState(dosarMedical !== {} ? dosarMedical.antecedenteHeredocolaterale : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["antecedenteHeredocolaterale"] = antecedenteHeredocolaterale;
        updateDosarMedical(dosar_medical);
    }, [antecedenteHeredocolaterale]);

    const [antecedentePersonale, setAntecedentePersonale] = useState(dosarMedical !== {} ? dosarMedical.antecedentePersonale : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["antecedentePersonale"] = antecedentePersonale;
        updateDosarMedical(dosar_medical);
    }, [antecedentePersonale]);

    const [antecedentePersonaleUM,setAntecedentePersonaleUM] = useState(dosarMedical !== {} ? dosarMedical.antecedentePersonaleUM : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["antecedentePersonaleUM"] = antecedentePersonaleUM;
        updateDosarMedical(dosar_medical);
    }, [antecedentePersonaleUM]);

    const [antecedentePersonaleNasteri,setAntecedentePersonaleNasterii] = useState(dosarMedical !== {} ? dosarMedical.antecedentePersonaleNasteri : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["antecedentePersonaleNasteri"] = antecedentePersonaleNasteri;
        updateDosarMedical(dosar_medical);
    }, [antecedentePersonaleNasteri]);

    const [fumatZeroChecked, setFumatZeroChecked] = useState(dosarMedical !== {} ? dosarMedical.fumatZeroChecked : false);
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["fumatZeroChecked"] = fumatZeroChecked;
        updateDosarMedical(dosar_medical);
    }, [fumatZeroChecked]);

    const [fumatAni, setFumatAni] = useState(dosarMedical !== {} ? dosarMedical.fumatAni : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["fumatAni"] = fumatAni;
        updateDosarMedical(dosar_medical);
    }, [fumatAni]);

    const [fumatTigariPerZi, setFumatTigariPerZi] = useState(dosarMedical !== {} ? dosarMedical.fumatTigariPerZi : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["fumatTigariPerZi"] = fumatTigariPerZi;
        updateDosarMedical(dosar_medical);
    }, [fumatTigariPerZi]);

    const [alcoolZeroChecked, setAlcoolZeroChecked] = useState(dosarMedical !== {} ? dosarMedical.alcoolZeroChecked : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["alcoolZeroChecked"] = alcoolZeroChecked;
        updateDosarMedical(dosar_medical);
    }, [alcoolZeroChecked]);

    const [alcoolOcazionalChecked, setAlcoolOcazionalChecked] = useState(dosarMedical !== {} ? dosarMedical.alcoolOcazionalChecked : '');
    useEffect(()=> {
        const dosar_medical = dosarMedical;
        dosar_medical["alcoolOcazionalChecked"] = alcoolOcazionalChecked;
        updateDosarMedical(dosar_medical);
    }, [alcoolOcazionalChecked]);

    return (
        <>
            <h5 style={{ textAlign: 'center' }}>DOSAR MEDICAL</h5>
            <FormGroup
                label="Numar dosar medical"
                value={numarDosarMedical}
                type='text'
                onChange={(e) => { 
                    setNumarDosarMedical(e.target.value);
                }}
            />
            <br />
            <p>Numele <b>{employeeData.nume}</b>, prenumele <b>{employeeData.prenume}</b><br />
                Sex: M {parseInt(employeeData.cnp[0]) % 2 == 1 ? <Checked /> : <UnChecked />} F {parseInt(employeeData.cnp[0]) % 2 == 0 ? <Checked /> : <UnChecked />} &nbsp;
                varsta <b>{calculate_age(new Date(parse_server_date(employeeData.data_nasterii)))} ani</b>, data nasterii: <b>{employeeData.data_nasterii}</b><br />
                CNP <b>{employeeData.cnp}</b><br />
                Adresa: <b>{employeeData.adresa}</b>
            </p>            
            <p>Ocupatia/Functia: <b>{medicalRequest.solicitare_functie}</b><br /></p>    
            <FormGroup
                label="Formarea profesionala:"
                value={formareProfesionala}
                type={'text'}
                onChange={(e) => {
                    setFormareProfesionala(e.target.value);
                }}
            />
            <br/>
            <p>Ruta profesionala<br /></p>
            <DynamicTable
                columns={["Postul si locul de munca", "Perioada", "Ocupatia/Functia", "Noxe"]}
                rows={profesionalRouteData === undefined ? [{columns: ['','','','']}] : profesionalRouteData}
                setRows={setProfesionalRouteData}
            />        
            <div className="form-group">
                <label>Activitati indeplinite la actualul loc de munca:</label>
                <textarea 
                    className="form-control no-outline rounded_input"
                    rows={2}
                    placeholder={"Activitati indeplinite la actualul loc de munca"}
                    style={{resize: 'none'}}
                    value={activitatiLocActualDeMunca}
                    onChange={(e)=>{setActivitatiLocActualDeMunca(e.target.value)}}
                ></textarea>
            </div>         
            <YesNoDetailsArea
                checkLabel={"Boli profesionale"}
                value={boliProfesionale}
                setValue={setBoliProfesionale}
            />  
            <YesNoDetailsArea
                checkLabel={"Accidente de munca"}
                value={accidenteMunca}
                setValue={setAccidenteMunca}
            />   
            <div className='row'>
                <div className='col-md-7'>
                    <FormGroup
                        label={"Medic de familie"}
                        value={medicFamilie}
                        onChange={(e) => {setMedicFamilie(e.target.value)}}
                    />
                </div>
                <div className='col-md-5'>
                    <FormGroup
                        label={"Telefon"}
                        value={medicFamilieTelefon}
                        onChange={(e) => {setMedicFamilieTelefon(e.target.value)}}
                    />
                </div>
            </div>                          
            <p>
                Declar pe propria raspundere ca nu sunt in evidenta cu epilepsie, boli psihice, boli neurologice si nu sunt sub tratament pentru boli neuropsihice,
                diabet: <br /> {<b>{employeeData.nume} {employeeData.prenume}</b>} <br />
            </p>
            <FormGroupArea
                rows={5}
                label={"Antecedente Heredocolaterale"}
                value={antecedenteHeredocolaterale}
                onChange={(e) => {setAntecedenteHeredocolaterale(e.target.value)}}
            />
            <FormGroupArea
                rows={5}
                label={"Antecedente Personale Fiziologice si Patologice/vaccinari/droguri"}
                value={antecedentePersonale}
                onChange={(e) => {setAntecedentePersonale(e.target.value)}}
            />
            <div className='row'>
                <div className='col-md-6'>
                    <FormGroup
                        label={"UM"}
                        value={antecedentePersonaleUM}
                        onChange={(e) => {setAntecedentePersonaleUM(e.target.value)}}
                    />
                </div>
                <div className='col-md-6'>
                    <FormGroup
                        label={"Nasteri"}
                        value={antecedentePersonaleNasteri}
                        onChange={(e) => {setAntecedentePersonaleNasterii(e.target.value)}}
                    />
                </div>
            </div>            
            <div className='row'>
                <div className='col-md-4'>
                    <label>Fumat:</label>
                    <Checkbox 
                        checkLabel={0}
                        value={fumatZeroChecked}
                        setValue={setFumatZeroChecked}
                    />
                </div>
                <div className='col-md-4'>
                    <FormGroup
                        label={"De la x ani"}
                        value={fumatAni}
                        onChange={(e) => setFumatAni(e.target.value)}
                    />
                </div>
                <div className='col-md-4'>
                    <FormGroup
                        label={"Nr. mediu tigari pe zi"}
                        value={fumatTigariPerZi}
                        onChange={(e) => setFumatTigariPerZi(e.target.value)}
                    />
                </div>
            </div>
            <label>Alcool</label>
            <div className='row'>
                <div className='col-md-4'>
                    <Checkbox
                        checkLabel={0}
                        value={alcoolZeroChecked}
                        setValue={setAlcoolZeroChecked}
                    />
                </div>
                <div className='col-md-4'>
                    <Checkbox
                        checkLabel={"Ocazional"}
                        value={alcoolOcazionalChecked}
                        setValue={setAlcoolOcazionalChecked}
                    />
                </div>
            </div>
        </>
    );
}

export const ExamenMedical = ({medicalRequest, signatureDetails, examenMedicalData, setExamenMedicalData, employeeData={}, risksFile={}, withoutAptFile = false}) => {    

    const [simptomeActuale, setSimptomeActuale] = useState(examenMedicalData['simptomeActuale'] != undefined ? examenMedicalData['simptomeActuale']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['simptomeActuale'] = simptomeActuale;
        setExamenMedicalData(cpy);
    }, [simptomeActuale]);

    const [reactualizareAnamnezeiProfesionale, setReactivareaAnamnezeiProfesionale] = useState(examenMedicalData['reactualizareAnamnezeiProfesionale'] != undefined ? examenMedicalData['reactualizareAnamnezeiProfesionale']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['reactualizareAnamnezeiProfesionale'] = reactualizareAnamnezeiProfesionale;
        setExamenMedicalData(cpy);
    }, [reactualizareAnamnezeiProfesionale]);

    const [simptomeLocMunca, setSimptomeLocMunca] = useState(examenMedicalData['simptomeLocMunca'] != undefined ? examenMedicalData['simptomeLocMunca']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['simptomeLocMunca'] = simptomeLocMunca;
        setExamenMedicalData(cpy);
    }, [simptomeLocMunca]);

    const [reactualizareAnamnezeiNeprofesionale, setReactivareaAnamnezeiNeprofesionale] = useState(examenMedicalData['reactualizareAnamnezeiNeprofesionale'] != undefined ? examenMedicalData['reactualizareAnamnezeiNeprofesionale']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['reactualizareAnamnezeiNeprofesionale'] = reactualizareAnamnezeiNeprofesionale;
        setExamenMedicalData(cpy);
    }, [reactualizareAnamnezeiNeprofesionale]);


    const [t, setT] = useState(examenMedicalData['t'] != undefined ? examenMedicalData['t']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['t'] = t;
        setExamenMedicalData(cpy);
    }, [t]);

    const [G, setG] = useState(examenMedicalData['G'] != undefined ? examenMedicalData['G']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['G'] = G;
        setExamenMedicalData(cpy);
    }, [G]);

    useEffect(()=>{
        if(t !== '' && G !== '')
        {
            try{
                const t_num = parseFloat(t) / 100;
                const g_num = parseFloat(G);
                const final = (g_num/(t_num)/(t_num)).toFixed(2);
                setImc(`${final}`);
            }
            catch(e)
            { console.log(e); }
        }
    },[t, G])

    const [imc, setImc] = useState(examenMedicalData['imc'] != undefined ? examenMedicalData['imc']:null);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['imc'] = imc;
        setExamenMedicalData(cpy);
    }, [imc]);

    const [obezitate, setObezitate] = useState(examenMedicalData['obezitate'] != undefined ? examenMedicalData['obezitate']:null);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['obezitate'] = obezitate;
        setExamenMedicalData(cpy);
    }, [obezitate]);

    const [tegumenteMucoase, setTegumenteMucoase] = useState(examenMedicalData['tegumenteMucoase'] != undefined ? examenMedicalData['tegumenteMucoase']:'normal colorate, integre, fara leziuni');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['tegumenteMucoase'] = tegumenteMucoase;
        setExamenMedicalData(cpy);
    }, [tegumenteMucoase]);

    const [tesutCelularSubcutanat, setTestuCelularSubcutanat] = useState(examenMedicalData['tesutCelularSubcutanat'] != undefined ? examenMedicalData['tesutCelularSubcutanat']:'normal reprezentat');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['tesutCelularSubcutanat'] = tesutCelularSubcutanat;
        setExamenMedicalData(cpy);
    }, [tesutCelularSubcutanat]);

    const [sistemGanglionar, setSistemGanglionar] = useState(examenMedicalData['sistemGanglionar'] != undefined ? examenMedicalData['sistemGanglionar']:'superficial nepalpabil');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['sistemGanglionar'] = sistemGanglionar;
        setExamenMedicalData(cpy);
    }, [sistemGanglionar]);

    const [aparatLocomotor, setAparatLocomotor] = useState(examenMedicalData['aparatLocomotor'] != undefined ? examenMedicalData['aparatLocomotor']:'aparent integru morfofunctional');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['aparatLocomotor'] = aparatLocomotor;
        setExamenMedicalData(cpy);
    }, [aparatLocomotor]);

    const [aparatRespirator, setAparatRespirator] = useState(examenMedicalData['aparatRespirator'] != undefined ? examenMedicalData['aparatRespirator']:'torace normal conformat, excursii costale simetrice, murmur vezicular normal bilateral, fara raluri');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['aparatRespirator'] = aparatRespirator;
        setExamenMedicalData(cpy);
    }, [aparatRespirator]);

    const [aparatCV, setAparatCV] = useState(examenMedicalData['aparatCV'] != undefined ? examenMedicalData['aparatCV']:'arie cardiaca de aspect normal, soc apexian sp.V i.c. linia medio-claviculara; zgomote cardiace ritmice, fara sufluri, fara acuze subiective');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['aparatCV'] = aparatCV;
        setExamenMedicalData(cpy);
    }, [aparatCV]);

    const [TA, setTA] = useState(examenMedicalData['TA'] != undefined ? examenMedicalData['TA']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['TA'] = TA;
        setExamenMedicalData(cpy);
    }, [TA]);

    const [AV, setAV] = useState(examenMedicalData['AV'] != undefined ? examenMedicalData['AV']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['AV'] = AV;
        setExamenMedicalData(cpy);
    }, [AV]);

    const [pedioase, setPedioase] = useState(examenMedicalData['pedioase'] != undefined ? examenMedicalData['pedioase']:'pulsatile');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['pedioase'] = pedioase;
        setExamenMedicalData(cpy);
    }, [pedioase]);

    const [varice, setVarice] = useState(examenMedicalData['varice'] != undefined ? examenMedicalData['varice']:'absente');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['varice'] = varice;
        setExamenMedicalData(cpy);
    }, [varice]);

    const [aparatDigestiv, setAparatDigestiv] = useState(examenMedicalData['aparatDigestiv'] != undefined ? examenMedicalData['aparatDigestiv']:'abdomen suplu, mobil cu miscarile respiratorii, nedureros la palpare');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['aparatDigestiv'] = aparatDigestiv;
        setExamenMedicalData(cpy);
    }, [aparatDigestiv]);

    const [aparatUrogenital, setAparatUrogenital] = useState(examenMedicalData['aparatUrogenital'] != undefined ? examenMedicalData['aparatUrogenital']:'loje renale libere,Giordano(-), mictiuni fiziologice');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['aparatUrogenital'] = aparatUrogenital;
        setExamenMedicalData(cpy);
    }, [aparatUrogenital]);

    const [sncAnalizatori, setSncAnalizatori] = useState(examenMedicalData['sncAnalizatori'] != undefined ? examenMedicalData['sncAnalizatori']:'OTS, ROT simetrice, proba Romberg (-)');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['sncAnalizatori'] = sncAnalizatori;
        setExamenMedicalData(cpy);
    }, [sncAnalizatori]);

    const [acuitateVizuale, setAcuitateVizuala] = useState(examenMedicalData['acuitateVizuale'] != undefined ? examenMedicalData['acuitateVizuale']:'VAO=1fc');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['acuitateVizuale'] = acuitateVizuale;
        setExamenMedicalData(cpy);
    }, [acuitateVizuale]);

    const [vedereCromatica, setVedereCromatica] = useState(examenMedicalData['vedereCromatica'] != undefined ? examenMedicalData['vedereCromatica']:'normala');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['vedereCromatica'] = vedereCromatica;
        setExamenMedicalData(cpy);
    }, [vedereCromatica]);

    const [vedereReliefFaraCorectie, setVedereReliefFaraCorectie] = useState(examenMedicalData['vedereReliefFaraCorectie'] != undefined ? examenMedicalData['vedereReliefFaraCorectie']:true);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['vedereReliefFaraCorectie'] = vedereReliefFaraCorectie;
        setExamenMedicalData(cpy);
    }, [vedereReliefFaraCorectie]);

    const [vedereReliefCorectieOD, setVedereReliefCorectieOD] = useState(examenMedicalData['vedereReliefCorectieOD'] != undefined ? examenMedicalData['vedereReliefCorectieOD']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['vedereReliefCorectieOD'] = vedereReliefCorectieOD;
        setExamenMedicalData(cpy);
    }, [vedereReliefCorectieOD]);

    const [vedereReliefCorectieOS, setVedereReliefCorectieOS] = useState(examenMedicalData['vedereReliefCorectieOS'] != undefined ? examenMedicalData['vedereReliefCorectieOS']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['vedereReliefCorectieOS'] = vedereReliefCorectieOS;
        setExamenMedicalData(cpy);
    }, [vedereReliefCorectieOS]);

    const [voceTare, setVoceTare] = useState(examenMedicalData['voceTare'] != undefined ? examenMedicalData['voceTare']:'normal');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['voceTare'] = voceTare;
        setExamenMedicalData(cpy);
    }, [voceTare]);

    const [voceSoptita, setVoceSoptita] = useState(examenMedicalData['voceSoptita'] != undefined ? examenMedicalData['voceSoptita']:'normal');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['voceSoptita'] = voceSoptita;
        setExamenMedicalData(cpy);
    }, [voceSoptita]);

    const [sistemEndocrin, setSistemEndocrin] = useState(examenMedicalData['sistemEndocrin'] != undefined ? examenMedicalData['sistemEndocrin']:'clinic normal');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['sistemEndocrin'] = sistemEndocrin;
        setExamenMedicalData(cpy);
    }, [sistemEndocrin]);

    const [concluziiExamenClinicSanatos, setConcluziiExamenClinicSanatos] = useState(examenMedicalData['concluziiExamenClinicSanatos'] != undefined ? examenMedicalData['concluziiExamenClinicSanatos']:true);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['concluziiExamenClinicSanatos'] = concluziiExamenClinicSanatos;
        setExamenMedicalData(cpy);
    }, [concluziiExamenClinicSanatos]);

    const [concluziiExamenClinicDiagnosticCheck, setConcluziiExamenClinicDiagnosticCheck] = useState(examenMedicalData['concluziiExamenClinicDiagnosticCheck'] != undefined ? examenMedicalData['concluziiExamenClinicDiagnosticCheck']:false);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['concluziiExamenClinicDiagnosticCheck'] = concluziiExamenClinicDiagnosticCheck;
        setExamenMedicalData(cpy);
    }, [concluziiExamenClinicDiagnosticCheck]);

    const [concluziiExamenClinicDiagnostic, setConcluziiExamenClinicDiagnostic] = useState(examenMedicalData['concluziiExamenClinicDiagnostic'] != undefined ? examenMedicalData['concluziiExamenClinicDiagnostic']:'');
        useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['concluziiExamenClinicDiagnostic'] = concluziiExamenClinicDiagnostic;
        setExamenMedicalData(cpy);
    }, [concluziiExamenClinicDiagnostic]);


    const [exameneSuplimentare, setExameneSuplimentare] = useState(examenMedicalData['exameneSuplimentare'] != undefined ? examenMedicalData['exameneSuplimentare']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['exameneSuplimentare'] = exameneSuplimentare;
        setExamenMedicalData(cpy);
    }, [exameneSuplimentare]);

    const [concluziiExamenMMSanatos, setConcluziiExamenMMSanatos] = useState(examenMedicalData['concluziiExamenMMSanatos'] != undefined ? examenMedicalData['concluziiExamenMMSanatos']:true);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['concluziiExamenMMSanatos'] = concluziiExamenMMSanatos;
        setExamenMedicalData(cpy);
    }, [concluziiExamenMMSanatos]);

    const [concluziiExamenMMDiagnosticCheck, setConcluziiExamenMMDiagnosticCheck] = useState(examenMedicalData['concluziiExamenMMDiagnosticCheck'] != undefined ? examenMedicalData['concluziiExamenMMDiagnosticCheck']:false);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['concluziiExamenMMDiagnosticCheck'] = concluziiExamenMMDiagnosticCheck;
        setExamenMedicalData(cpy);
    }, [concluziiExamenMMDiagnosticCheck]);

    const [concluziiExamenMMDiagnostic, setConcluziiExamenMMDiagnostic] = useState(examenMedicalData['concluziiExamenMMDiagnostic'] != undefined ? examenMedicalData['concluziiExamenMMDiagnostic']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['concluziiExamenMMDiagnostic'] = concluziiExamenMMDiagnostic;
        setExamenMedicalData(cpy);
    }, [concluziiExamenMMDiagnostic]);

    const [apt, setApt] = useState(examenMedicalData['apt'] != undefined ? examenMedicalData['apt']:true);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['apt'] = apt;
        setExamenMedicalData(cpy);
    }, [apt]);

    const [aptConditionat, setAptConditionat] = useState(examenMedicalData['aptConditionat'] != undefined ? examenMedicalData['aptConditionat']:false);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['aptConditionat'] = aptConditionat;
        setExamenMedicalData(cpy);
    }, [aptConditionat]);

    const [inaptTemporar, setInaptTemporar] = useState(examenMedicalData['inaptTemporar'] != undefined ? examenMedicalData['inaptTemporar']:false);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['inaptTemporar'] = inaptTemporar;
        setExamenMedicalData(cpy);
    }, [inaptTemporar]);

    const [inapt, setInapt] = useState(examenMedicalData['inapt'] != undefined ? examenMedicalData['inapt']:false);
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['inapt'] = inapt;
        setExamenMedicalData(cpy);
    }, [inapt]);

    const [recomandari, setRecomandari] = useState(examenMedicalData['recomandari'] != undefined ? examenMedicalData['recomandari']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['recomandari'] = recomandari;
        setExamenMedicalData(cpy);
    }, [recomandari]);

    const [dataExamenUrmator, setDataExamenUrmator] = useState(examenMedicalData['dataExamenUrmator'] != undefined ? examenMedicalData['dataExamenUrmator']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['dataExamenUrmator'] = dataExamenUrmator;
        setExamenMedicalData(cpy);
    }, [dataExamenUrmator]);

    const [numarFisaAptitudini, setNumarFisaAptitudini] = useState(examenMedicalData['numarFisaAptitudini'] != undefined ? examenMedicalData['numarFisaAptitudini']:'');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['numarFisaAptitudini'] = numarFisaAptitudini;
        setExamenMedicalData(cpy);
    },[numarFisaAptitudini]);

    const [dataInregistrareFisaAptitudini, setDataInregistrareFisaAptitudini] = useState(examenMedicalData['dataInregistrareFisaAptitudini'] != undefined ? examenMedicalData['dataInregistrareFisaAptitudini']:new Date());
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['dataInregistrareFisaAptitudini'] = dataInregistrareFisaAptitudini;
        setExamenMedicalData(cpy);
    },[dataInregistrareFisaAptitudini]);

    const [locMuncaObservatii, setLocMuncaObservatii] = useState(examenMedicalData['locMuncaObservatii'] != undefined ? examenMedicalData['locMuncaObservatii']: '');
    useEffect(()=>{
        const cpy = examenMedicalData;
        cpy['locMuncaObservatii'] = locMuncaObservatii;
        setExamenMedicalData(cpy);
    }, [locMuncaObservatii]);

    const [isPreviewAptitudiniFileOpen, setIsPreviewAptitudiniFileOpen] = useState(false);

    return(
        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
            {medicalRequest === undefined || signatureDetails == undefined && (
                <LoadingCircleComponent/>
            )}                        
            {medicalRequest !== undefined && signatureDetails !== undefined && (
                <>
                    <h4>Examen medical {medicalRequest.solicitare_angajare ? "la angajare" : "periodic"}</h4>                                              
                    <br/>
                    {/* {medicalRequest.solicitare_angajare === false && (
                        
                    )} */}
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <FormGroup
                                    label={'Simptome actuale'}
                                    value={simptomeActuale}
                                    onChange={(e) => {setSimptomeActuale(e.target.value)}}
                                />                        
                            </div>
                            <div className='col-md-12'>
                                <FormGroup
                                    label={'Reactualizarea anamnezei profesionale'}
                                    value={reactualizareAnamnezeiProfesionale}
                                    onChange={(e) => {setReactivareaAnamnezeiProfesionale(e.target.value)}}
                                />
                            </div>
                            <div className='col-md-12'>
                                <FormGroup
                                    label={'Simptome la locul de munca'}
                                    value={simptomeLocMunca}
                                    onChange={(e) => {setSimptomeLocMunca(e.target.value)}}
                                />
                            </div>
                            <div className='col-md-12'>
                                <FormGroup
                                    label={'Reactualizarea anamnezei neprofesionale'}
                                    value={reactualizareAnamnezeiNeprofesionale}
                                    onChange={(e) => {setReactivareaAnamnezeiNeprofesionale(e.target.value)}}
                                />
                            </div>                                                                        
                        </div>
                        <hr/>
                        </>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup
                                label={'T (cm)'}
                                value={t}
                                onChange={(e) => {
                                    setT(e.target.value);
                                }}
                            />
                        </div>
                        <div className='col-md-6'>
                            <FormGroup
                                label={'G (kg)'}
                                value={G}
                                onChange={(e) => {
                                    setG(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <FormGroup
                        label={'IMC'}
                        value={imc}
                        onChange={(e) => {setImc(e.target.value)}}
                    />
                    <YesNoDetails
                        checkLabel={'obezitate'}
                        optionLabel={'Grad'}
                        value={obezitate}
                        setValue={setObezitate}
                    />   
                    <br/>           
                    <h5 style={{textAlign: 'center'}}>Examen clinic</h5>
                    <br/>
                    <FormGroupArea
                        label={'Tegumente si mucoase'}
                        value={tegumenteMucoase}
                        onChange={(e) => {setTegumenteMucoase(e.target.value)}}
                        rows={2}
                    />
                    <FormGroupArea
                        label={'Tesut celular subcutanat'}
                        value={tesutCelularSubcutanat}
                        onChange={(e) => {setTestuCelularSubcutanat(e.target.value)}}
                        rows={2}
                    />
                    <FormGroupArea
                        label={'Sistem ganglionar'}
                        value={sistemGanglionar}
                        onChange={(e) => {setSistemGanglionar(e.target.value)}}
                        rows={2}
                    />
                    <FormGroupArea
                        label={'Aparat locomotor'}
                        value={aparatLocomotor}
                        onChange={(e) => {setAparatLocomotor(e.target.value)}}
                        rows={2}
                    />
                    <FormGroupArea
                        label={'Aparat respirator'}
                        value={aparatRespirator}
                        onChange={(e) => {setAparatRespirator(e.target.value)}}
                        rows={2}
                    />
                    <FormGroupArea
                        label={'Aparat CV:'}
                        value={aparatCV}
                        onChange={(e) => {setAparatCV(e.target.value)}}
                        rows={2}
                    />
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup
                                label={'TA (mmHg)'}
                                value={TA}
                                onChange={(e) => setTA(e.target.value)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <FormGroup
                                label={'AV /min'}
                                value={AV}
                                onChange={(e) => setAV(e.target.value)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <FormGroup
                                label={'pedioase'}
                                value={pedioase}
                                onChange={(e) => setPedioase(e.target.value)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <FormGroup
                                label={'varice'}
                                value={varice}
                                onChange={(e) => setVarice(e.target.value)}
                            />
                        </div>
                    </div> 
                    <FormGroupArea
                        label={'Aparat digestiv'}
                        rows={2}
                        value={aparatDigestiv}
                        onChange={(e) => setAparatDigestiv(e.target.value)}
                    />
                    <FormGroupArea
                        label={'Aparat urogenital'}
                        rows={2}
                        value={aparatUrogenital}
                        onChange={(e) => setAparatUrogenital(e.target.value)}
                    />
                    <FormGroupArea
                        label={'SNC, analizatori:'}
                        rows={2}
                        value={sncAnalizatori}
                        onChange={(e) => setSncAnalizatori(e.target.value)}
                    />
                    <label>a) <br/></label>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup
                                label={'acuitate vizuala'}
                                value={acuitateVizuale}
                                onChange={(e) => setAcuitateVizuala(e.target.value)}
                            />
                        </div>    
                        <div className='col-md-6'>
                            <FormGroup
                                label={'vedere cromatica'}
                                value={vedereCromatica}
                                onChange={(e) => setVedereCromatica(e.target.value)}
                            />
                        </div>    
                    </div>         
                    <Checkbox
                        checkLabel={'vedere in relief fara corectie optica'}
                        value={vedereReliefFaraCorectie}
                        setValue={setVedereReliefFaraCorectie}
                    />                   
                    <label>Cu corectie optica: <br/></label>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup
                                label={'OD'}
                                value={vedereReliefCorectieOD}
                                onChange={(e) => setVedereReliefCorectieOD(e.target.value)}
                            />
                        </div>    
                        <div className='col-md-6'>
                            <FormGroup
                                label={'OS'}
                                value={vedereReliefCorectieOS}
                                onChange={(e) => setVedereReliefCorectieOS(e.target.value)}
                            />
                        </div>    
                    </div>        
                    <label>b) <br/></label>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup
                                label={'Voce tare'}
                                value={voceTare}
                                onChange={(e) => setVoceTare(e.target.value)}
                            />
                        </div>    
                        <div className='col-md-6'>
                            <FormGroup
                                label={'Voce soptita'}
                                value={voceSoptita}
                                onChange={(e) => setVoceSoptita(e.target.value)}
                            />
                        </div>    
                    </div>        
                    <FormGroupArea
                        label={'Sistem endocrin'}
                        rows={2}
                        value={sistemEndocrin}
                        onChange={(e) => setSistemEndocrin(e.target.value)}
                    />
                    <label>Concluzii examen clinic: <br/></label>
                    <div className='row'>
                        <div className='col-md-7'>
                            <Checkbox
                                checkLabel={'sanatos clinic in momentul examinarii'}
                                value={concluziiExamenClinicSanatos}
                                setValue={(value) => {
                                    setConcluziiExamenClinicDiagnosticCheck(!value);
                                    setConcluziiExamenClinicSanatos(value);

                                    setConcluziiExamenMMSanatos(value);
                                    setConcluziiExamenMMDiagnosticCheck(!value);
                                }}
                            />
                            <Checkbox
                                checkLabel={'diagnostic'}
                                value={concluziiExamenClinicDiagnosticCheck}
                                setValue={(value) => {
                                    setConcluziiExamenClinicDiagnosticCheck(value);
                                    setConcluziiExamenClinicSanatos(!value);

                                    setConcluziiExamenMMSanatos(!value);
                                    setConcluziiExamenMMDiagnosticCheck(value);
                                }}
                            />
                            <FormGroupArea
                                label={'Diagnostic'}
                                rows={2}
                                value={concluziiExamenClinicDiagnostic}
                                onChange={(e) => {
                                    setConcluziiExamenClinicDiagnostic(e.target.value);
                                    setConcluziiExamenMMDiagnostic(e.target.value);
                                }}
                            />
                        </div>
                        <div className='col-md-5'>
                            <p style={{textAlign: 'center'}}>
                                Semnatura si parafa medicului, <br/>
                                <b style={{textAlign: 'center'}}>{signatureDetails.name}</b>
                            </p>                            
                        </div>
                    </div>
                    <br/>           
                    <h5 style={{textAlign: 'center'}}>EXAMENE SUPLIMENTARE (conform anexei):</h5>
                    <br/>
                    <FormGroupArea
                        label={'Examene suplimentare'} 
                        rows={10}          
                        value={exameneSuplimentare}         
                        onChange={(e) => setExameneSuplimentare(e.target.value)}
                    />
                    <br/>
                    <label><b>Concluziile examinarii de specialitate (medicina muncii):</b><br/></label>
                    <Checkbox
                        checkLabel={'sanatos clinic in momentul examinarii'}
                        value={concluziiExamenMMSanatos}
                        setValue={(value) => {
                            setConcluziiExamenMMSanatos(value);
                            setConcluziiExamenMMDiagnosticCheck(!value);
                        }}
                    />
                    <Checkbox
                        checkLabel={'diagnostic'}
                        value={concluziiExamenMMDiagnosticCheck}
                        setValue={(value) => {
                            setConcluziiExamenMMSanatos(!value);
                            setConcluziiExamenMMDiagnosticCheck(value);
                        }}
                    />
                    <FormGroupArea
                        label={'Diagnostic'}
                        rows={2}
                        value={concluziiExamenMMDiagnostic}
                        onChange={(e) => setConcluziiExamenMMDiagnostic(e.target.value)}
                    />
                    {withoutAptFile === false && (
                        <>
                            <label>
                                <b>AVIZ MEDICAL:</b><br/>
                                pentru exercitarea ocupatiei/functiei <b>{medicalRequest.solicitare_functie}</b><br/>
                            </label>             
                            <br/>       
                            <div className='row'>
                                <div className='col-md-5'>
                                    <Checkbox
                                        checkLabel={'Apt'}
                                        value={apt}
                                        setValue={setApt}
                                    />
                                    <Checkbox
                                        checkLabel={'Apt conditionat'}
                                        value={aptConditionat}
                                        setValue={setAptConditionat}
                                    />
                                    <Checkbox
                                        checkLabel={'Inapt temporar'}
                                        value={inaptTemporar}
                                        setValue={setInaptTemporar}
                                    />
                                    <Checkbox
                                        checkLabel={'Inapt'}
                                        value={inapt}
                                        setValue={setInapt}
                                    />
                                </div>
                                <div className='col-md-7'>                            
                                    <FormGroupArea
                                        label={'Recomandari'}
                                        rows={5}
                                        value={recomandari}
                                        onChange={(e) => setRecomandari(e.target.value)}
                                    />
                                </div>
                            </div> 
                            <br/>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label>Data: <b>{get_today_date()}</b></label>
                                    <br/>
                                    <FormGroup
                                        label={'Data urmatorului examen medical'}
                                        type='date'
                                        value={dataExamenUrmator}
                                        onChange={(e) => setDataExamenUrmator(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <p style={{textAlign: 'center'}}>
                                        Medic de medicina muncii,<br/>
                                        <b style={{textAlign: 'center'}}>{signatureDetails.name}</b><br/>
                                        Semnatura si parafa <br/>                                
                                    </p> 
                                </div>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup
                                        label={'Numar fisa aptitudini'}
                                        type={'text'}
                                        value={numarFisaAptitudini}
                                        onChange={(e) => {setNumarFisaAptitudini(e.target.value)}}
                                    />
                                    <FormGroup
                                        label={'Data inregistrare fisa aptitudini'}
                                        type={'date'}
                                        value={dataInregistrareFisaAptitudini}
                                        onChange={(e) => {setDataInregistrareFisaAptitudini(e.target.value)}}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormGroupArea
                                        label={'Observatii loc munca - fisa aptitudini'}
                                        type={'text'}
                                        value={locMuncaObservatii}
                                        onChange={(e) => {setLocMuncaObservatii(e.target.value)}}
                                    />
                                </div>
                            </div>  
                            <hr/>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <RoundButton 
                                        className="custom-btn-primary"
                                        text="Previzualizeaza fisa aptitudini"
                                        onClickHandler={() =>{ setIsPreviewAptitudiniFileOpen(true); }}
                                    />  
                                </div>
                            </div>
                            <Modal
                                isOpen={isPreviewAptitudiniFileOpen}
                                toggle={()=>{setIsPreviewAptitudiniFileOpen(false)}}
                                size={'xl'}
                            >
                                {risksFile.sectieDepartament !== undefined && (
                                    <>
                                        <ModalHeader>Previzualizare fisa aptitudini</ModalHeader>
                                        <ModalBody>
                                            <MedicalAptitudiniFilePreview                                        
                                                data={examenMedicalData}
                                                numarFisaAptitudini={numarFisaAptitudini}
                                                signatureDetails={signatureDetails}
                                                medicalRequest={medicalRequest}
                                                societate={{
                                                    Nume: employeeData.unitate.Nume,
                                                    Adresa: employeeData.unitate.Adresa,
                                                    Telefon: employeeData.unitate.Telefon,
                                                    Fax: employeeData.unitate.Fax,
                                                }}
                                                angajat={{
                                                    Nume: employeeData.nume,
                                                    Prenume: employeeData.prenume,
                                                    Cnp: employeeData.cnp,
                                                    functie: medicalRequest.solicitare_functie,
                                                    loc_munca: `${risksFile.sectieDepartament.split(" ")[0]} ${risksFile.locMunca} ${locMuncaObservatii}`
                                                }}
                                                clinicData={{
                                                    name: ''
                                                }}
                                            />
                                        </ModalBody>  
                                    </>
                                )}
                            </Modal>
                        </>
                    )}
                </>
            )}
        </LayoutCard>
    )
}

const MedicalExamHistoryViewer = ({isOpen, toggle, workerId}) => {
    const renderTipControl = (requestsJSON) => {
        requestsJSON = JSON.parse(requestsJSON);
        if(requestsJSON.solicitare_angajare){
            return ("Angajare");
        }
        if(requestsJSON.solicitare_control_medical_periodic){
            return ("Periodic");
        }
        if(requestsJSON.solicitare_adaptare){
            return ("Adaptare");
        }
        if(requestsJSON.solicitare_reluarea_muncii){
            return ("Reluarea muncii");
        }
        if(requestsJSON.solicitare_supraveghere_speciala){
            return("Supraveghere speciala");
        }
        if(requestsJSON.solicitare_la_cerere){
            return("La cerere");
        }
        if(requestsJSON.solicitare_schimbareaLoculuiDeMunca){
            return("Schimbarea locului de munca");
        }
        if(requestsJSON.solicitare_altele){
            return("Altele");
        }
        return "-";
    }

    const [detailsDialogSelectedTab, setDetailsDialogSelectedTab] = useState(0);
    const [medicalRecords, setMedicalRecords] = useState(undefined);
    const [selectedRiskFileId, setSelectedRiskFileId] = useState(undefined);
    const [selectedMedicalRecordData, setSelectedMedicalRecordData] = useState(undefined);
    useEffect(()=> {
        if(workerId === undefined) return;
        get_ajax('MedicSSM/MedicalFiles/GetMedicalFilesHistory?id=' + workerId, (response) => {
            setMedicalRecords(response);
            if(response.length > 0)
                setSelectedRiskFileId(response[0].id);
        });
    }, [workerId]);

    useEffect(()=>{
        if(selectedRiskFileId === undefined) return;
        get_ajax('MedicSSM/MedicalFiles/GetMedicalRecordData?id=' + selectedRiskFileId, (response) => {
            response.dosarMedicalData = JSON.parse(response.dosarMedicalData);
            response.employeeData = JSON.parse(response.employeeData);
            response.medicalRequest = JSON.parse(response.medicalRequest);
            response.risksFile = JSON.parse(response.risksFile);       
            response.examenMedical = JSON.parse(response.examenMedical);     
            setSelectedMedicalRecordData(response);
        });
    }, [selectedRiskFileId]);

    return (
        <CustomResourceModal
            size={'xl'}
            isOpen={isOpen}
            toggle={toggle}
            title={"Investigatii medicale"}
        >
            {medicalRecords === undefined && (<LoadingCircleComponent/>)}
            {medicalRecords !== undefined && (
                <div className='row'>
                    <div className='col-md-8'>
                        {(selectedMedicalRecordData === undefined && medicalRecords.length !== 0 ) && (<LoadingCircleComponent/>)}
                        {selectedMedicalRecordData !== undefined && (
                            <>
                            <ul className="nav nav-tabs">
                            <li className="nav-item">
                            <a className={detailsDialogSelectedTab == 0 ? "nav-link active" :"nav-link"} onClick={()=>setDetailsDialogSelectedTab(0)}>Fisa solicitare</a>
                            </li>
                            <li className="nav-item">
                            <a className={detailsDialogSelectedTab == 1 ? "nav-link active" :"nav-link"} onClick={()=>setDetailsDialogSelectedTab(1)}>Fisa factori risc</a>
                            </li>
                            <li className="nav-item">
                            <a className={detailsDialogSelectedTab == 2 ? "nav-link active" :"nav-link"} onClick={()=>setDetailsDialogSelectedTab(2)}>Dosar medical</a>
                            </li>
                            <li className="nav-item">
                            <a className={detailsDialogSelectedTab == 3 ? "nav-link active" :"nav-link"} onClick={()=>setDetailsDialogSelectedTab(3)}>Examen medical</a>
                            </li>
                            </ul>
                            <br/>
                            {detailsDialogSelectedTab == 0 && (
                            <FisaSolicitare
                                employeeData={selectedMedicalRecordData.employeeData}
                                medicalRequest={selectedMedicalRecordData.medicalRequest}
                                risksFile={selectedMedicalRecordData.risksFile}
                                medicalRequestDate={selectedMedicalRecordData.medicalRequestDate}
                                medicalRequestSignature={selectedMedicalRecordData.medicalRequestSignature}
                            />
                            )}
                            {detailsDialogSelectedTab == 1 && (
                                <FisaFactoriRisc
                                    risksFileSignature={selectedMedicalRecordData.risksFileSignature}
                                    employeeData={selectedMedicalRecordData.employeeData}
                                    medicalRequest={selectedMedicalRecordData.medicalRequest}
                                    risksFile={selectedMedicalRecordData.risksFile}
                                    medicalRequestDate={selectedMedicalRecordData.medicalRequestDate}
                                    risksFileCreatedBy={selectedMedicalRecordData.risksFileCreatedBy}
                                    medicalRequestSignature={selectedMedicalRecordData.medicalRequestSignature}
                                />
                            )}
                            {detailsDialogSelectedTab == 2 && (
                                <DosarMedicalRender
                                    employeeData={selectedMedicalRecordData.employeeData}
                                    medicalRequest={selectedMedicalRecordData.medicalRequest}
                                    dosarMedical={selectedMedicalRecordData.dosarMedicalData}
                                    signatureDetails={selectedMedicalRecordData.clinicSignatureDetails}
                                />
                            )}
                            {detailsDialogSelectedTab == 3 && (
                                <ExamenMedicalRenderer
                                    medicalRequest={selectedMedicalRecordData.medicalRequest}
                                    clinicSignatureDetails={selectedMedicalRecordData.clinicSignatureDetails}
                                    examenMedical={selectedMedicalRecordData.examenMedical}
                                    doctorSignature={selectedMedicalRecordData.doctorSignature}
                                    medicalExamCreatedAt={selectedMedicalRecordData.medicalExamCreatedAt}
                                />
                            )}
                            </>
                        )}
                    </div>
                    <div className='col-md-4'>
                        <h4>Istoric</h4>
                        {medicalRecords.length === 0 && (
                            <p style={{textAlign: 'center'}}>Nu exista istoric pentru fisa de riscuri</p>
                        )}
                        <ul className="list-group">
                            {medicalRecords.map((r, i) => 
                                <li 
                                    key={i}
                                    className={r.id == selectedRiskFileId ? "list-group-item active_side_menu" : "list-group-item"}
                                    style={{cursor: 'pointer'}}
                                    onClick={()=>{ setSelectedRiskFileId(r.id);}}
                                >
                                    {r.createdAt}
                                </li>                      
                            )}  
                        </ul>
                    </div>
                </div>
            )}
        </CustomResourceModal>
    );
}