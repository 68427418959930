"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.PageList = void 0;
var react_1 = __importStar(require("react"));
var DataProvider_1 = require("../../utils/DataProvider");
var CircleLoadingIndicator_1 = require("../generic-building-blocks/CircleLoadingIndicator");
var FormGroup_1 = require("../generic-building-blocks/FormGroup");
var RoundButton_1 = require("../generic-building-blocks/RoundButton");
var UserDialogs_1 = require("../generic-building-blocks/UserDialogs");
var react_beautiful_dnd_1 = require("react-beautiful-dnd");
var PageList = function (_a) {
    var material_id = _a.material_id, target_endpoint = _a.target_endpoint, selected_page_id = _a.selected_page_id, page_selected_handler = _a.page_selected_handler, update_trigger = _a.update_trigger;
    (0, react_1.useEffect)(function () {
        if (material_id === undefined)
            return;
        loadPages();
    }, [material_id]);
    var loadPages = function (redirect) {
        if (redirect === void 0) { redirect = true; }
        if (material_id === undefined)
            return;
        (0, DataProvider_1.get_ajax)("".concat(target_endpoint, "/GetPages?materialId=").concat(material_id), function (response) {
            if (response.length > 0 && redirect)
                page_selected_handler(response[0].pageId);
            setPages(response);
            setIsLoading(false);
        });
    };
    (0, react_1.useEffect)(function () {
        loadPages(false);
    }, [update_trigger]);
    var _b = (0, react_1.useState)(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = (0, react_1.useState)([]), pages = _c[0], setPages = _c[1];
    var _d = (0, react_1.useState)(false), isAddNewPageDialogOpen = _d[0], setIsAddNewPageDialogOpen = _d[1];
    var _e = (0, react_1.useState)(''), addNewPageName = _e[0], setAddNewPageName = _e[1];
    (0, react_1.useEffect)(function () {
        setAddNewPageName('');
    }, [isAddNewPageDialogOpen]);
    var onDragEnd = function (result) {
        if (!result.destination) {
            return;
        }
        var pages_cpy = __spreadArray([], pages, true);
        var removed = pages_cpy.splice(result.source.index, 1)[0];
        pages_cpy.splice(result.destination.index, 0, removed);
        setPages(pages_cpy);
        (0, DataProvider_1.post)("".concat(target_endpoint, "/ReorderPages"), {
            'materialId': material_id,
            'sourceIndex': result.source.index,
            'destinationIndex': result.destination.index
        }, function (response) {
            (0, DataProvider_1.parseTrueOrErrorMessage)(response, function (response) { });
        });
    };
    if (isLoading)
        return (react_1["default"].createElement(CircleLoadingIndicator_1.LoadingCircleComponent, null));
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        react_1["default"].createElement("div", { className: "list-group" },
            react_1["default"].createElement(react_beautiful_dnd_1.DragDropContext, { onDragEnd: onDragEnd },
                react_1["default"].createElement(react_beautiful_dnd_1.Droppable, { droppableId: "page_tree_viewer" }, function (provided) { return (react_1["default"].createElement("div", __assign({ ref: provided.innerRef }, provided.droppableProps),
                    pages.map(function (page, i) { return (react_1["default"].createElement(react_beautiful_dnd_1.Draggable, { draggableId: "page_tree_viewer_draggable_id_".concat(i), key: i, index: i }, function (provided) { return (react_1["default"].createElement("div", __assign({ ref: provided.innerRef }, provided.dragHandleProps, provided.draggableProps),
                        react_1["default"].createElement("div", { className: selected_page_id === page.pageId ? "list-group-item layout_card_active" : "list-group-item", onClick: function () { page_selected_handler(page.pageId); } }, page.pageName))); })); }),
                    provided.placeholder)); }))),
        react_1["default"].createElement("br", null),
        pages.length === 0 && react_1["default"].createElement("p", { style: { textAlign: 'center' } }, "Nu exista pagini"),
        react_1["default"].createElement(RoundButton_1.RoundButton, { disabled: false, className: 'custom-btn-primary', text: 'Pagina noua', onClickHandler: function () { setIsAddNewPageDialogOpen(true); } }),
        react_1["default"].createElement(UserDialogs_1.CreateResourceDialog, { isOpen: isAddNewPageDialogOpen, toggle: function () { setIsAddNewPageDialogOpen(!isAddNewPageDialogOpen); }, saveBtnOverwride: "Adauga", title: "Pagina noua", onConfirm: function () {
                (0, DataProvider_1.post)("".concat(target_endpoint, "/CreatePage"), {
                    'materialId': material_id,
                    'newPageName': addNewPageName
                }, function (response) {
                    (0, DataProvider_1.parseTrueOrErrorMessage)(response, function (response) {
                        loadPages();
                        setIsAddNewPageDialogOpen(false);
                    });
                });
            } },
            react_1["default"].createElement(FormGroup_1.FormGroup, { value: addNewPageName, onChange: function (e) { setAddNewPageName(e.target.value); }, label: 'Nume pagina' }))));
};
exports.PageList = PageList;
