"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.MediaImage = exports.MediaEditor = exports.MediaRecord = exports.MediaEditorPayload = exports.eLearningMediaEditorAllowedFileTypesVideos = exports.eLearningMediaEditorAllowedFileTypesImages = void 0;
var react_1 = __importStar(require("react"));
var RoundButton_1 = require("../../generic-building-blocks/RoundButton");
var react_beautiful_dnd_1 = require("react-beautiful-dnd");
var reactstrap_1 = require("reactstrap");
var DataProvider_1 = require("../../../utils/DataProvider");
var SearchBox_1 = require("../../generic-building-blocks/SearchBox");
var CircleLoadingIndicator_1 = require("../../generic-building-blocks/CircleLoadingIndicator");
var LayoutCard_1 = require("../../generic-building-blocks/LayoutCard");
var FileTypeIcon_1 = require("../../generic-building-blocks/FileTypeIcon");
var Utils_1 = require("../../../utils/Utils");
var CdnImage_1 = require("../../generic-building-blocks/CdnImage");
var ai_1 = require("react-icons/ai");
var AuthService_1 = require("../../api-authorization/AuthService");
exports.eLearningMediaEditorAllowedFileTypesImages = [
    'png',
    'jpg',
    'jpeg',
    'gif',
    'tiff',
];
exports.eLearningMediaEditorAllowedFileTypesVideos = [
    'mp4',
    'mov',
    'wmv',
    'flv',
    'avi',
    'avchd',
    'webm',
    'mkv',
];
var MediaEditorPayload = /** @class */ (function () {
    function MediaEditorPayload() {
        this.records = [];
        this.previewType = "0";
    }
    return MediaEditorPayload;
}());
exports.MediaEditorPayload = MediaEditorPayload;
var MediaRecord = /** @class */ (function () {
    function MediaRecord() {
    }
    return MediaRecord;
}());
exports.MediaRecord = MediaRecord;
var MediaEditor = function (_a) {
    var componentId = _a.componentId, initialValue = _a.initialValue, updateValue = _a.updateValue, media_resources_endpoint = _a.media_resources_endpoint;
    var _b = (0, react_1.useState)(false), loadingRecords = _b[0], setLoadingRecords = _b[1];
    var _c = (0, react_1.useState)((initialValue !== undefined && initialValue !== null && JSON.stringify(initialValue) !== "{}") ? initialValue : { records: [], previewType: "0" }), value = _c[0], setValue = _c[1];
    var _d = (0, react_1.useState)(false), isAddNewMediaDialogOpen = _d[0], setIsAddNewMediaDialogOpen = _d[1];
    var onDragEnd = function (result) {
        if (!result.destination) {
            return;
        }
        var valueCpy = value;
        var removed = valueCpy.records.splice(result.source.index, 1);
        valueCpy.records.splice(result.destination.index, 0, removed[0]);
        updateValue(valueCpy);
        setValue(valueCpy);
    };
    var removeMediaRecords = function (index) {
        setLoadingRecords(true);
        var valueCpy = value;
        valueCpy.records.splice(index, 1);
        updateValue(valueCpy);
        setValue(valueCpy);
        setTimeout(function () {
            setLoadingRecords(false);
        }, 100);
    };
    var _e = (0, react_1.useState)(''), searchBoxValue = _e[0], setSearchBoxValue = _e[1];
    (0, react_1.useEffect)(function () {
        if (isAddNewMediaDialogOpen === false)
            return;
        load_dialog_records();
        setAttachMediaSelectedItems([]);
    }, [isAddNewMediaDialogOpen, searchBoxValue]);
    var load_dialog_records = function (set_as_active) {
        if (set_as_active === void 0) { set_as_active = undefined; }
        (0, DataProvider_1.get_ajax)("".concat(media_resources_endpoint, "/GetMediaResources?search=").concat(searchBoxValue, "&page=0"), function (response) {
            setAttachMediaDialogRecords(response);
            if (set_as_active !== undefined) {
                setAttachMediaSelectedItems([response.filter(function (x) { return x.id === set_as_active; })[0]]);
            }
            setAttachMediaDialogIsLoading(false);
        });
    };
    var _f = (0, react_1.useState)(true), attachMediaDialogIsLoading = _f[0], setAttachMediaDialogIsLoading = _f[1];
    var _g = (0, react_1.useState)([]), attachMediaDialogRecords = _g[0], setAttachMediaDialogRecords = _g[1];
    var _h = (0, react_1.useState)([]), attachMediaSelectedItems = _h[0], setAttachMediaSelectedItems = _h[1];
    var addSelectedMediaRecords = function () {
        var valueCpy = value;
        attachMediaSelectedItems.forEach(function (record) {
            var extension = record.blobName.split('.').pop().toLowerCase();
            if (!exports.eLearningMediaEditorAllowedFileTypesImages.includes(extension) && !exports.eLearningMediaEditorAllowedFileTypesVideos.includes(extension))
                alert("Fisierul ".concat(record.originalName, " nu este valid pentru tipul 'Media'. Acesta va fi ignorat"));
            else {
                valueCpy.records.push({
                    file_name: record.originalName,
                    media_record_id: record.id,
                    blob_name: record.blobName
                });
            }
        });
        updateValue(valueCpy);
        setValue(valueCpy);
        setAttachMediaSelectedItems([]);
    };
    var _j = (0, react_1.useState)(undefined), file = _j[0], setFile = _j[1];
    var onDropHandler = function (ev) {
        ev.preventDefault();
        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    setFile(file);
                    break;
                }
            }
        }
        else {
            if (ev.dataTransfer.files.length > 0)
                setFile(ev.dataTransfer.files[0]);
        }
    };
    var uploadFile = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, request, token;
        return __generator(this, function (_a) {
            data = new FormData();
            // data.encoding = "multipart/form-data";
            data.append("file", file);
            request = new XMLHttpRequest();
            request.upload.addEventListener('progress', function (e) {
                setUploadFileProgress(e.loaded);
                setUploadFileProgressTotal(e.total);
                if (e.total === e.loaded) {
                    setTimeout(function () {
                        setFile(undefined);
                        setUploadFileProgress(0);
                        setUploadFileProgressTotal(0);
                    }, 1000);
                }
            }.bind(this), false);
            request.onreadystatechange = function () {
                if (request.readyState === 4) {
                    load_dialog_records(request.response);
                }
            };
            request.open('POST', "".concat(media_resources_endpoint, "/UploadNewResource"));
            token = (0, AuthService_1.getAccessToken)();
            request.setRequestHeader('Authorization', token);
            request.send(data);
            return [2 /*return*/];
        });
    }); };
    var _k = (0, react_1.useState)(0), uploadFileProgress = _k[0], setUploadFileProgress = _k[1];
    var _l = (0, react_1.useState)(0), uploadFileProgressTotal = _l[0], setUploadFileProgressTotal = _l[1];
    var perc = ((uploadFileProgress * 100) / uploadFileProgressTotal).toFixed(2);
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        react_1["default"].createElement("div", { className: 'row mediaEditor' },
            react_1["default"].createElement("div", { className: 'col-md-8' },
                react_1["default"].createElement(react_beautiful_dnd_1.DragDropContext, { onDragEnd: onDragEnd },
                    react_1["default"].createElement(react_beautiful_dnd_1.Droppable, { droppableId: "media_dnd_".concat(componentId) }, function (provided) { return (react_1["default"].createElement("div", __assign({ ref: provided.innerRef }, provided.droppableProps), (value !== null && value.records !== null && !loadingRecords) && (react_1["default"].createElement(react_1["default"].Fragment, null,
                        value.records.map(function (record, index) {
                            return react_1["default"].createElement(react_beautiful_dnd_1.Draggable, { draggableId: "page_components_viewer_draggable_id_".concat(index), key: index, index: index }, function (provided) { return (react_1["default"].createElement("div", __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps),
                                react_1["default"].createElement("div", { className: 'row' },
                                    react_1["default"].createElement("div", __assign({ className: 'col-md-4' }, provided.dragHandleProps),
                                        react_1["default"].createElement(exports.MediaImage, { src: record.blob_name })),
                                    react_1["default"].createElement("div", { className: 'col-md-8' },
                                        react_1["default"].createElement("span", { style: { margin: 0, position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%' } },
                                            record.file_name,
                                            react_1["default"].createElement(ai_1.AiOutlineClose, { className: 'float-right', style: { marginRight: '25px' }, size: 18, onClick: function () { removeMediaRecords(index); } })))),
                                react_1["default"].createElement("br", null))); });
                        }),
                        provided.placeholder)))); })),
                (value === null || value === undefined || value.records.length === 0) && (react_1["default"].createElement("small", null, "Nu exista fisiere media atasate."))),
            react_1["default"].createElement("div", { className: 'col-md-4' },
                react_1["default"].createElement(RoundButton_1.RoundButton, { className: "custom-btn-primary", text: "Adauga fisier media", onClickHandler: function () { setIsAddNewMediaDialogOpen(true); } }),
                react_1["default"].createElement("br", null),
                react_1["default"].createElement("div", { className: 'form-group' },
                    react_1["default"].createElement("label", null, "Previzualizare:"),
                    react_1["default"].createElement("select", { className: 'form-control no-outline', value: value.previewType, onChange: function (e) {
                            var cpy = JSON.parse(JSON.stringify(value));
                            cpy.previewType = e.target.value;
                            updateValue(cpy);
                            setValue(cpy);
                        } },
                        react_1["default"].createElement("option", { value: 0 }, "o coloana"),
                        react_1["default"].createElement("option", { value: 1 }, "2 coloane"),
                        react_1["default"].createElement("option", { value: 2 }, "3 coloane"))))),
        react_1["default"].createElement(reactstrap_1.Modal, { isOpen: isAddNewMediaDialogOpen, toggle: function () { setIsAddNewMediaDialogOpen(false); }, size: 'xl' },
            react_1["default"].createElement(reactstrap_1.ModalHeader, null, "Ataseaza fisier media"),
            react_1["default"].createElement(reactstrap_1.ModalBody, null,
                react_1["default"].createElement("div", { className: 'row' },
                    react_1["default"].createElement("div", { className: 'col-md-8' },
                        react_1["default"].createElement(SearchBox_1.SearchBox, { value: searchBoxValue, placeholder: "Cauta", onChange: function (e) { setSearchBoxValue(e.target.value); } }),
                        react_1["default"].createElement("br", null),
                        attachMediaDialogIsLoading && (react_1["default"].createElement(CircleLoadingIndicator_1.LoadingCircleComponent, null)),
                        !attachMediaDialogIsLoading && (react_1["default"].createElement(react_1["default"].Fragment, null,
                            react_1["default"].createElement("div", { className: 'row' }, attachMediaDialogRecords.map(function (rec, i) {
                                return react_1["default"].createElement("div", { className: 'col-md-4', key: i },
                                    react_1["default"].createElement("div", { className: 'maxHeight', style: { paddingBottom: '25px' } },
                                        react_1["default"].createElement(LayoutCard_1.LayoutCard, { className: attachMediaSelectedItems.indexOf(rec) !== -1 ?
                                                "layout_card1 shadow-sm no_padding maxHeight top_bottom inner-border-primary" :
                                                "layout_card1 shadow-sm no_padding maxHeight top_bottom inner-border-card1", onClick: function () {
                                                var cpy = __spreadArray([], attachMediaSelectedItems, true);
                                                var indexOf = attachMediaSelectedItems.indexOf(rec);
                                                if (indexOf !== -1)
                                                    cpy.splice(indexOf, 1);
                                                else
                                                    cpy.push(rec);
                                                setAttachMediaSelectedItems(cpy);
                                            } },
                                            react_1["default"].createElement(FileTypeIcon_1.MediaPreviewIcon, { size: 52, style: { margin: 'auto', display: 'block', marginTop: '40px' }, blobName: rec.blobName, fileName: rec.originalName, has_video_controls: false }),
                                            react_1["default"].createElement("p", { style: {
                                                    textAlign: 'center',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    marginBottom: '0'
                                                }, className: 'padded_bottom' },
                                                react_1["default"].createElement("b", null, rec.originalName),
                                                react_1["default"].createElement("br", null),
                                                react_1["default"].createElement("small", null, (0, Utils_1.formatBytes)(rec.fileSize))))));
                            })),
                            attachMediaDialogRecords.length == 0 && (react_1["default"].createElement(react_1["default"].Fragment, null,
                                react_1["default"].createElement("br", null),
                                react_1["default"].createElement("p", { style: { textAlign: 'center' } }, "Nu exista resurse."),
                                react_1["default"].createElement("br", null)))))),
                    react_1["default"].createElement("div", { className: 'col-md-4' },
                        react_1["default"].createElement("div", { className: 'drop_zone', onDrop: onDropHandler, onDragOver: function (ev) { ev.preventDefault(); } },
                            react_1["default"].createElement("p", { style: { marginBottom: '0' } }, file === undefined ? "Trage fisierele aici" : file.name)),
                        react_1["default"].createElement(RoundButton_1.RoundButton, { className: "custom-btn-primary", text: 'Alege fisier', onClickHandler: function () { document.getElementById("add_resource_input").click(); } }),
                        react_1["default"].createElement("input", { type: "file", id: "add_resource_input", hidden: true, onChange: function (e) {
                                if (e.target.files.length > 0)
                                    setFile(e.target.files[0]);
                            } }),
                        react_1["default"].createElement("br", null),
                        react_1["default"].createElement(RoundButton_1.RoundButton, { disabled: file === undefined, className: "custom-btn-primary", text: 'Incarca fisier', onClickHandler: uploadFile }),
                        uploadFileProgressTotal != 0 && (react_1["default"].createElement("div", { style: { margin: 'auto', width: "calc(100% - 20px)" } },
                            react_1["default"].createElement("br", null),
                            react_1["default"].createElement("div", { className: "progress" },
                                react_1["default"].createElement("div", { className: "progress-bar", style: { width: perc + "%" } }, perc + "%"))))))),
            react_1["default"].createElement(reactstrap_1.ModalFooter, null,
                react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: function () { setIsAddNewMediaDialogOpen(false); } }, "Anuleaza"),
                react_1["default"].createElement(reactstrap_1.Button, { color: "primary", className: "no-outline", onClick: function () { addSelectedMediaRecords(); setIsAddNewMediaDialogOpen(false); } }, "Ataseaza")))));
};
exports.MediaEditor = MediaEditor;
var MediaImage = function (_a) {
    var src = _a.src;
    return (react_1["default"].createElement(CdnImage_1.CdnMediaResource, { blobName: src, preview_style: { width: '95%', marginBottom: '5px', display: 'block', margin: 'auto' }, has_controls: true }));
};
exports.MediaImage = MediaImage;
