import React, { useEffect, useState } from 'react';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { RoundButton, RoundRedirectButton } from 'shared/dist/components/generic-building-blocks/RoundButton';
import AOS from 'aos';
import "aos/dist/aos.css";
import {PageConfigurator} from 'shared/dist/components/elearning_builder/PageConfigurator';
import {PageList} from 'shared/dist/components/elearning_builder/PageList';
import {FiEdit2} from 'react-icons/fi';
import {PageDetailsEditor} from 'shared/dist/components/elearning_builder/PageDetailsEditor';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { FormGroup } from 'shared/dist/components/generic-building-blocks/FormGroup';
import { get_ajax, post, parseTrueOrErrorMessage } from 'shared/dist/utils/DataProvider';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import {MdIntegrationInstructions} from 'react-icons/md';
import {CourseViewer} from 'shared/dist/components/elearning_viewer/CourseViewer';

export const TrainingDetails = (props) => {
    useEffect(()=>{
        AOS.init();
    }, []);
    const [trainingId, setTrainingId] = useState(undefined);
    useEffect(()=>{
        if(props.match.params.training_id != undefined)
            setTrainingId(props.match.params.training_id);
    }, [props.match.params.training_id]);

    const [detaliiInstruireIsEdit, setDetaliiInstruireIsEdit] = useState(false);
    const [trainingDetails, setTrainingDetails] = useState(undefined);
    const [pages, setPages] = useState(undefined);
    const [selectedPageId, setSelectedPageId] = useState(undefined);
    useEffect(()=>{ if(trainingId === undefined) { return; } loadData(); }, [trainingId]);
    const loadData = () => {
        get_ajax(`ExpertSSM/InstruiriManager/GetInstructionDetails?id=${trainingId}`, (response)=>{
            setTrainingDetails(response);
        });
        get_ajax(`ExpertSSM/InstruiriManager/GetPages?id=${trainingId}`, (response) => {
            if(response.length > 0)
                setSelectedPageId(response[0].pageId);
            setPages(response);
        });
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const pages_cpy = [...pages];
        const [removed] = pages_cpy.splice(result.source.index, 1);
        pages_cpy.splice(result.destination.index, 0, removed);
        setPages(pages_cpy);
        post(`ExpertSSM/InstruiriManager/ReorderPages`, {
            'id': trainingId, 
            'sourceIndex': result.source.index,
            'destinationIndex': result.destination.index
        }, (response)=>{
            parseTrueOrErrorMessage(response, (response) => {});
        });
    }
    
    const [selectNewInstructionModalVisible, setSelectNewInstructionModalVisible] = useState(false);
    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [attachDialogIsLoading, setAttachDialogIsLoading] = useState(true);
    const [attachDialogRecords, setAttachDialogRecords] = useState([]);
    const [attachMediaSelectedItems, setAttachMediaSelectedItems] = useState([]);
    useEffect(()=>{
        if(selectNewInstructionModalVisible === false) return;
        load_dialog_records();
        setAttachMediaSelectedItems([]);
    }, [selectNewInstructionModalVisible, searchBoxValue]);
    const load_dialog_records = () => {
        get_ajax("ExpertSSM/InstructionsManager/GetInstructions?search=" + searchBoxValue + "&page=0", (response) =>{
            setAttachDialogRecords(response);
            setAttachDialogIsLoading(false);
        });
    }
    const addSelectedPages = () => {
        const pagesCpy = [...pages];
        attachMediaSelectedItems.forEach(record => {
            pagesCpy.push({
                pageId: record.id,
                pageName: record.originalName
            });
            post('ExpertSSM/InstruiriManager/AddNewPage', {
                'id': trainingId, 
                'pageId': record.id,
                'pageName': record.originalName
            }, (response) => {
                parseTrueOrErrorMessage(response, (response) => {});
            });
        });
        if(pages.length === 0)
            setSelectedPageId(pagesCpy[0].pageId);
        setPages(pagesCpy);
    }

    return (
        <>
            <div className='row'>
                <div className='col-md-4'>
                    <RoundRedirectButton 
                        className="custom-btn-primary"
                        text="Inapoi"
                        redirectUrl={'/expertssm/training_sets'}
                    />
                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-md-4'>
                    <div data-aos="flip-up">
                        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                            <div className='row'>
                                <div className='col-md-7'>
                                    <h4>Detalii instruire</h4>
                                </div>
                                <div className='col-md-5'>
                                    {!detaliiInstruireIsEdit && (
                                        <div className="float-right">
                                            <FiEdit2 onClick={()=>{setDetaliiInstruireIsEdit(true);}}/>                                
                                        </div>
                                    )}
                                    {detaliiInstruireIsEdit && (
                                        <div className='row'>
                                            <RoundButton
                                                className="custom-btn-primary"
                                                onClickHandler={()=>{
                                                    post('ExpertSSM/InstruiriManager/SetInstructionDetails', 
                                                    {
                                                        'id': trainingId,
                                                        'materialName': trainingDetails.originalName
                                                    }, (response) => {
                                                        parseTrueOrErrorMessage(response, (response) => {
                                                            setDetaliiInstruireIsEdit(false);
                                                            loadData();
                                                        });
                                                    });
                                                }}
                                                text="Salveaza"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {trainingDetails === undefined && (<><LoadingCircleComponent/><br/></>)}
                            {trainingDetails !== undefined && (
                                <>
                                    <FormGroup
                                        label={'Nume'}
                                        value={trainingDetails.originalName}
                                        disabled={!detaliiInstruireIsEdit}
                                        onChange={(e) => {
                                            const cpy = JSON.parse(JSON.stringify(trainingDetails));
                                            cpy.originalName = e.target.value;
                                            setTrainingDetails(cpy);
                                        }}
                                    />
                                    <FormGroup
                                        label={'Actualizat de catre'}
                                        value={trainingDetails.updatedBy}
                                        disabled={true}
                                    />
                                    <FormGroup
                                        label={'Actualizat la data de'}
                                        value={trainingDetails.updatedAt}
                                        disabled={true}
                                    />
                                </>
                            )}
                            <RoundButton
                                className="custom-btn-primary"
                                text="Seteaza ca prestabilit" 
                                onClickHandler={()=>{ 
                                    post('ExpertSSM/InstruiriManager/SetInstructionAsDefault', {'id': trainingId} ,(response) => {
                                        parseTrueOrErrorMessage(response, (response) => {
                                            alert("Actualizat.");
                                        }); 
                                    })
                                }}
                            />
                        </LayoutCard>
                    </div>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Materiale selectate</h4>
                        <br/>
                        {pages === undefined && (<LoadingCircleComponent/>)}
                        {pages !== undefined && (
                            <>  
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="page_tree_viewer">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                {pages.map((page, i) =>  (
                                                    <Draggable draggableId={`page_tree_viewer_draggable_id_${i}`} key={i} index={i}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.dragHandleProps}
                                                                {...provided.draggableProps}
                                                            >
                                                                <div 
                                                                    className={selectedPageId === page.pageId ? "list-group-item layout_card_active" : "list-group-item"}
                                                                    onClick={() => { setSelectedPageId(page.pageId); }}
                                                                >
                                                                    {page.pageName}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                {pages.length === 0 && <p style={{textAlign: 'center'}}>Nu exista instructiuni.</p>}         
                                <br/>   
                                <RoundButton
                                    disabled={false}
                                    className={'custom-btn-primary'}
                                    text={'Adauga material'}
                                    onClickHandler={()=>{ setSelectNewInstructionModalVisible(true); }}
                                />
                            </>
                        )}
                    </LayoutCard>
                </div>
                <div className='col-md-8'>
                    {selectedPageId !== undefined && (
                        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                            <CourseViewer
                                material_id={selectedPageId}
                                target_endpoint={'ExpertSSM/InstructionsManager'}
                            />
                        </LayoutCard>
                    )}
                </div>
            </div>

            <Modal isOpen={selectNewInstructionModalVisible} toggle={() => {setSelectNewInstructionModalVisible(false)}} size={'xl'}>
                <ModalHeader>Alege materiale</ModalHeader>
                <ModalBody>
                    <SearchBox 
                        value={searchBoxValue} 
                        placeholder="Cauta" 
                        onChange={(e) =>{ setSearchBoxValue(e.target.value); }}
                    />
                    <br/>
                    {attachDialogIsLoading && (<LoadingCircleComponent/>)}
                    {!attachDialogIsLoading && (
                        <>
                            <div className='row'>
                                {attachDialogRecords.map((rec, i)=> 
                                    <div className='col-md-4' key={i}>
                                        <LayoutCard 
                                            className={
                                                attachMediaSelectedItems.indexOf(rec) !== -1 ?
                                                    "layout_card1 shadow-sm no_padding maxHeight top_bottom inner-border-primary":
                                                    "layout_card1 shadow-sm no_padding maxHeight top_bottom inner-border-card1"}
                                            onClick={()=>{
                                                const cpy = [...attachMediaSelectedItems];
                                                const indexOf = attachMediaSelectedItems.indexOf(rec);
                                                if(indexOf !== -1)
                                                    cpy.splice(indexOf, 1);
                                                else
                                                    cpy.push(rec);
                                                setAttachMediaSelectedItems(cpy);
                                            }}
                                        >
                                            <MdIntegrationInstructions
                                                size={52} 
                                                style={{margin: 'auto', display: 'block', marginTop: '40px'}}
                                            />
                                            <p 
                                                style={{
                                                    textAlign: 'center',
                                                    marginBottom: '0',
                                                    whiteSpace: 'normal'
                                                }}
                                                className='padded_bottom'
                                            >
                                                {rec.originalName}
                                            </p>
                                        </LayoutCard>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="no-outline" onClick={() => { setSelectNewInstructionModalVisible(false); }}>Anuleaza</Button>
                    <Button color="primary" className="no-outline" onClick={() => { addSelectedPages(); setSelectNewInstructionModalVisible(false); }}>Adauga</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}