"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.CourseViewer = void 0;
var react_1 = __importStar(require("react"));
var DataProvider_1 = require("../../utils/DataProvider");
var CircleLoadingIndicator_1 = require("../generic-building-blocks/CircleLoadingIndicator");
var PageViewer_1 = require("./PageViewer");
var CourseViewer = function (_a) {
    var material_id = _a.material_id, target_endpoint = _a.target_endpoint;
    (0, react_1.useEffect)(function () {
        setIsLoading(true);
        setPages([]);
        setSelectedPageId(undefined);
        setSelectedPageIndex(0);
        if (material_id === undefined)
            return;
        loadPages();
    }, [material_id]);
    var _b = (0, react_1.useState)([]), pages = _b[0], setPages = _b[1];
    var _c = (0, react_1.useState)(true), isLoading = _c[0], setIsLoading = _c[1];
    var _d = (0, react_1.useState)(undefined), selectedPageId = _d[0], setSelectedPageId = _d[1];
    var _e = (0, react_1.useState)(0), selectedPageIndex = _e[0], setSelectedPageIndex = _e[1];
    (0, react_1.useEffect)(function () {
        if (pages.length > selectedPageIndex)
            setSelectedPageId(pages[selectedPageIndex].pageId);
    }, [selectedPageIndex]);
    var loadPages = function (redirect) {
        if (redirect === void 0) { redirect = true; }
        if (material_id === undefined)
            return;
        (0, DataProvider_1.get_ajax)("".concat(target_endpoint, "/GetPages?materialId=").concat(material_id), function (response) {
            if (response.length > 0 && redirect)
                setSelectedPageId(response[0].pageId);
            setPages(response);
            setIsLoading(false);
        });
    };
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        (isLoading && selectedPageId === undefined) && (react_1["default"].createElement(CircleLoadingIndicator_1.LoadingCircleComponent, null)),
        (!isLoading && selectedPageId === undefined) && (react_1["default"].createElement("p", { style: { textAlign: 'center' } }, "Aceasta instructiune este goala.")),
        (selectedPageId !== undefined && pages.length > 0) && (react_1["default"].createElement(react_1["default"].Fragment, null,
            react_1["default"].createElement("h4", { style: { textAlign: 'center' } }, pages[selectedPageIndex].pageName),
            react_1["default"].createElement("br", null),
            react_1["default"].createElement(PageViewer_1.PageViewer, { material_id: material_id, target_endpoint: target_endpoint, selected_page_id: selectedPageId }),
            react_1["default"].createElement("br", null),
            react_1["default"].createElement("ul", { className: "pagination" },
                react_1["default"].createElement("li", { className: "page-item" },
                    react_1["default"].createElement("button", { disabled: selectedPageIndex <= 0, className: "page-link btn no-outline", onClick: function () { setSelectedPageIndex(selectedPageIndex - 1); }, style: { borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0 } }, "Inapoi")),
                react_1["default"].createElement("li", { className: "page-item" },
                    react_1["default"].createElement("button", { className: "page-link btn no-outline", style: { cursor: "auto", borderRadius: 0 } }, pages[selectedPageIndex].pageName)),
                react_1["default"].createElement("li", { className: "page-item" },
                    react_1["default"].createElement("button", { disabled: pages.length <= selectedPageIndex + 1, className: "page-link btn no-outline", onClick: function () { setSelectedPageIndex(selectedPageIndex + 1); }, style: { borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }, "Inainte")))))));
};
exports.CourseViewer = CourseViewer;
