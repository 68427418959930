"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.PageViewer = void 0;
var react_1 = __importStar(require("react"));
var DataProvider_1 = require("../../utils/DataProvider");
var MediaViewer_1 = require("./components/MediaViewer");
var TextViewer_1 = require("./components/TextViewer");
var PageViewer = function (_a) {
    var material_id = _a.material_id, target_endpoint = _a.target_endpoint, selected_page_id = _a.selected_page_id;
    var _b = (0, react_1.useState)([]), components = _b[0], setComponents = _b[1];
    var _c = (0, react_1.useState)(true), isLoading = _c[0], setIsLoading = _c[1];
    (0, react_1.useEffect)(function () {
        if (material_id === undefined || selected_page_id === undefined)
            return;
        loadPageDetails();
    }, [material_id, selected_page_id]);
    var loadPageDetails = function (delay) {
        if (delay === void 0) { delay = false; }
        setIsLoading(true);
        (0, DataProvider_1.get_ajax)("".concat(target_endpoint, "/GetPage?materialId=").concat(material_id, "&pageId=").concat(selected_page_id), function (response) {
            setTimeout(function () {
                setComponents(response);
                setIsLoading(false);
            }, !delay ? 1 : 100);
        });
    };
    return (react_1["default"].createElement(react_1["default"].Fragment, null, components.map(function (component, index) { return (react_1["default"].createElement(react_1["default"].Fragment, { key: index },
        component.type === 0 && (react_1["default"].createElement(TextViewer_1.TextViewer, { initialValue: JSON.parse(component.json) })),
        component.type === 1 && (react_1["default"].createElement(MediaViewer_1.MediaViewer, { initialValue: JSON.parse(component.json) })))); })));
};
exports.PageViewer = PageViewer;
