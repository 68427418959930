"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.MaterialDetails = void 0;
var react_1 = __importStar(require("react"));
var DataProvider_1 = require("../../utils/DataProvider");
var CircleLoadingIndicator_1 = require("../generic-building-blocks/CircleLoadingIndicator");
var FormGroup_1 = require("../generic-building-blocks/FormGroup");
var MaterialDetails = function (_a) {
    var material_id = _a.material_id, target_endpoint = _a.target_endpoint, is_edit_mode = _a.is_edit_mode, trigger_save_event = _a.trigger_save_event, data_saved_callback = _a.data_saved_callback;
    (0, react_1.useEffect)(function () {
        if (material_id === undefined)
            return;
        (0, DataProvider_1.get_ajax)("".concat(target_endpoint, "/GetMaterialDetails?materialId=").concat(material_id), function (response) {
            setMaterialDetails(response);
            setIsLoading(false);
        });
    }, [material_id]);
    (0, react_1.useEffect)(function () {
        if (trigger_save_event === false)
            return;
        (0, DataProvider_1.post)("".concat(target_endpoint, "/SetMaterialDetails"), {
            'materialId': material_id,
            'materialName': materialDetails.originalName
        }, function (response) {
            (0, DataProvider_1.parseTrueOrErrorMessage)(response, function (response) {
                data_saved_callback();
            });
        });
    }, [trigger_save_event]);
    var _b = (0, react_1.useState)(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = (0, react_1.useState)(undefined), materialDetails = _c[0], setMaterialDetails = _c[1];
    if (isLoading)
        return (react_1["default"].createElement(CircleLoadingIndicator_1.LoadingCircleComponent, null));
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        react_1["default"].createElement(FormGroup_1.FormGroup, { label: 'Nume', value: materialDetails.originalName, disabled: !is_edit_mode, onChange: function (e) {
                var cpy = JSON.parse(JSON.stringify(materialDetails));
                cpy.originalName = e.target.value;
                setMaterialDetails(cpy);
            } }),
        react_1["default"].createElement(FormGroup_1.FormGroup, { label: 'Actualizat de catre', value: materialDetails.updatedBy, disabled: true }),
        react_1["default"].createElement(FormGroup_1.FormGroup, { label: 'Actualizat la data de', value: materialDetails.updatedAt, disabled: true })));
};
exports.MaterialDetails = MaterialDetails;
