import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {SearchBox} from 'shared/dist/components/generic-building-blocks/SearchBox';
import {RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import {get, get_ajax, post, parseTrueOrErrorMessage} from 'shared/dist/utils/DataProvider';
import {Redirect} from 'react-router-dom';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {FormGroup, FormGroupArea} from 'shared/dist/components/generic-building-blocks/FormGroup';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import {BsFillPersonFill, BsBuilding} from 'react-icons/bs';
import {VscOpenPreview} from 'react-icons/vsc';
import {AiFillWarning} from 'react-icons/ai';
import {FaFileMedical} from 'react-icons/fa';
import {CgDanger} from 'react-icons/cg';
import {IfYesDetails, YesNoDetails, YesNoDetailsArea } from 'shared/dist/components/generic-building-blocks/YesNoDetails';
import {YesNoPicker} from 'shared/dist/components/generic-building-blocks/YesNoPicker';
import {Checkbox} from 'shared/dist/components/generic-building-blocks/Checkbox';
import {conditiiMuncaDefaults, default_risks_macheta, riscuriDefaults} from '../medical-control/defaultOptions';
import {DynamicTable } from 'shared/dist/components/generic-building-blocks/DynamicTable';
import { UpdateResultSuccessDialog} from 'shared/dist/components/generic-building-blocks/UserDialogs';
import Autocomplete from 'shared/dist/components/generic-building-blocks/AutocompleteTextbox';
import {FaUser}from 'react-icons/fa';
import $ from 'jquery';

import {TOAST_NOTIFICATION, displayToastNotification} from 'shared/dist/utils/NotificationsContext';

import AOS from 'aos';
import "aos/dist/aos.css";
import { MediaControlRequestRender } from './shared/MedicalControlRequestRender';
import { getAccessToken } from 'shared/dist/components/api-authorization/AuthService';

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


function get_today_date(){
    var today = new Date(); 
    var dd = today.getDate(); 
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear(); 
    return `${dd}-${mm}-${yyyy}`;
}

function isEmptyOrSpaces(str){
    try { return str === null || str.match(/^ *$/) !== null; }
    catch { return true; }
}

export const Employees = () =>{
    const [isLoading, setIsLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [statistics, setStatistics] = useState(undefined);

    useEffect(()=>{     
        AOS.init();  
        get_ajax("EmployeeManagement/Employees/GetEmployeesStatistics", (response) =>{
            setStatistics(response);
        });                 
    }, []);

    const loadData = (searchBy, page) => {
        get_ajax("EmployeeManagement/Employees/GetEmployees?searchBy=" + searchBy + "&expireFilter=" + expirationFilter + "&page=" + page, (response) =>{
            setPageSize(response.objects_per_page);
            setEmployees(response.objects);
            setIsLoading(false);
        });
    }

    const [expirationFilter, setExpirationFilter] = useState('active');
    useEffect(()=>{
        if(currentPage == 0)
        {
            setSearchBoxValue('');
            loadData('', 0);
        }
        setCurrentPage(0);
    }, [expirationFilter]);
    const toggleExpirationFilter = (newValue) =>{
        if(newValue === expirationFilter)
            setExpirationFilter('');
        else
            setExpirationFilter(newValue);
    }
    
    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(0);

    useEffect(()=>{
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const [updateEmployeeXLSModalVisible,setUpdateEmployeesXLSModalVisible] = useState(false);

    return(
        <>
            <br/>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                <div className='row'>
                    <div className='col-md-6'>
                        <h4>Lucratori</h4>
                    </div>
                    <div className='col-md-3'>
                        <RoundButton
                            onClickHandler={()=>{
                                alert("Va rugam asteptati");
                                const url = "EmployeeManagement/Report/GenerateEmployeeReport";
                                const token = getAccessToken();
                                let headers = new Headers();
                                headers.append('Authorization', token);
                                fetch(url, {headers})
                                    .then(response => response.blob())
                                    .then(blobby => {
                                        const anchor = document.createElement("a");
                                        let objectUrl = window.URL.createObjectURL(blobby);
                                        anchor.href = objectUrl;
                                        anchor.download = 'Raport.xlsx';
                                        anchor.click();
                                        window.URL.revokeObjectURL(objectUrl);
                                    });
                            }}
                            className="btn-secondary"
                            text="Descarca raport"
                        />
                    </div>
                    <div className='col-md-3'>
                        <RoundButton
                            onClickHandler={()=>{
                                setUpdateEmployeesXLSModalVisible(true);
                            }}
                            className="btn-secondary"
                            text="Actualizare XLSX"
                        />
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-6">
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                        <RoundButton 
                            className="custom-btn-primary"
                            text="Lucrator nou"
                            onClickHandler={()=>window.location.href='/medical-control/workers/new'}
                        />
                    </div>
                </div>
            </LayoutCard>
            {isLoading && (
                <>
                    <br/>
                    <LoadingCircleComponent/>
                </>
            )}
            {!isLoading && (
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            {employees.map((e, i) =>
                                <div className="col-md-4" key={i}>
                                        <Link to = {"/medical-control/workers/details/" + e.id}>                                            
                                            <LayoutCard                                        
                                                className={e.isActive === false ? "layout_card1 shadow-sm disabled_grayscale" : "layout_card1 shadow-sm"}
                                                key={i} 
                                            >
                                                {e.isActive === true && (
                                                    <div className={
                                                        e.status == "expired" ? 'color_tag color_red' 
                                                        : e.status == "expire_7days" ? 'color_tag apt_cond' 
                                                        : e.status == "expire_14days" ? 'color_tag blue' 
                                                        : e.status == "expire_30days" ? 'color_tag inapt_temp' 
                                                        : e.status == "planned" ? 'color_tag green_color' 
                                                        : 'color_tag unknown'}
                                                    />
                                                )}
                                                <div style={{textAlign: 'center'}}>                                                    
                                                    <FaUser size={64} className="avatar_container"/>
                                                    <h6>{`${e.nume} ${e.prenume}`}</h6>
                                                    <label
                                                        style={{
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        {e.locMunca}
                                                        <br/>
                                                        {e.compartiment}
                                                    </label>
                                                    <br/>                                          
                                                </div>
                                            </LayoutCard>
                                        </Link>
                                </div>
                            )}                                        
                        </div>
                        {employees.length == 0 && (
                            <><br/><p style={{textAlign: 'center'}}>Nu exista lucratori.</p></>
                        )}
                        <ul className="pagination">
                            <li className="page-item">
                                <button 
                                    disabled={currentPage <= 0} 
                                    className="page-link btn no-outline" 
                                    onClick={()=>{setCurrentPage(currentPage-1)}}
                                    style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                >
                                    Inapoi
                                </button>
                            </li>
                            <li className="page-item">
                                <button 
                                    className="page-link btn no-outline"
                                    style={{cursor: "auto", borderRadius: 0}}
                                >
                                    {currentPage+1}
                                </button>
                            </li>
                            <li className="page-item">
                                <button 
                                    disabled={employees.length < pageSize} 
                                    className="page-link btn no-outline" 
                                    onClick={()=>{setCurrentPage(currentPage+1)}}
                                    style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                >
                                    Inainte
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">                        
                        {statistics === undefined && (<LoadingCircleComponent/>)}
                        {statistics !== undefined && (
                            <>
                                <div className="row noselect">
                                    <div className="col-md-6">
                                        <div
                                            data-aos="flip-up"
                                            data-aos-delay="50"
                                        >
                                            <LayoutCard
                                                className={expirationFilter === 'active' ? "layout_card_25bottom shadow-sm layout_card_active" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('active');}}
                                            >            
                                                <label>Lucratori activi</label>
                                                <br/>
                                                <h4 style={{marginBottom: '0', display: 'inline'}}>
                                                    {statistics.activeEmployeesCount}
                                                </h4>     
                                                <p style={{display: 'inline'}}>/ {statistics.totalEmployeesCount}</p> 
                                            </LayoutCard>
                                        </div>                                
                                    </div>
                                    <div className="col-md-6">
                                        <div
                                            data-aos="flip-up"
                                            data-aos-delay="50"
                                        >                                    
                                            <LayoutCard
                                                className={expirationFilter === 'expired' ? "layout_card_25bottom shadow-sm danger_card" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('expired');}}
                                            >                     
                                                <div className={'color_tag2 color_red'}/>                       
                                                <label>Scadenta depasita</label>
                                                <h4 style={{marginBottom: '0'}}>
                                                    {statistics.expired}
                                                </h4>     
                                            </LayoutCard>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div
                                            data-aos='flip-up'
                                            data-aos-delay="0"
                                        >
                                            <LayoutCard
                                                className={expirationFilter === 'expire_7days' ? "layout_card_25bottom shadow-sm warning_card" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('expire_7days');}}
                                            >       
                                                <div className={'color_tag2 apt_cond'}/>                                          
                                                <label>Scadenta in 7 de zile</label>
                                                <h4 style={{marginBottom: '0'}}>
                                                    {statistics.expire_7days}
                                                </h4>     
                                            </LayoutCard>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div
                                            data-aos="flip-up"
                                            data-aos-delay="50"
                                        >
                                            <LayoutCard
                                                className={expirationFilter === 'expire_14days' ? "layout_card_25bottom shadow-sm card_blue" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('expire_14days');}}
                                            >               
                                                <div className={'color_tag2 blue'}/>                  
                                                <label>14 de zile</label>
                                                <h4 style={{marginBottom: '0'}}>
                                                    {statistics.expire_14days}
                                                </h4>     
                                            </LayoutCard>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div
                                            data-aos='flip-up'
                                            data-aos-delay="50"
                                        >
                                            <LayoutCard
                                                className={expirationFilter === 'expire_30day' ? "layout_card_25bottom shadow-sm card_purple" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('expire_30day');}}
                                            >            
                                                <div className={'color_tag2 inapt_temp'}/>                     
                                                <label>30 de zile</label>
                                                <h4 style={{marginBottom: '0'}}>
                                                    {statistics.expire_30day}
                                                </h4>     
                                            </LayoutCard>
                                        </div>
                                    </div>
                                </div>                                
                                {/* <br/>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <label style={{marginBottom: 0}}>Lucratori apti</label>
                                    <label style={{marginBottom: 0}} className='float-right color_bar'>0</label>                                      
                                </LayoutCard>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <label style={{marginBottom: 0}}>Lucratori apti conditionat</label>
                                    <label style={{marginBottom: 0}} className='float-right color_bar color_orange'>0</label>                                      
                                </LayoutCard>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <label style={{marginBottom: 0}}>Lucratori inapti temporar</label>
                                    <label style={{marginBottom: 0}} className='float-right color_bar color_purple'>0</label>                                      
                                </LayoutCard>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <label style={{marginBottom: 0}}>Lucratori inapti</label>
                                    <label style={{marginBottom: 0}} className='float-right color_bar color_red'>0</label>                                      
                                </LayoutCard>   */}
                            </>
                        )}
                    </div>
                </div>
            )}            

            <UploadEmployeesUpdateXLSXModal
                isOpen={updateEmployeeXLSModalVisible}
                toggle={()=>{setUpdateEmployeesXLSModalVisible(false)}}
            />
        </>
    );
}

const UploadEmployeesUpdateXLSXModal = ({ isOpen, toggle }) => {
    const [uploadFileProgress, setUploadFileProgress] = useState(0);
    const [uploadFileProgressTotal, setUploadFileProgressTotal] = useState(0);
    let perc = (uploadFileProgress * 100) / uploadFileProgressTotal; 

    const [btnText, setBtnText] = useState('Alege fisier');

    const [selectedFile, setSelectedFile] = useState(null);
    const inputFileChanged = (e) => {
        setSelectedFile(e.target.files[0]);
        setBtnText(e.target.files[0].name);        
    }

    const uploadFiles = async () => {        
        var data = new FormData();
        data.encoding = "multipart/form-data";
        data.append('files', selectedFile);

        var request = new XMLHttpRequest();

        request.upload.addEventListener('progress', function (e) {
            setUploadFileProgress(e.loaded);
            setUploadFileProgressTotal(e.total);
            if (e.total === e.loaded) {
                setUploadFileProgress(0);
                setUploadFileProgressTotal(0);
                setBtnText('Alege fisier');
                toggle();                
            }
        }.bind(this), false);

        request.open('POST', 'EmployeeManagement/Employees/UploadXLSXBatchUpdateFile');
        const token = getAccessToken();
        request.setRequestHeader('Authorization', token);
        request.send(data);
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Incarcare fisier</ModalHeader>
            <ModalBody>
                <button
                    onClick={() => {
                        $('#autoInputObj_ARCH_F_UPL').trigger('click');
                    }}
                    className="btn btn-primary no-outline btn-block"
                >
                    {btnText}                    
                </button>
                <small>Atasati fisierul .xslx cu formatul corect.</small>   
                <hr/>
                <small>Recomandam acest import doar la configurarea initiala a aplicatiei.</small>                
                {uploadFileProgressTotal != 0 && (
                    <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                        <br />
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow={perc}
                                aria-valuemin="0" aria-valuemax="100" style={{ width: perc + "%" }}>
                                {perc + "%"}
                            </div>
                        </div>
                    </div>
                )}
                <input id="autoInputObj_ARCH_F_UPL" hidden type="file" style={{ display: 'none', position: 'fixed', top: '-5000em' }} onChange={inputFileChanged} multiple={false} />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={toggle}>Anuleaza</Button>
                <Button color="primary" className="no-outline" onClick={() => {
                    uploadFiles();
                    setUploadFileProgress(0);
                    setUploadFileProgressTotal(0);                    
                }}>Adauga</Button>
            </ModalFooter>
        </Modal>
    );
}

export const EmployeesNew = () =>{    
    let trueStep = 0;
    const[isLoading, setIsLoading] = useState(true);

    const[currentStep, setCurrentStep] = useState(0);
    const[registrationSteps, setRegistrationSteps] = useState([]);
    const[registrationStepsTooltips, setRegistrationStepsTooltips] = useState([]);    

    const prevStep = () =>{
        trueStep = trueStep - 1;
        setCurrentStep(trueStep);
    }
    const nextStep = () =>{
        trueStep = trueStep + 1;
        setCurrentStep(trueStep);
    }

    useEffect(()=>{
        get_ajax("EmployeeManagement/Employees/RequireUnitateSubunitateSelection", (response) =>{                                    
            const steps = [];
            const steps_tooltips = [];
            steps.push(
                <EmployeeNew_EmployeeInformation 
                    newUserData={newUserData}
                    updateNewUserData={updateNewUserData}
                    nextCallback={nextStep}
                />
            );
            steps_tooltips.push({"text": "Date identificare", "icon": <BsFillPersonFill/>});
            if(response === true)
            {
                steps.push(
                    <EmployeeNew_UnitateSubunitateSelection  
                        newUserData={newUserData}
                        updateNewUserData={updateNewUserData}
                        nextCallback={nextStep}
                        prevCallback={prevStep}
                    />
                );
                steps_tooltips.push({"text": "Selectie unitate / subunitate", "icon": <BsBuilding/>});
            }
            else if(response !== false){
                updateNewUserData('selectedUnitateId', response.selectedUnitateId);
                updateNewUserData('selectedSubunitateId', response.selectedSubunitateId);
            }
            steps.push(<EmployeeNew_FactoriRisc
                newUserData={fisaRiscuriData}
                updateNewUserData={updatefisaRiscuriData}
                nextCallback={nextStep}
                prevCallback={prevStep}
            />);
            steps_tooltips.push({"text":"Fisa de identificare a factorilor de risc profesional", "icon": <AiFillWarning/>});
            steps.push(<EmployeeNew_SolicitareExamenMedical
                newUserData={dosarMedicalRequestData}
                updateNewUserData={updatedosarMedicalRequestData}
                nextCallback={nextStep}
                prevCallback={prevStep}
                actual_user_data={fisaRiscuriData}
            />);
            steps_tooltips.push({"text":"Fisa de solicitare a examenului medical", "icon": <FaFileMedical/>});
            steps.push(<EmployeeNew_ReviewData
                newUserData={newUserData}       
                fisaRiscuriData={fisaRiscuriData}     
                dosarMedicalRequestData={dosarMedicalRequestData}
                prevCallback={prevStep}
            />);
            steps_tooltips.push({"text":"Revizuire", "icon": <VscOpenPreview/>});
            setRegistrationStepsTooltips(steps_tooltips);
            setRegistrationSteps(steps);

            setIsLoading(false);
        });
        updateNewUserData("solicitare_angajare", true);
    }, []);

    const [newUserData, setNewUserData] = useState({});    
    const updateNewUserData = (field, newValue) =>{
        const _newDataCpy = newUserData;
        _newDataCpy[field] = newValue;
        setNewUserData(_newDataCpy);        
    }

    const [fisaRiscuriData, setFisaRiscuriData] = useState(default_risks_macheta);    
    const updatefisaRiscuriData = (field, newValue) =>{
        const _newDataCpy = fisaRiscuriData;
        _newDataCpy[field] = newValue;
        setFisaRiscuriData(_newDataCpy);        
    }

    const [dosarMedicalRequestData, setDosarMedicalRequestData] = useState({});    
    const updatedosarMedicalRequestData = (field, newValue) =>{
        const _newDataCpy = dosarMedicalRequestData;
        _newDataCpy[field] = newValue;
        setDosarMedicalRequestData(_newDataCpy);        
    }

    return (
        <>
            <h4><Link className="redirectNavigationLink" to='/medical-control/workers'>Lucratori</Link> &gt; Lucrator nou</h4>
            <br/>
            {isLoading && (                
                <LoadingCircleComponent/>
            )}
            {!isLoading && (
                <>
                    <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div className="medical_registration_steps_container">
                            {registrationStepsTooltips.map((tooltip, i) =>                                 
                                <StepWithTooltipComponent
                                    key={i}
                                    index={i}
                                    isActive={currentStep == i}
                                    content={tooltip.icon}
                                    tooltipText={tooltip.text}
                                />
                            )}                                                        
                        </div>
                        <br/><br/>
                        {registrationSteps[currentStep]}                        
                    </div>
                    <div className="col-md-3"></div>
                    </div>
                </>
            )}
        </>
    );
}

//#region NewEmployeePages
export const EmployeeNew_EmployeeInformation = ({nextCallback, newUserData, updateNewUserData}) => {
    useEffect(()=>{ window.scrollTo(0,0); }, []);

    const [startedValidation, setStartedValidation] = useState(false);

    const [cnpValue, setCnpValue] = useState(newUserData["cnp"] != undefined ? newUserData["cnp"] : '');
    useEffect(()=>{        
        updateNewUserData("cnp", cnpValue);
    }, [cnpValue]);  

    const [numeValidationError, setNumeValidationError] = useState('');
    const [prenumeValidationError, setPrenumeValidationError] = useState('');
    const [dataNasteriiValidationError, setDataNasteriiValidationError] = useState('');
    const [adresaValidationError, setAdresaValidationError] = useState('');
    const [cnpValidationError, setCnpValidationError] = useState('');    

    const create_employee_data = () =>{
        let isOkay = true;
        if(isEmptyOrSpaces(newUserData['nume'])){
            isOkay = false;
            setNumeValidationError("Numele este obligatoriu");
        }else{setNumeValidationError('');}
        if(isEmptyOrSpaces(newUserData['prenume'])){
            isOkay = false;
            setPrenumeValidationError("Prenumele este obligatoriu");
        }else{setPrenumeValidationError('');}
        if(isEmptyOrSpaces(newUserData['data_nasterii'])){
            isOkay = false;
            setDataNasteriiValidationError("Data nasterii este obligatorie");
        }else{setDataNasteriiValidationError('');}
        if(isEmptyOrSpaces(newUserData['adresa'])){
            isOkay = false;
            setAdresaValidationError("Adresa este obligatorie");
        }else{setAdresaValidationError('');}
        if(isEmptyOrSpaces(newUserData['cnp'])){
            isOkay = false;
            setCnpValidationError("CNP-ul este obligatoriu");
        // }
        // else if(newUserData['cnp'].length != 13){
        //     isOkay = false;
        //     setCnpValidationError("CNP-ul nu este valid");
        }else{
            post('EmployeeManagement/Employees/CheckCnpUsedInCompany', 
            { 'cnp': newUserData['cnp']}, (response) => {
                if(response === true)
                {
                    isOkay = false;
                    setCnpValidationError("Exista un lucrator cu acest CNP. Va rugam actualizati datele din pagina lucratorului.");
                }
                else{
                    setCnpValidationError('');
                }

                if(!isOkay) 
                    return false;        
                nextCallback();
            });
        }
        setStartedValidation(true);
    }

    return (
        <>
            <h5>Date identificare</h5>
            <br/>
            <FormGroup 
                label={'Nume'}
                value={newUserData['nume']}      
                onChange={(e) =>{ updateNewUserData('nume', e.target.value); }}    
                use_validation={startedValidation}
                is_valid = {numeValidationError === ''}
                invalid_feedback = {numeValidationError}              
            />
            <FormGroup 
                label={'Prenume'}
                value={newUserData['prenume']}    
                onChange={(e) =>{ updateNewUserData('prenume', e.target.value); }}    
                use_validation={startedValidation}
                is_valid = {prenumeValidationError == ''}
                invalid_feedback = {prenumeValidationError}                             
            />
            <FormGroup 
                label={'Data nasterii'} 
                type='date'
                value={newUserData['data_nasterii']}    
                onChange={(e) =>{ updateNewUserData('data_nasterii', e.target.value); }}    
                use_validation={startedValidation}
                is_valid = {dataNasteriiValidationError == ''}
                invalid_feedback = {dataNasteriiValidationError}                                      
            />
            <FormGroup 
                label={'Adresa'}
                value={newUserData['adresa']}    
                onChange={(e) =>{ updateNewUserData('adresa', e.target.value); }}    
                use_validation={startedValidation}
                is_valid = {adresaValidationError == ''}
                invalid_feedback = {adresaValidationError}                                                    
            />
            <FormGroup 
                label={'Cnp'}
                value={cnpValue}                    
                onChange={(e) => { 
                    const value = e.target.value;
                    // if(value.length != 0)
                    //     if(/^[0-9]+$/.test(value) == false) return;
                    // if(value.length > 13) return;
                        setCnpValue(value);
                }}         
                use_validation={startedValidation}
                is_valid = {cnpValidationError == ''}
                invalid_feedback = {cnpValidationError}                                                             
            />  
            <FormGroup 
                label={'Numar telefon'}
                value={newUserData['phoneNumber']}    
                onChange={(e) =>{ updateNewUserData('phoneNumber', e.target.value); }}                                                    
            />
            <br/>
            <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                    <RoundButton 
                        className="custom-btn-primary"
                        text="Inainte"                        
                        onClickHandler={create_employee_data}
                    />
                </div>
            </div>
            <br/>
        </>
    );
}

//TODO Make sure something will be selected for the preview :-) even if no data is there :)
export const EmployeeNew_UnitateSubunitateSelection = ({nextCallback, prevCallback, newUserData, updateNewUserData}) =>{
    useEffect(()=>{ window.scrollTo(0,0); }, []);

    const [unitati, setUnitati] = useState([]);
    const [selectedUnitateIndex, setSelectedUnitateIndex] = useState(-1);

    const [subunitati, setSubunitati] = useState([]);
    const [selectedSubunitatiIndex, setSelectedSubunitatiIndex] = useState(-1);

    useEffect(()=>{
        get_ajax("EmployeeManagement/Employees/GetAngajatori", (response) => {
            setUnitati(response);
        });
    }, []);

    useEffect(()=>{
        if(unitati.length == 0) return;        
        if(newUserData["selectedUnitateId"] != undefined && 
            newUserData["selectedUnitateId"] != null &&
            newUserData["selectedUnitateId"] != -1) 
        {
            setSelectedUnitateIndex(newUserData['selectedUnitateId']);
        }
        else{
            setSelectedUnitateIndex(unitati[0].id);
            updateNewUserData('selectedUnitateId', unitati[0].id);
        }        
    }, [unitati]);

    useEffect(()=>{     
        if(selectedUnitateIndex == -1 || selectedUnitateIndex == undefined || selectedUnitateIndex == null) return;
        get_ajax("EmployeeManagement/Employees/GetSubunitati", (response) => {                        
            setSubunitati(response);   
            if(newUserData["selectedSubunitateId"] != undefined && 
                newUserData["selectedSubunitateId"] != null &&
                newUserData["selectedSubunitateId"] != -1) 
            {
                setSelectedSubunitatiIndex(newUserData['selectedSubunitateId']);
            }     
            else
            {
                setSelectedSubunitatiIndex(response[0].id);
                updateNewUserData('selectedSubunitateId', response[0].id);
            }
        });
    }, [selectedUnitateIndex]);

    return (
        <>            
            <h5>Selectie unitate / subunitate</h5>
            <br/>
            <div className="form-group">
                <label>Unitatea</label>
                <select 
                    className="form-control no-outline rounded_input" 
                    value={selectedUnitateIndex}
                    onChange={(e)=>{
                        setSelectedUnitateIndex(e.target.value);
                        updateNewUserData('selectedUnitateId', e.target.value);
                    }}
                >
                    {unitati.map((u, i) =>
                        <option value={u.id} key={i}>{u.nume}</option>
                    )}
                </select>
            </div>
            <div className="form-group">
                <label>Subunitate</label>
                <select 
                    className="form-control no-outline rounded_input"
                    value={selectedSubunitatiIndex}
                    onChange={(e)=>{
                        setSelectedSubunitatiIndex(e.target.value);
                        updateNewUserData('selectedSubunitateId', e.target.value);
                    }}
                >
                    {subunitati.map((s, i) =>
                        <option value={s.id} key={i}>{s.nume}</option>
                    )}
                </select>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-6">
                    <RoundButton 
                        className="btn-secondary"
                        text="Inapoi"                        
                        onClickHandler={()=>{
                            prevCallback();
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <RoundButton 
                        className="custom-btn-primary"
                        text="Inainte"                        
                        onClickHandler={()=>{
                            nextCallback();
                        }}
                    />
                </div>
            </div>
            <br/>
        </>
    )
}

export const EmployeeNew_FactoriRisc = ({nextCallback, prevCallback, newUserData, updateNewUserData, configMode=false, hideNavigation=false, hide_save_as_macheta=false, runReload = ()=>{}}) =>{        
    useEffect(()=>{ window.scrollTo(0,0); }, []);

    function inArray(target, array)
    {    
        for(var i = 0; i < array.length; i++) 
        if(array[i].toLowerCase() === target.toLowerCase())
                return true;   
        return false; 
    }

    //#region States and localStorage handlers   

    const [naveta, setNaveta] = useState(newUserData["naveta"] != undefined ? newUserData["naveta"] : null);
    useEffect(()=>{        
        if(configMode) return;
        updateNewUserData("naveta", naveta);
    }, [naveta]); 
    
    const [deplasariInteresServiciu, setDeplasariInteresServiciu] = useState(newUserData["deplasariInteresServiciu"] != undefined ? newUserData["deplasariInteresServiciu"] : null);
    useEffect(()=>{                
        updateNewUserData("deplasariInteresServiciu", deplasariInteresServiciu);
    }, [deplasariInteresServiciu]); 

    const [muncaEchipa, setMuncaEchipa] = useState(newUserData["muncaEchipa"] != undefined ? newUserData["muncaEchipa"] : true);
    useEffect(()=>{        
        updateNewUserData("muncaEchipa", muncaEchipa);
    }, [muncaEchipa]);   

    const [nrOrePeZi, setNrOrePeZi] = useState(newUserData["nrOrePeZi"] != undefined ? newUserData["nrOrePeZi"] : 8);
    useEffect(()=>{        
        updateNewUserData("nrOrePeZi", nrOrePeZi);
    }, [nrOrePeZi]);       

    const [nrSchimburiDeLucru, setNrSchimburiDeLucru] = useState(newUserData["nrSchimburiDeLucru"] != undefined ? newUserData["nrSchimburiDeLucru"] : 1);
    useEffect(()=>{        
        updateNewUserData("nrSchimburiDeLucru", nrSchimburiDeLucru);
    }, [nrSchimburiDeLucru]);       

    const [schimbNoapte, setSchimbNoapte] = useState(newUserData["schimbNoapte"] != undefined ? newUserData["schimbNoapte"] : null);
    useEffect(()=>{        
        updateNewUserData("schimbNoapte", schimbNoapte);
    }, [schimbNoapte]);      

    const [pauzeOrganizate, setPauzeOrganizate] = useState(newUserData["pauzeOrganizate"] != undefined ? newUserData["pauzeOrganizate"] : true);
    useEffect(()=>{        
        updateNewUserData("pauzeOrganizate", pauzeOrganizate);
    }, [pauzeOrganizate]);   

    const [bandaRulanta, setBandaRulanta] = useState(newUserData["bandaRulanta"] != undefined ? newUserData["bandaRulanta"] : false);
    useEffect(()=>{        
        updateNewUserData("bandaRulanta", bandaRulanta);
    }, [bandaRulanta]);  

    const [riscuri, setRiscuri] = useState(newUserData["riscuri"] != undefined ? newUserData["riscuri"] : riscuriDefaults);
    useEffect(()=>{        
        updateNewUserData("riscuri", riscuri);
    }, [riscuri]);  

    const [alteRiscuri, setAlteRiscuri] = useState(newUserData["alteRiscuri"] != undefined ? newUserData["alteRiscuri"] : '');
    useEffect(()=>{        
        updateNewUserData("alteRiscuri", alteRiscuri);
    }, [alteRiscuri]);  

    const [conduceMasinaInstitutiei, setConduceMasinaInstitutiei] = useState(newUserData["conduceMasinaInstitutiei"] != undefined ? newUserData["conduceMasinaInstitutiei"] : null);
    useEffect(()=>{        
        updateNewUserData("conduceMasinaInstitutiei", conduceMasinaInstitutiei);
    }, [conduceMasinaInstitutiei]);  

    const [conduceNumaiIntrauzinal, setConduceIntraUzinal] = useState(newUserData["conduceNumaiIntrauzinal"] != undefined ? newUserData["conduceNumaiIntrauzinal"] : false);
    useEffect(()=>{        
        updateNewUserData("conduceNumaiIntrauzinal", conduceNumaiIntrauzinal);
    }, [conduceNumaiIntrauzinal]);  

    const [inConditiiDeosebite, setInConditiiDeosebite] = useState(newUserData["inConditiiDeosebite"] != undefined ? newUserData["inConditiiDeosebite"] : false);
    useEffect(()=>{        
        updateNewUserData("inConditiiDeosebite", inConditiiDeosebite);
    }, [inConditiiDeosebite]);  

    const [inConditiiSpeciale, setInConditiiSpeciale] = useState(newUserData["inConditiiSpeciale"] != undefined ? newUserData["inConditiiSpeciale"] : false);
    useEffect(()=>{        
        updateNewUserData("inConditiiSpeciale", inConditiiSpeciale);
    }, [inConditiiSpeciale]);  

    const [sectorAlimentar, setSectorAlimentar] = useState(newUserData["sectorAlimentar"] != undefined ? newUserData["sectorAlimentar"] : false);
    useEffect(()=>{        
        updateNewUserData("sectorAlimentar", sectorAlimentar);
    }, [sectorAlimentar]);  

    const [portArma, setPortArma] = useState(newUserData["portArma"] != undefined ? newUserData["portArma"] : false);
    useEffect(()=>{        
        updateNewUserData("portArma", portArma);
    }, [portArma]);  

    const [operatiuniProcesTehnologic, setOperatiuniProcesTehnologic] = useState(newUserData["operatiuniProcesTehnologic"] != undefined ? newUserData["operatiuniProcesTehnologic"] : '');
    useEffect(()=>{        
        updateNewUserData("operatiuniProcesTehnologic", operatiuniProcesTehnologic);
    }, [operatiuniProcesTehnologic]);  

    const [suprafataLucruVerticala, setSuprafataLucruVerticala]= useState(newUserData["suprafataLucruVerticala"] != undefined ? newUserData["suprafataLucruVerticala"] : false);
    useEffect(()=>{        
        updateNewUserData("suprafataLucruVerticala", suprafataLucruVerticala);
    }, [suprafataLucruVerticala]);

    const [suprafataLucruOrizontala, setSuprafataLucruOrizontala]= useState(newUserData["suprafataLucruOrizontala"] != undefined ? newUserData["suprafataLucruOrizontala"] : false);
    useEffect(()=>{        
        updateNewUserData("suprafataLucruOrizontala", suprafataLucruOrizontala);
    }, [suprafataLucruOrizontala]);

    const [suprafataLucruOblica, setSuprafataLucruOblica] = useState(newUserData["suprafataLucruOblica"] != undefined ? newUserData["suprafataLucruOblica"] : false);
    useEffect(()=>{        
        updateNewUserData("suprafataLucruOblica", suprafataLucruOblica);
    }, [suprafataLucruOblica]);

    const [dimensiuneIncapereL, setDimensiuneIncapereL] = useState(newUserData["dimensiuneIncapereL"] != undefined ? newUserData["dimensiuneIncapereL"] : '');
    useEffect(()=>{        
        updateNewUserData("dimensiuneIncapereL", dimensiuneIncapereL);
    }, [dimensiuneIncapereL]);  

    const [dimensiuneIncaperel, setDimensiuneIncaperel] = useState(newUserData["dimensiuneIncaperel"] != undefined ? newUserData["dimensiuneIncaperel"] : '');
    useEffect(()=>{        
        updateNewUserData("dimensiuneIncaperel", dimensiuneIncaperel);
    }, [dimensiuneIncaperel]);  

    const [dimensiuneIncapereH, setDimensiuneIncapereH] = useState(newUserData["dimensiuneIncapereH"] != undefined ? newUserData["dimensiuneIncapereH"] : '');
    useEffect(()=>{        
        updateNewUserData("dimensiuneIncapereH", dimensiuneIncapereH);
    }, [dimensiuneIncapereH]);  

    const [dimensiuneObservatii, setDimensiuneObservatii] = useState(newUserData["dimensiuneObservatii"] != undefined ? newUserData["dimensiuneObservatii"] : '');
    useEffect(()=>{
        updateNewUserData("dimensiuneObservatii", dimensiuneObservatii);
    }, [dimensiuneObservatii])

    const [conditiiMunca, setConditiiMunca] = useState(newUserData["conditiiMunca"] != undefined ? newUserData["conditiiMunca"] : conditiiMuncaDefaults);
    useEffect(()=>{        
        updateNewUserData("conditiiMunca", conditiiMunca);
    }, [conditiiMunca]);  

    const [conditiiMuncaAltele, setConditiiMuncaAltele] = useState(newUserData["conditiiMuncaAltele"] != undefined ? newUserData["conditiiMuncaAltele"] : '');
    useEffect(()=>{        
        updateNewUserData("conditiiMuncaAltele", conditiiMuncaAltele);
    }, [conditiiMuncaAltele]);  

    const [efortFizicMic, setEfortFizicMic] = useState(newUserData["efortFizicMic"] != undefined ? newUserData["efortFizicMic"] : false);
    useEffect(()=>{        
        updateNewUserData("efortFizicMic", efortFizicMic);
    }, [efortFizicMic]);  

    const [efortFizicMediu, setEfortFizicMediu] = useState(newUserData["efortFizicMediu"] != undefined ? newUserData["efortFizicMediu"] : false);
    useEffect(()=>{        
        updateNewUserData("efortFizicMediu", efortFizicMediu);
    }, [efortFizicMediu]);  
    
    const [efortFizicMare, setEfortFizicMare] = useState(newUserData["efortFizicMare"] != undefined ? newUserData["efortFizicMare"] : false);
    useEffect(()=>{        
        updateNewUserData("efortFizicMare", efortFizicMare);
    }, [efortFizicMare]);  
    
    const [efortFizicFoarteMare, setEfortFizicFoarteMare] = useState(newUserData["efortFizicFoarteMare"] != undefined ? newUserData["efortFizicFoarteMare"] : false);
    useEffect(()=>{        
        updateNewUserData("efortFizicFoarteMare", efortFizicFoarteMare);
    }, [efortFizicFoarteMare]);  

    const [pozitiePreponderentOrtostatica, setPozitiePreponderentOrtostatica] = useState(newUserData["pozitiePreponderentOrtostatica"] != undefined ? newUserData["pozitiePreponderentOrtostatica"] : false);
    useEffect(()=>{        
        updateNewUserData("pozitiePreponderentOrtostatica", pozitiePreponderentOrtostatica);
    }, [pozitiePreponderentOrtostatica]);  

    const [pozitiePreponderentAsezat, setPozitiePreponderentAsezat] = useState(newUserData["pozitiePreponderentAsezat"] != undefined ? newUserData["pozitiePreponderentAsezat"] : false);
    useEffect(()=>{        
        updateNewUserData("pozitiePreponderentAsezat", pozitiePreponderentAsezat);
    }, [pozitiePreponderentAsezat]);  

    const [pozitiePreponderentAplecat, setPozitiePreponderentAplecat] = useState(newUserData["pozitiePreponderentAplecat"] != undefined ? newUserData["pozitiePreponderentAplecat"] : false);
    useEffect(()=>{        
        updateNewUserData("pozitiePreponderentAplecat", pozitiePreponderentAplecat);
    }, [pozitiePreponderentAplecat]);  

    const [pozitiePreponderentMixta, setPozitiePreponderentMixta] = useState(newUserData["pozitiePreponderentMixta"] != undefined ? newUserData["pozitiePreponderentMixta"] : false);
    useEffect(()=>{        
        updateNewUserData("pozitiePreponderentMixta", pozitiePreponderentMixta);
    }, [pozitiePreponderentMixta]);  

    const [pozitiiFortate, setPozitiiFortate] = useState(newUserData["pozitiiFortate"] != undefined ? newUserData["pozitiiFortate"] : null);
    useEffect(()=>{        
        updateNewUserData("pozitiiFortate", pozitiiFortate);
    }, [pozitiiFortate]);

    const [gesturiProfesionale, setGesturiProfesionale] = useState(newUserData["gesturiProfesionale"] != undefined ? newUserData["gesturiProfesionale"] : '');
    useEffect(()=>{        
        updateNewUserData("gesturiProfesionale", gesturiProfesionale);
    }, [gesturiProfesionale]);

    const [suprasolicitariVizuale, setSuprasolicitariVizuale] = useState(newUserData["suprasolicitariVizuale"] != undefined ? newUserData["suprasolicitariVizuale"] : false);
    useEffect(()=>{        
        updateNewUserData("suprasolicitariVizuale", suprasolicitariVizuale);
    }, [suprasolicitariVizuale]);

    const [suprasolicitariAuditive, setSuprasolicitariAuditive] = useState(newUserData["suprasolicitariAuditive"] != undefined ? newUserData["suprasolicitariAuditive"] : false);
    useEffect(()=>{        
        updateNewUserData("suprasolicitariAuditive", suprasolicitariAuditive);
    }, [suprasolicitariAuditive]);

    const [suprasolicitariNeuropsihosenzoriale, setSuprasolicitariNeuropsihosenzoriale] = useState(newUserData["suprasolicitariNeuropsihosenzoriale"] != undefined ? newUserData["suprasolicitariNeuropsihosenzoriale"] : false);
    useEffect(()=>{        
        updateNewUserData("suprasolicitariNeuropsihosenzoriale", suprasolicitariNeuropsihosenzoriale);
    }, [suprasolicitariNeuropsihosenzoriale]);

    const [suprasolicitariNeuroMentale, setSuprasolicitariNeuroMentale] = useState(newUserData["suprasolicitariNeuroMentale"] != undefined ? newUserData["suprasolicitariNeuroMentale"] : false);
    useEffect(()=>{        
        updateNewUserData("suprasolicitariNeuroMentale", suprasolicitariNeuroMentale);
    }, [suprasolicitariNeuroMentale]);

    const [suprasolicitariNeuroEmotionale, setSuprasolicitariNeuroEmotionale] = useState(newUserData["suprasolicitariNeuroEmotionale"] != undefined ? newUserData["suprasolicitariNeuroEmotionale"] : false);
    useEffect(()=>{        
        updateNewUserData("suprasolicitariNeuroEmotionale", suprasolicitariNeuroEmotionale);
    }, [suprasolicitariNeuroEmotionale]);

    const [suprasolicitariNeuroAltele, setSuprasolicitariNeuroAltele] = useState(newUserData["suprasolicitariNeuroAltele"] != undefined ? newUserData["suprasolicitariNeuroAltele"] : false);
    useEffect(()=>{        
        updateNewUserData("suprasolicitariNeuroAltele", suprasolicitariNeuroAltele);
    }, [suprasolicitariNeuroAltele]);

    const [miscariFortate, setMiscariFortate] = useState(newUserData["miscariFortate"] != undefined ? newUserData["miscariFortate"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariFortate", miscariFortate);
    }, [miscariFortate]);

    const [miscariRepetitive, setMiscariRepetitive] = useState(newUserData["miscariRepetitive"] != undefined ? newUserData["miscariRepetitive"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive", miscariRepetitive);
    }, [miscariRepetitive]);

    const [miscariRepetitive_cervical, setMiscariRepetitive_cervical] = useState(newUserData["miscariRepetitive_cervical"] != undefined ? newUserData["miscariRepetitive_cervical"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive_cervical", miscariRepetitive_cervical);
    }, [miscariRepetitive_cervical]);

    const [miscariRepetitive_toracal, setMiscariRepetitive_toracal] = useState(newUserData["miscariRepetitive_toracal"] != undefined ? newUserData["miscariRepetitive_toracal"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive_toracal", miscariRepetitive_toracal);
    }, [miscariRepetitive_toracal]);

    const [miscariRepetitive_lombar, setMiscariRepetitive_lombar] = useState(newUserData["miscariRepetitive_lombar"] != undefined ? newUserData["miscariRepetitive_lombar"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive_lombar", miscariRepetitive_lombar);
    }, [miscariRepetitive_lombar]);

    const [miscariRepetitive_umar, setMiscariRepetitive_umar] = useState(newUserData["miscariRepetitive_umar"] != undefined ? newUserData["miscariRepetitive_umar"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive_umar", miscariRepetitive_umar);
    }, [miscariRepetitive_umar]);

    const [miscariRepetitive_cot, setMiscariRepetitive_cot] = useState(newUserData["miscariRepetitive_cot"] != undefined ? newUserData["miscariRepetitive_cot"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive_cot", miscariRepetitive_cot);
    }, [miscariRepetitive_cot]);

    const [miscariRepetitive_pumn, setMiscariRepetitive_pumn] = useState(newUserData["miscariRepetitive_pumn"] != undefined ? newUserData["miscariRepetitive_pumn"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive_pumn", miscariRepetitive_pumn);
    }, [miscariRepetitive_pumn]);

    const [miscariRepetitive_sold, setMiscariRepetitive_sold] = useState(newUserData["miscariRepetitive_sold"] != undefined ? newUserData["miscariRepetitive_sold"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive_sold", miscariRepetitive_sold);
    }, [miscariRepetitive_sold]);

    const [miscariRepetitive_genunchi, setMiscariRepetitive_genunchi] = useState(newUserData["miscariRepetitive_genunchi"] != undefined ? newUserData["miscariRepetitive_genunchi"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive_genunchi", miscariRepetitive_genunchi);
    }, [miscariRepetitive_genunchi]);

    const [miscariRepetitive_glezna, setMiscariRepetitive_glezna] = useState(newUserData["miscariRepetitive_glezna"] != undefined ? newUserData["miscariRepetitive_glezna"] : false);
    useEffect(()=>{        
        updateNewUserData("miscariRepetitive_glezna", miscariRepetitive_glezna);
    }, [miscariRepetitive_glezna]);

    const [manipulareaManualaAMaselor, setManipulareaManualaAMaselor] = useState(newUserData["manipulareaManualaAMaselor"] != undefined ? newUserData["manipulareaManualaAMaselor"] : '');
    useEffect(()=>{        
        updateNewUserData("manipulareaManualaAMaselor", manipulareaManualaAMaselor);
    }, [manipulareaManualaAMaselor]);

    const [manipulareManualaGreutateMaxima, setManipulareManualaGreutateMaxima] = useState(newUserData["manipulareManualaGreutateMaxima"] != undefined ? newUserData["manipulareManualaGreutateMaxima"] : '');
    useEffect(()=>{        
        updateNewUserData("manipulareManualaGreutateMaxima", manipulareManualaGreutateMaxima);
    }, [manipulareManualaGreutateMaxima]);

    const [manipulareManualaRidicare, setManipulareManualaRidicare] = useState(newUserData["manipulareManualaRidicare"] != undefined ? newUserData["manipulareManualaRidicare"] : true);
    useEffect(()=>{        
        updateNewUserData("manipulareManualaRidicare", manipulareManualaRidicare);
    }, [manipulareManualaRidicare]);

    const [manipulareManualaCoborare, setManipulareManualaCoborare] = useState(newUserData["manipulareManualaCoborare"] != undefined ? newUserData["manipulareManualaCoborare"] : true);
    useEffect(()=>{        
        updateNewUserData("manipulareManualaCoborare", manipulareManualaCoborare);
    }, [manipulareManualaCoborare]);

    const [manipulareManualaImpingere, setManipulareManualaImpingere] = useState(newUserData["manipulareManualaImpingere"] != undefined ? newUserData["manipulareManualaImpingere"] : true);
    useEffect(()=>{        
        updateNewUserData("manipulareManualaImpingere", manipulareManualaImpingere);
    }, [manipulareManualaImpingere]);

    const [manipulareManualaTragere, setManipulareManualaTragere] = useState(newUserData["manipulareManualaTragere"] != undefined ? newUserData["manipulareManualaTragere"] : true);
    useEffect(()=>{        
        updateNewUserData("manipulareManualaTragere", manipulareManualaTragere);
    }, [manipulareManualaTragere]);

    const [manipulareManualaPurtare, setManipulareManualaPurtare] = useState(newUserData["manipulareManualaPurtare"] != undefined ? newUserData["manipulareManualaPurtare"] : true);
    useEffect(()=>{        
        updateNewUserData("manipulareManualaPurtare", manipulareManualaPurtare);
    }, [manipulareManualaPurtare]);

    const [manipulareManualaDeplasare, setManipulareManualaDeplasare] = useState(newUserData["manipulareManualaDeplasare"] != undefined ? newUserData["manipulareManualaDeplasare"] : true);
    useEffect(()=>{        
        updateNewUserData("manipulareManualaDeplasare", manipulareManualaDeplasare);
    }, [manipulareManualaDeplasare]);

    const [agentiChimici, setAgentiChimici] = useState(newUserData["agentiChimici"] != undefined ? newUserData["agentiChimici"] : false);
    useEffect(()=>{        
        updateNewUserData("agentiChimici", agentiChimici);
    }, [agentiChimici]);

    const [agentiChimiciTabelData, setAgentiChimiciTabelData] = useState(newUserData["agentiChimiciTabelData"] != undefined ? newUserData["agentiChimiciTabelData"] : [{columns: ['','','','','','']}]);
    useEffect(()=>{        
        updateNewUserData("agentiChimiciTabelData", agentiChimiciTabelData);
    }, [agentiChimiciTabelData]);

    const [agentiBiologici, setAgentiBiologici] = useState(newUserData["agentiBiologici"] != undefined ? newUserData["agentiBiologici"] : '');
    useEffect(()=>{        
        updateNewUserData("agentiBiologici", agentiBiologici);
    }, [agentiBiologici]);

    const [agentiBiologiciGrupa, setAgentiBiologiciGrupa] = useState(newUserData["agentiBiologiciGrupa"] != undefined ? newUserData["agentiBiologiciGrupa"] : '');
    useEffect(()=>{        
        updateNewUserData("agentiBiologiciGrupa", agentiBiologiciGrupa);
    }, [agentiBiologiciGrupa]);

    const [agentiCancerigeni, setAgentiCancerigeni] = useState(newUserData["agentiCancerigeni"] != undefined ? newUserData["agentiCancerigeni"] : '');
    useEffect(()=>{        
        updateNewUserData("agentiCancerigeni", agentiCancerigeni);
    }, [agentiCancerigeni]);

    const [pulberiProfesionale, setPulberiProfesionale] = useState(newUserData["pulberiProfesionale"] != undefined ? newUserData["pulberiProfesionale"] : false);
    useEffect(()=>{        
        updateNewUserData("pulberiProfesionale", pulberiProfesionale);
    }, [pulberiProfesionale]);

    const [pulberiProfesionaleTableData, setPulberiProfesionaleTableData] = useState(newUserData["pulberiProfesionaleTableData"] != undefined ? newUserData["pulberiProfesionaleTableData"] : [{columns: ['','','']}]);
    useEffect(()=>{        
        updateNewUserData("pulberiProfesionaleTableData", pulberiProfesionaleTableData);
    }, [pulberiProfesionaleTableData]);

    const [zgomotProfesionalLessVLE, setZgomotProfesionalLessVLE] = useState(newUserData["zgomotProfesionalLessVLE"] != undefined ? newUserData["zgomotProfesionalLessVLE"] : false);
    useEffect(()=>{        
        updateNewUserData("zgomotProfesionalLessVLE", zgomotProfesionalLessVLE);
    }, [zgomotProfesionalLessVLE]);

    const [zgomotProfesionalMoreVLE, setZgomotProfesionalMoreVLE] = useState(newUserData["zgomotProfesionalMoreVLE"] != undefined ? newUserData["zgomotProfesionalMoreVLE"] : false);
    useEffect(()=>{        
        updateNewUserData("zgomotProfesionalMoreVLE", zgomotProfesionalMoreVLE);
    }, [zgomotProfesionalMoreVLE]);

    const [zgomoteImpulsive, setZgomoteImplusive] = useState(newUserData["zgomoteImpulsive"] != undefined ? newUserData["zgomoteImpulsive"] : false);
    useEffect(()=>{        
        updateNewUserData("zgomoteImpulsive", zgomoteImpulsive);
    }, [zgomoteImpulsive]);

    const [vibratiiMecaniceLessVLE, setVibratiiMecaniceLessVLE] = useState(newUserData["vibratiiMecaniceLessVLE"] != undefined ? newUserData["vibratiiMecaniceLessVLE"] : false);
    useEffect(()=>{        
        updateNewUserData("vibratiiMecaniceLessVLE", vibratiiMecaniceLessVLE);
    }, [vibratiiMecaniceLessVLE]);

    const [vibratiiMecaniceMoreVLE, setVibratiiMecaniceMoreVLE] = useState(newUserData["vibratiiMecaniceMoreVLE"] != undefined ? newUserData["vibratiiMecaniceMoreVLE"] : false);
    useEffect(()=>{        
        updateNewUserData("vibratiiMecaniceMoreVLE", vibratiiMecaniceMoreVLE);
    }, [vibratiiMecaniceMoreVLE]);

    const [vibratiiMecaniceVertebrala, setVibratiiMecaniceVertebrala] = useState(newUserData["vibratiiMecaniceVertebrala"] != undefined ? newUserData["vibratiiMecaniceVertebrala"] : false);
    useEffect(()=>{        
        updateNewUserData("vibratiiMecaniceVertebrala", vibratiiMecaniceVertebrala);
    }, [vibratiiMecaniceVertebrala]);

    const [vibratiiMecaniceMembreSuperioare, setVibratiiMecaniceMembreSuperioare] = useState(newUserData["vibratiiMecaniceMembreSuperioare"] != undefined ? newUserData["vibratiiMecaniceMembreSuperioare"] : false);
    useEffect(()=>{        
        updateNewUserData("vibratiiMecaniceMembreSuperioare", vibratiiMecaniceMembreSuperioare);
    }, [vibratiiMecaniceMembreSuperioare]);

    const [vibratiiMecaniceActiuneAsupraIntreguluiCorp, setVibratiiMecaniceActiuneAsupraIntreguluiCorp] = useState(newUserData["vibratiiMecaniceActiuneAsupraIntreguluiCorp"] != undefined ? newUserData["vibratiiMecaniceActiuneAsupraIntreguluiCorp"] : false);
    useEffect(()=>{        
        updateNewUserData("vibratiiMecaniceActiuneAsupraIntreguluiCorp", vibratiiMecaniceActiuneAsupraIntreguluiCorp);
    }, [vibratiiMecaniceActiuneAsupraIntreguluiCorp]);


    const [temperaturaAer, setTemperaturaAer] = useState(newUserData["temperaturaAer"] != undefined ? newUserData["temperaturaAer"] : '');
    useEffect(()=>{        
        updateNewUserData("temperaturaAer", temperaturaAer);
    }, [temperaturaAer]);

    const [temperaturaRepetata, setTemperaturaRepetata] = useState(newUserData["temperaturaRepetata"] != undefined ? newUserData["temperaturaRepetata"] : false);
    useEffect(()=>{        
        updateNewUserData("temperaturaRepetata", temperaturaRepetata);
    }, [temperaturaRepetata]);
    
    const [presiuneAer, setPresiuneAer] = useState(newUserData["presiuneAer"] != undefined ? newUserData["presiuneAer"] : '');
    useEffect(()=>{        
        updateNewUserData("presiuneAer", presiuneAer);
    }, [presiuneAer]);

    const [umiditateRelativa, setUmiditateRelativa] = useState(newUserData["umiditateRelativa"] != undefined ? newUserData["umiditateRelativa"] : '');
    useEffect(()=>{        
        updateNewUserData("umiditateRelativa", umiditateRelativa);
    }, [umiditateRelativa]);

    const [iluminatSuficient, setIluminatSuficient] = useState(newUserData["iluminatSuficient"] != undefined ? newUserData["iluminatSuficient"] : false);
    useEffect(()=>{        
        updateNewUserData("iluminatSuficient", iluminatSuficient);
    }, [iluminatSuficient]);

    const [iluminatInsuficient, setIluminatInsuficient] = useState(newUserData["iluminatInsuficient"] != undefined ? newUserData["iluminatInsuficient"] : false);
    useEffect(()=>{        
        updateNewUserData("iluminatInsuficient", iluminatInsuficient);
    }, [iluminatInsuficient]);

    const [iluminatNatural, setIluminatNatural] = useState(newUserData["iluminatNatural"] != undefined ? newUserData["iluminatNatural"] : false);
    useEffect(()=>{        
        updateNewUserData("iluminatNatural", iluminatNatural);
    }, [iluminatNatural]);

    const [iluminatArtificial, setIluminatArtificial] = useState(newUserData["iluminatArtificial"] != undefined ? newUserData["iluminatArtificial"] : false);
    useEffect(()=>{        
        updateNewUserData("iluminatArtificial", iluminatArtificial);
    }, [iluminatArtificial]);

    const [iluminatMixt, setIluminatMixt] = useState(newUserData["iluminatMixt"] != undefined ? newUserData["iluminatMixt"] : false);
    useEffect(()=>{        
        updateNewUserData("iluminatMixt", iluminatMixt);
    }, [iluminatMixt]);

    const [mijloaceProtectieColectiva, setMijloaceProtectieColectiva] = useState(newUserData["mijloaceProtectieColectiva"] != undefined ? newUserData["mijloaceProtectieColectiva"] : '');
    useEffect(()=>{        
        updateNewUserData("mijloaceProtectieColectiva", mijloaceProtectieColectiva);
    }, [mijloaceProtectieColectiva]);

    const [mijloaceProtectieIndividuala, setMijloaceProtectieIndividuala] = useState(newUserData["mijloaceProtectieIndividuala"] != undefined ? newUserData["mijloaceProtectieIndividuala"] : '');
    useEffect(()=>{        
        updateNewUserData("mijloaceProtectieIndividuala", mijloaceProtectieIndividuala);
    }, [mijloaceProtectieIndividuala]);

    const [echipamentDeLucru, setEchipamentDeLucru] = useState(newUserData["echipamentDeLucru"] != undefined ? newUserData["echipamentDeLucru"] : '');
    useEffect(()=>{        
        updateNewUserData("echipamentDeLucru", echipamentDeLucru);
    }, [echipamentDeLucru]);

    const [anexeIgenicoVestiar, setAnexeIgenicoVestiar]  = useState(newUserData["anexeIgenicoVestiar"] != undefined ? newUserData["anexeIgenicoVestiar"] : false);
    useEffect(()=>{        
        updateNewUserData("anexeIgenicoVestiar", anexeIgenicoVestiar);
    }, [anexeIgenicoVestiar]);

    const [anexeIgenicoChiuveta, setAnexeIgenicoChiuveta]  = useState(newUserData["anexeIgenicoChiuveta"] != undefined ? newUserData["anexeIgenicoChiuveta"] : false);
    useEffect(()=>{        
        updateNewUserData("anexeIgenicoChiuveta", anexeIgenicoChiuveta);
    }, [anexeIgenicoChiuveta]);

    const [anexeIgenicoWC, setAnexeIgenicoWC]  = useState(newUserData["anexeIgenicoWC"] != undefined ? newUserData["anexeIgenicoWC"] : false);
    useEffect(()=>{        
        updateNewUserData("anexeIgenicoWC", anexeIgenicoWC);
    }, [anexeIgenicoWC]);

    const [anexeIgenicoDus, setAnexeIgenicoDus]  = useState(newUserData["anexeIgenicoDus"] != undefined ? newUserData["anexeIgenicoDus"] : false);
    useEffect(()=>{        
        updateNewUserData("anexeIgenicoDus", anexeIgenicoDus);
    }, [anexeIgenicoDus]);

    const [anexeIgenicoSalaDeMese, setAnexeIgenicoSalaDeMese] = useState(newUserData["anexeIgenicoSalaDeMese"] != undefined ? newUserData["anexeIgenicoSalaDeMese"] : false);
    useEffect(()=>{        
        updateNewUserData("anexeIgenicoSalaDeMese", anexeIgenicoSalaDeMese);
    }, [anexeIgenicoSalaDeMese]);

    const [anexeIgenicoSpatiuRecreere, setAnexeIgenicoSpariuRecreere] = useState(newUserData["anexeIgenicoSpatiuRecreere"] != undefined ? newUserData["anexeIgenicoSpatiuRecreere"] : false);
    useEffect(()=>{        
        updateNewUserData("anexeIgenicoSpatiuRecreere", anexeIgenicoSpatiuRecreere);
    }, [anexeIgenicoSpatiuRecreere]);

    const [anexeIgenicoAltele, setAnexeIgenicoAltele] = useState(newUserData["anexeIgenicoAltele"] != undefined ? newUserData["anexeIgenicoAltele"] : '');
    useEffect(()=>{        
        updateNewUserData("anexeIgenicoAltele", anexeIgenicoAltele);
    }, [anexeIgenicoAltele]);

    const [observatii, setObservatii] = useState(newUserData["observatii"] != undefined ? newUserData["observatii"] : '');
    useEffect(()=>{        
        updateNewUserData("observatii", observatii);
    }, [observatii]);

    const [radiatii, setRadiatii] = useState(newUserData["radiatii"] != undefined ? newUserData["radiatii"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatii", radiatii);
    }, [radiatii]);

    const [radiatiiIonizateDataIntrariiMediuExpunereProfesionala, setRadiatiiIonizateDataIntrariiMediuExpunereProfesionala] = useState(newUserData["radiatiiIonizateDataIntrariiMediuExpunereProfesionala"] != undefined ? newUserData["radiatiiIonizateDataIntrariiMediuExpunereProfesionala"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateDataIntrariiMediuExpunereProfesionala", radiatiiIonizateDataIntrariiMediuExpunereProfesionala);
    }, [radiatiiIonizateDataIntrariiMediuExpunereProfesionala]);

    const [radiatiiIonizateGrupaA, setRadiatiiIonizateGrupaA] = useState(newUserData["radiatiiIonizateGrupaA"] != undefined ? newUserData["radiatiiIonizateGrupaA"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateGrupaA", radiatiiIonizateGrupaA);
    }, [radiatiiIonizateGrupaA]);

    const [radiatiiIonizateGrupaB, setRadiatiiIonizateGrupaB]  = useState(newUserData["radiatiiIonizateGrupaB"] != undefined ? newUserData["radiatiiIonizateGrupaB"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateGrupaB", radiatiiIonizateGrupaB);
    }, [radiatiiIonizateGrupaB]);

    const [radiatiiIonizateAparaturaFolosita, setRadiatiiIonizateAparaturaFolosita] = useState(newUserData["radiatiiIonizateAparaturaFolosita"] != undefined ? newUserData["radiatiiIonizateAparaturaFolosita"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateAparaturaFolosita", radiatiiIonizateAparaturaFolosita);
    }, [radiatiiIonizateAparaturaFolosita]);

    const [radiatiiIonizateProcesTehnologic, setRadiatiiIonizateProcesTehnologic]  = useState(newUserData["radiatiiIonizateProcesTehnologic"] != undefined ? newUserData["radiatiiIonizateProcesTehnologic"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateProcesTehnologic", radiatiiIonizateProcesTehnologic);
    }, [radiatiiIonizateProcesTehnologic]);

    const [radiatiiIonizateOperatiuniIndeplinite, setRadiatiiIonizateOperatiuniIndeplinite] = useState(newUserData["radiatiiIonizateOperatiuniIndeplinite"] != undefined ? newUserData["radiatiiIonizateOperatiuniIndeplinite"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateOperatiuniIndeplinite", radiatiiIonizateOperatiuniIndeplinite);
    }, [radiatiiIonizateOperatiuniIndeplinite]);

    const [radiatiiIonizateSurseDeschise, setRadiatiiIonizateSurseDeschise] = useState(newUserData["radiatiiIonizateSurseDeschise"] != undefined ? newUserData["radiatiiIonizateSurseDeschise"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateSurseDeschise", radiatiiIonizateSurseDeschise);
    }, [radiatiiIonizateSurseDeschise]);

    const [radiatiiIonizateSurseInchise, setRadiatiiIonizateSurseInchise]  = useState(newUserData["radiatiiIonizateSurseInchise"] != undefined ? newUserData["radiatiiIonizateSurseInchise"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateSurseInchise", radiatiiIonizateSurseInchise);
    }, [radiatiiIonizateSurseInchise]);
    
    const [radiatiiIonizateTipExpunere_X_externa, setRadiatiiIonizateTipExpunere_X_externa] = useState(newUserData["radiatiiIonizateTipExpunere_X_externa"] != undefined ? newUserData["radiatiiIonizateTipExpunere_X_externa"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_X_externa", radiatiiIonizateTipExpunere_X_externa);
    }, [radiatiiIonizateTipExpunere_X_externa]);

    const [radiatiiIonizateTipExpunere_gamma_externa, setRadiatiiIonizateTipExpunere_gamma_externa]  = useState(newUserData["radiatiiIonizateTipExpunere_gamma_externa"] != undefined ? newUserData["radiatiiIonizateTipExpunere_gamma_externa"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_gamma_externa", radiatiiIonizateTipExpunere_gamma_externa);
    }, [radiatiiIonizateTipExpunere_gamma_externa]);

    const [radiatiiIonizateTipExpunere_interna, setRadiatiiIonizateTipExpunere_interna] = useState(newUserData["radiatiiIonizateTipExpunere_interna"] != undefined ? newUserData["radiatiiIonizateTipExpunere_interna"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_interna", radiatiiIonizateTipExpunere_interna);
    }, [radiatiiIonizateTipExpunere_interna]);

    const [radiatiiIonizateTipExpunere_interna_externa, setRadiatiiIonizateTipExpunere_interna_externa]  = useState(newUserData["radiatiiIonizateTipExpunere_interna_externa"] != undefined ? newUserData["radiatiiIonizateTipExpunere_interna_externa"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_interna_externa", radiatiiIonizateTipExpunere_interna_externa);
    }, [radiatiiIonizateTipExpunere_interna_externa]);

    const [radiatiiIonizateTipExpunere_masuriprotectieindividuala, setRadiatiiIonizateTipExpunere_masuriprotectieindividuala]  = useState(newUserData["radiatiiIonizateTipExpunere_masuriprotectieindividuala"] != undefined ? newUserData["radiatiiIonizateTipExpunere_masuriprotectieindividuala"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_masuriprotectieindividuala", radiatiiIonizateTipExpunere_masuriprotectieindividuala);
    }, [radiatiiIonizateTipExpunere_masuriprotectieindividuala]);

    const [radiatiiIonizate_expunere_anterioara_perioada, setRadiatiiIonizate_expunere_anterioara_perioada]  = useState(newUserData["radiatiiIonizate_expunere_anterioara_perioada"] != undefined ? newUserData["radiatiiIonizate_expunere_anterioara_perioada"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizate_expunere_anterioara_perioada", radiatiiIonizate_expunere_anterioara_perioada);
    }, [radiatiiIonizate_expunere_anterioara_perioada]);

    const [conditiiExpunereRadiatiiGRUPA, setConditiiExpunereRadiatiiGRUPA]  = useState(newUserData["conditiiExpunereRadiatiiGRUPA"] != undefined ? newUserData["conditiiExpunereRadiatiiGRUPA"] : '');
    useEffect(()=>{        
        updateNewUserData("conditiiExpunereRadiatiiGRUPA", conditiiExpunereRadiatiiGRUPA);
    }, [conditiiExpunereRadiatiiGRUPA]);

    const [radiatiiExpunereAnterioaraEXTRA, setRadiatiiExpunereAnterioaraEXTRA]  = useState(newUserData["radiatiiExpunereAnterioaraEXTRA"] != undefined ? newUserData["radiatiiExpunereAnterioaraEXTRA"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiExpunereAnterioaraEXTRA", radiatiiExpunereAnterioaraEXTRA);
    }, [radiatiiExpunereAnterioaraEXTRA]);

    const [radiatiiSupraExpunereAnterioaraEXTRA, setRadiatiiSupraExpunereAnterioaraEXTRA]  = useState(newUserData["radiatiiSupraExpunereAnterioaraEXTRA"] != undefined ? newUserData["radiatiiSupraExpunereAnterioaraEXTRA"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiSupraExpunereAnterioaraEXTRA", radiatiiSupraExpunereAnterioaraEXTRA);
    }, [radiatiiSupraExpunereAnterioaraEXTRA]);

    const [radiatiiSupraExpunereAnterioaraEXTRATIPT, setRadiatiiSupraExpunereAnterioaraEXTRATIPT]  = useState(newUserData["radiatiiSupraExpunereAnterioaraEXTRATIPT"] != undefined ? newUserData["radiatiiSupraExpunereAnterioaraEXTRATIPT"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiSupraExpunereAnterioaraEXTRATIPT", radiatiiSupraExpunereAnterioaraEXTRATIPT);
    }, [radiatiiSupraExpunereAnterioaraEXTRATIPT]);

    const [radiatiiIonizate_expunere_anterioara_nr_ani, setRadiatiiIonizate_expunere_anterioara_nr_ani]  = useState(newUserData["radiatiiIonizate_expunere_anterioara_nr_ani"] != undefined ? newUserData["radiatiiIonizate_expunere_anterioara_nr_ani"] : 0);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizate_expunere_anterioara_nr_ani", radiatiiIonizate_expunere_anterioara_nr_ani);
    }, [radiatiiIonizate_expunere_anterioara_nr_ani]);

    const [radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa, setRadiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa] = useState(newUserData["radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa"] != undefined ? newUserData["radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa"] : 0);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa", radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa);
    }, [radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa]);

    const [radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna, setRadiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna]  = useState(newUserData["radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna"] != undefined ? newUserData["radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna"] : 0);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna", radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna);
    }, [radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna]);

    const [radiatiiIonizate_expunere_anterioara_doza_totala, setRadiatiiIonizate_expunere_anterioara_doza_totala]  = useState(newUserData["radiatiiIonizate_expunere_anterioara_doza_totala"] != undefined ? newUserData["radiatiiIonizate_expunere_anterioara_doza_totala"] : 0);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizate_expunere_anterioara_doza_totala", radiatiiIonizate_expunere_anterioara_doza_totala);
    }, [radiatiiIonizate_expunere_anterioara_doza_totala]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa, setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa]  = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa", radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa, setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa]  = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa", radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna, setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna]  = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna", radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa, setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa]  = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa", radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data, setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data]  = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data", radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza, setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza]  = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza", radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii, setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii] = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii", radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa, setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa] = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa", radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa, setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa] = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa", radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna, setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna] = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna", radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa, setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa]  = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa"] : false);
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa", radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data, setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_data] = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data", radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza, setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza] = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza", radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza]);

    const [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii, setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii] = useState(newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii"] != undefined ? newUserData["radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii", radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii);
    }, [radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii]);

    const [radiatiiNeionizateTipul, setRadiatiiNeionizateTipul]  = useState(newUserData["radiatiiNeionizateTipul"] != undefined ? newUserData["radiatiiNeionizateTipul"] : '');
    useEffect(()=>{        
        updateNewUserData("radiatiiNeionizateTipul", radiatiiNeionizateTipul);
    }, [radiatiiNeionizateTipul]);
    //#endregion

    const [compartimenteAutocompleteOptions, setCompartimenteAutocompleteOptions] = useState([]);
    const [locuriMuncaAutocompleteOptions, setLocuriMuncaAutocompleteOptions] = useState([]);

    // useEffect(()=>{alert(JSON.stringify(locuriMuncaAutocompleteOptions))}, [locuriMuncaAutocompleteOptions]);

    useEffect(()=>{
        get_ajax("EmployeeManagement/Employees/GetCompartimenteAutocompleteList", (response) => {
            setCompartimenteAutocompleteOptions(response);
        });        
        // get_ajax("EmployeeManagement/Employees/GetLocuriMuncaAutocompleteList", (response) => {
        //     setLocuriMuncaAutocompleteOptions(response);
        // });
        get_ajax("EmployeeManagement/Employees/GetLocuriMuncaAutocompleteList?compartimentId="+sectieDepartament, (response) => {
            setLocuriMuncaAutocompleteOptions(response);
        });
    }, []);

    const [locMunca, setLocMunca] = useState(newUserData["locMunca"] != undefined ? newUserData["locMunca"] : '');
    useEffect(()=>{       
        if(configMode) return;
        updateNewUserData("locMunca", locMunca);
        if(inArray(locMunca, locuriMuncaAutocompleteOptions))
        {
            get_ajax("EmployeeManagement/Employees/GetRiscuriPayload?displayName="+sectieDepartament+"&secondDisplayName="+locMunca+"&type=2", (response) => {
                if(response == "{}")
                {
                    get_ajax("EmployeeManagement/Employees/GetRiscuriPayload?displayName="+locMunca+"&type=1", (response) => {
                        updatePayloadPresets(JSON.parse(response));
                    });   
                }
                else
                {
                    if(isJsonString(response))
                        updatePayloadPresets(JSON.parse(response));
                    else
                    {
                        alert(response);
                    }
                }
            }); 
        }         
    }, [locMunca]);   

    const [sectieDepartament, setSectieDepartament] = useState(newUserData["sectieDepartament"] != undefined ? newUserData["sectieDepartament"] : '');
    useEffect(()=>{        
        if(configMode) return;
        updateNewUserData("sectieDepartament", sectieDepartament);
        if(inArray(sectieDepartament, compartimenteAutocompleteOptions))
        {
            get_ajax("EmployeeManagement/Employees/GetRiscuriPayload?displayName="+sectieDepartament+"&type=0", (response) => {
                if(isJsonString(response))
                        updatePayloadPresets(JSON.parse(response));
                else
                {
                    alert(response);
                }
            }); 
            get_ajax("EmployeeManagement/Employees/GetLocuriMuncaAutocompleteList?compartimentId="+sectieDepartament, (response) => {
                setLocuriMuncaAutocompleteOptions(response);
            }); 
        }          
    }, [sectieDepartament]);  

    const updatePayloadPresets = (preset) => {        
        console.log(preset['schimbNoapte']);
        console.log(typeof(preset['schimbNoapte']));
        Object.keys(preset).forEach(key => {            
            switch(typeof(preset[key])){
                case 'boolean':                
                    if(preset[key] == true){                        
                        updateNewUserData(key, true);
                    }
                    break;
                case 'number':
                    if(newUserData[key] < preset[key]){
                        updateNewUserData(key, preset[key]);
                    }
                    break;
                case 'string':
                    // if(isEmptyOrSpaces(preset[key])) break;
                    // -> nu stiu cat de buna e chestia asta, mai verifica pls
                    updateNewUserData(key, preset[key]);
                    break;
                case 'object':
                    if(!Array.isArray(preset[key]))
                    {
                        if(newUserData[key] == null && preset[key] != null)
                        updateNewUserData(key, preset[key]);
                    }
                    else{
                        if(!(preset[key].length > 0)) break;
                        if(preset[key][0].hasOwnProperty('columns')){
                            const rows = [...newUserData[key]];
                            for(var i = 0; i < preset[key].length; i++){
                                rows.push(preset[key][i]);
                            }
                            var should_run = true;
                            while(should_run){
                                should_run = false;
                                for(var i = 0; i < rows.length; i++){
                                    var should_remove = true;
                                    for(var j = 0; j < rows[i].columns.length; j++)
                                    {
                                        if(isEmptyOrSpaces(rows[i].columns[j]) == false)
                                        {
                                            should_remove = false;
                                            break;
                                        }
                                    }
                                    if(should_remove){
                                        rows.splice(i, 1);
                                        should_run = true;
                                        break;
                                    }
                                }
                            }                            
                            updateNewUserData(key, rows);
                        }                        
                        if(preset[key][0].hasOwnProperty('label')){
                            console.log(newUserData);
                            const rows = [...newUserData[key]];
                            for(var i = 0; i < preset[key].length; i++){
                                if(preset[key][i].value == true)
                                    rows[i].value = true;
                            }
                            updateNewUserData(key, rows);                            
                        }
                    }                                    
                    break;
            }
        });
        if(hideNavigation) {runReload(); return;}
        nextCallback();
        prevCallback();
    }    

    return (
        <>
            <h5>Fisa de identificare a factorilor de risc profesional</h5>
            <br/>            
            {configMode == false && (
                <>
                    <div className="form-group">                        
                        <label>Sectie / departament</label>                        
                        <Autocomplete
                            placeholder={'Sectie / departament'}
                            suggestions={compartimenteAutocompleteOptions}
                            value={sectieDepartament}      
                            onChange={(e) =>{ setSectieDepartament(e); }}       
                            // onBlur={()=>{
                            //     if(isEmptyOrSpaces(sectieDepartament)) return;
                            //     if(inArray(sectieDepartament, compartimenteAutocompleteOptions) == false){                                    
                            //         alert("Alegeti un compartiment din lista");                                                                        
                            //     }
                            // }}
                        />
                    </div>   
                    <div className="form-group">     
                    <>
                            <label>Denumirea postului si a locului de munca</label>                        
                            <Autocomplete
                                placeholder={'Denumirea postului si a locului de munca'}
                                suggestions={locuriMuncaAutocompleteOptions}
                                value={locMunca}
                                onChange={(e) =>{ setLocMunca(e); }}                    
                            />
                    </>                  
                    </div>                                                         
                    <YesNoDetails
                        checkLabel={"Naveta"}
                        optionLabel={"Numar ore pe zi"}
                        value={naveta}
                        setValue={setNaveta}
                        inputType={'number'}
                    />   
                </>
            )}
            {/* Descrierea activitatii */}
            <>
                <h6 style={{fontWeight: 'bold'}}>Descrierea activitatii:</h6>
                <YesNoPicker
                    bold={true}
                    checkLabel={"In echipa?"}
                    value={muncaEchipa}
                    setValue={setMuncaEchipa}
                />
                <FormGroup 
                    label={'Numar ore pe zi'}
                    value={nrOrePeZi}
                    type="text"      
                    onChange={(e) =>{ setNrOrePeZi(e.target.value); }}                    
                />
                <FormGroup 
                    label={'Numar schimburi de lucru'}
                    value={nrSchimburiDeLucru}
                    type="text"      
                    onChange={(e) =>{ setNrSchimburiDeLucru(e.target.value); }}                    
                />
                <YesNoDetails
                    checkLabel={"Schimb de noapte"}
                    optionLabel={"Observatii"}
                    value={schimbNoapte}
                    setValue={setSchimbNoapte}
                />  
                <YesNoPicker
                    checkLabel={"Pauze organizate"}
                    value={pauzeOrganizate}
                    setValue={setPauzeOrganizate}
                />
                <Checkbox
                    checkLabel={"Banda rulanta"}
                    value={bandaRulanta}
                    setValue={setBandaRulanta}
                />
                <h6 style={{fontWeight: 'bold'}}>Riscuri</h6>
                <br/>
                <div className="row">
                    {riscuri.map((risc, i)=>
                        <div key={i} className="col-md-6">
                            <Checkbox
                                checkLabel={risc.label}
                                value={risc.value}
                                setValue={(value)=>{
                                    const _riscuri = [...riscuri];
                                    _riscuri[i].value = value;
                                    setRiscuri(_riscuri);
                                }}
                            />
                            {(risc.required == false) && (
                                <button>STERGE</button>
                            )}
                        </div>
                    )}
                </div>      
                <div className="form-group">
                    <h6>Alte riscuri</h6>
                    <textarea 
                        className="form-control no-outline rounded_input"
                        rows={5}
                        placeholder={"Alte riscuri"}
                        style={{resize: 'none'}}
                        value={alteRiscuri}
                        onChange={(e)=>{setAlteRiscuri(e.target.value)}}
                    ></textarea>
                </div>      
                <br/>
                <YesNoDetails
                    checkLabel={"Conduce masina institutiei"}
                    optionLabel={"Ce categorie"}
                    value={conduceMasinaInstitutiei}
                    setValue={setConduceMasinaInstitutiei}
                />  
                <Checkbox
                    checkLabel={"Conduce numai intrauzinal"}
                    value={conduceNumaiIntrauzinal}
                    setValue={setConduceIntraUzinal}
                />
                <label>Loc de munca:</label>
                <Checkbox
                    checkLabel={"In conditii deosebite"}
                    value={inConditiiDeosebite}
                    setValue={setInConditiiDeosebite}
                />                
                <Checkbox
                    checkLabel={"In conditii speciale"}
                    value={inConditiiSpeciale}
                    setValue={setInConditiiSpeciale}
                />          
                <Checkbox
                    checkLabel={"Sector alimentar"}
                    value={sectorAlimentar}
                    setValue={setSectorAlimentar}
                />          
                <Checkbox
                    checkLabel={"Port arma"}
                    value={portArma}
                    setValue={setPortArma}
                />                
                <div className="form-group">
                    <label>Operatiuni executate de lucrator in cadrul procesului tehnologic</label>
                    <textarea 
                        className="form-control no-outline rounded_input"
                        rows={5}
                        placeholder={"Operatiuni executate de lucrator in cadrul procesului tehnologic"}
                        style={{resize: 'none'}}
                        value={operatiuniProcesTehnologic}
                        onChange={(e)=>{setOperatiuniProcesTehnologic(e.target.value)}}
                    ></textarea>
                </div>
                <br/>
            </>     
            {/* Descrierea spatiului de lucru */}
            <>
            <h6 style={{fontWeight: 'bold'}}>Descrierea spatiului de lucru</h6>
            <br/>
            <h6>Dimensiune incapere</h6>
            <div className="row">
                <div className="col-md-4">
                    <FormGroup 
                        label={'Lungime (m)'}
                        value={dimensiuneIncapereL}
                        type="number"      
                        onChange={(e) =>{ setDimensiuneIncapereL(e.target.value); }}                    
                    />
                </div>
                <div className="col-md-4">
                    <FormGroup 
                        label={'Latime (m)'}
                        value={dimensiuneIncaperel}
                        type="number"      
                        onChange={(e) =>{ setDimensiuneIncaperel(e.target.value); }}                    
                    />
                </div>
                <div className="col-md-4">
                    <FormGroup 
                        label={'Inaltime (m)'}
                        value={dimensiuneIncapereH}
                        type="number"      
                        onChange={(e) =>{ setDimensiuneIncapereH(e.target.value); }}                    
                    />
                </div>
            </div>
            <FormGroup 
                label={'Observatii'}
                value={dimensiuneObservatii}
                type="text"      
                onChange={(e) =>{ setDimensiuneObservatii(e.target.value); }}                    
            />

            <h6>Suprafata de lucru</h6>
            <div className="row">
                <div className="col-md-4">
                    <Checkbox 
                        checkLabel={'verticala'}
                        value={suprafataLucruVerticala}  
                        setValue={setSuprafataLucruVerticala}                                              
                    />
                </div>
                <div className="col-md-4">
                    <Checkbox 
                        checkLabel={'orizontala'}
                        value={suprafataLucruOrizontala}                          
                        setValue={setSuprafataLucruOrizontala}
                    />
                </div>
                <div className="col-md-4">
                    <Checkbox 
                        checkLabel={'oblica'}
                        value={suprafataLucruOblica}                    
                        setValue={setSuprafataLucruOblica}
                    />
                </div>
            </div>

            <h6>Munca</h6>
            <div className="row">
                {conditiiMunca.map((risc, i)=>
                    <div key={i} className="col-md-6">
                        <Checkbox
                            checkLabel={risc.label}
                            value={risc.value}
                            setValue={(value)=>{
                                const _riscuri = [...conditiiMunca];
                                _riscuri[i].value = value;
                                setConditiiMunca(_riscuri);
                            }}
                        />
                        {(risc.required == false) && (
                            <button>STERGE</button>
                        )}
                    </div>
                )}
            </div>    
            <div className="form-group">
                <label>Altele</label>
                <textarea 
                    className="form-control no-outline rounded_input"
                    rows={5}
                    placeholder={"Altele"}
                    style={{resize: 'none'}}
                    value={conditiiMuncaAltele}
                    onChange={(e)=>{setConditiiMuncaAltele(e.target.value)}}
                ></textarea>
            </div> 
            <br/>
            <YesNoDetails
                checkLabel={"Deplasari pe teren in interes de serviciu"}
                optionLabel={"Descrie"}
                value={deplasariInteresServiciu}
                setValue={setDeplasariInteresServiciu}                
            />   
            <br/>
            <h6>Efort fizic</h6>
            <div className="row">
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Mic"
                        value={efortFizicMic}
                        setValue={(e)=>{
                            setEfortFizicFoarteMare(false);
                            setEfortFizicMare(false);                            
                            setEfortFizicMediu(false);                            
                            setEfortFizicMic(e);
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Mediu"
                        value={efortFizicMediu}
                        setValue={(e)=>{                            
                            setEfortFizicFoarteMare(false);
                            setEfortFizicMare(false);                            
                            setEfortFizicMediu(e);
                            setEfortFizicMic(false);
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Mare"
                        value={efortFizicMare}
                        setValue={(e) =>{
                            setEfortFizicFoarteMare(false);
                            setEfortFizicMare(e);                            
                            setEfortFizicMediu(false);
                            setEfortFizicMic(false);
                        }}
                    />            
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Foarte mare"
                        value={efortFizicFoarteMare}
                        setValue={(e) => {
                            setEfortFizicFoarteMare(e);
                            setEfortFizicMare(false);
                            setEfortFizicMediu(false);
                            setEfortFizicMic(false);
                        }}
                    />
                </div>
            </div>
            <h6>Pozitie preponderent</h6>
            <div className="row">
                <div className="col-md-3">
                    <Checkbox
                        value={pozitiePreponderentOrtostatica}
                        setValue={setPozitiePreponderentOrtostatica}
                        checkLabel="ortostatica / in picioare"
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        value={pozitiePreponderentAsezat}
                        setValue={setPozitiePreponderentAsezat}
                        checkLabel="asezat"
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        value={pozitiePreponderentAplecat}
                        setValue={setPozitiePreponderentAplecat}
                        checkLabel="aplecata"
                    />            
                </div>
                <div className="col-md-3">
                    <Checkbox
                        value={pozitiePreponderentMixta}
                        setValue={setPozitiePreponderentMixta}
                        checkLabel="mixta"
                    />
                </div>
            </div>     
            <YesNoDetailsArea
                bold={true}
                checkLabel={"Pozitii fortate, nefiziologice"}
                optionLabel={"Ce tip"}        
                value={pozitiiFortate}    
                setValue={setPozitiiFortate}    
            />
            <div className="form-group">
                <h6>Gesturi profesionale</h6>
                <textarea 
                    className="form-control no-outline rounded_input"
                    rows={5}
                    placeholder={"Gesturi profesionale"}
                    style={{resize: 'none'}}
                    value={gesturiProfesionale}
                    onChange={(e)=>{setGesturiProfesionale(e.target.value)}}
                ></textarea>
            </div>
            <br/>                
            <h6>Suprasolicitari</h6>
            <div className="row">
                <div className="col-md-4">
                    <Checkbox
                        value={suprasolicitariVizuale}
                        setValue={setSuprasolicitariVizuale}
                        checkLabel="vizuale"
                    />
                </div>
                <div className="col-md-4">
                    <Checkbox
                        value={suprasolicitariAuditive}
                        setValue={setSuprasolicitariAuditive}
                        checkLabel="auditive"
                    />
                </div>
                <div className="col-md-4">
                    <Checkbox
                        value={suprasolicitariNeuropsihosenzoriale}
                        setValue={setSuprasolicitariNeuropsihosenzoriale}
                        checkLabel="suprasolicitari neuropsihosenzoriale"
                    />            
                </div>                
            </div>    
            {suprasolicitariNeuropsihosenzoriale && (
                <div className="row">
                    <div className="col-md-4">
                        <Checkbox
                            value={suprasolicitariNeuroMentale}
                            setValue={setSuprasolicitariNeuroMentale}
                            checkLabel="mentale"
                        />
                    </div>
                    <div className="col-md-4">
                        <Checkbox
                            value={suprasolicitariNeuroEmotionale}
                            setValue={setSuprasolicitariNeuroEmotionale}
                            checkLabel="emotionale"
                        />
                    </div>
                    <div className="col-md-4">
                        <Checkbox
                            value={suprasolicitariNeuroAltele}
                            setValue={setSuprasolicitariNeuroAltele}
                            checkLabel="altele"
                        />
                    </div>
                </div>
            )}
            <h6>Suprasolicitari osteomusculoarticulare</h6>
            <YesNoPicker                        
                checkLabel="Miscari fortate?"
                value={miscariFortate}
                setValue={setMiscariFortate}
            />
            <YesNoPicker                        
                checkLabel="Miscari repetitive?"
                value={miscariRepetitive}
                setValue={setMiscariRepetitive}
            />
            {(miscariRepetitive || miscariFortate) && (
                <div className="row">
                    <div className="col-md-4">
                        <label>Coloana vertebrala</label>
                        <YesNoPicker                        
                            checkLabel="Cervicala"
                            value={miscariRepetitive_cervical}
                            setValue={setMiscariRepetitive_cervical}
                        />
                        <YesNoPicker                        
                            checkLabel="Toracala"
                            value={miscariRepetitive_toracal}
                            setValue={setMiscariRepetitive_toracal}
                        />
                        <YesNoPicker                        
                            checkLabel="Lombara"
                            value={miscariRepetitive_lombar}
                            setValue={setMiscariRepetitive_lombar}
                        />
                    </div>
                    <div className="col-md-4">
                        <label>Membre superioare</label>
                        <YesNoPicker                        
                            checkLabel="Umar"
                            value={miscariRepetitive_umar}
                            setValue={setMiscariRepetitive_umar}
                        />
                        <YesNoPicker                        
                            checkLabel="Cot"
                            value={miscariRepetitive_cot}
                            setValue={setMiscariRepetitive_cot}
                        />
                        <YesNoPicker                        
                            checkLabel="Pumn"
                            value={miscariRepetitive_pumn}
                            setValue={setMiscariRepetitive_pumn}
                        />
                    </div>
                    <div className="col-md-4">
                        <label>Membre inferioare</label>
                        <YesNoPicker                        
                            checkLabel="Sold"
                            value={miscariRepetitive_sold}
                            setValue={setMiscariRepetitive_sold}
                        />
                        <YesNoPicker                        
                            checkLabel="Genunchi"
                            value={miscariRepetitive_genunchi}
                            setValue={setMiscariRepetitive_genunchi}
                        />
                        <YesNoPicker                        
                            checkLabel="Glezna"
                            value={miscariRepetitive_glezna}
                            setValue={setMiscariRepetitive_glezna}
                        />
                    </div>
                </div>                  
            )}                                  
            <YesNoDetails
                checkLabel={"Manipularea manuala a maselor"}
                optionLabel={"Precizati caracteristicile maselor manipulate"}
                value={manipulareaManualaAMaselor}
                setValue={setManipulareaManualaAMaselor}
            />
            {manipulareaManualaAMaselor != null && (
                <>
                    <div className="row">
                        <div className="col-md-4">
                            <Checkbox
                                checkLabel={"Ridicare"}
                                value={manipulareManualaRidicare}
                                setValue={setManipulareManualaRidicare}
                            />
                        </div>
                        <div className="col-md-4">
                            <Checkbox
                                checkLabel={"Coborare"}
                                value={manipulareManualaCoborare}
                                setValue={setManipulareManualaCoborare}
                            />
                        </div>
                        <div className="col-md-4">
                            <Checkbox
                                checkLabel={"Impingere"}
                                value={manipulareManualaImpingere}
                                setValue={setManipulareManualaImpingere}
                            />
                        </div>
                        <div className="col-md-4">
                            <Checkbox
                                checkLabel={"Tragere"}
                                value={manipulareManualaTragere}
                                setValue={setManipulareManualaTragere}
                            />
                            </div>
                        <div className="col-md-4">
                            <Checkbox
                                checkLabel={"Purtare"}
                                value={manipulareManualaPurtare}
                                setValue={setManipulareManualaPurtare}
                            />
                        </div>
                        <div className="col-md-4">                        
                            <Checkbox
                                checkLabel={"Deplasare"}
                                value={manipulareManualaDeplasare}
                                setValue={setManipulareManualaDeplasare}
                            />    
                        </div>                    
                    </div>
                    <FormGroup 
                        label={'Greutate maxima manipulata'}
                        value={manipulareManualaGreutateMaxima}      
                        onChange={(e) =>{ setManipulareManualaGreutateMaxima(e.target.value); }}                            
                    />
                </>
            )}
            <YesNoPicker
                bold={true}
                checkLabel={"Agenti chimici?"}
                value={agentiChimici}
                setValue={setAgentiChimici}
            />
            {agentiChimici && (
                <DynamicTable
                    columns={["Tipul agentului chimic", "< V.L.E.", "> V.L.E", "Fp", "C", "P"]}
                    legend={"V.L.E. = valoarea-limita de expunere profesionala | Fp = Foarte periculor | C = Cancerigen | P = Patrunde prin piele"}
                    rows={agentiChimiciTabelData}
                    setRows={setAgentiChimiciTabelData}
                />
            )}
            <div className="row">
                <div className="col-md-8">
                    <FormGroup
                        bold={true}
                        label={'Agenti biologici'}
                        value={agentiBiologici}
                        onChange={(e)=>{setAgentiBiologici(e.target.value)}}
                    />
                </div>
                <div className="col-md-4">
                    <FormGroup
                        bold={true}
                        label={'Grupa'}
                        value={agentiBiologiciGrupa}
                        onChange={(e) => {setAgentiBiologiciGrupa(e.target.value)}}
                    />
                </div>
            </div>      
            <FormGroup
                bold={true}
                label={'Agenti cancerigeni'}
                value={agentiCancerigeni}
                onChange={(e) =>{setAgentiCancerigeni(e.target.value)}}
            />                  
            <YesNoPicker
                bold={true}
                checkLabel={"Pulberi profesionale"}
                value={pulberiProfesionale}
                setValue={setPulberiProfesionale}
            />
            {pulberiProfesionale && (
                <DynamicTable
                    columns={["Tipul pulberilor", "< V.L.E.", "> V.L.E"]}                    
                    rows={pulberiProfesionaleTableData}
                    setRows={setPulberiProfesionaleTableData}
                />
            )}                
            <h6>Zgomot profesional</h6>
            <br/>
            <div className="row">
                <div className="col-md-3">
                    <Checkbox
                        checkLabel={"< V.L.E"}
                        value={zgomotProfesionalLessVLE}
                        setValue={setZgomotProfesionalLessVLE}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel={"> V.L.E"}
                        value={zgomotProfesionalMoreVLE}
                        setValue={setZgomotProfesionalMoreVLE}
                    />
                </div>
            </div>                    
            <YesNoPicker
                bold={true}
                checkLabel={"Zgomote impulsive"}
                value={zgomoteImpulsive}
                setValue={setZgomoteImplusive}
            />
            <h6>Vibratii mecanice</h6>            
            <div className="row">
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="< V.L.E."
                        value={vibratiiMecaniceLessVLE}
                        setValue={setVibratiiMecaniceLessVLE}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="> V.L.E."
                        value={vibratiiMecaniceMoreVLE}
                        setValue={setVibratiiMecaniceMoreVLE}
                    />
                </div>    
            </div>
            {(vibratiiMecaniceMoreVLE || vibratiiMecaniceLessVLE) && (
                <>
                    <label>Specificati zona:</label>
                    <br/>
                    <div className="row">
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel="Coloana vertebrala"
                                value={vibratiiMecaniceVertebrala}
                                setValue={setVibratiiMecaniceVertebrala}
                            />
                        </div>
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel="Membre superioare"
                                value={vibratiiMecaniceMembreSuperioare}
                                setValue={setVibratiiMecaniceMembreSuperioare}
                            />  
                        </div>
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel="Actiune asupra intregului organism"
                                value={vibratiiMecaniceActiuneAsupraIntreguluiCorp}
                                setValue={setVibratiiMecaniceActiuneAsupraIntreguluiCorp}
                            />
                        </div>
                    </div>                                        
                </>
            )}                        

            <h6>Microclimat</h6>
            <FormGroup
                label={"Temperatura aer"}
                value={temperaturaAer}
                onChange={(e) => setTemperaturaAer(e.target.value)}
            />
            <YesNoPicker    
                checkLabel={"Variatii repetate de temperatura"}
                value={temperaturaRepetata}
                setValue={setTemperaturaRepetata}
            />
            <FormGroup
                label={"Presiune aer"}
                value={presiuneAer}
                onChange={(e) => setPresiuneAer(e.target.value)}
            />
            <FormGroup
                label={"Umiditate relativa"}
                value={umiditateRelativa}
                onChange={(e) => setUmiditateRelativa(e.target.value)}
            />                        

            <YesNoPicker
                bold={true}
                checkLabel={"Radiatii"}
                value={radiatii}
                setValue={setRadiatii}
            />
            {radiatii && (
                <>
                    <h6>Parte speciala pentru expunerea profesionala la radiatii ionizante</h6>
                    <br/>
                    <FormGroup
                        label={"Data intrarii in mediul cu expunere profesionala la radiatii ionizate"}
                        type={'date'}
                        value={radiatiiIonizateDataIntrariiMediuExpunereProfesionala}
                        onChange={(e) => {setRadiatiiIonizateDataIntrariiMediuExpunereProfesionala(e.target.value)}}
                    />
                    <h6>Clasificare actuala</h6>                    
                    <div className="row">
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel={'Grupa A'}  
                                value={radiatiiIonizateGrupaA}
                                setValue={setRadiatiiIonizateGrupaA}
                            />
                        </div>
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel={'Grupa B'}  
                                value={radiatiiIonizateGrupaB}
                                setValue={setRadiatiiIonizateGrupaB}
                            />
                        </div>
                    </div>
                    <FormGroup
                        label="Conditii de expunere"
                        value={conditiiExpunereRadiatiiGRUPA}
                        onChange={(e)=>{setConditiiExpunereRadiatiiGRUPA(e.target.value)}}
                    />
                    <div className="form-group">
                        <h6>Aparatura folosita</h6>
                        <textarea 
                            className="form-control no-outline rounded_input"
                            rows={2}
                            placeholder={"Aparatura folosita"}
                            style={{resize: 'none'}}
                            value={radiatiiIonizateAparaturaFolosita}
                            onChange={(e)=>{setRadiatiiIonizateAparaturaFolosita(e.target.value)}}
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <h6>Proces tehnologic</h6>
                        <textarea 
                            className="form-control no-outline rounded_input"
                            rows={5}
                            placeholder={"Proces tehnologic"}
                            style={{resize: 'none'}}
                            value={radiatiiIonizateProcesTehnologic}
                            onChange={(e)=>{setRadiatiiIonizateProcesTehnologic(e.target.value)}}
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <h6>Operatiuni indeplinite</h6>
                        <textarea 
                            className="form-control no-outline rounded_input"
                            rows={5}
                            placeholder={"Operatiuni indeplinite"}
                            style={{resize: 'none'}}
                            value={radiatiiIonizateOperatiuniIndeplinite}
                            onChange={(e)=>{setRadiatiiIonizateOperatiuniIndeplinite(e.target.value)}}
                        ></textarea>
                    </div>
                    <h6>Surse folosite</h6>                    
                    <div className="row">
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel={'Inchise'}  
                                value={radiatiiIonizateSurseInchise}
                                setValue={setRadiatiiIonizateSurseInchise}
                            />
                        </div>
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel={'Deschise'}  
                                value={radiatiiIonizateSurseDeschise}
                                setValue={setRadiatiiIonizateSurseDeschise}
                            />
                        </div>
                    </div>
                    <h6>Tip expunere</h6>                    
                    <div className="row">
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel={'X externa'}  
                                value={radiatiiIonizateTipExpunere_X_externa}
                                setValue={setRadiatiiIonizateTipExpunere_X_externa}
                            />
                        </div>
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel={'Gamma externa'}  
                                value={radiatiiIonizateTipExpunere_gamma_externa}
                                setValue={setRadiatiiIonizateTipExpunere_gamma_externa}
                            />
                        </div>
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel={'Interna'}  
                                value={radiatiiIonizateTipExpunere_interna}
                                setValue={setRadiatiiIonizateTipExpunere_interna}
                            />
                        </div>
                        <div className="col-md-3">
                            <Checkbox
                                checkLabel={'Externa si interna'}  
                                value={radiatiiIonizateTipExpunere_interna_externa}
                                setValue={setRadiatiiIonizateTipExpunere_interna_externa}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Masuri de protectie individuala</label>
                        <textarea 
                            className="form-control no-outline rounded_input"
                            rows={2}
                            placeholder={"Masuri de protectie individuala"}
                            style={{resize: 'none'}}
                            value={radiatiiIonizateTipExpunere_masuriprotectieindividuala}
                            onChange={(e)=>{setRadiatiiIonizateTipExpunere_masuriprotectieindividuala(e.target.value)}}
                        ></textarea>
                    </div>

                    <h6>Expunere anterioara</h6>
                    <FormGroup
                        label="Expunere anterioara"
                        value={radiatiiExpunereAnterioaraEXTRA}
                        onChange={(e)=>{setRadiatiiExpunereAnterioaraEXTRA(e.target.value)}}
                    />
                    <div className="row">
                        <div className="col-md-6">
                            <FormGroup
                                label="Perioada"
                                value={radiatiiIonizate_expunere_anterioara_perioada}
                                onChange={(e)=>{setRadiatiiIonizate_expunere_anterioara_perioada(e.target.value)}}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormGroup
                                type={'number'}
                                label="Numar ani"
                                value={radiatiiIonizate_expunere_anterioara_nr_ani}
                                onChange={(e)=>{setRadiatiiIonizate_expunere_anterioara_nr_ani(e.target.value)}}
                            />
                        </div>
                    </div>                    
                    <FormGroup
                        type={'number'}
                        label={'Doza cumulata prin expunere externa (mSv)'}
                        value={radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa}
                        onChange={(e)=>{setRadiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa(e.target.value)}}
                    />
                    <FormGroup
                        type={'number'}
                        label={'Doza cumulata prin expunere interna'}
                        value={radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna}
                        onChange={(e)=>{setRadiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna(e.target.value)}}
                    />
                    <FormGroup
                        type={'number'}
                        label={'Doza totala'}
                        value={radiatiiIonizate_expunere_anterioara_doza_totala}
                        onChange={(e)=>{setRadiatiiIonizate_expunere_anterioara_doza_totala(e.target.value)}}
                    />
                    <h6>Supraexpuneri anterioare</h6>
                    <FormGroup
                        type={'text'}
                        label={'Supraexpuneri anterioare'}
                        value={radiatiiSupraExpunereAnterioaraEXTRA}
                        onChange={(e)=>{setRadiatiiSupraExpunereAnterioaraEXTRA(e.target.value)}}
                    />
                    <div className="row">
                        <div className="col-md-6">
                            <h6>Exceptionale</h6>
                            <br/>
                            <label>Tip expunere</label>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <Checkbox
                                        checkLabel={'X externa'}  
                                        value={radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa}
                                        setValue={setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Checkbox
                                        checkLabel={'Gamma externa'}  
                                        value={radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa}
                                        setValue={setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Checkbox
                                        checkLabel={'Interna'}  
                                        value={radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna}
                                        setValue={setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Checkbox
                                        checkLabel={'Externa si interna'}  
                                        value={radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa}
                                        setValue={setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa}
                                    />
                                </div>
                            </div>
                            <FormGroup
                                type={'date'}
                                label={'Data'}
                                value={radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data}
                                onChange={(e)=>{setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data(e.target.value)}}
                            />
                            <FormGroup
                                type={'number'}
                                label={'Doza (mSv)'}
                                value={radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza}
                                onChange={(e)=>{setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza(e.target.value)}}
                            />
                            <FormGroup                                
                                label={'Concluzii'}
                                value={radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii}
                                onChange={(e)=>{setRadiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii(e.target.value)}}
                            />
                        </div>
                        <div className="col-md-6">
                            <h6>Accidentale</h6>
                            <br/>
                            <label>Tip expunere</label>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <Checkbox
                                        checkLabel={'X externa'}  
                                        value={radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa}
                                        setValue={setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Checkbox
                                        checkLabel={'Gamma externa'}  
                                        value={radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa}
                                        setValue={setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Checkbox
                                        checkLabel={'Interna'}  
                                        value={radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna}
                                        setValue={setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Checkbox
                                        checkLabel={'Externa si interna'}  
                                        value={radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa}
                                        setValue={setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa}
                                    />
                                </div>
                            </div>
                            <FormGroup
                                type={'date'}
                                label={'Data'}
                                value={radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data}
                                onChange={(e)=>{setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_data(e.target.value)}}
                            />
                            <FormGroup
                                type={'number'}
                                label={'Doza (mSv)'}
                                value={radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza}
                                onChange={(e)=>{setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza(e.target.value)}}
                            />
                            <FormGroup                                
                                label={'Concluzii'}
                                value={radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii}
                                onChange={(e)=>{setRadiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii(e.target.value)}}
                            />
                        </div>
                    </div>

                    <h6></h6>
                    <FormGroup
                        label={'Radiatii neionizante'}
                        value={radiatiiSupraExpunereAnterioaraEXTRATIPT}
                        onChange={(e) => setRadiatiiSupraExpunereAnterioaraEXTRATIPT(e.target.value)}
                    />
                    <div className="form-group">
                        <label>Tipul</label>
                        <textarea 
                            className="form-control no-outline rounded_input"
                            rows={2}
                            placeholder={"Tipul"}
                            style={{resize: 'none'}}
                            value={radiatiiNeionizateTipul}
                            onChange={(e)=>{setRadiatiiNeionizateTipul(e.target.value)}}
                        ></textarea>
                    </div>                

                    <br/>
                    <hr/>
                    <br/>
                </>
            )}            

            <h6>Iluminat</h6>            
            <div className="row">
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="suficient"
                        value={iluminatSuficient}
                        setValue={setIluminatSuficient}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="insuficient"
                        value={iluminatInsuficient}
                        setValue={setIluminatInsuficient}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="natural"
                        value={iluminatNatural}
                        setValue={setIluminatNatural}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="artificial"
                        value={iluminatArtificial}
                        setValue={setIluminatArtificial}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="mixt"
                        value={iluminatMixt}
                        setValue={setIluminatMixt}
                    />
                </div>
            </div>
            </>

            <div className="form-group">
                <h6>Mijloace de protectie colectiva</h6>
                <textarea 
                    className="form-control no-outline rounded_input"
                    rows={5}
                    placeholder={"Mijloace de protectie colectiva"}
                    style={{resize: 'none'}}
                    value={mijloaceProtectieColectiva}
                    onChange={(e)=>{setMijloaceProtectieColectiva(e.target.value)}}
                ></textarea>
            </div>
            <div className="form-group">
                <h6>Mijloace de protectie individuala</h6>
                <textarea 
                    className="form-control no-outline rounded_input"
                    rows={5}
                    placeholder={"Mijloace de protectie individuala"}
                    style={{resize: 'none'}}
                    value={mijloaceProtectieIndividuala}
                    onChange={(e)=>{setMijloaceProtectieIndividuala(e.target.value)}}
                ></textarea>
            </div>   
            <div className="form-group">
                <h6>Echipament de lucru</h6>
                <textarea 
                    className="form-control no-outline rounded_input"
                    rows={5}
                    placeholder={"Echipament de lucru"}
                    style={{resize: 'none'}}
                    value={echipamentDeLucru}
                    onChange={(e)=>{setEchipamentDeLucru(e.target.value)}}
                ></textarea>
            </div>   

            <h6>Anexe igenico-sanitare</h6>            
            <div className="row">
                <div className="col-md-4">
                    <Checkbox
                        checkLabel="vestiar"
                        value={anexeIgenicoVestiar}
                        setValue={setAnexeIgenicoVestiar}
                    />
                </div>
                <div className="col-md-4">
                    <Checkbox
                        checkLabel="chiuveta"
                        value={anexeIgenicoChiuveta}
                        setValue={setAnexeIgenicoChiuveta}
                    />
                </div>
                <div className="col-md-4">
                    <Checkbox
                        checkLabel="WC"
                        value={anexeIgenicoWC}
                        setValue={setAnexeIgenicoWC}
                    />
                </div>
                <div className="col-md-4">
                    <Checkbox
                        checkLabel="dus"
                        value={anexeIgenicoDus}
                        setValue={setAnexeIgenicoDus}
                    />
                </div>
                <div className="col-md-4">
                    <Checkbox
                        checkLabel="sala de mese"
                        value={anexeIgenicoSalaDeMese}
                        setValue={setAnexeIgenicoSalaDeMese}
                    />
                </div>
                <div className="col-md-4">
                    <Checkbox
                        checkLabel="spatiu de recreere"
                        value={anexeIgenicoSpatiuRecreere}
                        setValue={setAnexeIgenicoSpariuRecreere}
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Altele</label>
                <textarea 
                    className="form-control no-outline rounded_input"
                    rows={2}
                    placeholder={"Altele"}
                    style={{resize: 'none'}}
                    value={anexeIgenicoAltele}
                    onChange={(e)=>{setAnexeIgenicoAltele(e.target.value)}}
                ></textarea>
            </div>   
            <br/>
            <div className="form-group">
                <h6>Observatii</h6>
                <textarea 
                    className="form-control no-outline rounded_input"
                    rows={2}
                    placeholder={"Observatii"}
                    style={{resize: 'none'}}
                    value={observatii}
                    onChange={(e)=>{setObservatii(e.target.value)}}
                ></textarea>
            </div>             

            {!hide_save_as_macheta && (
                <div className='row'>
                    <div className='col-md-8'></div>
                    <div className='col-md-4'>
                        <RoundButton 
                            className="custom-btn-primary"
                            text="Salveaza ca macheta noua"                        
                            onClickHandler={()=>{
                                post("EmployeeManagement/RiskPresets/AddAsNewRiskPreset", {
                                    'presetJSON': JSON.stringify(newUserData)
                                }, (response) => {
                                    parseTrueOrErrorMessage(response, (response)=>{
                                        displayToastNotification("Salvat cu succes.", TOAST_NOTIFICATION.SUCCESS);
                                    });
                                });
                            }}
                        />
                    </div>
                </div>
            )}

            {configMode == false && hideNavigation == false && (
                <>
                    <br/>
                    <div className="row">
                        <div className="col-md-6">
                            <RoundButton 
                                className="btn-secondary"
                                text="Inapoi"                        
                                onClickHandler={()=>{
                                    prevCallback();
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <RoundButton 
                                className="custom-btn-primary"
                                text="Inainte"                        
                                onClickHandler={()=>{
                                    if(!inArray(sectieDepartament, compartimenteAutocompleteOptions)){
                                        alert("Alegeti un compartiment din lista"); 
                                        return;                                                           
                                    }
                                    if(isEmptyOrSpaces(locMunca)){
                                        alert("Alegeti un loc de munca");
                                        return;
                                    }
                                    nextCallback();
                                }}
                            />
                        </div>
                    </div>
                </>
            )}            
            <br/>
        </>
    )
}

export const EmployeeNew_SolicitareExamenMedical = ({nextCallback, prevCallback, newUserData, updateNewUserData, actual_user_data}) =>{    
    useEffect(()=>{ window.scrollTo(0,0); }, []);
    
    //#region State management
    const [solicitare_angajare, setSolicitare_angajare] = useState(newUserData["solicitare_angajare"] != undefined ? newUserData["solicitare_angajare"] : false);
    useEffect(()=>{        
        updateNewUserData("solicitare_angajare", solicitare_angajare);
    }, [solicitare_angajare]); 
    const [solicitare_control_medical_periodic, setSolicitare_control_medical_periodic]= useState(newUserData["solicitare_control_medical_periodic"] != undefined ? newUserData["solicitare_control_medical_periodic"] : false);
    useEffect(()=>{        
        updateNewUserData("solicitare_control_medical_periodic", solicitare_control_medical_periodic);
    }, [solicitare_control_medical_periodic]); 
    const [solicitare_adaptare, setSolicitare_adaptare]= useState(newUserData["solicitare_adaptare"] != undefined ? newUserData["solicitare_adaptare"] : false);
    useEffect(()=>{        
        updateNewUserData("solicitare_adaptare", solicitare_adaptare);
    }, [solicitare_adaptare]); 
    const [solicitare_reluarea_muncii, setSolicitare_reluarea_muncii] = useState(newUserData["solicitare_reluarea_muncii"] != undefined ? newUserData["solicitare_reluarea_muncii"] : false);
    useEffect(()=>{        
        updateNewUserData("solicitare_reluarea_muncii", solicitare_reluarea_muncii);
    }, [solicitare_reluarea_muncii]); 
    const [solicitare_supraveghere_speciala, setSolicitare_supraveghere_speciala] = useState(newUserData["solicitare_supraveghere_speciala"] != undefined ? newUserData["solicitare_supraveghere_speciala"] : false);
    useEffect(()=>{        
        updateNewUserData("solicitare_supraveghere_speciala", solicitare_supraveghere_speciala);
    }, [solicitare_supraveghere_speciala]); 
    const [solicitare_la_cerere, setSolicitare_la_cerere] = useState(newUserData["solicitare_la_cerere"] != undefined ? newUserData["solicitare_la_cerere"] : false);
    useEffect(()=>{        
        updateNewUserData("solicitare_la_cerere", solicitare_la_cerere);
    }, [solicitare_la_cerere]); 
    const [solicitare_schimbareaLoculuiDeMunca, setSolicitareSchimbareaLoculuiDeMunca] = useState(newUserData["solicitare_schimbareaLoculuiDeMunca"] != undefined ? newUserData["solicitare_schimbareaLoculuiDeMunca"] : false);
    useEffect(()=>{        
        updateNewUserData("solicitare_schimbareaLoculuiDeMunca", solicitare_schimbareaLoculuiDeMunca);
    }, [solicitare_schimbareaLoculuiDeMunca]); 
    const [solicitare_altele, setSolicitareAltele] = useState(newUserData["solicitare_altele"] != undefined ? newUserData["solicitare_altele"] : null);
    useEffect(()=>{        
        updateNewUserData("solicitare_altele", solicitare_altele);
    }, [solicitare_altele]); 

    const [solicitare_profesie, setSolicitareProfesie] = useState(newUserData["solicitare_profesie"] != undefined ? newUserData["solicitare_profesie"] : '');
    useEffect(()=>{        
        updateNewUserData("solicitare_profesie", solicitare_profesie);
    }, [solicitare_profesie]); 
    const [solicitare_functie, setSolicitareFunctie] = useState(newUserData["solicitare_functie"] != undefined ? newUserData["solicitare_functie"] : '');
    useEffect(()=>{        
        updateNewUserData("solicitare_functie", solicitare_functie);
    }, [solicitare_functie]); 
    const [solicitare_loc_munca, setSolicitareLocMunca] = useState(actual_user_data["locMunca"] != undefined ? actual_user_data["locMunca"] : '');    
    const [solicitare_compartiment, setSolicitareCompartiment] = useState(actual_user_data["sectieDepartament"] != undefined ? actual_user_data["sectieDepartament"] : '');    
    //#endregion

    return (
        <>
            <h5>Fisa de solicitare a examenului medical</h5>
            <br/>
            <div className="row">
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Angajare"
                        value={solicitare_angajare}
                        setValue={setSolicitare_angajare}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Control medical periodic"
                        value={solicitare_control_medical_periodic}
                        setValue={setSolicitare_control_medical_periodic}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Adaptare"
                        value={solicitare_adaptare}
                        setValue={setSolicitare_adaptare}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Reluarea muncii"
                        value={solicitare_reluarea_muncii}
                        setValue={setSolicitare_reluarea_muncii}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Supraveghere speciala"
                        value={solicitare_supraveghere_speciala}
                        setValue={setSolicitare_supraveghere_speciala}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="La cerere"
                        value={solicitare_la_cerere}
                        setValue={setSolicitare_la_cerere}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Schimbarea locului de munca"
                        value={solicitare_schimbareaLoculuiDeMunca}
                        setValue={setSolicitareSchimbareaLoculuiDeMunca}
                    />
                </div>
                <div className="col-md-3">
                    <IfYesDetails
                        checkLabel={'Altele'}
                        optionLabel={'Altele'}
                        value={solicitare_altele}
                        setValue={setSolicitareAltele}
                    />
                </div>
            </div> 

            <FormGroup
                label={"Profesie"}
                value={solicitare_profesie}
                onChange={(e) => {setSolicitareProfesie(e.target.value)}}                
            />
            <FormGroup
                label={"Functie"}
                value={solicitare_functie}
                onChange={(e) => {setSolicitareFunctie(e.target.value)}}            
            />
            <FormGroup
                label={"Loc munca"}
                value={solicitare_loc_munca}
                onChange={(e) => {setSolicitareLocMunca(e.target.value)}}         
                disabled={true}
            />
            <FormGroup
                label={"Compartiment"}
                value={solicitare_compartiment}
                onChange={(e) => {setSolicitareCompartiment(e.target.value)}}                
                disabled={true}
            />

            <br/>
            <div className="row">
                <div className="col-md-6">
                    <RoundButton 
                        className="btn-secondary"
                        text="Inapoi"                        
                        onClickHandler={()=>{
                            prevCallback();
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <RoundButton 
                        className="custom-btn-primary"
                        text="Inainte"                        
                        onClickHandler={()=>{
                            if(isEmptyOrSpaces(solicitare_profesie)){
                                alert("Specificati o profesie");
                                return;
                            }
                            if(isEmptyOrSpaces(solicitare_functie)){
                                alert("Specificati o functie");
                                return;
                            }
                            if(!(solicitare_angajare || solicitare_control_medical_periodic || solicitare_adaptare
                                || solicitare_reluarea_muncii || solicitare_supraveghere_speciala || solicitare_la_cerere
                                || solicitare_schimbareaLoculuiDeMunca || solicitare_altele)){
                                    alert("Alegeti tipul de solicitare");
                                    return;
                            }
                            nextCallback();
                        }}
                    />
                </div>
            </div>         
            <br/>     
        </>
    )
}

export const EmployeeNew_ReviewData = ({prevCallback, newUserData, fisaRiscuriData, dosarMedicalRequestData})=>{
    useEffect(()=>{ window.scrollTo(0,0); }, []);
    
    const [redirectToEmployeesList, setRedirectToEmployeesList] = useState(false);
    const [addedSuccessDialogVisible, setAddedSuccessDialogVisible] = useState(false);

    const [previewCreatedBy, setPreviewCreatedBy] = useState('');
    const [updatedNewUserData, setUpdatedNewUserData] = useState(undefined);

    const [isFinishEnabled, setIsFinishedEnabled] = useState(true);

    useEffect(()=>{        
        get_ajax('EmployeeManagement/Employees/GetCurrentUserDetails', (user_name) =>{
            setPreviewCreatedBy(user_name);
        });       

        get_ajax('EmployeeManagement/Employees/GetSelectedUnitateSubunitateDetails?unitateId='+newUserData.selectedUnitateId + "&subunitateId=" + newUserData.selectedSubunitateId, (response) => {            
            const cpy = newUserData;
            cpy['unitate'] = JSON.parse(response.unitate);
            cpy['subunitate'] = JSON.parse(response.subunitate);                            
            setUpdatedNewUserData(cpy);            
        })         ;
        setIsFinishedEnabled(true);   
    }, []);

    return(
        <>
            <h5>Revizuire date</h5>
            <br/>
            {updatedNewUserData === undefined && (
                <LoadingCircleComponent/>
            )}
            {updatedNewUserData !== undefined && (
                <>
                    <MediaControlRequestRender
                        employeeData={updatedNewUserData}
                        medicalRequest={dosarMedicalRequestData}
                        risksFile={fisaRiscuriData}
                        medicalRequestDate={get_today_date()}
                        risksFileCreatedBy={previewCreatedBy}
                    />
                    <br/>
                    <div className="row">
                        <div className="col-md-6">
                            <RoundButton 
                                className="btn-secondary"
                                text="Inapoi"                        
                                onClickHandler={()=>{
                                    prevCallback();
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <RoundButton 
                                className="custom-btn-primary"
                                text="Finalizeaza"              
                                disabled={!isFinishEnabled}          
                                onClickHandler={()=>{
                                    setIsFinishedEnabled(false);
                                    post('EmployeeManagement/Employees/AddNewWorkerWithDosarMedical', {
                                        'newUserData': JSON.stringify(newUserData),
                                        'fisaRiscuriData': JSON.stringify(fisaRiscuriData),
                                        'dosarMedicalRequestData': JSON.stringify(dosarMedicalRequestData)
                                    }, (response) =>{                                        
                                        parseTrueOrErrorMessage(response, (response) => {
                                            setAddedSuccessDialogVisible(true);                                
                                        });
                                    });
                                }}
                            />
                        </div>
                    </div>         
                    <br/>   
                    <UpdateResultSuccessDialog
                        label={'Lucratorul a fost adaugat cu succes.'}
                        isOpen={addedSuccessDialogVisible}
                        toggle={()=>{setAddedSuccessDialogVisible(false); window.location.href='/medical-control/workers'; }}
                        onConfirm={()=>{setRedirectToEmployeesList(true);}}
                    />
                    {redirectToEmployeesList && (
                        <Redirect to='/medical-control/workers'/>
                    )}
                </>
            )}            
        </>
    );
}
//#endregion



const StepWithTooltipComponent = ({isActive, content, tooltipText, index}) =>{    
    return(
        <>
            <div className={isActive ? "step step_active" : "step"} id={"_tooltip_content_Id_" + index}>
                <h6>{content}</h6>
            </div>
            <UncontrolledTooltip placement="bottom" target={"_tooltip_content_Id_" + index}>
                {tooltipText}
            </UncontrolledTooltip>   
        </>
    )
}