"use strict";
exports.__esModule = true;
exports.downloadURI = exports.formatBytes = exports.NullOrUndefined = void 0;
var NullOrUndefined = function (object) {
    if (object === null || object === undefined)
        return true;
    else
        return false;
};
exports.NullOrUndefined = NullOrUndefined;
var formatBytes = function (bytes, decimals) {
    if (decimals === void 0) { decimals = 2; }
    if (bytes === 0)
        return '0 Bytes';
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
exports.formatBytes = formatBytes;
var downloadURI = function (uri, name) {
    var link = document.createElement("a");
    if (!uri.includes("blob.core"))
        link.target = "_blank";
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
exports.downloadURI = downloadURI;
