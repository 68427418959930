import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {SearchBox} from 'shared/dist/components/generic-building-blocks/SearchBox';
import {RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import {get, get_ajax, post} from 'shared/dist/utils/DataProvider';
import {Redirect} from 'react-router-dom';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {FormGroup} from 'shared/dist/components/generic-building-blocks/FormGroup';
import { UncontrolledTooltip } from 'reactstrap';
import {BsFillPersonFill, BsBuilding} from 'react-icons/bs';
import {VscOpenPreview} from 'react-icons/vsc';
import {AiFillWarning} from 'react-icons/ai';
import {FaFileMedical} from 'react-icons/fa';
import {CgDanger} from 'react-icons/cg';
import {IfYesDetails, YesNoDetails, YesNoDetailsArea } from 'shared/dist/components/generic-building-blocks/YesNoDetails';
import {YesNoPicker} from 'shared/dist/components/generic-building-blocks/YesNoPicker';
import {Checkbox} from 'shared/dist/components/generic-building-blocks/Checkbox';
import {DynamicTable } from 'shared/dist/components/generic-building-blocks/DynamicTable';
import { UpdateResultSuccessDialog} from 'shared/dist/components/generic-building-blocks/UserDialogs';
import Autocomplete from 'shared/dist/components/generic-building-blocks/AutocompleteTextbox';
import {FaUser}from 'react-icons/fa';

import AOS from 'aos';
import "aos/dist/aos.css";
import { DateIdentificareContainer, FisaAptitudiniViewer, FisaRiscuriViewer, MedicalControlRequester, RequestMedicalControlNotification, UpdateRisksFileNotification, WorkplaceContainer } from '../medical-control/WorkersDetails';

function get_today_date(){
    var today = new Date(); 
    var dd = today.getDate(); 
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear(); 
    return `${dd}-${mm}-${yyyy}`;
}

function isEmptyOrSpaces(str){
    try { return str === null || str.match(/^ *$/) !== null; }
    catch { return true; }
}

export const HrManagerWorkers = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [statistics, setStatistics] = useState(undefined);

    useEffect(()=>{     
        AOS.init();  
        get_ajax("HrAdmin/Employees/GetEmployeesStatistics", (response) =>{
            setStatistics(response);
        });                 
    }, []);

    const loadData = (searchBy, page) => {
        get_ajax("HrAdmin/Employees/GetEmployees?searchBy=" + searchBy + "&expireFilter=" + expirationFilter + "&page=" + page, (response) =>{
            setPageSize(response.objects_per_page);
            setEmployees(response.objects);
            setIsLoading(false);
        });
    }

    const [expirationFilter, setExpirationFilter] = useState('active');
    useEffect(()=>{
        if(currentPage == 0)
        {
            setSearchBoxValue('');
            loadData('', 0);
        }
        setCurrentPage(0);
    }, [expirationFilter]);
    const toggleExpirationFilter = (newValue) =>{
        if(newValue === expirationFilter)
            setExpirationFilter('');
        else
            setExpirationFilter(newValue);
    }
    
    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(0);

    useEffect(()=>{
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    return(
        <>
            <br/>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                <h4>Lucratori</h4>
                <br/>
                <div className="row">
                    <div className="col-md-6">
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </div>
                </div>
            </LayoutCard>
            {isLoading && (
                <>
                    <br/>
                    <LoadingCircleComponent/>
                </>
            )}
            {!isLoading && (
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            {employees.map((e, i) =>
                                <div className="col-md-4" key={i}>
                                        <Link to = {"/hrmanager/workers/details/" + e.id}>                                            
                                            <LayoutCard                                        
                                                className={e.isActive === false ? "layout_card1 shadow-sm disabled_grayscale" : "layout_card1 shadow-sm"}
                                                key={i} 
                                            >
                                                {e.isActive === true && (
                                                    <div className={
                                                        e.status == "expired" ? 'color_tag color_red' 
                                                        : e.status == "expire_7days" ? 'color_tag apt_cond' 
                                                        : e.status == "expire_14days" ? 'color_tag blue' 
                                                        : e.status == "expire_30days" ? 'color_tag inapt_temp' 
                                                        : e.status == "planned" ? 'color_tag green_color' 
                                                        : 'color_tag unknown'}
                                                    />
                                                )}
                                                <div style={{textAlign: 'center'}}>                                                    
                                                    <FaUser size={64} className="avatar_container"/>
                                                    <h6>{`${e.nume} ${e.prenume}`}</h6>
                                                    <label>
                                                        {e.locMunca}
                                                        {/* {e.isTrueEmployee == false && (
                                                            <CgDanger style={{marginLeft: '5px'}}/>
                                                        )} */}
                                                    </label>
                                                    <br/>                                          
                                                </div>
                                            </LayoutCard>
                                        </Link>
                                </div>
                            )}                                        
                        </div>
                        {employees.length == 0 && (
                            <><br/><p style={{textAlign: 'center'}}>Nu exista lucratori.</p></>
                        )}
                        <ul className="pagination">
                            <li className="page-item">
                                <button 
                                    disabled={currentPage <= 0} 
                                    className="page-link btn no-outline" 
                                    onClick={()=>{setCurrentPage(currentPage-1)}}
                                    style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                >
                                    Inapoi
                                </button>
                            </li>
                            <li className="page-item">
                                <button 
                                    className="page-link btn no-outline"
                                    style={{cursor: "auto", borderRadius: 0}}
                                >
                                    {currentPage+1}
                                </button>
                            </li>
                            <li className="page-item">
                                <button 
                                    disabled={employees.length < pageSize} 
                                    className="page-link btn no-outline" 
                                    onClick={()=>{setCurrentPage(currentPage+1)}}
                                    style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                >
                                    Inainte
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">                        
                        {statistics === undefined && (<LoadingCircleComponent/>)}
                        {statistics !== undefined && (
                            <>
                                <div className="row noselect">
                                    <div className="col-md-6">
                                        <div
                                            data-aos="flip-up"
                                            data-aos-delay="50"
                                        >
                                            <LayoutCard
                                                className={expirationFilter === 'active' ? "layout_card_25bottom shadow-sm layout_card_active" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('active');}}
                                            >            
                                                <label>Lucratori activi</label>
                                                <br/>
                                                <h4 style={{marginBottom: '0', display: 'inline'}}>
                                                    {statistics.activeEmployeesCount}
                                                </h4>     
                                                <p style={{display: 'inline'}}>/ {statistics.totalEmployeesCount}</p> 
                                            </LayoutCard>
                                        </div>                                
                                    </div>
                                    <div className="col-md-6">
                                        <div
                                            data-aos="flip-up"
                                            data-aos-delay="50"
                                        >                                    
                                            <LayoutCard
                                                className={expirationFilter === 'expired' ? "layout_card_25bottom shadow-sm danger_card" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('expired');}}
                                            >                     
                                                <div className={'color_tag2 color_red'}/>                       
                                                <label>Scadenta depasita</label>
                                                <h4 style={{marginBottom: '0'}}>
                                                    {statistics.expired}
                                                </h4>     
                                            </LayoutCard>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div
                                            data-aos='flip-up'
                                            data-aos-delay="0"
                                        >
                                            <LayoutCard
                                                className={expirationFilter === 'expire_7days' ? "layout_card_25bottom shadow-sm warning_card" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('expire_7days');}}
                                            >       
                                                <div className={'color_tag2 apt_cond'}/>                                          
                                                <label>Scadenta in 7 de zile</label>
                                                <h4 style={{marginBottom: '0'}}>
                                                    {statistics.expire_7days}
                                                </h4>     
                                            </LayoutCard>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div
                                            data-aos="flip-up"
                                            data-aos-delay="50"
                                        >
                                            <LayoutCard
                                                className={expirationFilter === 'expire_14days' ? "layout_card_25bottom shadow-sm card_blue" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('expire_14days');}}
                                            >               
                                                <div className={'color_tag2 blue'}/>                  
                                                <label>14 de zile</label>
                                                <h4 style={{marginBottom: '0'}}>
                                                    {statistics.expire_14days}
                                                </h4>     
                                            </LayoutCard>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div
                                            data-aos='flip-up'
                                            data-aos-delay="50"
                                        >
                                            <LayoutCard
                                                className={expirationFilter === 'expire_30day' ? "layout_card_25bottom shadow-sm card_purple" : "layout_card_25bottom shadow-sm"}
                                                onClick={() => {toggleExpirationFilter('expire_30day');}}
                                            >            
                                                <div className={'color_tag2 inapt_temp'}/>                     
                                                <label>30 de zile</label>
                                                <h4 style={{marginBottom: '0'}}>
                                                    {statistics.expire_30day}
                                                </h4>     
                                            </LayoutCard>
                                        </div>
                                    </div>
                                </div>                                
                                {/* <br/>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <label style={{marginBottom: 0}}>Lucratori apti</label>
                                    <label style={{marginBottom: 0}} className='float-right color_bar'>0</label>                                      
                                </LayoutCard>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <label style={{marginBottom: 0}}>Lucratori apti conditionat</label>
                                    <label style={{marginBottom: 0}} className='float-right color_bar color_orange'>0</label>                                      
                                </LayoutCard>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <label style={{marginBottom: 0}}>Lucratori inapti temporar</label>
                                    <label style={{marginBottom: 0}} className='float-right color_bar color_purple'>0</label>                                      
                                </LayoutCard>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <label style={{marginBottom: 0}}>Lucratori inapti</label>
                                    <label style={{marginBottom: 0}} className='float-right color_bar color_red'>0</label>                                      
                                </LayoutCard>   */}
                            </>
                        )}
                    </div>
                </div>
            )}            
        </>
    );
}

export const HrManagerWorkersDetails = (props) => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    
    const [employeeName, setEmployeeName] = useState('');
    const [employeeDetails, setEmployeeDetails] = useState(undefined);

    useEffect(()=>{
        AOS.init();     
        loadEmployeeDetails(); 
    }, []);

    const loadEmployeeDetails = () =>{
        get_ajax("HrAdmin/Employees/GetEmployeeDetails?employeeId=" + props.match.params.employee_id, (response) => {                        
            setEmployeeName(response.nume + " " + response.prenume);
            setEmployeeDetails(response);
        }); 
    }    

    const [isFisaRiscuriDialogOpen, setIsFisaRiscuriDialogOpen] = useState(false);
    const [isFisaAptitudiniDialogOpen, setIsFisaAptitudiniDialogOpen] = useState(false);
    const [isMedicalControlRequesterDialogOpen, setIsMedicalControlRequesterDialogOpen] = useState(false);

    const [isFisaRiscuriNotificationDialogOpen, setIsFisaRiscuriNotificationDialogOpen] = useState(false);
    const [isMedicalControlNotificationDialogOpen, setIsMedicalControlNotificationDialogOpen] = useState(false);

    const [confirmationNextFunction, setConfirmationNextFunction] = useState(undefined);

    return (
        <>
            <h4><Link className="redirectNavigationLink" to='/hrmanager/workers'>Lucratori</Link> &gt; {employeeName}</h4>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <DateIdentificareContainer
                        canEdit={false}
                        dateIdentificare={employeeDetails}
                        reloadCallback={()=>{
                            loadEmployeeDetails(); 
                            setConfirmationNextFunction('fisa_riscuri_notification');
                            setIsConfirmationDialogOpen(true);
                        }}
                    />
                </div>
                <div className='col-md-6'>
                    <WorkplaceContainer
                        canEdit={false}
                        endpoint={"HrAdmin"}    
                        dateIdentificare={employeeDetails}
                        reloadCallback={()=>{
                            loadEmployeeDetails(); 
                            setConfirmationNextFunction('fisa_riscuri_notification');
                            setIsConfirmationDialogOpen(true);
                        }}
                    />                    
                </div>
            </div>
            {employeeDetails !== undefined && employeeDetails.unitateId !== '' && employeeDetails.subunitateId !== '' && (
                <div className='row'>
                    <div className='col-md-3'>                
                        <LayoutCard 
                            className="layout_card_25bottom layout_card_padded shadow-sm layout_card2"
                            onClick={()=>{setIsFisaRiscuriDialogOpen(true)}}
                        >
                            <h6 style={{textAlign: 'center', marginBottom: '0'}}>Fisa riscuri</h6>
                        </LayoutCard>                    
                    </div>
                    <div className='col-md-3'></div>    
                    <div className='col-md-3'></div>                
                    <div className='col-md-3'>
                        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm layout_card2"
                            onClick={()=>{setIsFisaAptitudiniDialogOpen(true)}}
                        >
                            <h6 style={{textAlign: 'center', marginBottom: '0'}}>Fisa aptitudini</h6>
                        </LayoutCard>
                    </div>            
                </div>
            )}                 
            <UpdateResultSuccessDialog 
                isOpen={isConfirmationDialogOpen} 
                toggle={() => {
                    setIsConfirmationDialogOpen(false); 
                    if(confirmationNextFunction === 'fisa_riscuri_notification') {
                        setIsFisaRiscuriNotificationDialogOpen(true);
                    }
                    if(confirmationNextFunction === 'medical_control_notification') {
                        setIsMedicalControlNotificationDialogOpen(true);
                    }
                    loadEmployeeDetails();
                }} 
                onConfirm={() => {
                    setIsConfirmationDialogOpen(false);
                    if(confirmationNextFunction === 'fisa_riscuri_notification') {
                        setIsFisaRiscuriNotificationDialogOpen(true);
                    }
                    if(confirmationNextFunction === 'medical_control_notification') {
                        setIsMedicalControlNotificationDialogOpen(true);
                    }
                    loadEmployeeDetails();
                }}
            />
            {employeeDetails !== undefined && (
                <>
                    <FisaRiscuriViewer   
                        canEdit={false}                 
                        endpoint={"HrAdmin"}        
                        isOpen={isFisaRiscuriDialogOpen}
                        toggle={() => {setIsFisaRiscuriDialogOpen(!isFisaRiscuriDialogOpen); }}
                        employeeId={employeeDetails.id}
                        reloadCallback={() => {
                            setIsFisaRiscuriDialogOpen(false); 
                            setConfirmationNextFunction('medical_control_notification');
                            setIsConfirmationDialogOpen(true); 
                        }}
                        toggleReload={() => {setIsFisaRiscuriDialogOpen(false); setTimeout(()=>{setIsFisaRiscuriDialogOpen(true);}, 1000)}}
                    />
                    <FisaAptitudiniViewer
                        canEdit={false}
                        endpoint={"HrAdmin"}
                        isOpen={isFisaAptitudiniDialogOpen}
                        toggle={() => {setIsFisaAptitudiniDialogOpen(!isFisaAptitudiniDialogOpen)}}
                        employeeId={employeeDetails.id}
                    />
                    <MedicalControlRequester
                        isOpen={isMedicalControlRequesterDialogOpen}
                        toggle={() => {setIsMedicalControlRequesterDialogOpen(!isMedicalControlRequesterDialogOpen)}}
                        employeeId={employeeDetails.id}
                    />
                </>
            )}  
            <UpdateRisksFileNotification
                isOpen={isFisaRiscuriNotificationDialogOpen}
                toggle={()=>{
                    setConfirmationNextFunction(undefined);
                    setIsFisaRiscuriNotificationDialogOpen(!isFisaRiscuriNotificationDialogOpen);
                }}
                openRisksFile={()=>{
                    setConfirmationNextFunction(undefined);
                    setIsFisaRiscuriNotificationDialogOpen(!isFisaRiscuriNotificationDialogOpen);
                    setIsFisaRiscuriDialogOpen(true); 
                }}
            />   
            <RequestMedicalControlNotification
                isOpen={isMedicalControlNotificationDialogOpen}
                toggle={()=>{
                    setConfirmationNextFunction(undefined);
                    setIsMedicalControlNotificationDialogOpen(!isMedicalControlNotificationDialogOpen);
                }}
                requestMedicalControl={()=>{
                    setConfirmationNextFunction(undefined);
                    setIsMedicalControlNotificationDialogOpen(!isMedicalControlNotificationDialogOpen);
                    setIsMedicalControlRequesterDialogOpen(true); 
                }}
            />       
        </>
    );
}