import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import { get_ajax, post } from 'shared/dist/utils/DataProvider';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { MdModelTraining } from 'react-icons/md';
import { CreateResourceDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import { FormGroup } from 'shared/dist/components/generic-building-blocks/FormGroup';
import { Redirect } from 'react-router-dom';
import {CdnResourceDownloadBtn, RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import Autocomplete from 'shared/dist/components/generic-building-blocks/AutocompleteTextbox';

export const Trainings = () => {
    function inArray(target, array)
    {    
        for(var i = 0; i < array.length; i++) 
        if(array[i].toLowerCase() === target.toLowerCase())
                return true;   
        return false; 
    }

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(12);

    useEffect(()=>{
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);

    const [compartimenteAutocompleteOptions, setCompartimenteAutocompleteOptions] = useState([]);
    useEffect(()=>{     
        AOS.init();  
        get_ajax("ExpertSSM/Employees/GetCompartimenteAutocompleteList", (response) => {
            setCompartimenteAutocompleteOptions(response);            
        });                  
    }, []);

    const loadData = (searchBy, page) => {
        get_ajax("ExpertSSM/InstruiriManager/GetInstruiri?search=" + searchBy + "&page=" + page, (response) =>{
            setRecords(response);
            setIsLoading(false);
        });
    }

    const [redirectToResource, setRedirectToResource] = useState(undefined);

    const [sectieDepartament, setSectieDepartament] = useState('');
    const [isNewInstructionDialogOpen, setIsNewInstructionDialogOpen] = useState(false);
    const [newInstructionName, setNewInstructionName] = useState('');
    useEffect(()=>{
        setNewInstructionName('');
    }, [isNewInstructionDialogOpen]);
    const [newInstructionType, setNewInstructionType] = useState(0);

    return(
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Instruiri</h4>
                        <br/>
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </LayoutCard>
                    {isLoading && (<LoadingCircleComponent/>)}
                    {!isLoading && (
                        <>
                            <div className='row'>
                                {records.map((rec, i)=> 
                                    <div className='col-md-4' key={i}>
                                        <div className='maxHeight' style={{paddingBottom: '25px'}}>
                                        <LayoutCard 
                                            className="layout_card1 shadow-sm no_padding maxHeight top_bottom"
                                            onClick={()=>{
                                                setRedirectToResource("/expertssm/training_sets/details/" + rec.id);
                                            }}
                                        >
                                            <MdModelTraining
                                                size={52} 
                                                style={{margin: 'auto', display: 'block', marginTop: '40px'}}
                                            />
                                            <p 
                                                style={{
                                                    textAlign: 'center',
                                                    marginBottom: '0',
                                                    whiteSpace: 'normal'
                                                }}
                                                className='padded_bottom'
                                            >
                                                {rec.originalName}
                                                {rec.isDefault && (<> ✅ </>)}
                                                {rec.type != 0 && (
                                                    <><br/>{rec.compartimentName}</>
                                                )}
                                            </p>
                                        </LayoutCard>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {records.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista resurse.</p><br/></>
                            )}
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={records.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                <div className='col-md-4'>
                    <RoundButton
                        className={"custom-btn-primary"}
                        text={'Instruire noua'}
                        onClickHandler={()=>{setIsNewInstructionDialogOpen(true)}}
                    />
                </div>
            </div>
            <CreateResourceDialog
                isOpen={isNewInstructionDialogOpen}
                toggle={()=>{setIsNewInstructionDialogOpen(!isNewInstructionDialogOpen)}}
                saveBtnOverwride={"Adauga"}
                title={"Instructiune noua"}
                onConfirm={()=>{
                    if(newInstructionType != 0)
                        if(inArray(sectieDepartament, compartimenteAutocompleteOptions) == false){                                    
                            alert("Alegeti un compartiment din lista");                                                                        
                            return;
                        }
                    post('ExpertSSM/InstruiriManager/CreateNewInstruction', {
                        'instructionName': newInstructionName,
                        'type': newInstructionType,
                        'compartiment': sectieDepartament
                    }, (response)=>{
                        setRedirectToResource("/expertssm/training_sets/details/" + response);
                    });
                }}
            >
                <FormGroup
                    label={"Nume instructiune"}
                    value={newInstructionName}
                    onChange={(e) => {setNewInstructionName(e.target.value)}}
                />
                <div className='form-group'>
                    <label>Tip:</label>
                    <select 
                        value={newInstructionType} 
                        className='form-control'
                        onChange={(e) => {setNewInstructionType(e.target.value)}}
                    >
                        <option value={0}>Instruire introductiv generala</option>
                        <option value={1}>Instruire la locul de munca</option>
                        <option value={2}>Instruire periodica</option>
                        <option value={3}>Instruire periodica suplimentara</option>
                    </select>
                </div>
                {newInstructionType != 0 && (
                    <div className='form-group'>
                        <label>Compartiment:</label>
                        <Autocomplete
                            placeholder={'Sectie / departament'}
                            suggestions={compartimenteAutocompleteOptions}
                            value={sectieDepartament}      
                            onChange={(e) =>{ setSectieDepartament(e); }}       
                        />
                    </div>
                )}
            </CreateResourceDialog>
            {redirectToResource !== undefined && (
                <Redirect to={redirectToResource}/>
            )}
        </>
    );
}