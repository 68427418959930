import React from 'react';
import { NullOrUndefined } from 'shared/dist/utils/Utils';
import { Checked, UnChecked } from './MedicalControlRequestRender';

const format_date = (date) => {
    try
    {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; 
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '-' + mm + '-' + yyyy;
    }
    catch(e) {console.log(e);}
}

export const MedicalAptitudiniFileViewer = ({data, signatureDetails, doctorSignature}) => {
    console.log(data);
    return(
        <>
            {signatureDetails !== undefined && (
                <div className='row'>
                    <div className='col-md-6'>
                        <label>Unitatea medicala: <b>{signatureDetails.numeUnitateMedicala}</b></label>
                        <label>Cabinet de medicina muncii: <b>{signatureDetails.numeUnitateMedicala}</b></label>                        
                    </div>
                    <div className='col-md-6'>
                        <label>
                            Adresa: <b>{signatureDetails.adresaUnitateMedicala}</b><br/>
                            Tel: <b>{signatureDetails.telefonUnitateMedicala}</b><br/>
                        </label>
                    </div>
                </div>                
            )}  
            <hr/>              
            <label>
                Angajare {data.solicitare_angajare ? <Checked/> : <UnChecked/>} &nbsp;
                Examen medical periodic {data.solicitare_control_medical_periodic ? <Checked/> : <UnChecked/>} &nbsp;
                Adaptare {data.solicitare_adaptare ? <Checked/> : <UnChecked/>} &nbsp;
                Reluarea muncii {data.solicitare_reluarea_muncii ? <Checked/> : <UnChecked/>} &nbsp;
                Supraveghere speciala {data.solicitare_supraveghere_speciala ? <Checked/> : <UnChecked/>} &nbsp;
                Schimbarea locului de munca {data.solicitare_schimbareaLoculuiDeMunca ? <Checked/> : <UnChecked/>} &nbsp;
                Alte {NullOrUndefined(data.solicitare_altele) ? <UnChecked/> : <Checked/>}
                {!NullOrUndefined(data.solicitare_altele) && (
                    <><b> {data.solicitare_altele}</b></>
                )}
            </label><br/>
            <br/>
            <h5 style={{ textAlign: 'center' }}>
                MEDICINA MUNCII - FISA DE APTITUDINE <br/> Nr. <b>{data.NR_FISA_APTITUDINI}</b>/<b>{data.dataInregistrareFisaAptitudini || data.DATA}</b><br/>                
            </h5>            
            <small style={{ textAlign: 'center', display: 'block', width: '100%' }}>(Un exemplar se trimite la angajator, unul se inmaneaza angajatului)</small>
            <br />
            <br/>
            <label>
                Societate: <b>{data.societate.Nume}</b> <br/>
                Adresa: <b>{data.societate.Adresa}</b> Tel.: <b>{data.societate.Telefon}</b> Fax: <b>{data.societate.Fax}</b>
            </label><br/>
            <label>
            Numele: <b>{data.angajat.Nume}</b>, prenumele: <b>{data.angajat.Prenume}</b><br/>            
            CNP: <b>{data.angajat.Cnp}</b><br/><br/>
            Ocupatia/Functia <b>{data.angajat.functie}</b><br/>
            Post si locul de munca <b>{data.angajat.loc_munca}</b><br/>
            </label><br/>            
            <br/>
            <div className='row'>
                <div className='col-md-5'>
                    <p style={{textAlign: 'center'}}>AVIZ MEDICAL:</p>
                    <br/>
                    <p style={{marginBottom: 0}}>Apt <div className='float-right'>{data.apt ? <Checked /> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Apt conditionat <div className='float-right'>{data.aptConditionat ? <Checked/> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Inapt temporar <div className='float-right'>{data.inaptTemporar ? <Checked/> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Inapt <div className='float-right'>{data.inapt ? <Checked/> : <UnChecked/>}</div></p>
                </div>
                <div className='col-md-7'>
                    <p style={{textAlign: 'center'}}>Recomandari:</p>
                    <br/>
                    <p style={{textAlign: 'center'}}>{data.recomandari}</p>
                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <label>
                        Data<br/>
                        <b>{data.DATA}</b>
                    </label>
                </div>
                <div className='col-md-6'>
                    <div style={{textAlign: 'center'}}>
                        <label>
                        Medic de medicina muncii<br/>
                        <b>{data.clinicData.name}</b><br/>
                        (semnatura si parafa)
                        </label><br/>
                        <img src={doctorSignature} style={{maxWidth: '100%'}}/>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <label>Data urmatorului examen medical: <b>{data.nextExam}</b></label>
        </>
    );
}

export const MedicalAptitudiniFileViewerManual = ({data, signatureDetails}) => {
    return(
        <>                    
            <h5 style={{ textAlign: 'center' }}>
                MEDICINA MUNCII - FISA DE APTITUDINE <br/> Nr. <b>{data.NR_FISA_APTITUDINI}</b>/<b>{data.DATA}</b><br/>                
            </h5>            
            <small style={{ textAlign: 'center', display: 'block', width: '100%' }}>(Un exemplar se trimite la angajator, unul se inmaneaza angajatului)</small>
            <br />
            <br/>
            <label>
                Societate: <b>{data.societate.Nume}</b> <br/>
                Adresa: <b>{data.societate.Adresa}</b> Tel.: <b>{data.societate.Telefon}</b> Fax: <b>{data.societate.Fax}</b>
            </label><br/>
            <label>
            Numele: <b>{data.angajat.Nume}</b>, prenumele: <b>{data.angajat.Prenume}</b><br/>            
            CNP: <b>{data.angajat.Cnp}</b><br/><br/>
            Ocupatia/Functia <b>{data.angajat.functie}</b><br/>
            Post si locul de munca <b>{data.angajat.loc_munca}</b><br/>
            </label><br/>            
            <br/>
            <div className='row'>
                <div className='col-md-5'>
                    <p style={{textAlign: 'center'}}>AVIZ MEDICAL:</p>
                    <br/>
                    <p style={{marginBottom: 0}}>Apt <div className='float-right'>{data.apt ? <Checked /> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Apt conditionat <div className='float-right'>{data.aptConditionat ? <Checked/> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Inapt temporar <div className='float-right'>{data.inaptTemporar ? <Checked/> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Inapt <div className='float-right'>{data.inapt ? <Checked/> : <UnChecked/>}</div></p>
                </div>
                <div className='col-md-7'>
                    <p style={{textAlign: 'center'}}>Recomandari:</p>
                    <br/>
                    <p style={{textAlign: 'center'}}>{data.recomandari}</p>
                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <label>
                        Data<br/>
                        <b>{data.DATA}</b>
                    </label>
                </div>
                <div className='col-md-6'>
                    <div style={{textAlign: 'center'}}>
                        <label>
                        Medic de medicina muncii<br/>
                        <b>{data.clinicData.name}</b><br/>                        
                        </label>
                    </div>
                </div>
            </div>
            <br/>
            <label>Introdus in aplicatie de catre: <b>{data.createdBy_d_Name}</b></label>
            <br/>
            <label>Data urmatorului examen medical: <b>{data.nextExam}</b></label>
        </>
    );
}

export const MedicalAptitudiniFilePreview = ({medicalRequest, data, signatureDetails, doctorSignature, societate, angajat, clinicData, numarFisaAptitudini}) => {
    console.log(data);
    return(
        <>
            {signatureDetails !== undefined && (
                <div className='row'>
                    <div className='col-md-6'>
                        <label>Unitatea medicala: <b>{signatureDetails.numeUnitateMedicala}</b></label>
                        <label>Cabinet de medicina muncii: <b>{signatureDetails.numeUnitateMedicala}</b></label>                        
                    </div>
                    <div className='col-md-6'>
                        <label>
                            Adresa: <b>{signatureDetails.adresaUnitateMedicala}</b><br/>
                            Tel: <b>{signatureDetails.telefonUnitateMedicala}</b><br/>
                        </label>
                    </div>
                </div>                
            )}  
            <hr/>              
            <label>
                Angajare {medicalRequest.solicitare_angajare ? <Checked/> : <UnChecked/>} &nbsp;
                Examen medical periodic {medicalRequest.solicitare_control_medical_periodic ? <Checked/> : <UnChecked/>} &nbsp;
                Adaptare {medicalRequest.solicitare_adaptare ? <Checked/> : <UnChecked/>} &nbsp;
                Reluarea muncii {medicalRequest.solicitare_reluarea_muncii ? <Checked/> : <UnChecked/>} &nbsp;
                Supraveghere speciala {medicalRequest.solicitare_supraveghere_speciala ? <Checked/> : <UnChecked/>} &nbsp;
                Schimbarea locului de munca {medicalRequest.solicitare_schimbareaLoculuiDeMunca ? <Checked/> : <UnChecked/>} &nbsp;
                Alte {NullOrUndefined(medicalRequest.solicitare_altele) ? <UnChecked/> : <Checked/>}
                {!NullOrUndefined(medicalRequest.solicitare_altele) && (
                    <><b> {medicalRequest.solicitare_altele}</b></>
                )}
            </label><br/>
            <br/>
            <h5 style={{ textAlign: 'center' }}>
                MEDICINA MUNCII - FISA DE APTITUDINE <br/> Nr. <b>{data.numarFisaAptitudini}</b>/<b>{format_date(new Date(data.dataInregistrareFisaAptitudini))}</b><br/>                
            </h5>            
            <small style={{ textAlign: 'center', display: 'block', width: '100%' }}>(Un exemplar se trimite la angajator, unul se inmaneaza angajatului)</small>
            <br />
            <br/>
            <label>
                Societate: <b>{societate.Nume}</b> <br/>
                Adresa: <b>{societate.Adresa}</b> Tel.: <b>{societate.Telefon}</b> Fax: <b>{societate.Fax}</b>
            </label><br/>
            <label>
            Numele: <b>{angajat.Nume}</b>, prenumele: <b>{angajat.Prenume}</b><br/>            
            CNP: <b>{angajat.Cnp}</b><br/><br/>
            Ocupatia/Functia <b>{angajat.functie}</b><br/>
            Post si locul de munca <b>{angajat.loc_munca}</b><br/>
            </label><br/>            
            <br/>
            <div className='row'>
                <div className='col-md-5'>
                    <p style={{textAlign: 'center'}}>AVIZ MEDICAL:</p>
                    <br/>
                    <p style={{marginBottom: 0}}>Apt <div className='float-right'>{data.apt ? <Checked /> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Apt conditionat <div className='float-right'>{data.aptConditionat ? <Checked/> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Inapt temporar <div className='float-right'>{data.inaptTemporar ? <Checked/> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Inapt <div className='float-right'>{data.inapt ? <Checked/> : <UnChecked/>}</div></p>
                </div>
                <div className='col-md-7'>
                    <p style={{textAlign: 'center'}}>Recomandari:</p>
                    <br/>
                    <p style={{textAlign: 'center'}}>{data.recomandari}</p>
                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <label>
                        Data<br/>
                        <b>{format_date(new Date())}</b>
                    </label>
                </div>
                <div className='col-md-6'>
                    <div style={{textAlign: 'center'}}>
                        <label>
                        Medic de medicina muncii<br/>
                        <b>{clinicData.name}</b><br/>
                        (semnatura si parafa)
                        </label><br/>
                        <img src={doctorSignature} style={{maxWidth: '100%'}}/>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <label>Data urmatorului examen medical: <b>{format_date(new Date(data.dataExamenUrmator))}</b></label>
        </>
    );
}