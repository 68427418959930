import React, { useEffect, useState } from 'react';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {get, get_ajax, post, parseTrueOrErrorMessage} from 'shared/dist/utils/DataProvider';
import {CustomResourceModal} from 'shared/dist/components/generic-building-blocks/UserDialogs';
import {RoundButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { UncontrolledTooltip } from 'reactstrap';
import {Checked, UnChecked} from './shared/MedicalControlRequestRender';
import {FormGroup, FormGroupArea} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {Collapse} from 'reactstrap';
import {SiMicrosoftexcel} from 'react-icons/si';
import { getAccessToken } from 'shared/dist/components/api-authorization/AuthService';
import {BsPrinter} from 'react-icons/bs';
import { printPage } from 'shared/dist/utils/PdfBuilder';

export const renderTipControl = (requestsJSON) => {
    requestsJSON = JSON.parse(requestsJSON);
    if(requestsJSON.solicitare_angajare){
        return ("Angajare");
    }
    if(requestsJSON.solicitare_control_medical_periodic){
        return ("Periodic");
    }
    if(requestsJSON.solicitare_adaptare){
        return ("Adaptare");
    }
    if(requestsJSON.solicitare_reluarea_muncii){
        return ("Reluarea muncii");
    }
    if(requestsJSON.solicitare_supraveghere_speciala){
        return("Supraveghere speciala");
    }
    if(requestsJSON.solicitare_la_cerere){
        return("La cerere");
    }
    if(requestsJSON.solicitare_schimbareaLoculuiDeMunca){
        return("Schimbarea locului de munca");
    }
    if(requestsJSON.solicitare_altele){
        return("Altele");
    }
    return "-";
}

export const Reports = () => {
    const [investigationsToValidate, setInvestigationsToValidate] = useState(undefined);
    useEffect(()=>{
        load_pending_investigations();
        get_ajax("EmployeeManagement/Employees/GetAngajatori", (response) => { setUnitati(response); });
        get_ajax("EmployeeManagement/Employees/GetSubunitati", (response) => { setSubunitati(response); });
    }, []);
    const load_pending_investigations = () => {get_ajax("EmployeeManagement/Report/GetMedicalControlsPendingValidationCount", (response) =>{ setInvestigationsToValidate(response); });}
    const [isInvestigatiiValidationDialogOpne, setIsInvestigationValidationDialogOpen] = useState(false);
    const [filtersDialogIsOpen, setFiltersDialogIsOpen] = useState(false);

    const [unitati, setUnitati] = useState([]);
    const [selectedUnitate, setSelectedUnitate] = useState('');
    const [subunitati, setSubunitati] = useState([]);
    const [selectedSubunitate, setSelectedSubunitate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [medicalRecords, setMedicalRecords] = useState([]);
    const pageSize = 12;

    const [reportDownloadingInProgress, setReportDownloadingInProgress] = useState(false);
    const [readyForDownloadCount, setReadyForDownloadCount] = useState(undefined);

    useEffect(()=>{
        if(currentPage != 0) setCurrentPage(0);
        else {load_page();}
    }, [selectedUnitate, selectedSubunitate, startDate, endDate]);

    useEffect(()=>{
        load_page();
    }, [currentPage]);

    const load_page = () => {
        get_ajax("EmployeeManagement/Report/GetMedicalControlsReport?page=" + currentPage + 
        "&unitateId=" + selectedUnitate + 
        "&subunitateId=" + selectedSubunitate +
        "&startDate=" + startDate + "&endDate=" + endDate, 
        (response) => { 
            setReadyForDownloadCount(response.total < 10000 ? response.total : 10000);
            setMedicalRecords(response.rows); 
            setIsLoading(false);
        });
    }

    return(
        <>
            <br/>
            <div className="row">
                <div className="col-md-8">
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div className='row'>
                            <div className='col-md-10'>
                                <h4>Rapoarte</h4>
                            </div>
                            <div className='col-md-2'>
                                <RoundButton
                                    className="custom-btn-primary"
                                    text="Filtre"
                                    onClickHandler={()=>{ setFiltersDialogIsOpen(!filtersDialogIsOpen); }}
                                />
                            </div>
                        </div>
                        <Collapse isOpen={filtersDialogIsOpen}>
                            <hr/>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup
                                        label={'Data inceput'}
                                        type='date'
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup
                                        label={'Data sfarsit'}
                                        type='date'
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label>Unitate</label>
                                        <select 
                                            className='form-control no-outline'
                                            value={selectedUnitate}
                                            onChange={(e) => setSelectedUnitate(e.target.value)}
                                        >
                                            <option value={''}>Alege unitate</option>
                                            {unitati.map((cli, i) => 
                                                <option value={cli.id} key={i}>{cli.nume}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label>Subunitate</label>
                                        <select 
                                            className='form-control no-outline'
                                            value={selectedSubunitate}
                                            onChange={(e) => setSelectedSubunitate(e.target.value)}
                                        >
                                            <option value={''}>Alege client</option>
                                            {subunitati.map((cli, i) => 
                                                <option value={cli.id} key={i}>{cli.nume}</option>
                                            )}
                                        </select>
                                    </div>
                                </div> 
                            </div>
                        </Collapse>
                        <br/>
                        {isLoading && (<LoadingCircleComponent/>)}
                        {isLoading === false && (
                            <>
                            {medicalRecords.length === 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista controale medicale.</p></>
                            )}
                            {medicalRecords.length !== 0 && (
                                <table className="table tableLastRight">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Data</th>
                                            <th scope="col">Client</th>
                                            <th scope="col">Nume si prenume</th>                                                                    
                                            <th scope="col">Tip control</th>                                                                    
                                            <th scope="col">Total</th>
                                            <th scope='col'></th>
                                            {/* <th scope='col'></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>    
                                        {medicalRecords.map((rec, i) =>                         
                                        <tr key={i}>
                                            <th scope="row">{i+1}</th>
                                            <td>{rec.createdAtString}</td>
                                            <td>{rec.angajatorName}</td>                                            
                                            <td>{rec.numePrenume}</td>
                                            <td>{renderTipControl(rec.requestJSON)}</td>
                                            <td>{rec.total}</td>
                                            {rec.fisaAptitudiniId === "none" && (<td></td>)}
                                            {rec.fisaAptitudiniId !== "none" && (
                                                <td onClick={()=>{
                                                    printPage(`/Utils/PdfGenerator/FisaAptitudini?aptitudiniFileId=${rec.fisaAptitudiniId}`);
                                                }}>
                                                    <BsPrinter/>
                                                </td>
                                            )}
                                        </tr>
                                        )}
                                    </tbody>
                                </table>   
                            )}
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={medicalRecords.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul> 
                            </>
                        )}
                    </LayoutCard>
                </div>
                <div className="col-md-4">
                    {investigationsToValidate === undefined && (
                        <LoadingCircleComponent/>
                    )}
                    {investigationsToValidate !== undefined && (
                        <LayoutCard
                            className="layout_card2 noselect"     
                            onClick = {()=>{ setIsInvestigationValidationDialogOpen(true) }}               
                        >                            
                            <label>Validare investigatii</label>
                            <br/>
                            <h4 style={{marginBottom: '0', display: 'inline'}}>
                                {investigationsToValidate}
                            </h4>                                               
                        </LayoutCard>
                    )}
                    <div className='row'>
                        <div className='col-md-8'>
                            {readyForDownloadCount === undefined && (<LoadingCircleComponent/>)}
                            {readyForDownloadCount !== undefined && (
                                <LayoutCard
                                    className={reportDownloadingInProgress ? "layout_card3 noselect disabled_grayscale" : "layout_card3 noselect"}
                                    onClick={()=>{
                                        if(reportDownloadingInProgress) return;
                                        setReportDownloadingInProgress(true);
                                        const url = "EmployeeManagement/Report/GenerateReport?page=" + currentPage + "&unitateId=" + selectedUnitate + "&subunitateId=" + selectedSubunitate + "&startDate=" + startDate + "&endDate=" + endDate;
                                        const token = getAccessToken();
                                        let headers = new Headers();
                                        headers.append('Authorization', token);
                                        fetch(url, {headers})
                                            .then(response => response.blob())
                                            .then(blobby => {
                                                const anchor = document.createElement("a");
                                                let objectUrl = window.URL.createObjectURL(blobby);
                                                anchor.href = objectUrl;
                                                anchor.download = 'Raport.xlsx';
                                                anchor.click();
                                                window.URL.revokeObjectURL(objectUrl);
                                                setReportDownloadingInProgress(false);
                                            });
                                    }}
                                >
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <label>Descarca raport</label>
                                            <br/>
                                            <h4 style={{marginBottom: '0', display: 'inline'}}>
                                                {readyForDownloadCount}
                                            </h4> <small>investigatii</small>
                                        </div>
                                        <div className='col-md-4'>
                                            <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                                <SiMicrosoftexcel size={28} style={{display: 'block', margin: 'auto'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </LayoutCard>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <InvestigatiiValidationDialog
                isOpen={isInvestigatiiValidationDialogOpne}
                toggle={() => {setIsInvestigationValidationDialogOpen(false); load_pending_investigations(); }}
            />
        </>
    );
}

const InvestigatiiValidationDialog = ({ isOpen, toggle }) => {
    const [investigationDefinitions, setInvestigationDefinitions] = useState([]);
    useEffect(()=>{
        if(isOpen === false) return;
        get_ajax("EmployeeManagement/Report/GetInvestigationsDefinitions", (response) =>{ 
            for(var i in response)
            {
                for(var j in response[i].records)
                {
                    response[i].records[j].controls = JSON.parse(response[i].records[j].controls)
                }
            }
            setInvestigationDefinitions(response);
        });
        setOpenedRecord(undefined);
        setSelectedRecords([]);
    }, [isOpen]);

    const calculate_total = (controls) => {
        var totalRON = 0;
        var totalEUR = 0;
        for(var i in controls){
            var c = controls[i];
            if(c.currency == 0)
                totalRON += c.price;
            else
                totalEUR += c.price;
        }
        var ronStr = `${totalRON} RON`;
        var eurStr = `${totalEUR} EUR`; 
        return `${totalRON != 0 ? ronStr + " " : ""}${totalEUR != 0 ? eurStr : ""}`;
    }

    const [openedRecord, setOpenedRecord] = useState(undefined);
    useEffect(()=>{
        if(openedRecord == undefined) setRequestedChanges('');
    }, [openedRecord]);
    const [definitionsSet, setDefinitionsSet] = useState([]);
    const [requestedChanges, setRequestedChanges] = useState('');

    const [selectedRecords, setSelectedRecords] = useState([]);
    const [groupsSelected, setGroupsSelected] = useState([]);

    return (
        <CustomResourceModal
            size={'xl'}
            isOpen={isOpen}
            toggle={toggle}
            title={"Validare investigatii"}
        >
            {openedRecord === undefined && (
                <>
                    <div className='row'>
                        <div className='col-md-8'></div>
                        <div className='col-md-4'>
                            <RoundButton 
                                className="custom-btn-primary"
                                text={`Valideaza selectia (${selectedRecords.length})`}
                                onClickHandler={()=>{ 
                                    post('EmployeeManagement/Report/ValidateRecords', 
                                    {
                                        'recordsJSON': JSON.stringify(selectedRecords)
                                    }, (response) => {
                                        parseTrueOrErrorMessage(response, (response) => {
                                            toggle();
                                        });
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <br/>
                    {investigationDefinitions.filter(a => a.records.length != 0).map((def, i) =>
                        <div key={i}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h5 style={{marginBottom: '0'}}>{def.angajatorName}</h5>
                                    <label>{def.subunitateName}</label>
                                </div>
                                <div className='col-md-6'>
                                    <h5 style={{marginBottom: '0'}}>{def.clinicName}</h5>
                                    <label>{def.doctorName}</label>
                                </div>
                            </div>
                            <br/>
                            {def.investigations.map((inves, j) => 
                                <UncontrolledTooltip key={j} placement="bottom" target={`tooltip_investigation_target_${i}_${j}`}>
                                    {inves.nume}
                                </UncontrolledTooltip>   
                            )}
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input 
                                                type={'checkbox'}
                                                checked={groupsSelected.includes(i)}
                                                onChange={(e)=>{
                                                    if(e.target.checked)
                                                    {
                                                        const g = [...groupsSelected];
                                                        g.push(i);
                                                        setGroupsSelected(g);

                                                        const _rec = [...selectedRecords];
                                                        for(var qqq in def.records){
                                                            var r = def.records[qqq];
                                                            _rec.push(r.id);
                                                        }
                                                        setSelectedRecords(_rec);
                                                    }
                                                    else
                                                    {
                                                        const g = [...groupsSelected];
                                                        g.splice(g.indexOf(i), 1);
                                                        setGroupsSelected(g);

                                                        const _rec = [...selectedRecords];
                                                        for(var qqq in def.records){
                                                            var r = def.records[qqq];
                                                            _rec.splice(_rec.indexOf(r.id), 1);
                                                        }
                                                        setSelectedRecords(_rec);
                                                    }
                                                }}
                                            />
                                        </th>
                                        <th scope="col">Data</th>
                                        <th scope="col">Nume si prenume</th>
                                        <th scope="col">Compartiment</th>
                                        {def.investigations.map((inves, j) => 
                                            <th style={{cursor: 'pointer'}} key={j} scope="col" id={`tooltip_investigation_target_${i}_${j}`}>{inves.cod}</th>
                                        )}
                                        <th scope="col">Total</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {def.records.map((rec, j) => 
                                        <tr key={j}>
                                            <th>
                                                <input 
                                                    type={'checkbox'}
                                                    checked={selectedRecords.includes(rec.id)}
                                                    onChange={(e) => {
                                                        if(e.target.checked)
                                                        {
                                                            const _rec = [...selectedRecords];
                                                            _rec.push(rec.id);
                                                            setSelectedRecords(_rec);
                                                        }
                                                        else
                                                        {
                                                            const _rec = [...selectedRecords];
                                                            _rec.splice(_rec.indexOf(rec.id), 1);
                                                            setSelectedRecords(_rec);
                                                        }
                                                    }}
                                                />
                                            </th>
                                            <td>{rec.created_at}</td>
                                            <td>{rec.nume_prenume}</td>
                                            <td>{rec.compartiment}</td>
                                            {def.investigations.map((inves, j) => 
                                                <td key={j} id={`tooltip_investigation_target_${i}_${j}`}>
                                                    {rec.controls.filter(x => x.id == inves.id).length !== 0 ? <Checked/> : <UnChecked/>}
                                                </td>
                                            )}
                                            <td scope="col">
                                                {calculate_total(rec.controls)}
                                            </td>
                                            <td scope="col">
                                                <button 
                                                    className='btn btn-secondary no-outline'
                                                    onClick={()=>{ setDefinitionsSet(def.investigations); setOpenedRecord(rec); }}
                                                >...</button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <hr/>
                            <br/>
                        </div>
                    )}  
                </>
            )}
            {openedRecord !== undefined && (
                <>
                    <div className='row'>
                        <div className='col-md-3'>
                            <RoundButton 
                                className="custom-btn-primary"
                                text="Inapoi"
                                onClickHandler={()=>{ setOpenedRecord(undefined) }}
                            />
                        </div>
                    </div>
                    <br/>
                    {definitionsSet.map((inves, j) => 
                        <UncontrolledTooltip key={j} placement="bottom" target={`tooltip_investigation_target_zzz_${j}`}>
                            {inves.nume}
                        </UncontrolledTooltip>   
                    )}
                    <div className='row'>
                        <div className='col-md-4'>
                            <FormGroup
                                label={'Efectuat la data de'}
                                value={openedRecord.created_at}
                                disabled={true}
                            />
                            <FormGroup
                                label={'Nume prenume'}
                                value={openedRecord.nume_prenume}
                                disabled={true}
                            />
                            <FormGroup
                                label={'Compartiment'}
                                value={openedRecord.compartiment}
                                disabled={true}
                            />
                        </div>
                        <div className='col-md-8'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        {definitionsSet.map((inves, j) => 
                                            <th style={{cursor: 'pointer'}} key={j} scope="col" id={`tooltip_investigation_target_zzz_${j}`}>{inves.cod}</th>
                                        )}
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {definitionsSet.map((inves, j) =>
                                    <td key={j} id={`tooltip_investigation_target_zzz_${j}`}>
                                        {openedRecord.controls.filter(x => x.id == inves.id).length !== 0 ? <Checked/> : <UnChecked/>}
                                    </td>
                                    )}
                                    <td scope="col">
                                        {calculate_total(openedRecord.controls)}
                                    </td>
                                </tbody>
                            </table>
                            <FormGroupArea
                                label={'Observatii'}
                                value={requestedChanges}
                                onChange={(e) => setRequestedChanges(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-8'></div>
                        <div className='col-md-4'>
                            <RoundButton 
                                className="custom-btn-primary"
                                text="Solicita modificare"
                                onClickHandler={()=>{ 
                                    post('EmployeeManagement/Report/RequestChanges', {
                                        'recordId':openedRecord.id,
                                        'requestedChanges': requestedChanges
                                    }, (response)=>{
                                        parseTrueOrErrorMessage(response, (response) => {
                                            toggle();
                                        });
                                    })
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </CustomResourceModal>
    )
}