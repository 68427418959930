"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.FormGroupArea = exports.FormGroup = void 0;
var react_1 = __importStar(require("react"));
var FormGroup = function (_a) {
    var label = _a.label, _b = _a.hide_label, hide_label = _b === void 0 ? false : _b, _c = _a.type, type = _c === void 0 ? 'text' : _c, _d = _a.autocomplete, autocomplete = _d === void 0 ? 'off' : _d, _e = _a.use_validation, use_validation = _e === void 0 ? false : _e, _f = _a.is_valid, is_valid = _f === void 0 ? true : _f, _g = _a.invalid_feedback, invalid_feedback = _g === void 0 ? '' : _g, _h = _a.onChange, onChange = _h === void 0 ? function (e) { } : _h, value = _a.value, _j = _a.disabled, disabled = _j === void 0 ? false : _j, _k = _a.bold, bold = _k === void 0 ? false : _k;
    var _l = (0, react_1.useState)("form-control no-outline rounded_input"), inputClassName = _l[0], setInputClassName = _l[1];
    (0, react_1.useEffect)(function () {
        if (use_validation) {
            if (is_valid) {
                setInputClassName("form-control no-outline rounded_input is-valid");
            }
            else {
                setInputClassName("form-control no-outline rounded_input is-invalid");
            }
        }
    }, [is_valid, use_validation, invalid_feedback]);
    return (react_1["default"].createElement("div", { className: "form-group" },
        !hide_label && (react_1["default"].createElement(react_1["default"].Fragment, null,
            bold && (react_1["default"].createElement("h6", null, label)),
            !bold && (react_1["default"].createElement("label", null, label)))),
        react_1["default"].createElement("input", { disabled: disabled, value: value, 
            // defaultValue={value}
            type: type, placeholder: label, autoComplete: autocomplete, className: inputClassName, onChange: onChange }),
        (use_validation && !is_valid) && (react_1["default"].createElement("div", { className: "invalid-feedback" }, invalid_feedback))));
};
exports.FormGroup = FormGroup;
var FormGroupArea = function (_a) {
    var label = _a.label, _b = _a.hide_label, hide_label = _b === void 0 ? false : _b, _c = _a.rows, rows = _c === void 0 ? 5 : _c, _d = _a.use_validation, use_validation = _d === void 0 ? false : _d, _e = _a.is_valid, is_valid = _e === void 0 ? true : _e, _f = _a.invalid_feedback, invalid_feedback = _f === void 0 ? '' : _f, _g = _a.onChange, onChange = _g === void 0 ? function (e) { } : _g, value = _a.value, _h = _a.disabled, disabled = _h === void 0 ? false : _h, _j = _a.bold, bold = _j === void 0 ? false : _j;
    var _k = (0, react_1.useState)("form-control no-outline rounded_input"), inputClassName = _k[0], setInputClassName = _k[1];
    (0, react_1.useEffect)(function () {
        if (use_validation) {
            if (is_valid) {
                setInputClassName("form-control no-outline rounded_input is-valid");
            }
            else {
                setInputClassName("form-control no-outline rounded_input is-invalid");
            }
        }
    }, [is_valid, use_validation, invalid_feedback]);
    return (react_1["default"].createElement("div", { className: "form-group" },
        !hide_label && (react_1["default"].createElement(react_1["default"].Fragment, null,
            bold && (react_1["default"].createElement("h6", null, label)),
            !bold && (react_1["default"].createElement("label", null, label)))),
        react_1["default"].createElement("textarea", { disabled: disabled, value: value, placeholder: label, className: inputClassName, onChange: onChange, rows: rows, style: { resize: 'none' } }),
        (use_validation && !is_valid) && (react_1["default"].createElement("div", { className: "invalid-feedback" }, invalid_feedback))));
};
exports.FormGroupArea = FormGroupArea;
