import React, { useEffect, useState } from "react";
import { LayoutCard } from "shared/dist/components/generic-building-blocks/LayoutCard";
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import { renderTipControl } from "./Reports";
import {FcCancel} from 'react-icons/fc';
import {IoCheckmark, IoHourglassOutline} from 'react-icons/io5';

export const Requests = () => {
    useEffect(()=>{
        get_ajax('EmployeeManagement/Requests/GetDashboardStats', (response) => {
            setRequests(response);
        });
    }, []);

    const [requests, setRequests] = useState([]);

    return (
        <>
            <br/>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                <div className='row'>
                    <div className='col-md-10'>
                        <h4>Cereri</h4>
                    </div>
                </div>
                <br/>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Data</th>
                            <th scope="col">Angajator</th>
                            <th scope="col">Nume si prenume</th>                                                                    
                            <th scope="col">Tip control</th>                                                                    
                            <th scope="col">Aprobat ?</th>
                            <th scope="col">Finalizat ?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requests.map((rec, i) =>                         
                            <tr key={i}>
                                <th scope="row">{i+1}</th>
                                <td>{rec.createdAtString}</td>
                                <td>{rec.nume_unitate}</td>
                                <td>{rec.numePrenume}</td>
                                <td>{renderTipControl(rec.requestJSON)}</td>
                                <td>{rec.validatedBy === "" ? <FcCancel/> : <IoCheckmark/> }</td>
                                <td>{rec.completed !== true ? <IoHourglassOutline/> : <IoCheckmark/> }</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </LayoutCard>
        </>
    );
}