import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Home } from './pages/Home';

import './App.scss';
import { getAccessToken, GetRoles } from 'shared/dist/components/api-authorization/AuthService';
import {ClientPersonalizedLayout} from 'shared/dist/components/ClientPersonalizedLayout';
import { 
  Employees as MedicalControl_Employees, 
  EmployeesNew as MedicalControl_EmployeesNew,  
} from './pages/medical-control/Workers';
import {
  EmployeeDetails as MedicalControl_EmployeesDetails
} from './pages/medical-control/WorkersDetails';
import { Reports as MedicalControl_Reports} from './pages/medical-control/Reports';
import {PaddingBox} from 'shared/dist/components/generic-building-blocks/PaddingBox';
import { BsPeopleFill, BsTable } from "react-icons/bs";
import {GiSettingsKnobs} from 'react-icons/gi';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { CenterScreenContainer } from 'shared/dist/components/generic-building-blocks/CenterScreenContainer';
import { ConfiguratorMachetaDetails, ConfiguratorMachete, ConfiguratorMacheteNew } from './pages/medical-control/ConfiguratorMachete';
import {FaFileMedicalAlt, FaLayerGroup} from 'react-icons/fa';
import { ConfiguratorInvestigatii } from './pages/medic-interface/ConfiguratorInvestigatii';
import {FaCommentMedical} from 'react-icons/fa';
import { MedicalExamRequestsContainer, MedicalExamRequestsDetails } from './pages/medic-interface/MedicalExamRequests';
import {MedicalFileWorkerDetails, MedicalFolderContainer} from './pages/medic-interface/MedicalFolder';
import { MedicReports } from './pages/medic-interface/MedicReports';
import { BsListCheck } from 'react-icons/bs';
import { HrManagerRequests } from './pages/hrmanager/HrManagerRequests';
import { RiArchiveFill } from 'react-icons/ri';
import { MediaLibrary } from './pages/expertssm/MediaLibrary';
import {MdIntegrationInstructions, MdModelTraining} from 'react-icons/md';
import { Instructiuni } from './pages/expertssm/Instructions';
import { InstructionDetails } from './pages/expertssm/InstructionDetails';
import { Trainings } from './pages/expertssm/Trainings';
import { TrainingDetails } from './pages/expertssm/TrainingDetails';
import { InstructionGroupDetails, InstructionGroups } from './pages/expertssm/InstructionGroups';
import { HrManagerWorkers, HrManagerWorkersDetails } from './pages/hrmanager/HrManagerWorkers';
import {IoHourglassOutline} from 'react-icons/io5';
import { Requests } from './pages/medical-control/Requests';

export default class App extends Component {
    static displayName = App.name;    

    constructor(props)
    {
        super(props);        
        this.state = {
          current_role: undefined,     
          isLoadingNewUI: false,     
        };

        getAccessToken();
        let allowed_roles = [];
        GetRoles((roles)=> {                    
          allowed_roles = roles.filter((e)=>{
            return (
              e.name === "Medic SSM" || e.name === "Dosar medical" || e.name === "HrManager" || e.name === "HrManagerReadOnly"
            );
          });                 
          let c_role = {name: window.localStorage.getItem("__current_role__")};
          if(
              window.localStorage.getItem("__current_role__") != null &&
              window.localStorage.getItem("__current_role__") != "null" &&
              window.localStorage.getItem("__current_role__") != ""
          )
          {
            if(c_role === '' || c_role === null || c_role === undefined)
              c_role = allowed_roles[0];
          } 
          else{
            c_role = allowed_roles[0];
          }  
          this.setState({allowed_roles: allowed_roles, current_role: c_role});
        });  
        setTimeout(() => { 
          if(this.state.current_role === undefined || this.state.isLoadingNewUI)
          {
            window.location.reload();
          }
        }, 5 * 1000)
    }

    returnAllowedRoutes = () => {                  
        const current_role = this.state.current_role.name;   
        if(current_role === undefined) return ([]);
        window.localStorage.setItem("__current_role__", current_role);
        if (current_role == 'Dosar medical'){
          return(
            [
              {
                "route": "/medical-control/workers",
                "name": "Lucratori",
                "icon": <BsPeopleFill size={28}/>,                    
              },
              {
                "route": "/medical-control/requests",
                "name": "Cereri in curs",
                "icon": <IoHourglassOutline size={28} fill='white' color='white'/>
              },
              {
                "route": "/medical-control/reports",
                "name": "Raport",
                "icon": <BsTable size={28}/>,
              },
              {
                "route": "/settings/medical-control/configure_machete",
                "name": "Machete",
                "icon": <GiSettingsKnobs size={28}/>,                    
              }
            ]
          );
        }   
        if(current_role == 'Medic SSM') {
          return(
            [
              {
                "route": "/medic/medical_exam_requests",
                "name": "Cereri",
                "icon": <FaCommentMedical size={28}/>
              },
              {
                "route": "/medic/worker_medical_folder",
                "name": "Dosare",
                "icon": <BsPeopleFill size={28}/>,                    
              },
              {
                "route": "/medic/reports",
                "name": "Raport",
                "icon": <BsTable size={28}/>,
              },
              {
                "route": "/settings/medic/configure_investigatii",
                "name": "Investigatii",
                "icon": <FaFileMedicalAlt size={28}/>
              }
            ]
          );
        }  
        if(current_role == 'HrManager') {
          return(
            [
              {
                "route": "/hrmanager/workers",
                "name": "Lucratori",
                "icon": <BsPeopleFill size={28}/>,                    
              },
              {
                "route": "/hrmanager/requests",
                "name": "Cereri",
                "icon": <BsListCheck size={28}/>
              },
            ]
          )
        }  
        if(current_role == "HrManagerReadOnly")
        {
          return(
            [
              {
                "route": "/hrmanager/workers",
                "name": "Lucratori",
                "icon": <BsPeopleFill size={28}/>,                    
              },
            ]
          )
        }
        if(current_role == "SSM Expert") {
          return(
            [
              {
                "route": "/expertssm/training_sets",
                "name": "Instruiri",
                "icon": <MdModelTraining size={28}/>
              },
              {
                "route": "/expertssm/instructions",
                "name": "Materiale",
                "icon": <MdIntegrationInstructions size={28}/>
              },
              {
                "route": "/expertssm/instructions_groups",
                "name": "Grupuri",
                "icon": <FaLayerGroup size={28}/>
              },
              {
                "route": "/expertssm/media_galery",
                "name": "Arhiva media",
                "icon": <RiArchiveFill size={28}/>
              },
            ]
          )
        }
        else return ([]);
    }

    render() {  
        return (
          <>
          {(this.state.current_role === undefined || this.state.isLoadingNewUI && (
            <CenterScreenContainer>
              <LoadingCircleComponent/>
            </CenterScreenContainer>
          ))}
          {(!(this.state.current_role === undefined || this.state.isLoadingNewUI) && (
            <ClientPersonalizedLayout
              app={'ssm'}                     
              routes={this.returnAllowedRoutes()}   
              roleChanged={(new_role) => {
                this.setState({current_role: {name: new_role, isLoadingNewUI: true }});
                window.localStorage.setItem("__current_role__", new_role);
                window.location.href='/';
              }}          
            >
              <PaddingBox
                top='0'
                right='0'
                bottom='0'
                left='15px'
              >                
                <Route exact path='/' component={Home} />
                
                {/* Medical control employer */}
                <Route exact path='/medical-control/workers' component={MedicalControl_Employees} />
                <Route exact path='/medical-control/workers/new' component={MedicalControl_EmployeesNew} />
                <Route exact path='/medical-control/workers/details/:employee_id' component={MedicalControl_EmployeesDetails} />
                <Route exact path='/medical-control/reports' component={MedicalControl_Reports} />
                <Route exact path='/settings/medical-control/configure_machete' component={ConfiguratorMachete} />
                <Route exact path='/settings/medical-control/configure_machete/new' component={ConfiguratorMacheteNew} />
                <Route exact path='/settings/medical-control/configure_machete/details/:macheta_id' component={ConfiguratorMachetaDetails} />
                <Route exact path='/medical-control/requests' component={Requests} />

                {/* Medical control doctor */}
                <Route exact path='/settings/medic/configure_investigatii' component={ConfiguratorInvestigatii}/>         
                <Route exact path='/medic/medical_exam_requests' component={MedicalExamRequestsContainer}/>
                <Route exact path='/medic/medical_exam_requests/:selected_client_id' component={MedicalExamRequestsContainer}/>
                <Route exact path='/medic/medical_exam_requests/details/:exam_request_id' component={MedicalExamRequestsDetails}/>
                <Route exact path='/medic/worker_medical_folder' component={MedicalFolderContainer}/>
                <Route exact path='/medic/worker_medical_folder/:file_id' component={MedicalFileWorkerDetails}/>
                <Route exact path='/medic/reports' component={MedicReports}/>

                {/* Expert SSM employer */}
                <Route exact path='/expertssm/media_galery' component={MediaLibrary} />
                <Route exact path='/expertssm/training_sets' component={Trainings} />
                <Route exact path='/expertssm/training_sets/details/:training_id' component={TrainingDetails} />
                <Route exact path='/expertssm/instructions' component={Instructiuni} />
                <Route exact path='/expertssm/instructions/details/:instruction_id' component={InstructionDetails} />
                <Route exact path='/expertssm/instructions_groups' component={InstructionGroups} />
                <Route exact path='/expertssm/instructions_groups/details/:group_id' component={InstructionGroupDetails} />

                {/* Hr Manager */}
                <Route exact path='/hrmanager/requests' component={HrManagerRequests}/>
                <Route exact path='/hrmanager/workers' component={HrManagerWorkers} />
                <Route exact path='/hrmanager/workers/details/:employee_id' component={HrManagerWorkersDetails} />
              </PaddingBox>              
            </ClientPersonalizedLayout>
          ))}
          </>
        );
  }
}