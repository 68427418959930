import React, { useState, useEffect }  from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import { RoundButton, RoundButtonSmall } from 'shared/dist/components/generic-building-blocks/RoundButton';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { get_ajax, post, parseTrueOrErrorMessage } from 'shared/dist/utils/DataProvider';
import { FormGroup } from 'shared/dist/components/generic-building-blocks/FormGroup';
import { CreateResourceDialog, EditResourceDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';

export const ConfiguratorInvestigatii = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [investigatii, setInvestigatii] = useState([]);        

    const [clients, setClients] = useState([]);

    useEffect(()=>{     
        AOS.init();             
    }, []);

    const [clientSearchBy, setClientSearchBy] = useState('');
    useEffect(()=>{
        get_ajax('MedicSSM/General/GetClients?searchBy=' + clientSearchBy, (response) => {
            setClients(response);            
        });
    }, [clientSearchBy]);

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(12);

    useEffect(()=>{
        setPageSize(12);
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const loadData = (searchBy, page) => {
        get_ajax("MedicSSM/Investigations/GetInvestigations?searchBy=" + searchBy + "&page=" + page + "&angajatorId=" + selectedClientId, (response) =>{
            setInvestigatii(response);
            setIsLoading(false);
        });
    }    

    const [selectedClientId, setSelectedClientId] = useState(undefined);
    const [investigatiiTitleLabel, setInvestigatiiTitleLabel]= useState('Investigatiile mele');
    useEffect(()=>{
        if(selectedClientId == undefined){
            setInvestigatiiTitleLabel("Investigatiile mele"); 
        }
        else{
            setInvestigatiiTitleLabel(`Investigatii ${clients.filter(c => c.id == selectedClientId)[0].nume}`);
        }
        if(currentPage != 0) {setCurrentPage(0); return; }
        setSearchBoxValue(''); 
        loadData('', 0);
    }, [selectedClientId]);

    const [newInvestigatieDialogOpen, setNewInvestigatieDialogOpen] = useState(false);
    const [editInvestigationDialogOpen, setEditInvestigationDialogOpen] = useState(false);
    const [editSelectedInvestigation, setEditSelectedInvestigation] = useState({investigationCode: '', investigationName: ''});
    const [editPriceDialogOpen, setEditPriceDialogOpen] = useState(false);

    return(
        <>  
            <br/>   
            <div className="row">
                <div className="col-md-8">                    
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>{investigatiiTitleLabel}</h4>                                              
                        <br/>
                        <div className="row">
                            <div className="col-md-8">
                                <SearchBox 
                                    value={searchBoxValue} 
                                    placeholder="Cauta" 
                                    onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                                />
                            </div>
                            <div className="col-md-4">
                                {selectedClientId == undefined && (
                                    <RoundButton 
                                        className="custom-btn-primary"
                                        text="Investigatie noua"
                                        onClickHandler={()=>{setNewInvestigatieDialogOpen(true);}}
                                    />
                                )}
                                {selectedClientId != undefined && (
                                    <RoundButton 
                                        className="btn-secondary"
                                        text="Investigatiile mele"
                                        onClickHandler={()=>{setSelectedClientId(undefined);}}
                                    />
                                )}
                            </div>
                        </div>                        
                    </LayoutCard>                                 
                    <LayoutCard className="layout_card_padded shadow-sm">                    
                        {isLoading && (
                        <>
                            <br/>
                            <LoadingCircleComponent/>
                        </>
                        )}
                        {!isLoading && (
                            <>
                                {investigatii.length == 0 && (
                                    <><br/><p style={{textAlign: 'center'}}>Nu exista investigatii.</p></>
                                )}   
                                {investigatii.length != 0 && (
                                    <table className="table tableLastRight" style={{borderTop: 'none'}}>
                                        <thead style={{borderTop: 'none'}}>
                                            <tr>                         
                                                <th scope="col" style={{borderTop: 'none'}}>Cod</th>                   
                                                <th scope="col" style={{borderTop: 'none'}}>Nume</th>                                            
                                                <th scope="col" style={{borderTop: 'none'}}>{selectedClientId == undefined ? "Detalii" : "Pret"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {investigatii.map((c, i) => 
                                                <tr key={i}>                                        
                                                    <td>{c.investigationCode}</td>
                                                    <td>{c.investigationName}</td>                                        
                                                    <td>
                                                        {selectedClientId == undefined && (
                                                            <RoundButtonSmall
                                                                text="Detalii"
                                                                className="custom-btn-primary"
                                                                onClickHandler={()=>{
                                                                    setEditSelectedInvestigation(investigatii[i]);
                                                                    setEditInvestigationDialogOpen(true);
                                                                }}
                                                            />
                                                        )}
                                                        {selectedClientId != undefined && (
                                                            <RoundButtonSmall
                                                                text={
                                                                    c.price == undefined ? "Configureaza pret" :
                                                                    `${c.price.investigationPrice} ${c.price.currency == 0 ? "RON" : "EUR"}`
                                                                }
                                                                className="custom-btn-primary"
                                                                onClickHandler={()=>{
                                                                    setEditSelectedInvestigation(investigatii[i]);
                                                                    setEditPriceDialogOpen(true);
                                                                }}
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table> 
                                )}                                                                                            
                                <ul className="pagination">
                                    <li className="page-item">
                                        <button 
                                            disabled={currentPage <= 0} 
                                            className="page-link btn no-outline" 
                                            onClick={()=>{setCurrentPage(currentPage-1)}}
                                            style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                        >
                                            Inapoi
                                        </button>
                                    </li>
                                    <li className="page-item">
                                        <button 
                                            className="page-link btn no-outline"
                                            style={{cursor: "auto", borderRadius: 0}}
                                        >
                                            {currentPage+1}
                                        </button>
                                    </li>
                                    <li className="page-item">
                                        <button 
                                            disabled={investigatii.length < pageSize} 
                                            className="page-link btn no-outline" 
                                            onClick={()=>{setCurrentPage(currentPage+1)}}
                                            style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                        >
                                            Inainte
                                        </button>
                                    </li>
                                </ul>
                            </>
                        )}   
                    </LayoutCard>
                </div>
                <div className="col-md-4">
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <RoundButton
                            className="btn-secondary"
                            text="Adauga client"
                            onClickHandler={()=>{alert("Contacteaza administratorul platformei pentru a adauga un client nou.")}}
                        />
                        <br/>
                        <SearchBox                             
                            placeholder="Cauta"  
                            value={clientSearchBy}                           
                            onChange={(e) => {setClientSearchBy(e.target.value)}}
                        />
                        
                    </LayoutCard>                      
                    {clients.map((client, i) =>
                        <LayoutCard 
                            className={client.id == selectedClientId ? "layout_card1 layout_card_active shadow-sm" : "layout_card1 shadow-sm"}
                            key={i}
                            onClick={()=>{
                                if(selectedClientId != client.id)
                                    setSelectedClientId(client.id);
                                else
                                    setSelectedClientId(undefined);
                            }}
                        >
                            <h5>{client.nume}</h5>
                            <label style={{marginBottom: '0'}}>{client.adresa}</label>
                        </LayoutCard>
                    )}
                    {clients.length == 0 && (
                        <><br/><p style={{textAlign: 'center'}}>Nu exista clienti.</p></>
                    )}
                </div>
            </div>
            <>
                <NewInvestigatieDialog
                    isOpen={newInvestigatieDialogOpen}
                    toggle={()=>{setNewInvestigatieDialogOpen(false);}}
                    reloadTrigger={()=>{ if(currentPage != 0) setCurrentPage(0); else { setSearchBoxValue(''); loadData('', 0); } }}
                />     
                <EditInvestigation
                    isOpen={editInvestigationDialogOpen}
                    toggle={() =>{setEditInvestigationDialogOpen(false);}}
                    reloadTrigger={()=>{ loadData(searchBoxValue, currentPage); }}
                    investigationData={editSelectedInvestigation}
                />   
                <ConfigurePriceDialog
                    isOpen={editPriceDialogOpen}
                    toggle={()=>{setEditPriceDialogOpen(false);}}
                    reloadTrigger={()=>{ loadData(searchBoxValue, currentPage); }}
                    investigationData={editSelectedInvestigation}
                    selectedClientId={selectedClientId}
                /> 
            </>  
        </>
    );
}

const ConfigurePriceDialog = ({isOpen, toggle, reloadTrigger, investigationData, selectedClientId}) => {
    const [price, setPrice] = useState('');
    const [currency, setCurrency] = useState(0);

    useEffect(()=>{        
        if(investigationData.price == undefined) return;
        setPrice(investigationData.price.investigationPrice);
        setCurrency(investigationData.price.currency);
    }, [investigationData]);

    useEffect(()=>{
        if(isOpen === true) return;
        setPrice('');
    }, [isOpen]);

    return(
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'lg'}
            title={"Configureaza pret"}
            onConfirm={()=>{
                post('MedicSSM/Investigations/SetInvestigationPrice', {
                    'id': investigationData.id,
                    'clientId': selectedClientId,
                    'price': price,
                    'currency': currency
                }, (response)=>{
                    parseTrueOrErrorMessage(response, (response) => {
                        reloadTrigger();
                        toggle();                    
                    });
                })
            }}
        >
            <FormGroup
                label="Pret"
                value={price}
                type={"number"}
                onChange={(e)=>{setPrice(e.target.value)}}
            />
            <div className="form-group">
                <label>Moneda</label>
                <select
                    className="form-control no-outline rounded_input" 
                    value={currency}
                    onChange={(e) =>{setCurrency(e.target.value)}}
                >
                    <option value={0}>RON</option>
                    <option value={1}>EUR</option>
                </select>
            </div>
        </CreateResourceDialog>
    );
}

const NewInvestigatieDialog = ({isOpen, toggle, reloadTrigger}) => {
    const [nume, setNume] = useState('');
    const [cod, setCod] = useState('');      

    useEffect(()=>{
        setNume('');
        setCod('');
    }, [isOpen]);

    return(
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'lg'}
            title={"Investigatie noua"}
            onConfirm={()=>{
                post('MedicSSM/Investigations/CreateInvestigation', {
                    'name': nume,
                    'code': cod
                }, (response)=>{
                    parseTrueOrErrorMessage(response, (response) => {
                        reloadTrigger();
                        toggle();                    
                    }); 
                })
            }}
        >
            <FormGroup
                label="Nume"
                value={nume}
                onChange={(e)=>{setNume(e.target.value)}}
            />
            <FormGroup
                label="Cod"
                value={cod}
                onChange={(e)=>{setCod(e.target.value)}}
            />
        </CreateResourceDialog>
    )
}

const EditInvestigation = ({isOpen, toggle, reloadTrigger, investigationData}) => {
    const [nume, setNume] = useState('');
    const [cod, setCod] = useState('');     

    useEffect(()=>{
        setNume(investigationData.investigationName);
        setCod(investigationData.investigationCode);
    }, [investigationData]);

    return(
        <EditResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'lg'}
            title={"Editeaza investigatie"}
            onConfirm={()=>{
                post('MedicSSM/Investigations/UpdateInvestigation', {
                    'id': investigationData.id,
                    'name': nume,
                    'code': cod
                }, (response)=>{
                    parseTrueOrErrorMessage(response, (response) => {
                        reloadTrigger();
                        toggle();                    
                    });
                });
            }}
            onDelete={()=>{
                post('MedicSSM/Investigations/DeleteInvestigation', {
                    'id': investigationData.id,                    
                }, (response)=>{
                    parseTrueOrErrorMessage(response, (response) => {
                        reloadTrigger();
                        toggle();                    
                    });
                });
            }}
        >
            <FormGroup
                label="Nume"
                value={nume}
                onChange={(e)=>{setNume(e.target.value)}}
            />
            <FormGroup
                label="Cod"
                value={cod}
                onChange={(e)=>{setCod(e.target.value)}}
            />
        </EditResourceDialog>
    );
}
