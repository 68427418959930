"use strict";
exports.__esModule = true;
exports.printPage = exports.setPrint = exports.closePrint = exports.saveAsPdf = void 0;
var saveAsPdf = function (title) {
    if (title === void 0) { title = "export"; }
    var domToPdf = require('dom-to-pdf');
    var element = document.getElementById('saveAsPdfContent');
    // var options = {
    //     filename: `${title}.pdf`
    // };
    // domToPdf(element, options, function() { });
    console.log(element);
    //alert("SAVING PDF");
    var options = {
        filename: "".concat(title, ".pdf")
    };
    domToPdf(element, options, function (pdf) {
        console.log(pdf);
        console.log('done');
    });
};
exports.saveAsPdf = saveAsPdf;
function closePrint() {
    document.body.removeChild(this.__container__);
}
exports.closePrint = closePrint;
function setPrint() {
    this.contentWindow.__container__ = this;
    this.contentWindow.onbeforeunload = closePrint;
    this.contentWindow.onafterprint = closePrint;
    // Required for IE
    this.contentWindow.focus();
    this.contentWindow.print();
}
exports.setPrint = setPrint;
function printPage(sURL) {
    // TODO Improve security here, go to POST or maybe switch up the headers, don't know.
    // Or create a temp token when the user clicks and use that token for download, 
    // making it invalid after a couple minutes.
    var oHideFrame = document.createElement("iframe");
    oHideFrame.onload = setPrint;
    oHideFrame.style.position = "fixed";
    oHideFrame.style.right = "0";
    oHideFrame.style.bottom = "0";
    oHideFrame.style.width = "0";
    oHideFrame.style.height = "0";
    oHideFrame.style.border = "0";
    oHideFrame.src = sURL;
    document.body.appendChild(oHideFrame);
}
exports.printPage = printPage;
