import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { get_ajax, post, parseTrueOrErrorMessage } from 'shared/dist/utils/DataProvider';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import { getAccessToken, GetRoles } from 'shared/dist/components/api-authorization/AuthService';
import {FaUser}from 'react-icons/fa';
import {Link} from 'react-router-dom';
import { RoundButton, RoundRedirectButton } from 'shared/dist/components/generic-building-blocks/RoundButton';
import { CustomResourceModal, CustomResourceModalWithSave } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import { Checked, FisaFactoriRisc, FisaSolicitare, UnChecked } from '../medical-control/shared/MedicalControlRequestRender';
import { DosarMedicalEditor, DosarMedicalRender, ExamenMedical } from './MedicalExamRequests';
import {NullOrUndefined} from 'shared/dist/utils/Utils';
import {saveAsPdf} from 'shared/dist/utils/PdfBuilder';
import $ from 'jquery';
import { printPage } from 'shared/dist/utils/PdfBuilder';
import { MedicalAptitudiniFilePreview, MedicalAptitudiniFileViewer } from '../medical-control/shared/MedicalAptitudiniFileViewer';

function format(inputDate) {
    try{
        var date = new Date(inputDate);
        if (!isNaN(date.getTime())) {
            return date.getDate() + '-' + date.getMonth() + 1 + '-' + date.getFullYear();
        }
    }
    catch(e) { return "-"; }
}

export const MedicalFolderContainer = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [medicalFiles, setMedicalFiles] = useState([]);        

    const [clients, setClients] = useState([]);

    useEffect(()=>{
        AOS.init();  
    }, []);

    const [clientSearchBy, setClientSearchBy] = useState('');
    useEffect(()=>{
        get_ajax('MedicSSM/General/GetClients?searchBy=' + clientSearchBy, (response) => {
            setClients(response);            
        });
    }, [clientSearchBy]);

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(12);

    useEffect(()=>{
        setPageSize(12);
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const loadData = (searchBy, page) => {
        get_ajax("MedicSSM/MedicalFiles/GetMedicalFiles?searchBy=" + searchBy + "&page=" + page + "&angajatorId=" + selectedClientId, (response) =>{
            setMedicalFiles(response);
            setIsLoading(false);
        });
    }    

    const [selectedClientId, setSelectedClientId] = useState(undefined);    
    const [dosareMedicaleTitleLabel, setDosareMedicaleTitleLabel]= useState('Dosare medicale');
    useEffect(()=>{        
        if(selectedClientId == undefined)
            setDosareMedicaleTitleLabel("Dosare medicale"); 
        else
            setDosareMedicaleTitleLabel(`Dosare medicale ${clients.filter(c => c.id == selectedClientId)[0].nume}`);
        if(currentPage != 0) {setCurrentPage(0); return; }
        setSearchBoxValue(''); 
        loadData('', 0);
    }, [selectedClientId]);

    return(
        <>
            <br/>
            <div className="row">
                <div className="col-md-8">
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>{dosareMedicaleTitleLabel}</h4>                                              
                        <br/>
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </LayoutCard>
                    {isLoading && (
                        <>
                            <br/>
                            <LoadingCircleComponent/>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            {medicalFiles.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista dosare medicale.</p></>
                            )}   
                            {medicalFiles.length != 0 && (
                                <div className="row">
                                    {medicalFiles.map((e, i) => 
                                        <div className="col-md-4" key={i}>
                                            <Link to={`/medic/worker_medical_folder/${e.id}`}>                                            
                                                <LayoutCard                                        
                                                    className="layout_card1" key={i}                                                     
                                                >
                                                    <div style={{textAlign: 'center'}}>                                                    
                                                        <FaUser size={56} className="avatar_container"/>
                                                        <h6>{`${e.nume} ${e.prenume}`}</h6>
                                                        <label>
                                                            {e.locMunca}
                                                        </label>
                                                        <br/>                                          
                                                    </div>
                                                </LayoutCard>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            )}                                                                                            
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={medicalFiles.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                <div className="col-md-4">
                    {clients.map((client, i) =>
                        <LayoutCard 
                            className={client.id == selectedClientId ? "layout_card1 layout_card_active shadow-sm" : "layout_card1 shadow-sm"}
                            key={i}
                            onClick={()=>{
                                if(selectedClientId != client.id)
                                    setSelectedClientId(client.id);
                                else
                                    setSelectedClientId(undefined);
                            }}
                        >
                            <h5>{client.nume}</h5>
                            <label style={{marginBottom: '0'}}>{client.adresa}</label>
                        </LayoutCard>
                    )}
                    {clients.length == 0 && (
                        <><br/><p style={{textAlign: 'center'}}>Nu exista clienti.</p></>
                    )}
                </div>
            </div>
        </>
    );
}

export const MedicalFileWorkerDetails = (props) => {
    const [medicalFileId, setMedicalFileId] = useState(undefined)
    useEffect(()=>{
        if(props.match.params.file_id != undefined)
        setMedicalFileId(props.match.params.file_id);
    }, [props.match.params.file_id]);

    const [employeeData, setEmployeeData] = useState(undefined);
    const [doctorIdMapper, setDoctorIdMapper] = useState(undefined);
    const [medicalRecords, setMedicalRecords] = useState(undefined);
    useEffect(()=>{
        if(medicalFileId == undefined) return;
        get_ajax('MedicSSM/MedicalFiles/GetMedicalFileDetails?id=' + medicalFileId, (response) => {
            setEmployeeData(response.employeeData);
            setDoctorIdMapper(response.doctorNameMapper);
            setMedicalRecords(response.medicalRecords);
        });
        loadAttachedFiles();
    }, [medicalFileId]); 

    const renderTipControl = (requestsJSON) => {
        requestsJSON = JSON.parse(requestsJSON);
        if(requestsJSON.solicitare_angajare){
            return ("Angajare");
        }
        if(requestsJSON.solicitare_control_medical_periodic){
            return ("Periodic");
        }
        if(requestsJSON.solicitare_adaptare){
            return ("Adaptare");
        }
        if(requestsJSON.solicitare_reluarea_muncii){
            return ("Reluarea muncii");
        }
        if(requestsJSON.solicitare_supraveghere_speciala){
            return("Supraveghere speciala");
        }
        if(requestsJSON.solicitare_la_cerere){
            return("La cerere");
        }
        if(requestsJSON.solicitare_schimbareaLoculuiDeMunca){
            return("Schimbarea locului de munca");
        }
        if(requestsJSON.solicitare_altele){
            return("Altele");
        }
        return "-";
    }

    const [selectedMedicalFrozenRecordId, setSelectedMedicalFrozenRecordId] = useState(undefined);
    useEffect(()=>{
        if(selectedMedicalFrozenRecordId === undefined) return;
        setDetailsDialogSelectedTab(0);
        get_ajax('MedicSSM/MedicalFiles/GetMedicalRecordData?id=' + selectedMedicalFrozenRecordId, (response) => {
            response.dosarMedicalData = JSON.parse(response.dosarMedicalData);
            response.employeeData = JSON.parse(response.employeeData);
            response.medicalRequest = JSON.parse(response.medicalRequest);
            response.risksFile = JSON.parse(response.risksFile);       
            response.examenMedical = JSON.parse(response.examenMedical);     
            setSelectedMedicalRecordData(response);
        });
    }, [selectedMedicalFrozenRecordId]);
    const [selectedMedicalRecordData, setSelectedMedicalRecordData] = useState(undefined);
    const [detailsDialogSelectedTab, setDetailsDialogSelectedTab] = useState(0);
    const saveAsPdfHandler = () => {
        var type = "";
        if(detailsDialogSelectedTab == 0)
            type="fisa_solicitare";
        if(detailsDialogSelectedTab == 1)
            type="fisa_factori_risc";
        if(detailsDialogSelectedTab == 2)
            type="dosar_medical";
        if(detailsDialogSelectedTab == 3)
            type="examen_medical";
        const f_name = `${type}_${employeeData.nume}_${employeeData.prenume.replace(" ", "_")}`;
        saveAsPdf(f_name);
    }

    const [editMedicalHistoryDialogOpen, setEditMedicalHistoryDialogOpen] = useState(false);
    const [editMedicalRecordData, setEditMedicalRecordData] = useState(undefined);    

    const [medicalAptitudiniFiles, setMedicalAptitudiniFiles] = useState(undefined);
    useEffect(()=>{
        if(employeeData === undefined) return;
        get_ajax('MedicSSM/MedicalFiles/GetAptitudiniFiles?employeeId=' + employeeData.id, (response) => {
            setMedicalAptitudiniFiles(response);
        });
    }, [employeeData]);
    const [fisaAptitudini, setFisaAptitudini] = useState(undefined);
    const [fisaAptitudiniId, setFisaAptitudiniId] = useState(undefined);
    const loadAptitudiniFiles = (id) => {
        setFisaAptitudiniId(id);
        get_ajax('MedicSSM/MedicalFiles/GetAptitudiniFileDetails?aptitudiniFileId=' + id, (response) => {
            response.data = JSON.parse(response.data);   
            setFisaAptitudini(response);
        });
    }

    const [showUploadFileDialog, setShowUploadFileDialog] = useState(false);
    const [btnText, setBtnText] = useState('Alege fisierul');
    const [selectedFile, setSelectedFile] = useState(null);
    const inputFileChanged = (e) => {
        setSelectedFile(e.target.files[0]);
        setBtnText(e.target.files[0].name);        
    }
    const [uploadFileProgress, setUploadFileProgress] = useState(0);
    const [uploadFileProgressTotal, setUploadFileProgressTotal] = useState(0);
    let perc = (uploadFileProgress * 100) / uploadFileProgressTotal;

    const uploadFiles = async () => { 
        const targetUrl = `MedicSSM/MedicalFiles/UploadFile?directoryId=${medicalFileId}`;
        var data = new FormData();
        data.append('files', selectedFile);

        var request = new XMLHttpRequest();
        request.onreadystatechange = () => {
            if (request.readyState === 4) {
                setAttachedFiles(undefined);
                setTimeout(()=>{
                    loadAttachedFiles();
                }, 1000);
            }
        }
        request.upload.addEventListener('progress', function (e) {
            setUploadFileProgress(e.loaded);
            setUploadFileProgressTotal(e.total);
            if (e.total === e.loaded) {
                setUploadFileProgress(0);
                setUploadFileProgressTotal(0);
                setBtnText('Alege fisierul');
                setShowUploadFileDialog(false); 
            }
        }.bind(this), false);

        request.open('POST', targetUrl);
        const token = getAccessToken();
        request.setRequestHeader('Authorization', `${token}`);
        request.send(data);
    }

    const [attachedFiles, setAttachedFiles] = useState(undefined);
    const loadAttachedFiles = () => {
        get_ajax('MedicSSM/MedicalFiles/GetFilesInMedicalFile?medicalFileId=' + medicalFileId, (response) => {
            setAttachedFiles(response);
        });
    }

    const [previewFile, setPreviewFile] = useState(undefined);

    return(
        <>
            <div className='row'>
                <div className='col-md-3'>
                    <RoundRedirectButton 
                        className="custom-btn-primary"
                        text="Inapoi"
                        redirectUrl="/medic/worker_medical_folder"
                    />                        
                </div>
            </div>  
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Date identificare</h4>                                              
                        <br/>
                        {employeeData == undefined && (<LoadingCircleComponent/>)}
                        {employeeData != undefined && (
                            <label>
                                Nume <b>{employeeData.nume}</b><br/>
                                Prenume <b>{employeeData.prenume}</b><br/>
                                Data nasterii <b>{employeeData.dataNastere}</b><br/>
                                Adresa <b>{employeeData.adresa}</b><br/>
                                CNP <b>{employeeData.cnp}</b><br/>
                            </label>
                        )}
                    </LayoutCard>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Loc de munca</h4>                                              
                        <br/>
                        {employeeData == undefined && (<LoadingCircleComponent/>)}
                        {employeeData != undefined && (
                            <label>
                                Unitate <b>{employeeData.unitate}</b><br/>
                                Subunitate <b>{employeeData.subUnitate}</b><br/>
                                Sectie / Departament <b>{employeeData.sectieDepartament}</b><br/>
                                Loc munca <b>{employeeData.locMunca}</b><br/>
                                Profesie <b>{employeeData.solicitareProfesie}</b><br/>
                                Functie <b>{employeeData.solicitareFunctie}</b><br/>
                            </label>
                        )}
                    </LayoutCard>
                </div>
                <div className='col-md-6'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div className='row'>
                            <div className='col-md-8'>
                                <h4>Istoric medical</h4>
                            </div>
                            <div className='col-md-4'>
                                <RoundButton
                                    className="custom-btn-primary"
                                    text="Modifica"
                                    onClickHandler={()=>{ 
                                        get_ajax('MedicSSM/MedicalFiles/GetMedicalRecordData?id=' + medicalRecords[0].id, (response) => {
                                            response.dosarMedicalData = JSON.parse(response.dosarMedicalData);
                                            response.employeeData = JSON.parse(response.employeeData);
                                            response.medicalRequest = JSON.parse(response.medicalRequest);
                                            response.risksFile = JSON.parse(response.risksFile);       
                                            response.examenMedical = JSON.parse(response.examenMedical);     
                                            setEditMedicalRecordData(response);
                                            setEditMedicalHistoryDialogOpen(true); 
                                        });
                                    }}
                                />
                            </div>
                        </div>                                              
                        <br/>
                        {medicalRecords !== undefined && doctorIdMapper !== undefined && (
                            <table className="table tableLastRight">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Data</th>
                                    <th scope="col">Tip</th>
                                    <th scope="col">Doctor</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medicalRecords.map((record, i) => 
                                        <tr key={i}>
                                            <th scope="row">1</th>
                                            <td>{record.createdAt}</td>
                                            <td>{renderTipControl(record.medicalRequestJSON)}</td>
                                            <td>{doctorIdMapper[record.createdBy]}</td>
                                            <td>
                                            <button 
                                                className='btn btn-secondary no-outline'
                                                onClick={()=>{ setSelectedMedicalFrozenRecordId(record.id); }}
                                            >...</button>
                                            </td>
                                        </tr> 
                                    )}
                                </tbody>
                            </table>
                        )}
                        {!(medicalRecords !== undefined && doctorIdMapper !== undefined) && (
                            <LoadingCircleComponent/>
                        )}
                    </LayoutCard>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Fise aptitudini emise</h4>
                        {medicalAptitudiniFiles === undefined && (<LoadingCircleComponent/>)}
                        {medicalAptitudiniFiles !== undefined && (
                            <table className="table tableLastRight">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Data</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medicalAptitudiniFiles.map((file, index) => 
                                        <tr key={index}>
                                            <th>{index+1}</th>
                                            <td>{file.createdAt}</td>
                                            <td>
                                            <button 
                                                className='btn btn-secondary no-outline'
                                                onClick={()=>{ 
                                                    loadAptitudiniFiles(file.id);
                                                }}
                                            >...</button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </LayoutCard>
                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-md-12'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div className='row'>
                            <div className='col-md-6'>
                                <h4>Fisiere atasate</h4>
                            </div>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-8'></div>
                                    <div className='col-md-4'>
                                        <RoundButton
                                            className="custom-btn-primary"
                                            text="Incarca"
                                            onClickHandler={() => { setShowUploadFileDialog(true); }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        {attachedFiles === undefined && (<LoadingCircleComponent/>)}
                        {attachedFiles !== undefined && (
                            <table className="table tableLastRight">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nume fisier</th>
                                    <th scope="col">Data incarcare</th>
                                    <th scope="col">Descarca</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachedFiles.map((file, index) => 
                                        <tr>
                                            <th scope="row">{index+1}</th>
                                            <td>{file.originalDisplayName}</td>
                                            <td>{file.createdAt.split('T')[0]}</td>
                                            <td>
                                                <RoundButton 
                                                    className="custom-btn-primary"
                                                    text="Descarca"
                                                    onClickHandler={()=>{
                                                        let url = `MedicSSM/MedicalFiles/GetBlobDownload?fileId=${file.id}`;
                                                        var blob;
                                                        var xmlHTTP = new XMLHttpRequest();
                                                        xmlHTTP.open('GET', url, true);
                                                        xmlHTTP.responseType = 'arraybuffer';
                                                        xmlHTTP.onload = function(e) {
                                                            blob = new Blob([this.response]);   
                                                        };
                                                        xmlHTTP.onprogress = function(pr) {
                                                            // TODO Maybe add some progress bar to the user later on
                                                            //pr.loaded - current state
                                                            //pr.total  - max
                                                        };
                                                        xmlHTTP.onloadend = function(e){
                                                            var fileName = file.originalDisplayName;
                                                            var tempEl = document.createElement("a");
                                                            tempEl.style.display = "none";
                                                            document.body.appendChild(tempEl);
                                                            url = window.URL.createObjectURL(blob);
                                                            tempEl.href = url;
                                                            tempEl.download = fileName;
                                                            tempEl.click();
                                                            window.URL.revokeObjectURL(url);
                                                        }
                                                        const token = getAccessToken();
                                                        xmlHTTP.setRequestHeader('Authorization', token);
                                                        xmlHTTP.send();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <button 
                                                    className='btn btn-secondary no-outline'
                                                    disabled={!file.originalDisplayName.toLowerCase().endsWith('.pdf')}
                                                    onClick={async ()=>{ 
                                                        setPreviewFile(file.id);

                                                        const endpoint = `MedicSSM/MedicalFiles/PreviewResource?fileId=${file.id}`;
                                                        const token = getAccessToken();
                                                        const response = await fetch(endpoint, {
                                                            headers: {
                                                                'Authorization': `${token}`
                                                            }
                                                        });
                                        
                                                        if (!response.ok) {
                                                            throw new Error('Network response was not ok ' + response.statusText);
                                                        }
                                        
                                                        const blob = await response.blob();
                                                        const url = URL.createObjectURL(blob);
                                                        const iframe = document.getElementById('filepreviewiframeid');
                                                        iframe.src = url;
                                                    }}
                                                >...</button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </LayoutCard>
                </div>
            </div>

            <CustomResourceModal
                size={'lg'}
                isOpen={previewFile !== undefined}
                toggle={()=>{setPreviewFile(undefined)}}
                title={"Previzualizare"}
            >
                <iframe id='filepreviewiframeid' style={{width: '100%', height: '80vh'}}></iframe>
            </CustomResourceModal>
                                                    
            <CustomResourceModal
                size={'lg'}
                isOpen={fisaAptitudini !== undefined}
                toggle={()=>{setFisaAptitudini(undefined)}}
                title={"Fise aptitudini"}
            >
                {fisaAptitudini !== undefined && (
                    <>
                        <div className='row'>
                            <div className='col-md-8'></div>
                            <div className='col-md-4'>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text="Salveaza PDF"
                                    onClickHandler={()=>{
                                        printPage(`/Utils/PdfGenerator/FisaAptitudini?aptitudiniFileId=${fisaAptitudiniId}`);
                                    }}
                                />
                            </div>
                        </div>
                        <br/>
                        <MedicalAptitudiniFileViewer
                            doctorSignature={fisaAptitudini.doctorSignature}
                            signatureDetails={fisaAptitudini.data.clinicData}
                            data={fisaAptitudini.data}
                        />
                    </>
                )}
            </CustomResourceModal>

            <CustomResourceModalWithSave
                size={'xl'}
                isOpen={editMedicalHistoryDialogOpen}
                toggle={() => setEditMedicalHistoryDialogOpen(false)}
                title={"Modifica istoric medical"}
                onConfirm={()=>{
                    post("MedicSSM/MedicalFiles/UploadMedicalRecordData", {
                        'id': medicalRecords[0].id,
                        'examJSON': JSON.stringify(editMedicalRecordData.examenMedical),
                        'dosarMedicalJSON': JSON.stringify(editMedicalRecordData.dosarMedicalData)
                    }, (response)=>{
                        parseTrueOrErrorMessage(response, (response) => {
                            window.location.reload();
                        });
                    });
                }}
            >
                {editMedicalRecordData === undefined && (
                    <LoadingCircleComponent/>
                )}
                {editMedicalRecordData !== undefined && (
                    <div className='row'>
                        <div className='col-md-6'>
                            <DosarMedicalEditor
                                employeeData={editMedicalRecordData.employeeData}
                                medicalRequest={editMedicalRecordData.medicalRequest}
                                dosarMedical={editMedicalRecordData.dosarMedicalData}
                                signatureDetails={editMedicalRecordData.clinicSignatureDetails}
                                updateDosarMedical={(newDosarMedical)=>{    
                                    let _editMedicalRecordData = editMedicalRecordData;
                                    _editMedicalRecordData.dosarMedicalData = newDosarMedical;
                                    setEditMedicalRecordData(_editMedicalRecordData);
                                }}
                            />
                        </div>
                        <div className='col-md-6'>
                            <ExamenMedical                        
                                medicalRequest={editMedicalRecordData.medicalRequest}
                                signatureDetails={editMedicalRecordData.clinicSignatureDetails}
                                examenMedicalData={editMedicalRecordData.examenMedical}   
                                setExamenMedicalData={(examenMedical)=>{
                                    let _editMedicalRecordData = editMedicalRecordData;
                                    _editMedicalRecordData.examenMedical = examenMedical;
                                    setEditMedicalRecordData(_editMedicalRecordData);
                                }}   
                                withoutAptFile={true}                               
                            />
                        </div>
                    </div>
                )}
            </CustomResourceModalWithSave>
                
            <CustomResourceModal
                size={'xl'}
                isOpen={selectedMedicalFrozenRecordId !== undefined}
                toggle={() => setSelectedMedicalFrozenRecordId(undefined)}
                title={"Investigatie medicala"}
            >
                {selectedMedicalRecordData === undefined && <LoadingCircleComponent/>}
                {selectedMedicalRecordData !== undefined && (
                    <>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                        <a className={detailsDialogSelectedTab == 0 ? "nav-link active" :"nav-link"} onClick={()=>setDetailsDialogSelectedTab(0)}>Fisa solicitare</a>
                        </li>
                        <li className="nav-item">
                        <a className={detailsDialogSelectedTab == 1 ? "nav-link active" :"nav-link"} onClick={()=>setDetailsDialogSelectedTab(1)}>Fisa factori risc</a>
                        </li>
                        <li className="nav-item">
                        <a className={detailsDialogSelectedTab == 2 ? "nav-link active" :"nav-link"} onClick={()=>setDetailsDialogSelectedTab(2)}>Dosar medical</a>
                        </li>
                        <li className="nav-item">
                        <a className={detailsDialogSelectedTab == 3 ? "nav-link active" :"nav-link"} onClick={()=>setDetailsDialogSelectedTab(3)}>Examen medical</a>
                        </li>
                    </ul>
                    <br/>
                    <div className='row'>
                        <div className='col-md-8'></div>
                        <div className='col-md-4'>
                            <RoundButton
                                className={"custom-btn-primary"}
                                text="Salveaza PDF"
                                onClickHandler={saveAsPdfHandler}
                            />
                        </div>
                    </div>
                    <br/>
                    <div id="saveAsPdfContent">
                        <div style={{width: '100%', padding: '25px'}}>
                        {detailsDialogSelectedTab == 0 && (
                            <FisaSolicitare
                                employeeData={selectedMedicalRecordData.employeeData}
                                medicalRequest={selectedMedicalRecordData.medicalRequest}
                                risksFile={selectedMedicalRecordData.risksFile}
                                medicalRequestDate={selectedMedicalRecordData.medicalRequestDate}
                                medicalRequestSignature={selectedMedicalRecordData.medicalRequestSignature}
                            />
                        )}
                        {detailsDialogSelectedTab == 1 && (
                            <FisaFactoriRisc
                                risksFileSignature={selectedMedicalRecordData.risksFileSignature}
                                employeeData={selectedMedicalRecordData.employeeData}
                                medicalRequest={selectedMedicalRecordData.medicalRequest}
                                risksFile={selectedMedicalRecordData.risksFile}
                                medicalRequestDate={selectedMedicalRecordData.medicalRequestDate}
                                risksFileCreatedBy={selectedMedicalRecordData.risksFileCreatedBy}
                                medicalRequestSignature={selectedMedicalRecordData.medicalRequestSignature}
                            />
                        )}
                        {detailsDialogSelectedTab == 2 && (
                            <DosarMedicalRender
                                employeeData={selectedMedicalRecordData.employeeData}
                                medicalRequest={selectedMedicalRecordData.medicalRequest}
                                dosarMedical={selectedMedicalRecordData.dosarMedicalData}
                                signatureDetails={selectedMedicalRecordData.clinicSignatureDetails}
                            />
                        )}
                        {detailsDialogSelectedTab == 3 && (
                            <ExamenMedicalRenderer
                                employeeData={selectedMedicalRecordData.employeeData}
                                medicalRequest={selectedMedicalRecordData.medicalRequest}
                                clinicSignatureDetails={selectedMedicalRecordData.clinicSignatureDetails}
                                examenMedical={selectedMedicalRecordData.examenMedical}
                                doctorSignature={selectedMedicalRecordData.doctorSignature}
                                medicalExamCreatedAt={selectedMedicalRecordData.medicalExamCreatedAt}
                                nextExamDate={selectedMedicalRecordData.nextExamDate}
                            />
                        )}
                        </div>
                    </div>
                    </>
                )}
            </CustomResourceModal>

            <CustomResourceModalWithSave
                title={'Incarca fisier'}
                size={'md'}
                isOpen={showUploadFileDialog}
                toggle={()=>{setShowUploadFileDialog(false)}}
                onConfirm={()=>{
                    uploadFiles();
                }}
                save_label={'Adauga'}
            >
                <button
                    onClick={() => {
                        $('#autoInputObj_ARCH_F_UPL').trigger('click');
                    }}
                    className="btn btn-primary no-outline btn-block"
                >
                    {btnText}                    
                </button>
                {uploadFileProgressTotal != 0 && (
                    <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                        <br />
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valueNow={perc} aria-valueMin="0" aria-valueMax="100" style={{ width: perc + "%" }}>
                                {perc + "%"}
                            </div>
                        </div>
                    </div>
                )}
                <input id="autoInputObj_ARCH_F_UPL" hidden type="file" style={{ display: 'none', position: 'fixed', top: '-5000em' }} onChange={inputFileChanged} multiple={false} />
            </CustomResourceModalWithSave>
        </>
    )
}

export const ExamenMedicalRenderer = ({medicalRequest, clinicSignatureDetails, examenMedical, doctorSignature, medicalExamCreatedAt, nextExamDate, employeeData=undefined}) => {
    return(
        <>
            {employeeData !== undefined && (
                <>
                    <p>Nume si prenume: <b>{employeeData.nume} {employeeData.prenume}</b><br/>CNP: <b>{employeeData.cnp}</b></p>
                    <br/>
                </>
            )}
            <h5 style={{textAlign: 'center'}}>Examen medical {medicalRequest.solicitare_angajare ? "la angajare" : "periodic"}</h5>
            <br/>
            {/* Was previously blocked by this condition: medicalRequest.solicitare_angajare === false */}
            <>
                <label>
                    Simptome actuale <b>{examenMedical.simptomeActuale}</b> <br/>
                    Reactualizarea anamnezei profesionale <b>{examenMedical.reactualizareAnamnezeiProfesionale}</b> <br/>
                    Simptome la locul de munca <b>{examenMedical.simptomeLocMunca}</b> <br/>
                    Reactualizarea anamnezei neprofesionale <b>{examenMedical.reactualizareAnamnezeiNeprofesionale}</b> <br/>
                </label>
                <br/>
            </>
            <div className='row'>
                <div className='col-md-3'>
                    <label>T <b>{examenMedical.t}</b> cm</label>
                </div>
                <div className='col-md-3'>
                    <label>G <b>{examenMedical.G}</b> kg</label>
                </div>
                <div className='col-md-6'>
                    <label>IMC: <b>{examenMedical.imc}</b> obezitate nu {NullOrUndefined(examenMedical.obezitate) ? <Checked/> : <UnChecked/>} da {examenMedical.obezitate != null ? <Checked/> : <UnChecked/>} grad <b>{examenMedical.obezitate}</b></label>
                </div>
            </div>
            <br/>
            <div style={{border: '1px solid gray', padding: '5px', paddingLeft: '10px', paddingRight: '10px'}}>
                <h5 style={{textAlign: 'center'}}>EXAMEN CLINIC: </h5>
                <br/>
                <label>
                1. tegumente si mucoase <b>{examenMedical.tegumenteMucoase}</b><br/>
                2. tesut celular subcutanat <b>{examenMedical.tesutCelularSubcutanat}</b><br/>
                3. sistem ganglionar <b>{examenMedical.sistemGanglionar}</b><br/>
                4. aparat locomotor <b>{examenMedical.aparatLocomotor}</b><br/>
                5. aparat respirator <b>{examenMedical.aparatRespirator}</b><br/>
                6. aparat CV: <b>{examenMedical.aparatCV}</b><br/>
                TA <b>{examenMedical.TA}</b> mmHg, AV <b>{examenMedical.AV}</b>/min, pedioase <b>{examenMedical.pedioase}</b>, varice <b>{examenMedical.varice}</b><br/>
                7. aparat digestiv <b>{examenMedical.aparatDigestiv}</b><br/>
                8. aparat urogenital <b>{examenMedical.aparatUrogenital}</b><br/>
                9. SNC, analizatori: <b>{examenMedical.sncAnalizatori}</b><br/>
                a) acuitate vizuala <b>{examenMedical.acuitateVizuale}</b> vedere cromatica <b>{examenMedical.vedereCromatica}</b><br/>
                vedere in relief fara corectie optica {examenMedical.vedereReliefFaraCorectie ? <Checked/> : <UnChecked/>}<br/>
                cu corectie optica: OD <b>{examenMedical.vedereReliefCorectieOD != '' ? examenMedical.vedereReliefCorectieOD : '..........'}</b> OS <b>{examenMedical.vedereReliefCorectieOS != '' ? examenMedical.vedereReliefCorectieOS : '..........'}</b><br/>
                b) voce tare <b>{examenMedical.voceTare}</b> voce soptita <b>{examenMedical.voceSoptita}</b><br/>
                10. sistem endocrin <b>{examenMedical.sistemEndocrin}</b><br/>
                </label><br/>
                <div className='row'>
                    <div className='col-md-8'>
                        <label>
                        Concluzii examen clinic: <br/>
                        {examenMedical.concluziiExamenClinicSanatos ? <Checked/> : <UnChecked/>} sanatos clinic in momentul examinarii <br/>
                        {examenMedical.concluziiExamenClinicDiagnosticCheck ? <Checked/> : <UnChecked/>} diagnostic <p style={{whiteSpace: 'pre-wrap'}}><b>{examenMedical.concluziiExamenClinicDiagnostic}</b></p>
                        </label>
                    </div>
                    <div className='col-md-4'>
                        <p style={{textAlign: 'center'}}>
                            Semnatura si parafa medicului, <br/>
                            <b style={{textAlign: 'center'}}>{clinicSignatureDetails.name}</b><br/>
                            <img src={doctorSignature} style={{maxWidth: '100%'}}/>
                        </p>
                    </div>
                </div>
            </div>
            <br/>
            <div style={{border: '1px solid gray', padding: '5px', paddingLeft: '10px', paddingRight: '10px'}}>
                <h6 style={{textAlign: 'center'}}>EXAMENE SUPLIMENTARE (conform anexei): </h6>
                <br/>
                <p style={{whiteSpace: 'pre-wrap'}}>{examenMedical.exameneSuplimentare !== '' ? (<b>{examenMedical.exameneSuplimentare}</b>) : (<label>.........................................................................<br/>.........................................................................<br/>.........................................................................<br/>.........................................................................<br/>.........................................................................</label>)}</p>
            </div>
            <br/>
            <div style={{border: '1px solid gray', padding: '5px', paddingLeft: '10px', paddingRight: '10px'}}>
                <h6>Concluziile examinarii de specialitate (medicina muncii): </h6>
                <br/>
                <label>
                {examenMedical.concluziiExamenMMSanatos ? <Checked/> : <UnChecked/>} sanatos clinic in momentul examinarii <br/>
                {examenMedical.concluziiExamenMMDiagnosticCheck ? <Checked/> : <UnChecked/>} diagnostic <p style={{whiteSpace: 'pre-wrap'}}><b>{examenMedical.concluziiExamenMMDiagnostic}</b></p>
                </label>
                <br/><br/>
                <label>
                    <b>AVIZ MEDICAL:</b><br/>
                    pentru exercitarea ocupatiei/functiei: <b>{medicalRequest.solicitare_functie}</b><br/>
                </label>
                <br/>
                <div className='row'>
                <div className='col-md-5'>
                    <p style={{textAlign: 'center'}}>AVIZ MEDICAL:</p>
                    <br/>
                    <p style={{marginBottom: 0}}>Apt <div className='float-right'>{examenMedical.apt ? <Checked /> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Apt conditionat <div className='float-right'>{examenMedical.aptConditionat ? <Checked/> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Inapt temporar <div className='float-right'>{examenMedical.inaptTemporar ? <Checked/> : <UnChecked/>}</div></p>
                    <p style={{marginBottom: 0}}>Inapt <div className='float-right'>{examenMedical.inapt ? <Checked/> : <UnChecked/>}</div></p>
                </div>
                <div className='col-md-7'>
                    <p style={{textAlign: 'center'}}>Recomandari:</p>
                    <br/>
                    <p style={{textAlign: 'center'}}>{examenMedical.recomandari}</p>
                </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-md-8'>
                        <label>
                            Data<br/>
                            <b>{medicalExamCreatedAt}</b>
                        </label>
                    </div>
                    <div className='col-md-4'>
                        <div style={{textAlign: 'center'}}>
                            <label>
                            Medic de medicina muncii<br/>
                            <b>{clinicSignatureDetails.name}</b><br/>
                            (semnatura si parafa)
                            </label><br/>
                            <img src={doctorSignature} style={{maxWidth: '100%'}}/>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <label>Data urmatorului examen medical: <b>{nextExamDate}</b></label>
            </div>
        </>
    )
}