import React, { useEffect, useState } from 'react';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import AOS from 'aos';
import "aos/dist/aos.css";
import {get, get_ajax, post} from 'shared/dist/utils/DataProvider';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {IoWarningOutline, IoCheckmark, IoHourglassOutline} from 'react-icons/io5';
import {Link, Redirect} from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { displayToastNotification, TOAST_NOTIFICATION, useGlobalSync } from  'shared/dist/utils/NotificationsContext';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
export const chart_options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            onClick: null
        },
    },    
    scales: {
        y: {
            ticks: {
                precision: 0
            }
        },
    },
};

export const Home = () => {
    useEffect(()=>{
        AOS.init();
    }, []);

    const role = window.localStorage.getItem('__current_role__');
    if(role === "Medic SSM")
        return (<MedicHomePage/>);
    if(role === "Dosar medical")
        return (<MedicalControlSSMHomePage/>);
    return (
        <>
            <br/>
            <div className="row">
                <div className="col-md-6">
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div>
                            <h5>Bun venit!</h5>
                            <label>Va uram o zi frumoasa!</label>
                        </div>
                    </LayoutCard>
                </div>
            </div>
        </>
    )
}

const MedicalControlSSMHomePage = () => {
    const [monthlyChartData, setMonthlyChartData] = useState(undefined);
    const [cardStats, setCardStats] = useState(undefined);
    const [redirectToResource, setRedirectToResource] = useState(undefined);

    useEffect(()=>{
        get_ajax("EmployeeManagement/Home/GetDashboardStats", (response) => {
            setMonthlyChartData({
                labels: response.monthLabels,
                datasets: [
                  {
                    label: 'Controale scadente',
                    data: response.monthContents,
                    borderRadius: 30,
                    backgroundColor: document.documentElement.style.getPropertyValue("--side-menu-color"),
                  },
                ],
            });
            setCardStats(response.cards_stats);
        });  
    }, []);

    if(cardStats === undefined || monthlyChartData === undefined)
        return (<LoadingCircleComponent/>);
    return(
        <>
            {redirectToResource !== undefined && (<Redirect to={redirectToResource}/>)}
            <br/>
            <div className="row">
                <div className="col-md-9">
                    <LayoutCard className="layout_card4 layout_card_25bottom layout_card_padded shadow-sm"
                        onClick={()=>{
                            displayToastNotification(`Salut ${window.sessionStorage.getItem("nume_prenume")}!`, TOAST_NOTIFICATION.INFO);
                        }}
                    >
                        <div>
                            <h5>Bun venit, {window.sessionStorage.getItem("nume_prenume")}!</h5>
                            <label>Va uram o zi frumoasa!</label>
                        </div>
                    </LayoutCard>
                    <div className='row'>
                        <div className="col-md-4">
                            <div
                                data-aos="flip-up"
                                data-aos-delay="50"
                            >                                    
                                <LayoutCard
                                    className="layout_card_25bottom layout_card_padded shadow-sm"
                                    onClick={()=>{setRedirectToResource('/medical-control/requests')}}
                                >   
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <label>Controale in curs</label>
                                            <h4 
                                                style={cardStats.validated_not_completed === 0 ?
                                                    {marginBottom: '0', color: '#2ecc71'}:
                                                    {marginBottom: '0', color: '#e67e22'}} 
                                            >
                                                {cardStats.validated_not_completed}
                                            </h4>  
                                        </div>
                                        <div className='col-md-4'>
                                            <div style={{display: 'flex', alignItems: 'center', height: '100%', width: '100%'}}>
                                                {cardStats.validated_not_completed !== 0 && (
                                                    <IoHourglassOutline size={28} fill='#e67e22' color='#e67e22' style={{display: 'block', margin: 'auto'}}/>
                                                )}
                                                {cardStats.validated_not_completed === 0 && (
                                                    <IoCheckmark size={28} fill='#2ecc71' color='#2ecc71' style={{display: 'block', margin: 'auto'}}/>
                                                )}
                                            </div>
                                        </div>
                                    </div> 
                                </LayoutCard>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                data-aos="flip-up"
                                data-aos-delay="50"
                            >                                    
                                <LayoutCard
                                    className="layout_card_25bottom layout_card_padded shadow-sm"
                                >                            
                                    <label>Scadenta in 30 de zile</label>
                                    <h4 style={{marginBottom: '0'}}>
                                        {cardStats.expire_30day}
                                    </h4>     
                                </LayoutCard>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div
                                data-aos="flip-up"
                                data-aos-delay="50"
                            >
                                {cardStats.expired !== 0 && (
                                    <LayoutCard 
                                        className="layout_card_25bottom layout_card_padded shadow-sm danger_card"
                                    >
                                        <label>Scadenta depasita</label>
                                        <h4 style={{marginBottom: '0'}}>
                                            {cardStats.expired}
                                        </h4>  
                                    </LayoutCard>
                                )}
                                {cardStats.expired === 0 && cardStats.expire_7days != 0 && (
                                    <LayoutCard 
                                        className="layout_card_25bottom layout_card_padded shadow-sm warning_card"
                                    >
                                        <label>Scadenta in 7 zile</label>
                                        <h4 style={{marginBottom: '0'}}>
                                            {cardStats.expire_7days}
                                        </h4>  
                                    </LayoutCard>
                                )}
                                {cardStats.expired === 0 && cardStats.expire_7days === 0 && (
                                    <LayoutCard 
                                        className="layout_card_25bottom layout_card_padded shadow-sm"
                                    >
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <label>Alerte</label>
                                                <h4 
                                                    style={{marginBottom: '0', color: '#2ecc71'}} 
                                                >
                                                    0
                                                </h4>  
                                            </div>
                                            <div className='col-md-4'>
                                                <div style={{display: 'flex', alignItems: 'center', height: '100%', width: '100%'}}>
                                                    <IoCheckmark size={28} fill='#2ecc71' color='#2ecc71' style={{display: 'block', margin: 'auto'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </LayoutCard>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='row'>
                        <div className="col-md-12">
                            <div
                                data-aos="flip-up"
                                data-aos-delay="50"
                            >                                    
                                <LayoutCard
                                    className="layout_card_25bottom layout_card_padded shadow-sm layout_card5"
                                    onClick={()=>{setRedirectToResource('/medical-control/requests')}}
                                >    
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <label>Cereri la aprobare</label>
                                            <h4 
                                                // style={cardStats.validation_pending === 0 ?
                                                //     {marginBottom: '0', color: '#2ecc71'}:
                                                //     {marginBottom: '0', color: '#e67e22'}} 
                                                style={{marginBottom: '0', color: 'white'}}
                                            >
                                                {cardStats.validation_pending}
                                            </h4>  
                                        </div>
                                        <div className='col-md-4'>
                                            <div style={{display: 'flex', alignItems: 'center', height: '100%', width: '100%'}}>
                                                {cardStats.validation_pending !== 0 && (
                                                    // <IoHourglassOutline size={28} fill='#e67e22' color='#e67e22' style={{display: 'block', margin: 'auto'}}/>
                                                    <IoHourglassOutline size={28} fill='white' color='white' style={{display: 'block', margin: 'auto'}}/>
                                                )}
                                                {cardStats.validation_pending === 0 && (
                                                    // <IoCheckmark size={28} fill='#2ecc71' color='#2ecc71' style={{display: 'block', margin: 'auto'}}/>
                                                    <IoCheckmark size={28} fill='white' color='white' style={{display: 'block', margin: 'auto'}}/>
                                                )}
                                            </div>
                                        </div>
                                    </div>    
                                </LayoutCard>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div
                                data-aos="flip-up"
                                data-aos-delay="50"
                            >
                                <LayoutCard 
                                    className="layout_card_25bottom layout_card_padded shadow-sm cursor_pointer layout_card6"
                                    onClick={()=>{setRedirectToResource('/medical-control/reports')}}
                                >
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <label>Validare investigatii</label>
                                            <h4 
                                                // style={cardStats.controls_pending_validation === 0 ?
                                                //     {marginBottom: '0', color: '#2ecc71'}:
                                                //     {marginBottom: '0', color: '#e67e22'}} 
                                                style={{marginBottom: '0', color: 'white'}}
                                            >
                                                {cardStats.controls_pending_validation}
                                            </h4>  
                                        </div>
                                        <div className='col-md-4'>
                                            <div style={{display: 'flex', alignItems: 'center', height: '100%', width: '100%'}}>
                                                {cardStats.controls_pending_validation !== 0 && (
                                                    // <IoWarningOutline size={28} fill='#e67e22' color='#e67e22' style={{display: 'block', margin: 'auto'}}/>
                                                    <IoWarningOutline size={28} fill='white' color='white' style={{display: 'block', margin: 'auto'}}/>
                                                )}
                                                {cardStats.controls_pending_validation === 0 && (
                                                    // <IoCheckmark size={28} fill='#2ecc71' color='#2ecc71' style={{display: 'block', margin: 'auto'}}/>
                                                    <IoCheckmark size={28} fill='white' color='white' style={{display: 'block', margin: 'auto'}}/>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </LayoutCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                <Bar 
                    options={chart_options} 
                    data={monthlyChartData} 
                    height={'65vh'}
                />
            </LayoutCard>
        </>
    );
}

const MedicHomePage = () => {
    const [monthlyChartData, setMonthlyChartData] = useState(undefined);
    const [cardStats, setCardStats] = useState(undefined);
    const [redirectToResource, setRedirectToResource] = useState(undefined);

    useEffect(()=>{
        get_ajax("MedicSSM/Home/GetDashboardStats", (response) => {
            setMonthlyChartData({
                labels: response.monthLabels,
                datasets: [
                  {
                    label: 'Estimare controale viitoare',
                    data: response.monthContents,
                    borderRadius: 30,
                    backgroundColor: document.documentElement.style.getPropertyValue("--side-menu-color"),
                  },
                ],
            });
            setCardStats(response.cards_stats);
        });  
    }, []);

    if(cardStats === undefined || monthlyChartData === undefined)
        return (<LoadingCircleComponent/>);
    return(
        <>
            {redirectToResource !== undefined && (<Redirect to={redirectToResource}/>)}
            <br/>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <LayoutCard className="layout_card4 layout_card_25bottom layout_card_padded shadow-sm">
                                <div>
                                    <h5>Bun venit, {window.sessionStorage.getItem("nume_prenume")}!</h5>
                                    <label>Va uram o zi frumoasa!</label>
                                </div>
                            </LayoutCard>
                        </div>
                    </div>
                    <div className='row'>
                        {cardStats.client_requests.map((cl, i) => 
                            <div className='col-md-3' key={i}>
                                <Link to={'/medic/medical_exam_requests/'+cl.id}>
                                    <LayoutCard className="layout_card1 layout_card_25bottom layout_card_padded shadow-sm">
                                        <label>{cl.name}</label>
                                        <br/>                                     
                                        <h4 style={{marginBottom: '0', display: 'inline'}}>
                                            {cl.total}
                                        </h4> <small>{cl.total == 1 ? "cerere" : "cereri"}</small>
                                    </LayoutCard>
                                </Link>
                            </div>
                        )}
                    </div>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <Bar options={chart_options} data={monthlyChartData} height={'65vh'}/>
                    </LayoutCard>
                </div>
                {/* <div className='col-md-4'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        
                    </LayoutCard>
                </div> */}
            </div>
        </>
    );
}

const HrManageHomePage = () => {
    return(
        <>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div>
                            <h5>Bun venit, {window.sessionStorage.getItem("nume_prenume")}!</h5>
                            <label>Va uram o zi frumoasa!</label>
                        </div>
                    </LayoutCard>
                </div>
            </div>
        </>
    );
}