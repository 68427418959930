"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.CdnMediaResource = exports.CdnVideo = exports.CdnImage = void 0;
var react_1 = __importStar(require("react"));
var DataProvider_1 = require("../../utils/DataProvider");
var CircleLoadingIndicator_1 = require("./CircleLoadingIndicator");
var CdnImage = function (_a) {
    var blobName = _a.blobName, _b = _a.preview_style, preview_style = _b === void 0 ? { maxWidth: '100%', maxHeight: '120px' } : _b;
    var _c = (0, react_1.useState)(undefined), cdn_address = _c[0], setCdnAddress = _c[1];
    (0, react_1.useEffect)(function () {
        var _cdn_address = window.localStorage.getItem("cdn_address");
        if (_cdn_address === null || _cdn_address === undefined || _cdn_address === "")
            (0, DataProvider_1.get_ajax)('/configurator/get_cdn_address', function (address) {
                window.localStorage.setItem("cdn_address", address);
                setCdnAddress(address);
            });
        else
            setCdnAddress(_cdn_address);
    }, []);
    if (cdn_address === undefined)
        return (react_1["default"].createElement(CircleLoadingIndicator_1.LoadingCircleComponent, null));
    return (react_1["default"].createElement("img", { src: "".concat(cdn_address, "/").concat(blobName), style: preview_style }));
};
exports.CdnImage = CdnImage;
var CdnVideo = function (_a) {
    var blobName = _a.blobName, _b = _a.preview_style, preview_style = _b === void 0 ? { maxWidth: '100%', maxHeight: '120px' } : _b, has_controls = _a.has_controls;
    var _c = (0, react_1.useState)(undefined), cdn_address = _c[0], setCdnAddress = _c[1];
    (0, react_1.useEffect)(function () {
        var _cdn_address = window.localStorage.getItem("cdn_address");
        if (_cdn_address === null || _cdn_address === undefined || _cdn_address === "")
            (0, DataProvider_1.get_ajax)('/configurator/get_cdn_address', function (address) {
                window.localStorage.setItem("cdn_address", address);
                setCdnAddress(address);
            });
        else
            setCdnAddress(_cdn_address);
    }, []);
    if (cdn_address === undefined)
        return (react_1["default"].createElement(CircleLoadingIndicator_1.LoadingCircleComponent, null));
    var extension = blobName.split('.').pop().toLowerCase();
    return (react_1["default"].createElement("video", { style: preview_style, controls: has_controls },
        react_1["default"].createElement("source", { src: "".concat(cdn_address, "/").concat(blobName, "#t=0.1"), type: "video/".concat(extension) })));
};
exports.CdnVideo = CdnVideo;
var CdnMediaResource = function (_a) {
    var blobName = _a.blobName, _b = _a.preview_style, preview_style = _b === void 0 ? { maxWidth: '100%', maxHeight: '120px' } : _b, has_controls = _a.has_controls;
    var extension = blobName.split('.').pop().toLowerCase();
    switch (extension) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'tiff':
            return (react_1["default"].createElement(exports.CdnImage, { blobName: blobName, preview_style: preview_style }));
        case 'mp4':
        case 'mov':
        case 'wmv':
        case 'flv':
        case 'avi':
        case 'avchd':
        case 'webm':
        case 'mkv':
            return (react_1["default"].createElement(exports.CdnVideo, { blobName: blobName, preview_style: preview_style, has_controls: has_controls }));
        default:
            return (react_1["default"].createElement(react_1["default"].Fragment, null, "Not a valid resource"));
    }
};
exports.CdnMediaResource = CdnMediaResource;
