import React, { useEffect, useRef, useState } from 'react';
import { getAccessToken } from 'shared/dist/components/api-authorization/AuthService';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import AOS from 'aos';
import "aos/dist/aos.css";
import {CdnResourceDownloadBtn, RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {formatBytes} from 'shared/dist/utils/Utils';
import {FileTypeIcon, MediaPreviewIcon} from 'shared/dist/components/generic-building-blocks/FileTypeIcon';
import {CdnImage} from 'shared/dist/components/generic-building-blocks/CdnImage';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {CreateResourceDialog, DeleteConfirmationDialog} from 'shared/dist/components/generic-building-blocks/UserDialogs';
import { FormGroup } from 'shared/dist/components/generic-building-blocks/FormGroup';
import { Redirect } from 'react-router-dom';
import {FaLayerGroup} from 'react-icons/fa';
import {MdIntegrationInstructions} from 'react-icons/md';
import {FiEdit2} from 'react-icons/fi';


export const InstructionGroups = () => {
    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 12;

    useEffect(()=>{
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);

    useEffect(()=>{     
        AOS.init();                    
    }, []);

    const loadData = (searchBy, page) => {
        get_ajax("ExpertSSM/InstructionsGroupsManager/GetInstructionGroups?search=" + searchBy + "&page=" + page, (response) =>{
            setRecords(response);
            setIsLoading(false);
        });
    }

    const [redirectToResource, setRedirectToResource] = useState(undefined);

    const [isNewGroupInstructionDialogOpen, setIsNewGroupInstructionDialogOpen] = useState(false);
    const [newInstructionName, setNewInstructionName] = useState('');
    useEffect(()=>{
        setNewInstructionName('');
    }, [isNewGroupInstructionDialogOpen]);

    return (
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Grupuri materiale</h4>
                        <br/>
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </LayoutCard>
                    {isLoading && (<LoadingCircleComponent/>)}
                    {!isLoading && (
                        <>
                            <div className='row'>
                                {records.map((rec, i)=> 
                                    <div className='col-md-4' key={i}>
                                        <div className='maxHeight' style={{paddingBottom: '25px'}}>
                                        <LayoutCard 
                                            className="layout_card1 shadow-sm no_padding maxHeight top_bottom"
                                            onClick={()=>{
                                                setRedirectToResource("/expertssm/instructions_groups/details/" + rec.id);
                                            }}
                                        >
                                            <FaLayerGroup
                                                size={52} 
                                                style={{margin: 'auto', display: 'block', marginTop: '40px'}}
                                            />
                                            <p 
                                                style={{
                                                    textAlign: 'center',
                                                    marginBottom: '0',
                                                    whiteSpace: 'normal'
                                                }}
                                                className='padded_bottom'
                                            >
                                                {rec.originalName}
                                            </p>
                                        </LayoutCard>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {records.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista resurse.</p><br/></>
                            )}
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={records.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                <div className='col-md-4'>
                    <RoundButton
                        className={"custom-btn-primary"}
                        text={'Grup nou'}
                        onClickHandler={()=>{setIsNewGroupInstructionDialogOpen(true)}}
                    />
                </div>
            </div>

            <CreateResourceDialog
                isOpen={isNewGroupInstructionDialogOpen}
                toggle={()=>{setIsNewGroupInstructionDialogOpen(!isNewGroupInstructionDialogOpen)}}
                saveBtnOverwride={"Adauga"}
                title={"Material nou"}
                onConfirm={()=>{
                    post('ExpertSSM/InstructionsGroupsManager/CreateNewInstructiongroup', {
                        'name': newInstructionName,
                    }, (response)=>{
                        setRedirectToResource("/expertssm/instructions_groups/details/" + response);
                    });
                }}
            >
                <FormGroup
                    label={"Nume grup"}
                    value={newInstructionName}
                    onChange={(e) => {setNewInstructionName(e.target.value)}}
                />
            </CreateResourceDialog>
            {redirectToResource !== undefined && (
                <Redirect to={redirectToResource}/>
            )}
        </>
    );
}

export const InstructionGroupDetails = (props) => {

    useEffect(()=>{
        AOS.init();
    }, []);
    const [groupId, setGroupId] = useState(undefined);
    useEffect(()=>{
        if(props.match.params.group_id != undefined)
            setGroupId(props.match.params.group_id);
    }, [props.match.params.group_id]);

    useEffect(()=>{
        if(groupId === undefined) return;
        get_ajax("ExpertSSM/InstructionsGroupsManager/GetInstructionsGroupDetails?groupId=" + groupId, (response) =>{
            setMaterials(JSON.parse(response.materials));
        });
    }, [groupId]);

    const [materials, setMaterials] = useState([]);

    const [selectNewInstructionModalVisible, setSelectNewInstructionModalVisible] = useState(false);
    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [attachDialogIsLoading, setAttachDialogIsLoading] = useState(true);
    const [attachDialogRecords, setAttachDialogRecords] = useState([]);
    const [attachMediaSelectedItems, setAttachMediaSelectedItems] = useState([]);
    useEffect(()=>{
        if(selectNewInstructionModalVisible === false) return;
        load_dialog_records();
        setAttachMediaSelectedItems([]);
    }, [selectNewInstructionModalVisible, searchBoxValue]);
    const load_dialog_records = () => {
        get_ajax("ExpertSSM/InstructionsManager/GetInstructions?search=" + searchBoxValue + "&page=0", (response) =>{
            setAttachDialogRecords(response);
            setAttachDialogIsLoading(false);
        });
    }
    const addSelectedPages = () => {
        const pagesCpy = [...materials];
        attachMediaSelectedItems.forEach(record => {
            pagesCpy.push({
                pageId: record.id,
                pageName: record.originalName
            });
        });
        setMaterials(pagesCpy);
    }

    const [groupDetailsIsEdit, setGroupDetailsIsEdit] = useState(false);
    const saveGroupDetails = () => {

    }

    return (
        <>
            <div className='row'>
                <div className='col-md-4'>
                    <RoundRedirectButton 
                        className="custom-btn-primary"
                        text="Inapoi"
                        redirectUrl={'/expertssm/instructions_groups'}
                    />
                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-md-4'>
                    <div data-aos="flip-up">
                        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                            <div className='row'>
                                <div className='col-md-7'>
                                    <h4>Detalii grup materiale</h4>
                                    <p>This should be used to group additional materials based on 'loc de munca', 'compartiment', in order to allow the manager to add additional materials to a specific user</p>
                                </div>
                                <div className='col-md-5'>
                                    {!groupDetailsIsEdit && (
                                        <div className="float-right">
                                            <FiEdit2 onClick={()=>{setGroupDetailsIsEdit(true);}}/>                                
                                        </div>
                                    )}
                                    {groupDetailsIsEdit && (
                                        <div className='row'>
                                            <RoundButton
                                                className="custom-btn-primary"
                                                onClickHandler={()=>{ saveGroupDetails(); }}
                                                text="Salveaza"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <br/>
                        </LayoutCard>
                    </div>
                </div>
            </div>



            <Modal isOpen={selectNewInstructionModalVisible} toggle={() => {setSelectNewInstructionModalVisible(false)}} size={'xl'}>
                <ModalHeader>Alege materiale</ModalHeader>
                <ModalBody>
                    <SearchBox 
                        value={searchBoxValue} 
                        placeholder="Cauta" 
                        onChange={(e) =>{ setSearchBoxValue(e.target.value); }}
                    />
                    <br/>
                    {attachDialogIsLoading && (<LoadingCircleComponent/>)}
                    {!attachDialogIsLoading && (
                        <>
                            <div className='row'>
                                {attachDialogRecords.map((rec, i)=> 
                                    <div className='col-md-4' key={i}>
                                        <LayoutCard 
                                            className={
                                                attachMediaSelectedItems.indexOf(rec) !== -1 ?
                                                    "layout_card1 shadow-sm no_padding maxHeight top_bottom inner-border-primary":
                                                    "layout_card1 shadow-sm no_padding maxHeight top_bottom inner-border-card1"}
                                            onClick={()=>{
                                                const cpy = [...attachMediaSelectedItems];
                                                const indexOf = attachMediaSelectedItems.indexOf(rec);
                                                if(indexOf !== -1)
                                                    cpy.splice(indexOf, 1);
                                                else
                                                    cpy.push(rec);
                                                setAttachMediaSelectedItems(cpy);
                                            }}
                                        >
                                            <MdIntegrationInstructions
                                                size={52} 
                                                style={{margin: 'auto', display: 'block', marginTop: '40px'}}
                                            />
                                            <p 
                                                style={{
                                                    textAlign: 'center',
                                                    marginBottom: '0',
                                                    whiteSpace: 'normal'
                                                }}
                                                className='padded_bottom'
                                            >
                                                {rec.originalName}
                                            </p>
                                        </LayoutCard>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="no-outline" onClick={() => { setSelectNewInstructionModalVisible(false); }}>Anuleaza</Button>
                    <Button color="primary" className="no-outline" onClick={() => { addSelectedPages(); setSelectNewInstructionModalVisible(false); }}>Adauga</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}