import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {SearchBox} from 'shared/dist/components/generic-building-blocks/SearchBox';
import {RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import {get, get_ajax, post, parseTrueOrErrorMessage} from 'shared/dist/utils/DataProvider';
import {Redirect} from 'react-router-dom';
import {FiEdit2} from 'react-icons/fi';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {FormGroup, FormGroupArea} from 'shared/dist/components/generic-building-blocks/FormGroup';
import { CardBody, CardHeader, Collapse, Card, UncontrolledTooltip } from 'reactstrap';
import {BsFillPersonFill, BsBuilding} from 'react-icons/bs';
import {VscOpenPreview} from 'react-icons/vsc';
import {AiFillWarning} from 'react-icons/ai';
import {FaFileMedical} from 'react-icons/fa';
import {CgDanger} from 'react-icons/cg';
import {IfYesDetails, YesNoDetails, YesNoDetailsArea } from 'shared/dist/components/generic-building-blocks/YesNoDetails';
import {YesNoPicker} from 'shared/dist/components/generic-building-blocks/YesNoPicker';
import {Checkbox} from 'shared/dist/components/generic-building-blocks/Checkbox';
import {conditiiMuncaDefaults, default_risks_macheta, default_risk_file, riscuriDefaults} from '../medical-control/defaultOptions';
import {DynamicTable } from 'shared/dist/components/generic-building-blocks/DynamicTable';
import { ConfirmationDialog, CustomResourceModal, UpdateResultSuccessDialog} from 'shared/dist/components/generic-building-blocks/UserDialogs';
import Autocomplete from 'shared/dist/components/generic-building-blocks/AutocompleteTextbox';
import {FaUser}from 'react-icons/fa';
import {Modal, ModalHeader, ModalFooter, ModalBody, Button} from 'reactstrap';
import AOS from 'aos';
import "aos/dist/aos.css";
import { FisaFactoriRisc, MediaControlRequestRender } from './shared/MedicalControlRequestRender';
import { EmployeeNew_FactoriRisc } from './Workers';
import { MedicalAptitudiniFileViewer, MedicalAptitudiniFileViewerManual } from './shared/MedicalAptitudiniFileViewer';
import { printPage, saveAsPdf, SaveRawHtmlAsPdf } from 'shared/dist/utils/PdfBuilder';

function isEmptyOrSpaces(str){
    try { return str === null || str.match(/^ *$/) !== null; }
    catch { return true; }
}

export const EmployeeDetails = (props) =>{    
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    
    const [employeeName, setEmployeeName] = useState('');
    const [employeeDetails, setEmployeeDetails] = useState(undefined);

    useEffect(()=>{
        AOS.init();     
        loadEmployeeDetails(); 
    }, []);

    const loadEmployeeDetails = () =>{
        get_ajax("EmployeeManagement/Employees/GetEmployeeDetails?employeeId=" + props.match.params.employee_id, (response) => {                        
            setEmployeeName(response.nume + " " + response.prenume);
            setEmployeeDetails(response);
        }); 
    }    

    const [isFisaRiscuriDialogOpen, setIsFisaRiscuriDialogOpen] = useState(false);
    const [isFisaAptitudiniDialogOpen, setIsFisaAptitudiniDialogOpen] = useState(false);
    const [isMedicalControlRequesterDialogOpen, setIsMedicalControlRequesterDialogOpen] = useState(false);

    const [isFisaRiscuriNotificationDialogOpen, setIsFisaRiscuriNotificationDialogOpen] = useState(false);
    const [isMedicalControlNotificationDialogOpen, setIsMedicalControlNotificationDialogOpen] = useState(false);

    const [confirmationNextFunction, setConfirmationNextFunction] = useState(undefined);

    return (
        <>
            <h4><Link className="redirectNavigationLink" to='/medical-control/workers'>Lucratori</Link> &gt; {employeeName}</h4>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <DateIdentificareContainer
                        dateIdentificare={employeeDetails}
                        reloadCallback={()=>{
                            loadEmployeeDetails(); 
                            setConfirmationNextFunction('fisa_riscuri_notification');
                            setIsConfirmationDialogOpen(true);
                        }}
                    />
                </div>
                <div className='col-md-6'>
                    <WorkplaceContainer
                        dateIdentificare={employeeDetails}
                        reloadCallback={()=>{
                            loadEmployeeDetails(); 
                            setConfirmationNextFunction('fisa_riscuri_notification');
                            setIsConfirmationDialogOpen(true);
                        }}
                    />                    
                </div>
            </div>
            {employeeDetails !== undefined && employeeDetails.unitateId !== '' && employeeDetails.subunitateId !== '' && (
                <div className='row'>
                    <div className='col-md-3'>                
                        <LayoutCard 
                            className="layout_card_25bottom layout_card_padded shadow-sm layout_card2"
                            onClick={()=>{setIsFisaRiscuriDialogOpen(true)}}
                        >
                            <h6 style={{textAlign: 'center', marginBottom: '0'}}>Fisa riscuri</h6>
                        </LayoutCard>                    
                    </div>
                    <div className='col-md-3'>
                        <LayoutCard className="layout_card1 layout_card_25bottom layout_card_padded shadow-sm layout_card_primary"
                            onClick={()=>{setIsMedicalControlRequesterDialogOpen(true)}}
                        >
                            <h6 style={{textAlign: 'center', marginBottom: '0'}}>Solicita control</h6>
                        </LayoutCard>
                    </div>    
                    <div className='col-md-3'></div>                
                    <div className='col-md-3'>
                        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm layout_card2"
                            onClick={()=>{setIsFisaAptitudiniDialogOpen(true)}}
                        >
                            <h6 style={{textAlign: 'center', marginBottom: '0'}}>Fisa aptitudini</h6>
                        </LayoutCard>
                    </div>            
                </div>
            )}                 
            <UpdateResultSuccessDialog 
                isOpen={isConfirmationDialogOpen} 
                toggle={() => {
                    setIsConfirmationDialogOpen(false); 
                    if(confirmationNextFunction === 'fisa_riscuri_notification') {
                        setIsFisaRiscuriNotificationDialogOpen(true);
                    }
                    if(confirmationNextFunction === 'medical_control_notification') {
                        setIsMedicalControlNotificationDialogOpen(true);
                    }
                    loadEmployeeDetails();
                }} 
                onConfirm={() => {
                    setIsConfirmationDialogOpen(false);
                    if(confirmationNextFunction === 'fisa_riscuri_notification') {
                        setIsFisaRiscuriNotificationDialogOpen(true);
                    }
                    if(confirmationNextFunction === 'medical_control_notification') {
                        setIsMedicalControlNotificationDialogOpen(true);
                    }
                    loadEmployeeDetails();
                }}
            />
            {employeeDetails !== undefined && (
                <>
                    <FisaRiscuriViewer                            
                        isOpen={isFisaRiscuriDialogOpen}
                        toggle={() => {setIsFisaRiscuriDialogOpen(!isFisaRiscuriDialogOpen); }}
                        employeeId={employeeDetails.id}
                        reloadCallback={() => {
                            setIsFisaRiscuriDialogOpen(false); 
                            setConfirmationNextFunction('medical_control_notification');
                            setIsConfirmationDialogOpen(true); 
                        }}
                        toggleReload={() => {setIsFisaRiscuriDialogOpen(false); setTimeout(()=>{setIsFisaRiscuriDialogOpen(true);}, 1000)}}
                    />
                    <FisaAptitudiniViewer
                        isOpen={isFisaAptitudiniDialogOpen}
                        toggle={() => {setIsFisaAptitudiniDialogOpen(!isFisaAptitudiniDialogOpen)}}
                        employeeId={employeeDetails.id}
                    />
                    <MedicalControlRequester
                        isOpen={isMedicalControlRequesterDialogOpen}
                        toggle={() => {setIsMedicalControlRequesterDialogOpen(!isMedicalControlRequesterDialogOpen)}}
                        employeeId={employeeDetails.id}
                    />
                </>
            )}  
            <UpdateRisksFileNotification
                isOpen={isFisaRiscuriNotificationDialogOpen}
                toggle={()=>{
                    setConfirmationNextFunction(undefined);
                    setIsFisaRiscuriNotificationDialogOpen(!isFisaRiscuriNotificationDialogOpen);
                }}
                openRisksFile={()=>{
                    setConfirmationNextFunction(undefined);
                    setIsFisaRiscuriNotificationDialogOpen(!isFisaRiscuriNotificationDialogOpen);
                    setIsFisaRiscuriDialogOpen(true); 
                }}
            />   
            <RequestMedicalControlNotification
                isOpen={isMedicalControlNotificationDialogOpen}
                toggle={()=>{
                    setConfirmationNextFunction(undefined);
                    setIsMedicalControlNotificationDialogOpen(!isMedicalControlNotificationDialogOpen);
                }}
                requestMedicalControl={()=>{
                    setConfirmationNextFunction(undefined);
                    setIsMedicalControlNotificationDialogOpen(!isMedicalControlNotificationDialogOpen);
                    setIsMedicalControlRequesterDialogOpen(true); 
                }}
            />       
        </>
    );
}

export const DateIdentificareContainer = ({dateIdentificare, reloadCallback, canEdit=true}) => {

    function parse_server_date(date_string){    
        const parts = date_string.split('-');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }

    const [isEditEnabled, setIsEditEnabled] = useState(false);

    const [startedValidation, setStartedValidation] = useState(false);

    const[nume, setNume] = useState('');
    const[prenume, setPrenume] = useState('');
    const[dataNasterii, setDataNasterii] = useState(undefined);
    const[adresa, setAdresa] = useState('');
    const[cnp, setCnp] = useState('');    
    const[telefon, setTelefon] = useState('');

    const [numeValidationError, setNumeValidationError] = useState('');
    const [prenumeValidationError, setPrenumeValidationError] = useState('');
    const [dataNasteriiValidationError, setDataNasteriiValidationError] = useState('');
    const [adresaValidationError, setAdresaValidationError] = useState('');
    const [cnpValidationError, setCnpValidationError] = useState('');    

    useEffect(()=>{
        if(dateIdentificare === undefined) return;

        setNume(dateIdentificare.nume);
        setPrenume(dateIdentificare.prenume);                
        setDataNasterii(parse_server_date(dateIdentificare.dataNastere));
        setAdresa(dateIdentificare.adresa);
        setCnp(dateIdentificare.cnp);
        setTelefon(dateIdentificare.phoneNumber);
    },[dateIdentificare]);    

    const save_employee_data = () =>{
        let isOkay = true;
        if(isEmptyOrSpaces(nume)){
            isOkay = false;
            setNumeValidationError("Numele este obligatoriu");
        }else{setNumeValidationError('');}
        if(isEmptyOrSpaces(prenume)){
            isOkay = false;
            setPrenumeValidationError("Prenumele este obligatoriu");
        }else{setPrenumeValidationError('');}
        if(isEmptyOrSpaces(dataNasterii)){
            isOkay = false;
            setDataNasteriiValidationError("Data nasterii este obligatorie");
        }else{setDataNasteriiValidationError('');}
        if(isEmptyOrSpaces(adresa)){
            isOkay = false;
            setAdresaValidationError("Adresa este obligatorie");
        }else{setAdresaValidationError('');}
        if(isEmptyOrSpaces(cnp)){
            isOkay = false;
            setCnpValidationError("CNP-ul este obligatoriu");
        }
        else if(cnp.length != 13){
            isOkay = false;
            setCnpValidationError("CNP-ul nu este valid");
        }else{setCnpValidationError('');}
        setStartedValidation(true);
            
        if(!isOkay) 
            return false;        
        
        post('EmployeeManagement/Employees/UpdateEmployeeDetails', {
            'employeeId': dateIdentificare.id, 
            'nume': nume, 
            'prenume': prenume, 
            'adresa': adresa, 
            'cnp': cnp, 
            'dataNastere': dataNasterii,
            'phoneNumber': telefon
        }, (response) =>{ 
            parseTrueOrErrorMessage(response, (response) => {
                setIsEditEnabled(false);
                reloadCallback();
            });
        });        
    }

    return(
        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
            <div className='row'>
                <div className='col-md-7'>
                    <h4 style={{display: 'inline'}}>Date identificare</h4>
                </div>
                <div className='col-md-5'>
                    {canEdit && (
                        <>
                            {!isEditEnabled && (
                            <div className="float-right">
                                <FiEdit2 onClick={()=>{setIsEditEnabled(true);}}/>                                
                            </div>
                            )}  
                            {isEditEnabled && (
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <RoundButton
                                            className="btn-secondary"
                                            onClickHandler={()=>{ setIsEditEnabled(false); }}
                                            text="Anuleaza"
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <RoundButton
                                            className="custom-btn-primary"
                                            onClickHandler={()=>{save_employee_data()}}
                                            text="Salveaza"
                                        />
                                    </div>
                                </div>
                            )} 
                        </>
                    )}
                </div>
            </div>
            <br/>
            {dateIdentificare === undefined && (
                <LoadingCircleComponent/>
            )}
            {dateIdentificare !== undefined && (
                <>
                    <FormGroup 
                        label={'Nume'}
                        value={nume}
                        onChange={(e) =>{ setNume(e.target.value); }}     
                        disabled={!isEditEnabled}      
                        use_validation={startedValidation}
                        is_valid = {numeValidationError === ''}
                        invalid_feedback = {numeValidationError}                   
                    />
                    <FormGroup 
                        label={'Prenume'}
                        value={prenume}
                        onChange={(e) =>{ setPrenume(e.target.value); }}                            
                        disabled={!isEditEnabled}  
                        use_validation={startedValidation}
                        is_valid = {prenumeValidationError == ''}
                        invalid_feedback = {prenumeValidationError}                         
                    />
                    <FormGroup 
                        label={'Data nasterii'} 
                        type='date'
                        value={dataNasterii}
                        onChange={(e) =>{ setDataNasterii(e.target.value); }}                            
                        disabled={!isEditEnabled}     
                        use_validation={startedValidation}
                        is_valid = {dataNasteriiValidationError == ''}
                        invalid_feedback = {dataNasteriiValidationError}                        
                    />                  
                    <FormGroup 
                        label={'Adresa'}
                        value={adresa}    
                        onChange={(e) =>{ setAdresa(e.target.value); }}                            
                        disabled={!isEditEnabled}    
                        use_validation={startedValidation}
                        is_valid = {adresaValidationError == ''}
                        invalid_feedback = {adresaValidationError}                    
                    />
                    <FormGroup 
                        label={'Cnp'}
                        value={cnp}                    
                        disabled={!isEditEnabled}                       
                        onChange={(e) => { 
                            const value = e.target.value;
                            if(value.length != 0)
                                if(/^[0-9]+$/.test(value) == false) return;
                            if(value.length > 13) return;
                                setCnp(value);
                        }}    
                        use_validation={startedValidation}
                        is_valid = {cnpValidationError == ''}
                        invalid_feedback = {cnpValidationError}                              
                    />
                    <FormGroup 
                        label={'Numar telefon'}
                        value={telefon}    
                        onChange={(e) =>{ setTelefon(e.target.value); }}                            
                        disabled={!isEditEnabled}    
                    />
                </>
            )}
        </LayoutCard>
    )
}

export const WorkplaceContainer = ({dateIdentificare, reloadCallback, canEdit=true, endpoint = "EmployeeManagement"}) => {

    function inArray(target, array)
    {    
        for(var i = 0; i < array.length; i++) 
        if(array[i].toLowerCase() === target.toLowerCase())
                return true;   
        return false; 
    }

    const [isEditEnabled, setIsEditEnabled] = useState(false);

    const [unitati, setUnitati] = useState([]);
    const [selectedUnitateId, setSelectedUnitateId] = useState('');

    const [subunitati, setSubunitati] = useState([]);
    const [selectedSubunitatiId, setSelectedSubunitatiId] = useState('');

    const [profesie, setProfesie] = useState('');
    const [functie, setFunctie] = useState('')

    const [locMunca, setLocMunca] = useState(undefined);
    const [sectie, setSectie] = useState(undefined);

    useEffect(()=>{
        get_ajax(`${endpoint}/Employees/GetAngajatori`, (response) => {
            setUnitati(response);
        });
        get_ajax(`${endpoint}/Employees/GetSubunitati`, (response) => {                        
            setSubunitati(response);               
        });
    }, []);

    useEffect(()=>{
        if(dateIdentificare === undefined) return;
        setSelectedUnitateId(dateIdentificare.unitateId);
        setSelectedSubunitatiId(dateIdentificare.subunitateId);
        
        setProfesie(dateIdentificare.solicitareProfesie);
        setFunctie(dateIdentificare.solicitareFunctie);

        setLocMunca(dateIdentificare.locMunca);        
        setSectie(dateIdentificare.sectieDepartament);        
    }, [dateIdentificare]);    

    const [rldtrg, setRldtrg] = useState(false);
    useEffect(() => {
        setRldtrg(true);
    }, [locMunca, sectie]);
    useEffect(()=>{
        if(rldtrg === true)
            setRldtrg(false);
    }, [rldtrg]);

    const [compartimenteAutocompleteOptions, setCompartimenteAutocompleteOptions] = useState([]);
    const [locuriMuncaAutocompleteOptions, setLocuriMuncaAutocompleteOptions] = useState([]);

    useEffect(()=>{
        get_ajax(`${endpoint}/Employees/GetCompartimenteAutocompleteList`, (response) => {
            setCompartimenteAutocompleteOptions(response);
        });
        get_ajax(`${endpoint}/Employees/GetLocuriMuncaAutocompleteList`, (response) => {
            setLocuriMuncaAutocompleteOptions(response);
        });
    }, []);

    const save_employee_data = () => {
        post('EmployeeManagement/Employees/UpdateEmployeeWorkplaceData', {
            'employeeId': dateIdentificare.id, 
            'unitateId': selectedUnitateId, 
            'subunitateId': selectedSubunitatiId, 
            'locMunca': locMunca, 
            'sectieDepartament': sectie, 
            'functie': functie, 
            'profesie': profesie
        }, (response) =>{
            parseTrueOrErrorMessage(response, (response) => {
                setIsEditEnabled(false);
                reloadCallback();            
            });
        });        
    }

    return(
        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
            <div className='row'>
                <div className='col-md-7'>
                    <h4 style={{display: 'inline'}}>Loc munca</h4>
                </div>
                <div className='col-md-5'>
                    {canEdit && (
                        <>
                            {!isEditEnabled && (
                                <div className="float-right">
                                    <FiEdit2 onClick={()=>{setIsEditEnabled(true);}}/>                                
                                </div>
                            )}  
                            {isEditEnabled && (
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <RoundButton
                                            className="btn-secondary"
                                            onClickHandler={()=>{ setIsEditEnabled(false); }}
                                            text="Anuleaza"
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <RoundButton
                                            className="custom-btn-primary"
                                            onClickHandler={()=>{save_employee_data()}}
                                            text="Salveaza"
                                        />
                                    </div>
                                </div>
                            )}     
                        </>
                    )}
                </div>
            </div>
            <br/>
            {dateIdentificare === undefined && (
                <LoadingCircleComponent/>
            )}
            {dateIdentificare !== undefined && (
                <>
                    <div className="form-group">
                        <label>Unitatea</label>
                        <select 
                            className="form-control no-outline rounded_input" 
                            value={selectedUnitateId}
                            disabled={!isEditEnabled}
                            onChange={(e)=>{
                                setSelectedUnitateId(e.target.value);                                
                            }}
                        >
                            <option value="null">Alege unitatea</option>
                            {unitati.map((u, i) =>
                                <option value={u.id} key={i}>{u.nume}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Subunitate</label>
                        <select 
                            className="form-control no-outline rounded_input"
                            value={selectedSubunitatiId}
                            disabled={!isEditEnabled}
                            onChange={(e)=>{
                                setSelectedSubunitatiId(e.target.value);                                
                            }}
                        >
                            <option value="null">Alege subunitatea</option>
                            {subunitati.map((s, i) =>
                                <option value={s.id} key={i}>{s.nume}</option>
                            )}
                        </select>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            {rldtrg !== true && (
                                <>
                                    {sectie !== undefined && (
                                    <div className='form-group'>
                                        <label>Sectie / departament</label>                                
                                        <Autocomplete
                                            placeholder={'Sectie / departament'}
                                            suggestions={compartimenteAutocompleteOptions}                                    
                                            disabled={true}
                                            value={sectie}
                                            onChange={(e) => setSectie(e)}
                                        />
                                    </div>
                                    )}
                                    {locMunca !== undefined && (
                                        <div className='form-group'>
                                            <label>Loc munca</label>                                    
                                            <Autocomplete
                                                placeholder={'Loc munca'}
                                                suggestions={locuriMuncaAutocompleteOptions}
                                                disabled={true}
                                                value={locMunca}
                                                onChange={(e) => setLocMunca(e)}
                                            />
                                        </div>
                                    )}  
                                </>
                            )}
                        </div>
                        <div className='col-md-6'>
                            <FormGroup
                                label={"Profesie"}
                                disabled={!isEditEnabled}
                                value={profesie}
                                onChange={(e) => {setProfesie(e.target.value)}}
                            />
                            <FormGroup
                                label={"Functie"}
                                disabled={!isEditEnabled}
                                value={functie}
                                onChange={(e) => {setFunctie(e.target.value)}}
                            />
                        </div>
                    </div>
                    {dateIdentificare.status !== 'none' && dateIdentificare.status !== undefined && (
                        <>
                            <br/>
                            <label>Status lucrator:</label><br/>
                            <label><b>
                            {
                                dateIdentificare.status === "medical_control_pending" ? 
                                "Cerere in curs de solutionare" :
                                `Control medical planificat pentru data de ${dateIdentificare.status}`
                            }
                            </b></label>  
                        </>
                    )}                      
                </>
            )}
        </LayoutCard>
    );
}

export const FisaRiscuriViewer = ({employeeId, isOpen, toggle, toggleReload, reloadCallback, reloadEmployeeData, endpoint="EmployeeManagement", canEdit=true}) => {
    const [riskFiles, setRiskFiles] = useState([]);
    const [selectedRiskFileId, setSelectedRiskFileId] = useState(undefined);
    useEffect(()=>{
        run_reload();
    }, [isOpen]);

    const run_reload = () => {        
        get_ajax(`${endpoint}/Employees/GetRiskFilesByEmployee?employeeId=${employeeId}`, (response) => {            
            if(response.length !== 0)
            {
                setRiskFiles(response);
                setSelectedRiskFileId(response[0].id);
            }
            else{
                setIsCreate(true);
                setFisaRiscuriData(default_risks_macheta);
                setIsEditing(true);
            }
        });
    }

    const [isEditing, setIsEditing] = useState(false);
    const [fisaFactoriRiskData, setFisaFactoriRiskData] = useState(undefined);    

    const [isCreate, setIsCreate] = useState(false);

    const [fisaRiscuriData, setFisaRiscuriData] = useState(undefined);
    const updatefisaRiscuriData = (field, newValue) =>{
        const _newDataCpy = fisaRiscuriData;
        _newDataCpy[field] = newValue;
        setFisaRiscuriData(_newDataCpy);  
    }    

    useEffect(()=>{
        if(selectedRiskFileId === undefined) return;
        loadSelectedRiskFile();
    }, [selectedRiskFileId]);  
    const loadSelectedRiskFile = () => {        
        get_ajax(`${endpoint}/Employees/GetRiskFileDetails?riskFileId=${selectedRiskFileId}`, (response) => {                    
            response['employeeData'] = JSON.parse(response['employeeData']);            
            response['risksFile'] = JSON.parse(response['risksFile']);   
            console.log(response);         
            setFisaFactoriRiskData(response);
        });
    }          

    return(
        <CustomResourceModal
            size={'xl'}
            isOpen={isOpen}
            toggle={toggle}
            title={"Fise riscuri"}
        >
            <div className='row'>
                <div className='col-md-8'>                    
                    {(fisaFactoriRiskData === undefined && isEditing == false && riskFiles.length !== 0) && (<LoadingCircleComponent/>)}
                    {fisaFactoriRiskData !== undefined && (
                        <>
                        {isEditing === false && (
                            <div id="saveAsPdfContent">
                                <div style={{width: '100%', padding: '25px'}}>
                                    <FisaFactoriRisc
                                        risksFileSignature={fisaFactoriRiskData.risksFileSignature}
                                        employeeData={fisaFactoriRiskData.employeeData}
                                        medicalRequest={undefined}
                                        risksFile={fisaFactoriRiskData.risksFile}
                                        medicalRequestDate={fisaFactoriRiskData.medicalRequestDate}
                                        risksFileCreatedBy={fisaFactoriRiskData.risksFileCreatedBy}
                                        medicalRequestSignature={fisaFactoriRiskData.medicalRequestSignature}
                                    />
                                </div>
                            </div>
                        )}                        
                        </>
                    )}
                    {isEditing === true && fisaRiscuriData != undefined && (                            
                        <EmployeeNew_FactoriRisc                            
                            newUserData={fisaRiscuriData}
                            updateNewUserData={updatefisaRiscuriData}    
                            hideNavigation={true}  
                            runReload={()=>{toggleReload();}}                  
                        />
                    )}
                </div>
                <div className='col-md-4'>
                    {isEditing === false && (
                        <div className='row'>
                            {canEdit && (
                                <>
                                    <div className='col-md-6'>
                                        <RoundButton
                                            className="custom-btn-side-menu"
                                            onClickHandler={()=>{ setFisaRiscuriData(fisaFactoriRiskData.risksFile); setIsEditing(true); }}
                                            text="Modifica riscuri"
                                        />    
                                    </div>
                                    <div className='col-md-6'>
                                            <RoundButton
                                                className={"custom-btn-primary"}
                                                text="Salveaza PDF"
                                                onClickHandler={()=>{
                                                    printPage(`/Utils/PdfGenerator/FisaFactoriRisc?riskFileId=${selectedRiskFileId}`);
                                                    //saveAsPdf(`fisa_riscuri_${fisaFactoriRiskData.employeeData.nume}_${fisaFactoriRiskData.employeeData.prenume}`);
                                                }}
                                            />
                                    </div>
                                </>
                            )}
                        </div>      
                    )}   
                    {isEditing && (
                        <div className='row'>
                            <div className='col-md-6'>
                            <RoundButton
                                disabled={isCreate}
                                className="btn-secondary"
                                onClickHandler={()=>{  setIsEditing(false); loadSelectedRiskFile(); }}
                                text="Anuleaza"
                            />                        
                            </div>
                            <div className='col-md-6'>
                                <RoundButton
                                    className="custom-btn-primary"
                                    onClickHandler={()=>{ 
                                        setIsEditing(false);
                                        post('EmployeeManagement/Employees/UpdateRisksFile', {
                                            'employeeId': employeeId,
                                            'JSON': JSON.stringify(fisaRiscuriData)
                                        }, (response)=>{
                                            parseTrueOrErrorMessage(response, (response) => {
                                                reloadCallback();
                                            });
                                        });                                        
                                    }}
                                    text="Salveaza"
                                />
                            </div>
                        </div>
                    )}                 
                    <br/>                              
                    <h4>Istoric</h4>
                    {riskFiles.length === 0 && (
                        <p style={{textAlign: 'center'}}>Nu exista istoric pentru fisa de riscuri</p>
                    )}
                    <ul className="list-group">
                        {riskFiles.map((r, i) => 
                            <li 
                                key={i}
                                className={r.id == selectedRiskFileId ? "list-group-item active_side_menu" : "list-group-item"}
                                style={{cursor: 'pointer'}}
                                onClick={()=>{ setSelectedRiskFileId(r.id);}}
                            >
                                {r.createdAt}
                            </li>                      
                        )}  
                    </ul>
                </div>
            </div>  
            <div className='row'>
                <div className='col-md-8'></div>
                <div className='col-md-4'>
                    {isEditing && (
                        <div className='row'>
                            <div className='col-md-6'>
                                               
                            </div>
                            <div className='col-md-6'>
                                <RoundButton
                                    className="custom-btn-primary"
                                    onClickHandler={()=>{ 
                                        setIsEditing(false);
                                        post('EmployeeManagement/Employees/UpdateRisksFile', {
                                            'employeeId': employeeId,
                                            'JSON': JSON.stringify(fisaRiscuriData)
                                        }, (response)=>{
                                            parseTrueOrErrorMessage(response, (response) => {
                                                reloadCallback();
                                            });
                                        });                                        
                                    }}
                                    text="Salveaza"
                                />
                            </div>
                        </div>
                    )}  
                </div>
            </div>          
        </CustomResourceModal>
    )
}

export const FisaAptitudiniViewer = ({employeeId, isOpen, toggle, endpoint="EmployeeManagement", canEdit=true}) => {
    useEffect(()=>{
        loadFiseAptitudini();
    }, []);

    const loadFiseAptitudini = () =>{
        get_ajax(`${endpoint}/Employees/GetAptitudiniFiles?employeeId=${employeeId}`, (response) => {            
            setAptitudiniFiles(response);
            if(response.length > 0)
                setSelectedAptitudineFileId(response[0].id);         
        });
    }

    const [selectedAptitudineFileId, setSelectedAptitudineFileId] = useState(-1);
    const [aptitudiniFiles, setAptitudiniFiles] = useState([]);

    useEffect(()=>{
        if(selectedAptitudineFileId == -1) return;
        get_ajax(`${endpoint}/Employees/GetAptitudiniFileDetails?aptitudiniFileId=${selectedAptitudineFileId}`, (response) => {                        
            response.data = JSON.parse(response.data);            
            setFisaAptitudini(response);
        });
    }, [selectedAptitudineFileId]);
    const [fisaAptitudini, setFisaAptitudini] = useState(undefined);

    const [isEditing, setIsEditing] = useState(false);
    const [newFisaAptitudiniData, setNewFisaAptitudiniData] = useState({});
    const updateNewFisaAptitudiniData = (key, value) =>{
        const cpy = newFisaAptitudiniData;
        cpy[key] = value;
        setNewFisaAptitudiniData(cpy);
    }

    return(
        <CustomResourceModal
            size={'xl'}
            isOpen={isOpen}
            toggle={toggle}
            title={"Fise aptitudini"}
        >
            <div className='row'>
                <div className='col-md-8'>                    
                    {isEditing == false && (
                        <>
                            {(fisaAptitudini == undefined && aptitudiniFiles.length !== 0)  && (<LoadingCircleComponent/>)}
                            {fisaAptitudini !== undefined && (
                                <>
                                    <div className='row'>
                                        <div className='col-md-8'></div>
                                        <div className='col-md-4'>
                                            <RoundButton
                                                className={"custom-btn-primary"}
                                                text="Salveaza PDF"
                                                onClickHandler={
                                                    ()=>{
                                                        // post("/Utils/PdfGenerator/FisaAptitudini", {
                                                        //     aptitudiniFileId: selectedAptitudineFileId
                                                        // }, (html) => {                                                            
                                                        //     SaveRawHtmlAsPdf(html, "fisa_aptitudini");
                                                        // });

                                                        printPage(`/Utils/PdfGenerator/FisaAptitudini?aptitudiniFileId=${selectedAptitudineFileId}`);
                                                    }    
                                                }
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div id="saveAsPdfContent">
                                    <div style={{width: '100%', padding: '25px'}}>
                                    {fisaAptitudini.manualAdded == false && (
                                        <MedicalAptitudiniFileViewer
                                            doctorSignature={fisaAptitudini.doctorSignature}
                                            signatureDetails={fisaAptitudini.data.clinicData}
                                            data={fisaAptitudini.data}
                                        />
                                    )}
                                    {fisaAptitudini.manualAdded && (
                                        <MedicalAptitudiniFileViewerManual                                            
                                            data={fisaAptitudini.data}
                                        />
                                    )}
                                    </div>
                                    </div>
                                </>                                
                            )}
                        </>
                    )}
                    {isEditing && (
                        <FisaAptitudiniHistoryInput
                            updateData={updateNewFisaAptitudiniData}
                        />
                    )}
                </div>
                {true && (
                    <>
                        <div className='col-md-4'>
                            {canEdit && (<>
                                {isEditing === false && (
                                <RoundButton
                                    className="custom-btn-side-menu"
                                    onClickHandler={()=>{ setIsEditing(true); }}
                                    text="Adauga istoric"
                                />          
                            )} 
                            </>)}
                            {isEditing && (
                                <div className='row'>
                                    <div className='col-md-6'>
                                    <RoundButton                                
                                        className="btn-secondary"
                                        onClickHandler={()=>{  setIsEditing(false); }}
                                        text="Anuleaza"
                                    />                        
                                    </div>
                                    <div className='col-md-6'>
                                        <RoundButton
                                            className="custom-btn-primary"
                                            onClickHandler={()=>{ 
                                                setIsEditing(false);
                                                post('EmployeeManagement/Employees/AddFisaAptitudiniHistoryRecord', {
                                                    'employeeId': employeeId,                                        
                                                    'apt': newFisaAptitudiniData['apt'], 
                                                    'aptCond': newFisaAptitudiniData['aptConditionat'], 
                                                    'inaptTemp': newFisaAptitudiniData['inaptTemporar'], 
                                                    'inapt': newFisaAptitudiniData['inapt'], 
                                                    'recomandari': newFisaAptitudiniData['recomandari'], 
                                                    'dataExamenUrmator': newFisaAptitudiniData['dataExamenUrmator'], 
                                                    'nrReg': newFisaAptitudiniData['numarInregistrare'], 
                                                    'dataExaminare': newFisaAptitudiniData['dataEmitere'], 
                                                    'medicEmitere': newFisaAptitudiniData['medicEmitere']
                                                }, (response)=>{
                                                    parseTrueOrErrorMessage(response, (response) => {
                                                        setNewFisaAptitudiniData({});                              
                                                        loadFiseAptitudini();
                                                    });                                                    
                                                });                                        
                                            }}
                                            text="Salveaza"
                                        />
                                    </div>
                                </div>
                            )}    
                            <br/> 
                            <h4>Istoric</h4>
                            {aptitudiniFiles.length === 0 && (
                                <p style={{textAlign: 'center'}}>Nu exista istoric pentru fisa de riscuri</p>
                            )}
                            <ul className="list-group">
                                {aptitudiniFiles.map((r, i) => 
                                    <li 
                                        key={i}
                                        className={r.id == selectedAptitudineFileId ? "list-group-item active_side_menu" : "list-group-item"}
                                        style={{cursor: 'pointer'}}
                                        onClick={()=>{ setSelectedAptitudineFileId(r.id);}}
                                    >
                                        {r.createdAt}
                                    </li>                      
                                )}  
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </CustomResourceModal>
    );
}

const FisaAptitudiniHistoryInput = ({updateData}) => {
    
    const [apt, setApt] = useState(false);
    useEffect(()=>{        
        updateData('apt',apt);
    }, [apt]);
    const [aptConditionat, setAptConditionat] = useState(false);
    useEffect(()=>{        
        updateData('aptConditionat',aptConditionat);
    }, [aptConditionat]);
    const [inaptTemporar, setInaptTemporar] = useState(false);
    useEffect(()=>{        
        updateData('inaptTemporar',inaptTemporar);
    }, [inaptTemporar]);
    const [inapt, setInapt] = useState(false);
    useEffect(()=>{        
        updateData('inapt',inapt);
    }, [inapt]);

    const [recomandari, setRecomandari] = useState('');
    useEffect(()=>{        
        updateData('recomandari',recomandari);
    }, [recomandari]);
    const [dataExamenUrmator, setDataExamenUrmator] = useState('');
    useEffect(()=>{        
        updateData('dataExamenUrmator',dataExamenUrmator);
    }, [dataExamenUrmator]);

    const [dataEmitere, setDataEmitere] = useState('');
    useEffect(()=>{        
        updateData('dataEmitere',dataEmitere);
    }, [dataEmitere]);
    const [numarInregistrare, setNumarInregistrare] = useState('');
    useEffect(()=>{        
        updateData('numarInregistrare',numarInregistrare);
    }, [numarInregistrare]);
    const [medicEmitere, setMedicEmitere] = useState('');
    useEffect(()=>{        
        updateData('medicEmitere',medicEmitere);
    }, [medicEmitere]);

    return(
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <Checkbox
                        checkLabel={'Apt'}
                        value={apt}
                            setValue={setApt}
                    />
                    <Checkbox
                        checkLabel={'Apt conditionat'}
                        value={aptConditionat}
                        setValue={setAptConditionat}
                    />
                    <Checkbox
                        checkLabel={'Inapt temporar'}
                        value={inaptTemporar}
                        setValue={setInaptTemporar}
                    />
                    <Checkbox
                        checkLabel={'Inapt'}
                        value={inapt}
                        setValue={setInapt}
                    />
                    <FormGroupArea
                        label={'Recomandari'}
                        rows={5}
                        value={recomandari}
                        onChange={(e) => setRecomandari(e.target.value)}
                    />
                    <FormGroup
                        label={'Data urmatorului examen medical'}
                        type='date'
                        value={dataExamenUrmator}
                        onChange={(e) => setDataExamenUrmator(e.target.value)}
                    />
                </div>
                <div className='col-md-6'>
                    <FormGroup
                        label={'Data emitere'}
                        type='date'
                        value={dataEmitere}
                        onChange={(e) => setDataEmitere(e.target.value)}
                    />
                    <FormGroup
                        label={'Numar inregistrare'}
                        type={'text'}
                        value={numarInregistrare}
                        onChange={(e) => {setNumarInregistrare(e.target.value)}}
                    />
                    <FormGroup
                        label={'Medic SSM'}                        
                        value={medicEmitere}
                        onChange={(e) => {setMedicEmitere(e.target.value)}}
                    />
                </div>
            </div>     
        </>
    );
}

export const MedicalControlRequester = ({employeeId, isOpen, toggle}) => {
    const [angajare, setAngajare] = useState(false);
    const [controlMedicalPeriodic, setControlMedicalPeriodic] = useState(false);
    const [adaptare, setAdaptare] = useState(false);
    const [reluareaMuncii, setReluareaMuncii] = useState(false);
    const [supraveghereSpeciala, setSupraveghereSpeciala] = useState(false);
    const [laCerere, setLaCerere] = useState(false);
    const [schimbareaLoculuiDeMunca, setSchimbareaLoculuiDeMunca] = useState(false);
    const [altele, setAltele] = useState(null);

    const [fisaRiscuriCollapseOpen, setIsFisaRiscuriCollapseOpen] = useState(false);
    const [fisaRiscuri, setFisaRiscuri] = useState(undefined);

    useEffect(()=>{
        if(isOpen === false) return;
        get_ajax("EmployeeManagement/Employees/GetRiskFilesByEmployee?employeeId=" + employeeId, (response_arr) => {                        
            if(response_arr.length !== 0)
            {
                get_ajax("EmployeeManagement/Employees/GetRiskFileDetails?riskFileId=" + response_arr[0].id, (response) => {                    
                    response['employeeData'] = JSON.parse(response['employeeData']);            
                    response['risksFile'] = JSON.parse(response['risksFile']);            
                    setFisaRiscuri(response);
                });
            }
        });
    }, [isOpen]);

    return(
        <CustomResourceModal
            size={'xl'}
            isOpen={isOpen}
            toggle={toggle}
            title={"Solicita control medical"}
        >
            <div className="row">
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Angajare"
                        value={angajare}
                        setValue={setAngajare}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Control medical periodic"                        
                        value={controlMedicalPeriodic}
                        setValue={setControlMedicalPeriodic}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Adaptare"
                        value={adaptare}
                        setValue={setAdaptare}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Reluarea muncii"
                        value={reluareaMuncii}
                        setValue={setReluareaMuncii}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Supraveghere speciala"
                        value={supraveghereSpeciala}
                        setValue={setSupraveghereSpeciala}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="La cerere"
                        value={laCerere}
                        setValue={setLaCerere}
                    />
                </div>
                <div className="col-md-3">
                    <Checkbox
                        checkLabel="Schimbarea locului de munca"
                        value={schimbareaLoculuiDeMunca}
                        setValue={setSchimbareaLoculuiDeMunca}
                    />
                </div>
                <div className="col-md-3">
                    <IfYesDetails
                        checkLabel="Altele"
                        optionLabel={'Altele'}
                        value={altele}
                        setValue={setAltele}
                    />
                </div>
            </div> 
            <br/>
            <Card>
                <CardHeader style={{cursor: 'pointer'}} onClick={()=>{setIsFisaRiscuriCollapseOpen(!fisaRiscuriCollapseOpen)}}>Fisa factori risc</CardHeader>
                <Collapse isOpen={fisaRiscuriCollapseOpen}>
                    <CardBody>
                        {fisaRiscuri !== undefined && (
                            <FisaFactoriRisc
                                risksFileSignature={fisaRiscuri.risksFileSignature}
                                employeeData={fisaRiscuri.employeeData}
                                medicalRequest={undefined}
                                risksFile={fisaRiscuri.risksFile}
                                medicalRequestDate={fisaRiscuri.medicalRequestDate}
                                risksFileCreatedBy={fisaRiscuri.risksFileCreatedBy}
                                medicalRequestSignature={fisaRiscuri.medicalRequestSignature}
                            />
                        )}                        
                    </CardBody>
                </Collapse>
            </Card>
            <br/>
            <RoundButton
                disabled={fisaRiscuri === undefined}
                className="custom-btn-primary"
                onClickHandler={()=>{ 
                    post('EmployeeManagement/Employees/RequestControlMedical', {
                        'employeeId': employeeId,                                        
                        'angajare': angajare, 
                        'controlMedicalPeriodic': controlMedicalPeriodic, 
                        'adaptare': adaptare, 
                        'reluareaMuncii': reluareaMuncii, 
                        'supraveghereSpeciala': supraveghereSpeciala, 
                        'laCerere': laCerere, 
                        'schimbareaLoculuiDeMunca': schimbareaLoculuiDeMunca, 
                        'altele': altele
                    }, (response)=>{
                        parseTrueOrErrorMessage(response, (response) => {
                            window.location.reload();
                        });
                    }); 
                }}
                text="Solicita control medical"
            /> 
        </CustomResourceModal>
    )
}

export const UpdateRisksFileNotification = ({isOpen, toggle, openRisksFile}) => {
    return(
        <Modal isOpen={isOpen} toggle={toggle} size={'md'}>
            <ModalHeader>Actiuni necesare</ModalHeader>
            <ModalBody>
                <RoundButton
                    className="custom-btn-primary"
                    text="Actualizare fisa riscuri"
                    onClickHandler={()=>{ openRisksFile(); }}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={toggle}>Inchide</Button>                
            </ModalFooter>
        </Modal>
    )
}

export const RequestMedicalControlNotification = ({isOpen, toggle, requestMedicalControl}) => {
    return(
        <Modal isOpen={isOpen} toggle={toggle} size={'md'}>
            <ModalHeader>Actiuni necesare</ModalHeader>
            <ModalBody>
                <RoundButton
                    className="custom-btn-primary"
                    text="Solicita control medical"
                    onClickHandler={()=>{ requestMedicalControl(); }}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={toggle}>Inchide</Button>                
            </ModalFooter>
        </Modal>
    )
}