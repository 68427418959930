import React, { useEffect, useState } from 'react';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {get_ajax, post, parseTrueOrErrorMessage} from 'shared/dist/utils/DataProvider';
import {RoundButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { CustomResourceModal } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import { FisaFactoriRisc, FisaSolicitare } from '../medical-control/shared/MedicalControlRequestRender';

export const HrManagerRequests = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const [risksFiles, setRisksFiles] = useState([]);
    useEffect(()=>{
        load_requests();
    }, []);
    const load_requests = () => {
        setIsLoading(true);
        get_ajax("HrAdmin/MedicalRequests/GetPendingValidation", (response) =>{
            setRequests(response);
            setIsLoading(false);
        });
        get_ajax("HrAdmin/MedicalRequests/GetNotValidatedRisksFile", (response) =>{
            setRisksFiles(response);
            setIsLoadingRisksChanges(false);
        });

        get_ajax("HrAdmin/MedicalRequests/GetRiskFilesWithoutControl", (response) =>{
            setRisksWithoutControl(response);
            setIsLoadingWithoutControl(false);
        });
    }

    const [risksWithoutControl, setRisksWithoutControl] = useState([]);
    const [isLoadingWithoutControl, setIsLoadingWithoutControl] = useState(true);

    const[selectedRecords, setSelectedRecords] = useState([]);
    const[selectedRiskRecords, setSelectedRiskRecords] = useState([]);

    const renderTipControl = (requestsJSON) => {
        requestsJSON = JSON.parse(requestsJSON);
        if(requestsJSON.solicitare_angajare){
            return ("Angajare");
        }
        if(requestsJSON.solicitare_control_medical_periodic){
            return ("Periodic");
        }
        if(requestsJSON.solicitare_adaptare){
            return ("Adaptare");
        }
        if(requestsJSON.solicitare_reluarea_muncii){
            return ("Reluarea muncii");
        }
        if(requestsJSON.solicitare_supraveghere_speciala){
            return("Supraveghere speciala");
        }
        if(requestsJSON.solicitare_la_cerere){
            return("La cerere");
        }
        if(requestsJSON.solicitare_schimbareaLoculuiDeMunca){
            return("Schimbarea locului de munca");
        }
        if(requestsJSON.solicitare_altele != null){
            return("Altele");
        }
        return "-";
    }

    const [openedRequestId, setOpenedRequestId] = useState(undefined);
    const [employeeData, setEmployeeData] = useState(undefined);
    const [medicalRequest, setMedicalRequest] = useState(undefined);
    const [risksFile, setRisksFile] = useState(undefined);
    const [medicalRequestDate, setMedicalRequestDate] = useState(undefined);
    const [risksFileCreatedBy, setRisksFileCreatedBy] = useState(undefined);   
    const [risksFileSignature, setRisksFileSignature] = useState(undefined);
    const [medicalRequestSignature, setMedicalRequestSignature] = useState(undefined);
    useEffect(()=>{
        if(openedRequestId === undefined) return;
        get_ajax("HrAdmin/MedicalRequests/GetRequestRenderDetails?id="+openedRequestId, (response) =>{
            setEmployeeData(JSON.parse(response.employeeData));
            setMedicalRequest(JSON.parse(response.medicalRequest));
            setRisksFile(JSON.parse(response.risksFile));
            setMedicalRequestDate(response.medicalRequestDate);
            setRisksFileCreatedBy(response.risksFileCreatedBy);      
            setRisksFileSignature(response.risksFileSignature);    
            setMedicalRequestSignature(response.medicalRequestSignature);  
        });
    }, [openedRequestId]);

    const [isLoadingRiskChanges, setIsLoadingRisksChanges] = useState(true);

    const [fisaAptitudiniSelectedWorkerId, setFisaAptitudiniSelectedWorkerId] = useState(undefined);

    return (
        <>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div className='row'>
                            <div className='col-md-7'>
                                <h4>Cereri in asteptare</h4>
                            </div>
                            <div className='col-md-5'>
                                <RoundButton 
                                    className="custom-btn-primary"
                                    text={`Valideaza selectia (${selectedRecords.length})`}
                                    onClickHandler={()=>{ 
                                        post('HrAdmin/MedicalRequests/ValidateRequests', {
                                            requestsJson: JSON.stringify(selectedRecords)
                                        }, (response)=>{
                                            parseTrueOrErrorMessage(response, (response) => {
                                                setSelectedRecords([]);
                                                load_requests();
                                            });
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <br/>
                        {isLoading && (<LoadingCircleComponent/>)}
                        {!isLoading && (
                            <table className="table tableLastRight">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input 
                                                type={'checkbox'}
                                                checked={selectedRecords.length === requests.length}
                                                onClick={(e)=>{
                                                    if(e.target.checked === false)
                                                        setSelectedRecords([]);
                                                    else {
                                                        const _rec = [];
                                                        for(var i in requests)
                                                            _rec.push(requests[i].id);
                                                        setSelectedRecords(_rec);
                                                    }
                                                }}
                                            />
                                        </th>
                                        <th scope="col">Nume prenume</th>
                                        <th scope="col">Tip control</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {requests.map((req, i) => 
                                        <tr key={i}>
                                            <td>
                                                <input 
                                                    type={'checkbox'}
                                                    checked={selectedRecords.includes(req.id)}
                                                    onChange={(e) => {
                                                        if(e.target.checked == false){
                                                            const _rec = [...selectedRecords];
                                                            _rec.splice(_rec.indexOf(req.id), 1);
                                                            setSelectedRecords(_rec);
                                                        }
                                                        else{
                                                            const _rec = [...selectedRecords];
                                                            _rec.push(req.id);
                                                            setSelectedRecords(_rec);
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td>{req.numePrenume}</td>
                                            <td>{renderTipControl(req.requestJSON)}</td>
                                            <td>
                                                <button 
                                                    className='btn btn-secondary no-outline'
                                                    onClick={()=>{
                                                        setOpenedRequestId(req.id);
                                                    }}
                                                >...</button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>          
                        )}
                    </LayoutCard>
                </div>
                <div className='col-md-6'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div className='row'>
                            <div className='col-md-7'>
                                <h4>Modificari fisa riscuri</h4>
                            </div>
                            <div className='col-md-5'>
                                <RoundButton 
                                    className="custom-btn-primary"
                                    text={`Valideaza selectia (${selectedRiskRecords.length})`}
                                    onClickHandler={()=>{ 
                                        post('HrAdmin/MedicalRequests/ValidateRiskFiles', {
                                            requestsJson: JSON.stringify(selectedRiskRecords)
                                        }, (response)=>{
                                            parseTrueOrErrorMessage(response, (response) => {
                                                setSelectedRiskRecords([]);
                                                load_requests();
                                            });                                            
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <br/>
                        {isLoadingRiskChanges && (<LoadingCircleComponent/>)}
                        {!isLoadingRiskChanges && (
                            <table className='table tableLastRight'>
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input 
                                                type={'checkbox'}
                                                checked={selectedRiskRecords.length === risksFiles.length}
                                                onClick={(e)=>{
                                                    if(e.target.checked === false)
                                                        setSelectedRiskRecords([]);
                                                    else {
                                                        const _rec = [];
                                                        for(var i in risksFiles)
                                                            _rec.push(risksFiles[i].id);
                                                        setSelectedRiskRecords(_rec);
                                                    }
                                                }}
                                            />
                                        </th>
                                        <th scope="col">Nume prenume</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {risksFiles.map((req, i) => 
                                        <tr key={i}>
                                            <td>
                                                <input 
                                                    type={'checkbox'}
                                                    checked={selectedRiskRecords.includes(req.id)}
                                                    onChange={(e) => {
                                                        if(e.target.checked == false){
                                                            const _rec = [...selectedRiskRecords];
                                                            _rec.splice(_rec.indexOf(req.id), 1);
                                                            setSelectedRiskRecords(_rec);
                                                        }
                                                        else{
                                                            const _rec = [...selectedRiskRecords];
                                                            _rec.push(req.id);
                                                            setSelectedRiskRecords(_rec);
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td>{req.numePrenume}</td>
                                            <td>
                                                <button 
                                                    className='btn btn-secondary no-outline'
                                                    onClick={()=>{
                                                        setFisaAptitudiniSelectedWorkerId(req.workerId);
                                                    }}
                                                >...</button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </LayoutCard>

                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Aprobate fara control medical</h4>
                        <br/>
                        {isLoadingWithoutControl && (<LoadingCircleComponent/>)}
                        {!isLoadingWithoutControl && (
                            <table className='table tableLastRight'>
                                <thead>
                                    <tr>
                                        <th scope="col">Nume prenume</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {risksWithoutControl.map((req, i) => 
                                        <tr key={i}>
                                            <td>{req.numePrenume}</td>
                                            <td>
                                                <button 
                                                    className='btn btn-secondary no-outline'
                                                    onClick={()=>{
                                                        setFisaAptitudiniSelectedWorkerId(req.workerId);
                                                    }}
                                                >...</button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </LayoutCard>
                </div>
            </div>
            <CustomResourceModal
                size={'lg'}
                isOpen={openedRequestId !== undefined}
                toggle={()=>{setOpenedRequestId(undefined)}}
                title={"Detalii cerere"}
            >
                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                    <h4>Cerere examen medical</h4>                                              
                    <br/>
                    {((employeeData == undefined || medicalRequest == undefined || risksFile == undefined || medicalRequestDate == undefined) && (
                        <LoadingCircleComponent/>
                    ))}
                    {(!(employeeData == undefined || medicalRequest == undefined || risksFile == undefined || medicalRequestDate == undefined) && (
                        <FisaSolicitare
                            employeeData={employeeData}
                            medicalRequest={medicalRequest}
                            risksFile={risksFile}
                            medicalRequestDate={medicalRequestDate}
                            medicalRequestSignature={medicalRequestSignature}
                        />
                    ))}
                </LayoutCard>                 
                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                    <h4>Fisa riscuri</h4>                                              
                    <br/>
                    {((employeeData == undefined || medicalRequest == undefined || risksFile == undefined || medicalRequestDate == undefined || risksFileCreatedBy == undefined) && (
                        <LoadingCircleComponent/>
                    ))}
                    {(!(employeeData == undefined || medicalRequest == undefined || risksFile == undefined || medicalRequestDate == undefined || risksFileCreatedBy == undefined) && (
                        <FisaFactoriRisc
                            risksFileSignature={risksFileSignature}
                            employeeData={employeeData}
                            medicalRequest={medicalRequest}
                            risksFile={risksFile}
                            medicalRequestDate={medicalRequestDate}
                            risksFileCreatedBy={risksFileCreatedBy}
                            medicalRequestSignature={medicalRequestSignature}
                        />
                    ))}
                </LayoutCard>   
            </CustomResourceModal>
            
            <FisaRiscuriViewer
                isOpen={fisaAptitudiniSelectedWorkerId !== undefined}
                toggle={() => {setFisaAptitudiniSelectedWorkerId(undefined)}}
                employeeId={fisaAptitudiniSelectedWorkerId}
            />
        </>
    );
}

const FisaRiscuriViewer = ({employeeId, isOpen, toggle }) => {
    const [riskFiles, setRiskFiles] = useState([]);
    const [selectedRiskFileId, setSelectedRiskFileId] = useState(undefined);
    useEffect(()=>{
        if(employeeId === undefined) return;
        run_reload();
    }, [isOpen]);

    const run_reload = () => {        
        get_ajax("HrAdmin/MedicalRequests/GetRiskFilesByEmployee?employeeId=" + employeeId, (response) => {            
            if(response.length !== 0)
            {
                setRiskFiles(response);
                setSelectedRiskFileId(response[0].id);
            }
        });
    }
    const [fisaFactoriRiskData, setFisaFactoriRiskData] = useState(undefined);    
    useEffect(()=>{
        if(selectedRiskFileId === undefined) return;
        loadSelectedRiskFile();
    }, [selectedRiskFileId]);  
    const loadSelectedRiskFile = () => {        
        get_ajax("HrAdmin/MedicalRequests/GetRiskFileDetails?riskFileId=" + selectedRiskFileId, (response) => {                    
            response['employeeData'] = JSON.parse(response['employeeData']);            
            response['risksFile'] = JSON.parse(response['risksFile']);   
            console.log(response);         
            setFisaFactoriRiskData(response);
        });
    }          

    return(
        <CustomResourceModal
            size={'xl'}
            isOpen={isOpen}
            toggle={toggle}
            title={"Fise riscuri"}
        >
            <div className='row'>
                <div className='col-md-8'>                    
                    {fisaFactoriRiskData === undefined && (<LoadingCircleComponent/>)}
                    {fisaFactoriRiskData !== undefined && (
                        <FisaFactoriRisc
                            risksFileSignature={fisaFactoriRiskData.risksFileSignature}
                            employeeData={fisaFactoriRiskData.employeeData}
                            medicalRequest={undefined}
                            risksFile={fisaFactoriRiskData.risksFile}
                            medicalRequestDate={fisaFactoriRiskData.medicalRequestDate}
                            risksFileCreatedBy={fisaFactoriRiskData.risksFileCreatedBy}
                            medicalRequestSignature={fisaFactoriRiskData.medicalRequestSignature}
                        />
                    )}
                </div>
                <div className='col-md-4'>                            
                    <h4>Istoric</h4>
                    {riskFiles.length == 0 && (
                        <p style={{textAlign: 'center'}}>Nu exista istoric pentru fisa de riscuri</p>
                    )}
                    <ul className="list-group">
                        {riskFiles.map((r, i) => 
                            <li 
                                key={i}
                                className={r.id == selectedRiskFileId ? "list-group-item active_side_menu" : "list-group-item"}
                                style={{cursor: 'pointer'}}
                                onClick={()=>{ setSelectedRiskFileId(r.id);}}
                            >
                                {r.createdAt}
                            </li>                      
                        )}  
                    </ul>
                </div>
            </div>            
        </CustomResourceModal>
    )
}