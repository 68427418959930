"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.PreviewDialog = exports.CustomResourceModalWithSave = exports.CustomResourceModal = exports.EditResourceDialog = exports.CreateResourceDialog = exports.UpdateResultSuccessDialog = exports.TempConfirmationDialog = exports.ConfirmationDialog = exports.DeleteConfirmationDialog = void 0;
var react_1 = __importStar(require("react"));
var reactstrap_1 = require("reactstrap");
var DeleteConfirmationDialog = function (_a) {
    var isOpen = _a.isOpen, toggle = _a.toggle, onConfirm = _a.onConfirm, _b = _a.label, label = _b === void 0 ? "Confirma stergerea" : _b, _c = _a.text, text = _c === void 0 ? "Sunteti sigur ca doriti sa continuati?" : _c;
    return (react_1["default"].createElement(reactstrap_1.Modal, { isOpen: isOpen, toggle: toggle, size: 'sm' },
        react_1["default"].createElement(reactstrap_1.ModalHeader, null, "Confirmati?"),
        react_1["default"].createElement(reactstrap_1.ModalBody, null,
            react_1["default"].createElement("p", null, text)),
        react_1["default"].createElement(reactstrap_1.ModalFooter, null,
            react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: toggle }, "Anuleaza"),
            react_1["default"].createElement(reactstrap_1.Button, { color: "danger", className: "no-outline", onClick: function () { onConfirm(); toggle(); } }, label))));
};
exports.DeleteConfirmationDialog = DeleteConfirmationDialog;
var ConfirmationDialog = function (_a) {
    var isOpen = _a.isOpen, toggle = _a.toggle, onConfirm = _a.onConfirm, _b = _a.size, size = _b === void 0 ? 'sm' : _b, _c = _a.label, label = _c === void 0 ? "Salveaza" : _c, _d = _a.text, text = _d === void 0 ? "Confirmati actiunea?" : _d;
    return (react_1["default"].createElement(reactstrap_1.Modal, { isOpen: isOpen, toggle: toggle, size: size },
        react_1["default"].createElement(reactstrap_1.ModalHeader, null, "Confirmati?"),
        react_1["default"].createElement(reactstrap_1.ModalBody, null,
            react_1["default"].createElement("p", null, text)),
        react_1["default"].createElement(reactstrap_1.ModalFooter, null,
            react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: toggle }, "Anuleaza"),
            react_1["default"].createElement(reactstrap_1.Button, { color: "primary", className: "no-outline", onClick: function () { onConfirm(); toggle(); } }, label))));
};
exports.ConfirmationDialog = ConfirmationDialog;
var TempConfirmationDialog = function (_a) {
    var isOpen = _a.isOpen, toggle = _a.toggle, onConfirm = _a.onConfirm, onConfirmTemp = _a.onConfirmTemp, _b = _a.size, size = _b === void 0 ? 'sm' : _b, _c = _a.label, label = _c === void 0 ? "Salveaza" : _c, _d = _a.labelTemp, labelTemp = _d === void 0 ? "Salveaza temporar" : _d, _e = _a.text, text = _e === void 0 ? "Confirmati actiunea?" : _e;
    return (react_1["default"].createElement(reactstrap_1.Modal, { isOpen: isOpen, toggle: toggle, size: size },
        react_1["default"].createElement(reactstrap_1.ModalHeader, null, "Confirmati?"),
        react_1["default"].createElement(reactstrap_1.ModalBody, null,
            react_1["default"].createElement("p", null, text)),
        react_1["default"].createElement(reactstrap_1.ModalFooter, null,
            react_1["default"].createElement(reactstrap_1.Button, { color: "primary", className: "no-outline", onClick: function () { onConfirmTemp(); toggle(); } }, labelTemp),
            react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: toggle }, "Anuleaza"),
            react_1["default"].createElement(reactstrap_1.Button, { color: "primary", className: "no-outline", onClick: function () { onConfirm(); toggle(); } }, label))));
};
exports.TempConfirmationDialog = TempConfirmationDialog;
var UpdateResultSuccessDialog = function (_a) {
    var isOpen = _a.isOpen, toggle = _a.toggle, onConfirm = _a.onConfirm, _b = _a.label, label = _b === void 0 ? 'Datele au fost actualizate cu succes.' : _b;
    return (react_1["default"].createElement(reactstrap_1.Modal, { isOpen: isOpen, toggle: toggle, size: 'sm' },
        react_1["default"].createElement(reactstrap_1.ModalHeader, null, "Confirmare"),
        react_1["default"].createElement(reactstrap_1.ModalBody, null,
            react_1["default"].createElement("p", null, label)),
        react_1["default"].createElement(reactstrap_1.ModalFooter, null,
            react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: function () { onConfirm(); toggle(); } }, "Am inteles"))));
};
exports.UpdateResultSuccessDialog = UpdateResultSuccessDialog;
var CreateResourceDialog = function (props) {
    return (react_1["default"].createElement(reactstrap_1.Modal, { isOpen: props.isOpen, toggle: props.toggle, size: props.size },
        react_1["default"].createElement(reactstrap_1.ModalHeader, null, props.title),
        react_1["default"].createElement(reactstrap_1.ModalBody, null, props.children),
        react_1["default"].createElement(reactstrap_1.ModalFooter, null,
            react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: props.toggle }, "Anuleaza"),
            !props.hideCreateButton && (react_1["default"].createElement(reactstrap_1.Button, { className: "no-outline custom-btn-primary", onClick: function () { props.onConfirm(); } }, props.saveBtnOverwride ? props.saveBtnOverwride : "Salveaza")))));
};
exports.CreateResourceDialog = CreateResourceDialog;
var EditResourceDialog = function (props) {
    var _a = (0, react_1.useState)(false), isDeleteConfirmationDialogOpen = _a[0], setIsDeleteConfirmationDialogOpen = _a[1];
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        react_1["default"].createElement(reactstrap_1.Modal, { isOpen: (isDeleteConfirmationDialogOpen == false && props.isOpen), toggle: props.toggle, size: props.size },
            react_1["default"].createElement(reactstrap_1.ModalHeader, null, props.title),
            react_1["default"].createElement(reactstrap_1.ModalBody, null, props.children),
            react_1["default"].createElement(reactstrap_1.ModalFooter, null,
                react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: props.toggle }, "Anuleaza"),
                react_1["default"].createElement(reactstrap_1.Button, { color: "danger", className: "no-outline", onClick: function () { setIsDeleteConfirmationDialogOpen(true); } }, "Sterge"),
                react_1["default"].createElement(reactstrap_1.Button, { className: "no-outline custom-btn-primary", onClick: function () { props.onConfirm(); } }, "Salveaza"))),
        react_1["default"].createElement(exports.DeleteConfirmationDialog, { isOpen: isDeleteConfirmationDialogOpen, toggle: function () { return setIsDeleteConfirmationDialogOpen(!isDeleteConfirmationDialogOpen); }, onConfirm: function () {
                props.onDelete();
            } })));
};
exports.EditResourceDialog = EditResourceDialog;
var CustomResourceModal = function (props) {
    return (react_1["default"].createElement(reactstrap_1.Modal, { isOpen: props.isOpen, toggle: props.toggle, size: props.size },
        react_1["default"].createElement(reactstrap_1.ModalHeader, null, props.title),
        react_1["default"].createElement(reactstrap_1.ModalBody, null, props.children),
        react_1["default"].createElement(reactstrap_1.ModalFooter, null,
            react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: props.toggle }, "Inchide"))));
};
exports.CustomResourceModal = CustomResourceModal;
var CustomResourceModalWithSave = function (props) {
    return (react_1["default"].createElement(reactstrap_1.Modal, { isOpen: props.isOpen, toggle: props.toggle, size: props.size },
        react_1["default"].createElement(reactstrap_1.ModalHeader, null, props.title),
        react_1["default"].createElement(reactstrap_1.ModalBody, null, props.children),
        react_1["default"].createElement(reactstrap_1.ModalFooter, null,
            react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: props.toggle }, "Anuleaza"),
            react_1["default"].createElement(reactstrap_1.Button, { className: "no-outline custom-btn-primary", onClick: function () { props.onConfirm(); } }, props.save_label ? props.save_label : 'Salveaza'))));
};
exports.CustomResourceModalWithSave = CustomResourceModalWithSave;
var PreviewDialog = function (props) {
    return (react_1["default"].createElement(reactstrap_1.Modal, { isOpen: props.isOpen, toggle: props.toggle, size: props.size },
        react_1["default"].createElement(reactstrap_1.ModalHeader, null, props.title),
        react_1["default"].createElement(reactstrap_1.ModalBody, null, props.children),
        react_1["default"].createElement(reactstrap_1.ModalFooter, null,
            react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: props.toggle }, "Inchide"))));
};
exports.PreviewDialog = PreviewDialog;
