import React, { useEffect, useState } from 'react';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { RoundButton, RoundRedirectButton } from 'shared/dist/components/generic-building-blocks/RoundButton';
import AOS from 'aos';
import "aos/dist/aos.css";
import {PageConfigurator} from 'shared/dist/components/elearning_builder/PageConfigurator';
import {PageList} from 'shared/dist/components/elearning_builder/PageList';
import {MaterialDetails} from 'shared/dist/components/elearning_builder/MaterialDetails';
import {FiEdit2} from 'react-icons/fi';
import {PageDetailsEditor} from 'shared/dist/components/elearning_builder/PageDetailsEditor';

export const InstructionDetails = (props) => {
    useEffect(()=>{
        AOS.init();
    }, []);
    const [instructionId, setInstructionId] = useState(undefined);
    useEffect(()=>{
        if(props.match.params.instruction_id != undefined)
            setInstructionId(props.match.params.instruction_id);
    }, [props.match.params.instruction_id]);

    const [activeRightTab, setActiveRightTab] = useState(0);

    const [detaliiInstructiuneIsEdit, setDetaliiInstructiuneIsEdit] = useState(false);
    const [detaliiInstructiuneTriggerSave, setDetaliiInstructiuneTriggerSave] = useState(false);

    const [selectedPageId, setSelectedPageId] = useState(undefined);

    const [updateTrigger, setUpdateTrigger] = useState(false);

    return (
        <>
            <div className='row'>
                <div className='col-md-4'>
                    <RoundRedirectButton 
                        className="custom-btn-primary"
                        text="Inapoi"
                        redirectUrl={'/expertssm/instructions'}
                    />
                </div>
                <div className='col-md-8'>
                    <ul className="nav justify-content-end nav-tabs">
                        <li className="nav-item noselect">
                            <a 
                                className={activeRightTab === 0 ? "nav-link active" : "nav-link"}
                                onClick={() => setActiveRightTab(0)}
                            >Configurare</a>
                        </li>
                        <li className="nav-item noselect">
                            <a 
                                className={activeRightTab === 1 ? "nav-link active" : "nav-link"}
                                onClick={() => setActiveRightTab(1)}
                            >Istoric</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-md-4'>
                    <div data-aos="flip-up">
                        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                            <div className='row'>
                                <div className='col-md-7'>
                                    <h4>Detalii material</h4>
                                </div>
                                <div className='col-md-5'>
                                    {!detaliiInstructiuneIsEdit && (
                                        <div className="float-right">
                                            <FiEdit2 onClick={()=>{setDetaliiInstructiuneIsEdit(true);}}/>                                
                                        </div>
                                    )}
                                    {detaliiInstructiuneIsEdit && (
                                        <div className='row'>
                                            <RoundButton
                                                className="custom-btn-primary"
                                                onClickHandler={()=>{ setDetaliiInstructiuneTriggerSave(true); }}
                                                text="Salveaza"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <br/>
                            <MaterialDetails
                                material_id={instructionId}
                                target_endpoint={'ExpertSSM/InstructionsManager'}
                                is_edit_mode={detaliiInstructiuneIsEdit}
                                trigger_save_event={detaliiInstructiuneTriggerSave}
                                data_saved_callback={()=>{ setDetaliiInstructiuneTriggerSave(false); setDetaliiInstructiuneIsEdit(false); }}
                            />
                        </LayoutCard>
                    </div>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Pagini</h4>
                        <br/>
                        <PageList
                            material_id={instructionId}
                            target_endpoint={'ExpertSSM/InstructionsManager'}
                            selected_page_id={selectedPageId}
                            page_selected_handler={(pageId) => { setSelectedPageId(pageId); }}
                            update_trigger={updateTrigger}
                        />
                    </LayoutCard>
                </div>
                {(selectedPageId !== "" && selectedPageId !== null && selectedPageId !== undefined) && (
                    <div className='col-md-8'>
                        {activeRightTab === 0 && (
                            <>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <PageDetailsEditor
                                        material_id={instructionId}
                                        target_endpoint={'ExpertSSM/InstructionsManager'}
                                        selected_page_id={selectedPageId}
                                        update_page_list={()=>{ setUpdateTrigger((Math.random() + 1).toString(36).substring(7)); }}
                                    /> 
                                </LayoutCard>
                                <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    <PageConfigurator
                                        material_id={instructionId}
                                        target_endpoint={'ExpertSSM/InstructionsManager'}
                                        media_resources_endpoint={'ExpertSSM/MediaLibrary'}
                                        selected_page_id={selectedPageId}
                                    />
                                </LayoutCard>
                            </>
                        )}
                        {activeRightTab === 1 && (
                            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                    
                            </LayoutCard>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}