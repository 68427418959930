import React, { useEffect, useRef, useState } from 'react';
import { getAccessToken } from 'shared/dist/components/api-authorization/AuthService';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import AOS from 'aos';
import "aos/dist/aos.css";
import {CdnResourceDownloadBtn, RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {formatBytes} from 'shared/dist/utils/Utils';
import {FileTypeIcon, MediaPreviewIcon} from 'shared/dist/components/generic-building-blocks/FileTypeIcon';
import {CdnImage} from 'shared/dist/components/generic-building-blocks/CdnImage';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {CreateResourceDialog, DeleteConfirmationDialog} from 'shared/dist/components/generic-building-blocks/UserDialogs';
import { FormGroup } from 'shared/dist/components/generic-building-blocks/FormGroup';
import { Redirect } from 'react-router-dom';
import {MdIntegrationInstructions} from 'react-icons/md';

export const Instructiuni = () => {
    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(12);

    useEffect(()=>{
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);

    useEffect(()=>{     
        AOS.init();                    
    }, []);

    const loadData = (searchBy, page) => {
        get_ajax("ExpertSSM/InstructionsManager/GetInstructions?search=" + searchBy + "&page=" + page, (response) =>{
            setRecords(response);
            setIsLoading(false);
        });
    }

    const [isNewInstructionDialogOpen, setIsNewInstructionDialogOpen] = useState(false);
    const [newInstructionName, setNewInstructionName] = useState('');
    useEffect(()=>{
        setNewInstructionName('');
    }, [isNewInstructionDialogOpen]);
    const [newInstructionType, setNewInstructionType] = useState(0);

    const [redirectToResource, setRedirectToResource] = useState(undefined);
    
    return(
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Materiale</h4>
                        <br/>
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </LayoutCard>
                    {isLoading && (<LoadingCircleComponent/>)}
                    {!isLoading && (
                        <>
                            <div className='row'>
                                {records.map((rec, i)=> 
                                    <div className='col-md-4' key={i}>
                                        <div className='maxHeight' style={{paddingBottom: '25px'}}>
                                        <LayoutCard 
                                            className="layout_card1 shadow-sm no_padding maxHeight top_bottom"
                                            onClick={()=>{
                                                setRedirectToResource("/expertssm/instructions/details/" + rec.id);
                                            }}
                                        >
                                            <MdIntegrationInstructions
                                                size={52} 
                                                style={{margin: 'auto', display: 'block', marginTop: '40px'}}
                                            />
                                            <p 
                                                style={{
                                                    textAlign: 'center',
                                                    marginBottom: '0',
                                                    whiteSpace: 'normal'
                                                }}
                                                className='padded_bottom'
                                            >
                                                {rec.originalName}
                                            </p>
                                        </LayoutCard>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {records.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista resurse.</p><br/></>
                            )}
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={records.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                <div className='col-md-4'>
                    <RoundButton
                        className={"custom-btn-primary"}
                        text={'Material nou'}
                        onClickHandler={()=>{setIsNewInstructionDialogOpen(true)}}
                    />
                </div>
            </div>

            <CreateResourceDialog
                isOpen={isNewInstructionDialogOpen}
                toggle={()=>{setIsNewInstructionDialogOpen(!isNewInstructionDialogOpen)}}
                saveBtnOverwride={"Adauga"}
                title={"Material nou"}
                onConfirm={()=>{
                    post('ExpertSSM/InstructionsManager/CreateNewInstruction', {
                        'instructionName': newInstructionName,
                        'type': newInstructionType
                    }, (response)=>{
                        setRedirectToResource("/expertssm/instructions/details/" + response);
                    });
                }}
            >
                <FormGroup
                    label={"Nume material"}
                    value={newInstructionName}
                    onChange={(e) => {setNewInstructionName(e.target.value)}}
                />
                <div className='form-group'>
                    <label>Tip:</label>
                    <select 
                        value={newInstructionType} 
                        className='form-control'
                        onChange={(e) => {setNewInstructionType(e.target.value)}}
                    >
                        <option value={0}>Instructiune SSM</option>
                        <option value={1}>Instructiune lucru</option>
                        <option value={2}>Legislatie</option>
                        <option value={3}>Alte materiale</option>
                    </select>
                </div>
            </CreateResourceDialog>
            {redirectToResource !== undefined && (
                <Redirect to={redirectToResource}/>
            )}
        </>
    );
}