import React from 'react';
import {ImCheckboxUnchecked, ImCheckboxChecked} from 'react-icons/im';
import { NullOrUndefined } from 'shared/dist/utils/Utils';

function isEmptyOrSpaces(str){
    try { return str === null || str.match(/^ *$/) !== null; }
    catch { return true; }
}

function parseDateStringToFormat(str){
    var today = new Date(str);    
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
  
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    str = (dd + '-' + mm + '-' + yyyy);
    if(str === "NaN-NaN-NaN")
        return "";
    return (str);
}

export const MediaControlRequestRender = ({employeeData, risksFile, medicalRequest, medicalRequestDate, risksFileCreatedBy, risksFileSignature, medicalRequestSignature}) =>{    
    return(
        <>
            <FisaSolicitare
                employeeData={employeeData}
                medicalRequest={medicalRequest}
                risksFile={risksFile}
                medicalRequestDate={medicalRequestDate}
                medicalRequestSignature={medicalRequestSignature}
            />          

            <br/>
            <hr/>            
            <br/>

            <FisaFactoriRisc
                employeeData={employeeData}
                medicalRequest={medicalRequest}
                risksFile={risksFile}
                medicalRequestDate={medicalRequestDate}
                risksFileCreatedBy={risksFileCreatedBy}
                risksFileSignature={risksFileSignature}
                medicalRequestSignature={medicalRequestSignature}
            />            
        </>
    );
}

export const FisaSolicitare = ({employeeData, medicalRequest, risksFile, medicalRequestDate, medicalRequestSignature}) => {
    return (
        <>
            <h5 style={{textAlign: 'center'}}>FISA DE SOLICITARE A EXAMENULUI MEDICAL LA ANGAJARE</h5>                
            <br/>                                  
            <p>
                &emsp;&emsp;Subsemnatul <b>{employeeData.unitate.ReprezentantLegal}</b> angajator la intreprinderea/societatea comerciala/unitatea <b>{employeeData.unitate.Nume}</b>,
                adresa: <b>{employeeData.unitate.Adresa}</b>, tel.: <b>{employeeData.unitate.Telefon}</b>, cod CAEN si domeniu de activitate: <b>{employeeData.unitate.CodCAEN}</b> solicit 
                examen medical de medicina muncii pentru:
            </p>
            <div className="row">
                <div className="col-md-4">
                    <label>ANGAJARE {medicalRequest.solicitare_angajare ? <Checked/> : <UnChecked/>}</label> <br/>
                    <label>RELUAREA MUNCII {medicalRequest.solicitare_reluarea_muncii ? <Checked/> : <UnChecked/>} </label> <br/>
                    <label>SCHIMBAREA LOCULUI DE MUNCA {medicalRequest.solicitare_schimbareaLoculuiDeMunca ? <Checked/> : <UnChecked/>} </label> <br/>
                </div>
                <div className="col-md-4">
                    <label>CONTROL MEDICAL PERIODIC {medicalRequest.solicitare_control_medical_periodic ? <Checked/> : <UnChecked/>} </label> <br/>
                    <label>SUPRAVEGHERE SPECIALA {medicalRequest.solicitare_supraveghere_speciala ? <Checked/> : <UnChecked/>} </label> <br/>
                </div>
                <div className="col-md-4">
                    <label>ADAPTARE {medicalRequest.solicitare_adaptare ? <Checked/> : <UnChecked/>} </label> <br/>
                    <label>LA CERERE {medicalRequest.solicitare_la_cerere ? <Checked/> : <UnChecked/>} </label> <br/>
                    <label>
                        ALTELE {medicalRequest.solicitare_altele !== null ? <Checked/> : <UnChecked/>} 
                        {medicalRequest.solicitare_altele !== null && 
                        (<><br/><b>{medicalRequest.solicitare_altele}</b></>)}
                    </label> <br/>
                </div>
            </div>
            <p>
            conform legislatiei de securitate si sanatate in munca in vigoare, pentru:
            domnul/doamna <b>{employeeData.nume} {employeeData.prenume}</b>, nascut/a la <b>{employeeData.data_nasterii}</b>,
            CNP: <b>{employeeData.cnp}</b>, avand profesiunea/ocupatia de: <b>{medicalRequest.solicitare_profesie}</b> si
            care urmeaza a fi/este angajat/a in functia: <b>{medicalRequest.solicitare_functie}</b>, la locul
            de munca: <b>{risksFile.locMunca}</b> din sectia (atelier, compartiment etc.) <b>{risksFile.sectieDepartament}</b>            
            </p>          
            <p>
                &emsp;&emsp;Persoana examinata urmeaza sa efectueze activitatea profesionala la un loc/
                post de munca ce prezinta riscurile profesionale detaliate in <b>Fisa de
                identificare a factorilor de risc profesional</b>, anexata prezentei cereri.
            </p>    
            <div className="row">
                <div className="col-md-6">
                    Data <b>{medicalRequestDate}</b>
                </div>
                <div className="col-md-6">
                    <b>{employeeData.unitate.ReprezentantLegal}</b> <br/>
                    Semnatura si stampila angajatorului  
                    <br/>
                    {medicalRequestSignature !== null && medicalRequestSignature !== undefined && medicalRequestSignature != 'none' && medicalRequestSignature !== '' && (
                        <img src={medicalRequestSignature} style={{maxWidth: '100%'}}/>
                    )}
                </div>
            </div>            
        </>
    );
}

export const FisaFactoriRisc = ({employeeData, medicalRequest, risksFile, medicalRequestDate, risksFileCreatedBy, risksFileSignature, medicalRequestSignature}) =>{
    console.log("MRS");
    console.log(medicalRequestSignature);

    return(
        <>
            <div className="row">
                <div className="col-md-8">
                    <label>Unitatea: <b>{employeeData.unitate.Nume}</b></label> <br/>
                    <label>Adresa: <b>{employeeData.unitate.Adresa}</b></label> <br/>
                    <label>Telefon: <b>{employeeData.unitate.Telefon}</b></label> <br/>

                    <label>NUMELE SI PRENUMELE LUCRATORULUI: <b>{employeeData.nume} {employeeData.prenume}</b></label> <br/>
                    <label>CNP: <b>{employeeData.cnp}</b></label> <br/>
                                        
                </div>
                {employeeData.subunitate && (
                    <div className="col-md-4">
                        <label>Subunitatea: <b>{employeeData.subunitate.Nume}</b></label> <br/>
                        <label>Adresa: <b>{employeeData.subunitate.Adresa}</b></label> <br/>
                        <label>Telefon: <b>{employeeData.subunitate.Telefon}</b></label> <br/>
                    </div>
                )}
            </div>
            <br/>
            <h5 style={{textAlign: 'center'}}>Fisa de identificare a factorilor de risc profesional</h5>                
            <br/>     
            <label>Denumirea postului si a locului de munca: <b>{risksFile.locMunca}</b></label> <br/>
            <label>Sectia/Departamentul: <b>{risksFile.sectieDepartament}</b></label> <br/>
            <label>Naveta: <b>{NullOrUndefined(risksFile.naveta) ? 'NU' : 'DA' }</b> Nr ore/zi <b>{risksFile.naveta}</b></label> <br/>
            <h6>Descrierea activitatii:</h6>
            <label>In echipa: <b>{risksFile.muncaEchipa == false ? 'NU' : 'DA' }</b>&emsp; Nr. ore/zi <b>{risksFile.nrOrePeZi}</b> &emsp;</label> 
            <label>Nr. schimburi de lucru <b>{risksFile.nrSchimburiDeLucru} </b></label><br/>
            <label>Schimb de noapte {risksFile.schimbNoapte == null ? <b>NU</b> : <Checked/>} <b>{risksFile.schimbNoapte}</b>&emsp;</label> 
            <label>Pauze organizate <b>{risksFile.pauzeOrganizate == false ? "NU" : "DA"}</b> &emsp;</label> 
            <label>Banda rulanta <b>{risksFile.bandaRulanta == false ? "NU" : "DA"}</b> &emsp;</label> <br/>                        
            <h6>Risc de: &emsp;</h6>            
            {risksFile.riscuri.map((r, i) =>
                <label key={i}>{r.label} {r.value == false ? <UnChecked/> : <Checked/>}&emsp;</label>
            )}            
            <h6>Alte riscuri: &emsp;</h6>    
            {!isEmptyOrSpaces(risksFile.alteRiscuri) && (
                <><label>{risksFile.alteRiscuri}</label> <br/></>
            )}            
            <label>Conduce masina institutiei: <b>{NullOrUndefined(risksFile.conduceMasinaInstitutiei) ? 'NU' : 'DA' }</b> Ce categorie: {risksFile.conduceMasinaInstitutiei}</label> <br/>
            <label>Conduce utilaje/vehicule numai intrauzinal <b>{risksFile.conduceNumaiIntrauzinal == false ? 'NU' : 'DA'}</b> &emsp;</label> <br/>                                     
            <label>Loc de munca: &emsp;</label>
            <label>in conditii deosebite {risksFile.inConditiiDeosebite == false ? <b>NU</b> : <b>DA</b>} &emsp;</label>
            <label>in conditii speciale {risksFile.inConditiiSpeciale == false ? <b>NU</b> : <b>DA</b>} &emsp;</label> 
            <label>sector alimentar {risksFile.sectorAlimentar == false ? <b>NU</b> : <b>DA</b>} &emsp;</label>
            <label>port-arma {risksFile.portArma == false ? <b>NU</b> : <b>DA</b>} &emsp;</label><br/>
            <label>Operatiuni executate de lucrator in cadrul procesului tehnologic:</label><br/>
            <label>{risksFile.operatiuniProcesTehnologic}</label><br/>
            <label>Descrierea spatiului de lucru:</label><br/>
            <label>Dimensiuni incapere: L <b>{risksFile.dimensiuneIncapereL}</b>, l <b>{risksFile.dimensiuneIncaperel}</b>, H <b>{risksFile.dimensiuneIncapereH}</b> (m)</label> <b>{risksFile.dimensiuneObservatii}</b><br/>
            <label>Suprafata de lucru: &emsp; verticala {risksFile.suprafataLucruVerticala == false ? <UnChecked/> : <Checked/>} &emsp; orizontala {risksFile.suprafataLucruOrizontala == false ? <UnChecked/> : <Checked/>} &emsp; oblica {risksFile.suprafataLucruOblica == false ? <UnChecked/> : <Checked/>}</label><br/>
            <label>Munca: &emsp;</label>
            {risksFile.conditiiMunca.map((r, i)=>
                <label key={i}>{r.label} {r.value == false ? <UnChecked/> : <Checked/>}&emsp;</label>
            )}
            <br/>
            <><label>Altele: {risksFile.conditiiMuncaAltele}</label><br/></>
            <label>Deplasari pe teren in interesul serviciului: <b>{NullOrUndefined(risksFile.deplasariInteresServiciu) ? 'NU' : 'DA' }</b> Descriere: <b>{risksFile.deplasariInteresServiciu}</b></label> <br/>
            <label>Efort fizic: &emsp;mic {risksFile.efortFizicMic ? <Checked/> : <UnChecked/>} &emsp; mediu {risksFile.efortFizicMediu ? <Checked/> : <UnChecked/>} &emsp; mare {risksFile.efortFizicMare ? <Checked/> : <UnChecked/>} &emsp; foarte mare {risksFile.efortFizicFoarteMare ? <Checked/> : <UnChecked/>}</label> <br/>
            <label>
                Pozitie preponderent: 
                &emsp;ortostatica/in picioare {risksFile.pozitiePreponderentOrtostatica ? <Checked/> : <UnChecked/>}
                &emsp;asezat {risksFile.pozitiePreponderentAsezat ? <Checked/> : <UnChecked/>} 
                &emsp;aplecata {risksFile.pozitiePreponderentAplecat ? <Checked/> : <UnChecked/>} 
                &emsp;mixta {risksFile.pozitiePreponderentMixta ? <Checked/> : <UnChecked/>}
            </label><br/>
            <label>Pozitii fortate, nefiziologice: <b>{NullOrUndefined(risksFile.pozitiiFortate) ? 'NU' : 'DA' }</b> Ce tip: <b>{risksFile.pozitiiFortate}</b></label> <br/>            
            <label>Gesturi profesionale: <b>{risksFile.gesturiProfesionale}</b></label> <br/>
            <label>Suprasolicitari:
                &emsp;vizuale {risksFile.suprasolicitariVizuale ? <Checked/> : <UnChecked/>}
                &emsp;auditive {risksFile.suprasolicitariAuditive ? <Checked/> : <UnChecked/>}
                &emsp;suprasolicitari neuropsihosenzoriale {risksFile.suprasolicitariNeuropsihosenzoriale ? <Checked/> : <UnChecked/>}&emsp;
                <> (
                        &emsp;mentale {risksFile.suprasolicitariNeuroMentale ? <Checked/> : <UnChecked/>}
                        &emsp;emotionale {risksFile.suprasolicitariNeuroEmotionale ? <Checked/> : <UnChecked/>}
                        &emsp;altele {risksFile.suprasolicitariNeuroAltele ? <Checked/> : <UnChecked/>}
                        &emsp;) </>              
            </label><br/>
            <label>Suprasolicitari osteomusculoarticulare:</label><br/>
            <label>Miscari fortate: <b>{risksFile.miscariFortate ? "DA" : "NU"}</b></label><br/>
            <label>Repetitive: <b>{risksFile.miscariRepetitive ? "DA" : "NU"}</b></label><br/>
            <>
                    <label>Specificati zona:</label>
                    <label>Coloana vertebrala:</label><br/>
                    <label>cervicala: <b>{(risksFile.miscariFortate || risksFile.miscariRepetitive) ? risksFile.miscariRepetitive_cervical ? "DA" : "NU" : "NU"}&emsp;</b></label>
                    <label>toracala: <b>{(risksFile.miscariFortate || risksFile.miscariRepetitive) ?risksFile.miscariRepetitive_toracal ? "DA" : "NU" : "NU"}&emsp;</b></label>
                    <label>lombara: <b>{(risksFile.miscariFortate || risksFile.miscariRepetitive) ?risksFile.miscariRepetitive_lombar ? "DA" : "NU" : "NU"}&emsp;</b></label>
                    <label>Membre superioare: </label><br/>
                    <label>umar: <b>{(risksFile.miscariFortate || risksFile.miscariRepetitive) ? risksFile.miscariRepetitive_umar ? "DA" : "NU" : "NU"}&emsp;</b></label>
                    <label>cot: <b>{(risksFile.miscariFortate || risksFile.miscariRepetitive) ? risksFile.miscariRepetitive_cot ? "DA" : "NU" : "NU"}&emsp;</b></label>
                    <label>pumn: <b>{(risksFile.miscariFortate || risksFile.miscariRepetitive) ? risksFile.miscariRepetitive_pumn ? "DA" : "NU" : "NU"}&emsp;</b></label>
                    <label>Membre inferioare</label><br/>
                    <label>sold: <b>{(risksFile.miscariFortate || risksFile.miscariRepetitive) ? risksFile.miscariRepetitive_sold ? "DA" : "NU" : "NU"}&emsp;</b></label>
                    <label>genunchi: <b>{(risksFile.miscariFortate || risksFile.miscariRepetitive) ? risksFile.miscariRepetitive_genunchi ? "DA" : "NU" : "NU"}&emsp;</b></label>
                    <label>glezna: <b>{(risksFile.miscariFortate || risksFile.miscariRepetitive) ? risksFile.miscariRepetitive_glezna ? "DA" : "NU" : "NU"}&emsp;</b></label>
                </>          
            <label>Manipulare manuala a maselor: <b>{NullOrUndefined(risksFile.manipulareaManualaAMaselor) ? 'NU' : 'DA' }</b> Caracteristicile maselor manipulate: <b>{risksFile.manipulareaManualaAMaselor}</b></label> <br/>                                         
            <>
                <label>
                    ridicare {risksFile.manipulareManualaRidicare ? <Checked/> : <UnChecked/>} &emsp;
                    coborare {risksFile.manipulareManualaCoborare ? <Checked/> : <UnChecked/>} &emsp;
                    impingere {risksFile.manipulareManualaImpingere ? <Checked/> : <UnChecked/>} &emsp;
                    tragere {risksFile.manipulareManualaTragere ? <Checked/> : <UnChecked/>} &emsp;
                    purtare {risksFile.manipulareManualaPurtare ? <Checked/> : <UnChecked/>} &emsp;
                    deplasare {risksFile.manipulareManualaDeplasare ? <Checked/> : <UnChecked/>} &emsp;
                </label><br/>
                <label>Greutate maxima manipulata manual <b>{risksFile.manipulareManualaGreutateMaxima}</b></label><br/>
            </>
            <label>Agenti chimici: <b>{risksFile.agentiChimici ? "DA" : "NU"}</b></label><br/>
            {risksFile.agentiChimici && (
                <>
                    <table className="table table-bordered">
                        <thead>
                            <tr>                                
                                <th scope="col">Tipul agentului chimic </th>
                                <th scope="col">{`< V.L.E.`}</th>
                                <th scope="col">{`> V.L.E`}</th>
                                <th scope="col">Fp</th>
                                <th scope="col">C</th>
                                <th scope="col">P</th>
                            </tr>
                        </thead>
                        <tbody>
                            {risksFile.agentiChimiciTabelData.map((row, i) => 
                            <tr key={i}>
                                {row.columns.map((col, j) =>
                                    <td key={j}>{col}</td>
                                )}
                            </tr>
                        )}                            
                        </tbody>
                    </table>
                    <small>
                        Legenda: V.L.E. = valoarea-limita de expunere profesionala/Fp = foarte periculos/C = cancerigen/P = patrunde prin piele (Puteti atasa fisei un tabel separat.)
                    </small>
                    <br/><br/>
                </>
            )}
            <label>Agenti biologici: <b>{!isEmptyOrSpaces(risksFile.agentiBiologici) ? risksFile.agentiBiologici : "NU"}</b> Grupa <b>{risksFile.agentiBiologiciGrupa}</b></label><br/>
            <label>Agenti cancerigeni: <b>{!isEmptyOrSpaces(risksFile.agentiCancerigeni) ? risksFile.agentiCancerigeni : "NU"}</b></label><br/>
            <label>Pulberi profesionale: <b>{risksFile.pulberiProfesionale ? "DA" : "NU"}</b></label><br/>
            {risksFile.pulberiProfesionale && (
                <>
                    <table className="table table-bordered">
                        <thead>
                            <tr>                                
                                <th scope="col">Tipul pulberilor</th>
                                <th scope="col">{`< V.L.E.`}</th>
                                <th scope="col">{`> V.L.E`}</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {risksFile.pulberiProfesionaleTableData.map((row, i) => 
                            <tr key={i}>
                                {row.columns.map((col, j) =>
                                    <td key={j}>{col}</td>
                                )}
                            </tr>
                        )}                            
                        </tbody>
                    </table>
                    <small>
                        Legenda: V.L.E.= valoarea-limita de expunere profesionala
                    </small>
                    <br/><br/>
                </>
            )}            
            <label>Zgomot profesional: 
                &emsp;{`< V.L.E.`} {risksFile.zgomotProfesionalLessVLE ? <Checked/> : <UnChecked/>}
                &emsp;{`> V.L.E.`} {risksFile.zgomotProfesionalMoreVLE ? <Checked/> : <UnChecked/>}
            </label><br/>
            <label>Zgomote impulsive: <b>{risksFile.zgomoteImpulsive ? "DA" : "NU"}</b></label><br/>
            <label>Vibratii mecanice: 
                {(risksFile.vibratiiMecaniceLessVLE === false && risksFile.vibratiiMecaniceMoreVLE === false) && (
                    <b>&emsp; NU</b>
                )}
                {!(risksFile.vibratiiMecaniceLessVLE === false && risksFile.vibratiiMecaniceMoreVLE === false) && (
                    <>
                        &emsp;{`< V.L.E.`} {risksFile.vibratiiMecaniceLessVLE ? <Checked/> :  <UnChecked/>}
                        &emsp;{`> V.L.E.`} {risksFile.vibratiiMecaniceMoreVLE ? <Checked/> : <UnChecked/>}  
                    </>
                )}
            </label><br/>
            <>
                <label>Specificati zona:
                    &emsp;coloana vertebrala {risksFile.vibratiiMecaniceVertebrala ? <Checked/> : <UnChecked/>}
                    &emsp;membre superioare {risksFile.vibratiiMecaniceMembreSuperioare ? <Checked/> : <UnChecked/>}
                    &emsp;actiune asupra intregului organism {risksFile.vibratiiMecaniceActiuneAsupraIntreguluiCorp ? <Checked/> : <UnChecked/>}
                </label><br/>
            </>
            <label>Microclimat:</label><br/>
            <label>Temperatura aer: <b>{risksFile.temperaturaAer}</b>, variatii repetate de temperatura: <b>{risksFile.temperaturaRepetata ? "DA" : "NU"}</b></label><br/>
            <label>Presiune aer: <b>{risksFile.presiuneAer}</b></label><br/>
            <label>Umiditate relativa: <b>{risksFile.umiditateRelativa}</b></label><br/>
            <label>Radiatii: <b>{risksFile.radiatii ? "DA" : "NU"}</b></label><br/>                       
            <div>                
                <label>Radiatii ionizante: daca da, se va completa partea speciala.</label><br/><br/> 
                <h6 style={{textAlign: 'center'}}>PARTE SPECIALA PENTRU EXPUNEREA PROFESIONALA LA RADIATII IONIZANTE:</h6>
                {risksFile.radiatii === false && (
                    <b>Nu este cazul<br/></b>
                )}
                <label>Data intrarii in mediul cu expunere profesionala la radiatii ionizante: <b>{parseDateStringToFormat(risksFile.radiatiiIonizateDataIntrariiMediuExpunereProfesionala)}</b></label><br/><br/>
                <label style={{textAlign: 'center', width: '100%'}}>Clasificare actuala in grupa A {risksFile.radiatiiIonizateGrupaA ? <Checked/> : <UnChecked/>} sau B {risksFile.radiatiiIonizateGrupaB ? <Checked/> : <UnChecked/>} si conditii de expunere: {risksFile.conditiiExpunereRadiatiiGRUPA}</label><br/>
                <label>Aparatura folosita: <b>{risksFile.radiatiiIonizateAparaturaFolosita}</b></label><br/>
                <label>Proces tehnologic: <b>{risksFile.radiatiiIonizateProcesTehnologic}</b></label><br/>
                <label>Operatiuni indeplinite: <b>{risksFile.radiatiiIonizateOperatiuniIndeplinite}</b></label><br/>
                <label>Surse folosite:
                    &emsp;inchise {risksFile.radiatiiIonizateSurseInchise ? <Checked/> : <UnChecked/>}
                    &emsp;deschise {risksFile.radiatiiIonizateSurseDeschise ? <Checked/> : <UnChecked/>}             
                </label><br/>
                <label>Tip de expunere: 
                    &emsp;X externa {risksFile.radiatiiIonizateTipExpunere_X_externa ? <Checked/> : <UnChecked/>}
                    &emsp;gamma externa {risksFile.radiatiiIonizateTipExpunere_gamma_externa ? <Checked/> : <UnChecked/>}
                    &emsp;interna {risksFile.radiatiiIonizateTipExpunere_interna ? <Checked/> : <UnChecked/>}
                    &emsp;externa si interna {risksFile.radiatiiIonizateTipExpunere_interna_externa ? <Checked/> : <UnChecked/>}
                </label><br/>
                <label>Masuri de protectie individuala: <b>{risksFile.radiatiiIonizateTipExpunere_masuriprotectieindividuala}</b></label><br/>
                <label>Expunere anterioara: {risksFile.radiatii ? risksFile.radiatiiExpunereAnterioaraEXTRA : <b>Nu este cazul</b>}</label><br/>
                <label>Perioada: <b>{risksFile.radiatiiIonizate_expunere_anterioara_perioada}</b> nr. ani: <b>{risksFile.radiatiiIonizate_expunere_anterioara_nr_ani}</b></label><br/>
                <label>Doza cumulata prin expunere externa (mSv): <b>{risksFile.radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_externa}</b></label><br/>
                <label>Doza cumulata prin expunere interna: <b>{risksFile.radiatiiIonizate_expunere_anterioara_doza_cumulata_expunere_interna}</b></label><br/>
                <label>Doza totala: <b>{risksFile.radiatiiIonizate_expunere_anterioara_doza_totala}</b></label><br/>
                <label>Supraexpuneri anterioare: {risksFile.radiatiiSupraExpunereAnterioaraEXTRA}</label><br/>
                <label>- exceptionale {risksFile.radiatii ? "" : <b>Nu este cazul</b>}</label><br/>
                <label>Tip de expunere: 
                    &emsp;X externa {risksFile.radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_X_externa ? <Checked/> : <UnChecked/>}
                    &emsp;gamma externa {risksFile.radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_gamma_externa ? <Checked/> : <UnChecked/>}
                    &emsp;interna {risksFile.radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna ? <Checked/> : <UnChecked/>}
                    &emsp;externa si interna {risksFile.radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_interna_externa ? <Checked/> : <UnChecked/>}
                </label><br/>
                <label>Data: <b>{parseDateStringToFormat(risksFile.radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_data)}</b></label><br/>            
                <label>Doza (mSv): <b>{risksFile.radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_doza}</b></label><br/>
                <label>Concluzii: <b>{risksFile.radiatiiIonizateTipExpunere_supraexpuneri_exceptionale_concluzii}</b></label><br/>
                <label>- accidentale {risksFile.radiatii ? "" : <b>Nu este cazul</b>}</label><br/>
                <label>Tip de expunere: 
                    &emsp;X externa {risksFile.radiatiiIonizateTipExpunere_supraexpuneri_accidentale_X_externa ? <Checked/> : <UnChecked/>}
                    &emsp;gamma externa {risksFile.radiatiiIonizateTipExpunere_supraexpuneri_accidentale_gamma_externa ? <Checked/> : <UnChecked/>}
                    &emsp;interna {risksFile.radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna ? <Checked/> : <UnChecked/>}
                    &emsp;externa si interna {risksFile.radiatiiIonizateTipExpunere_supraexpuneri_accidentale_interna_externa ? <Checked/> : <UnChecked/>}
                </label><br/>
                <label>Data: <b>{parseDateStringToFormat(risksFile.radiatiiIonizateTipExpunere_supraexpuneri_accidentale_data)}</b></label><br/>            
                <label>Doza (mSv): <b>{risksFile.radiatiiIonizateTipExpunere_supraexpuneri_accidentale_doza}</b></label><br/>
                <label>Concluzii: <b>{risksFile.radiatiiIonizateTipExpunere_supraexpuneri_accidentale_concluzii}</b></label><br/>
                <label>Radiatii neionizante: {risksFile.radiatiiSupraExpunereAnterioaraEXTRATIPT}</label><br/>
                <label>Tipul: <b>{risksFile.radiatiiNeionizateTipul}</b></label>
            </div>
            <label>Iluminat: 
                &emsp;suficient {risksFile.iluminatSuficient ? <Checked/> : <UnChecked/>}
                &emsp;insuficient {risksFile.iluminatInsuficient ? <Checked/> : <UnChecked/>}
                &emsp;natural {risksFile.iluminatNatural ? <Checked/> : <UnChecked/>}
                &emsp;artificial {risksFile.iluminatArtificial ? <Checked/> : <UnChecked/>}
                &emsp;mixt {risksFile.iluminatMixt ? <Checked/> : <UnChecked/>}
            </label><br/>              
            <label>Mijloace de protectie colectiva: <b>{risksFile.mijloaceProtectieColectiva}</b></label><br/>
            <label>Mijloace de protectie individuala: <b>{risksFile.mijloaceProtectieIndividuala}</b></label><br/>
            <label>Echipament de lucru: <b>{risksFile.echipamentDeLucru}</b></label><br/>
            <label>Anexe igienico-sanitare: 
                &emsp;vestiar {risksFile.anexeIgenicoVestiar ? <Checked/> : <UnChecked/>}
                &emsp;chiuveta {risksFile.anexeIgenicoChiuveta ? <Checked/> : <UnChecked/>}
                &emsp;WC {risksFile.anexeIgenicoWC ? <Checked/> : <UnChecked/>}
                &emsp;dus {risksFile.anexeIgenicoDus ? <Checked/> : <UnChecked/>}
                &emsp;sala de mese {risksFile.anexeIgenicoSalaDeMese ? <Checked/> : <UnChecked/>}
                &emsp;spatiu de recreere {risksFile.anexeIgenicoSpatiuRecreere ? <Checked/> : <UnChecked/>}
            </label><br/>
            <label>Altele: <b>{isEmptyOrSpaces(risksFile.anexeIgenicoAltele) ? "NU" : risksFile.anexeIgenicoAltele}</b></label><br/>            
            <label>Observatii: <b>{isEmptyOrSpaces(risksFile.observatii) ? "NU" : risksFile.observatii}</b></label><br/>
            <br/>
            <label>Data completarii: <b>{medicalRequestDate}</b></label><br/>
        
            <label>
            Angajator,  <br/>
            <b>{employeeData.unitate.ReprezentantLegal}</b> <br/>
            (semnatura si stampila unitatii)
            </label><br/>
            {medicalRequestSignature !== null && medicalRequestSignature !== undefined && medicalRequestSignature !== 'none' && medicalRequestSignature !== '' && (
                <div className='row'>
                    <div className='col-md-6'>
                        <img src={medicalRequestSignature} style={{maxWidth: '100%'}}/>
                    </div>
                </div>
            )}
            <br/>
            <label>Lucrator desemnat</label><br/>
            <label>Serviciul intern de prevenire si protectie<br/><b>{risksFileCreatedBy}</b></label><br/>
            <label>Semnatura</label><br/>
            {risksFileSignature !== null && risksFileSignature !== undefined && risksFileSignature !== 'none' && risksFileSignature !== '' && (
                <div className='row'>
                    <div className='col-md-6'>
                        <img src={risksFileSignature} style={{maxWidth: '100%'}}/>
                    </div>
                </div>
            )}
            <br/>
            <br/>
            <br/>
            <br/>
        </>
    );
}

export const Checked = () =>{
    return(<ImCheckboxChecked/>);
}
export const UnChecked = () =>{
    return(<ImCheckboxUnchecked/>);
}