"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.PageConfigurator = exports.ComponentType = void 0;
var react_1 = __importStar(require("react"));
var DataProvider_1 = require("../../utils/DataProvider");
var RoundButton_1 = require("../generic-building-blocks/RoundButton");
var TextEditor_1 = require("./components/TextEditor");
var reactstrap_1 = require("reactstrap");
var io5_1 = require("react-icons/io5");
var io_1 = require("react-icons/io");
var ai_1 = require("react-icons/ai");
var UserDialogs_1 = require("../generic-building-blocks/UserDialogs");
var CircleLoadingIndicator_1 = require("../generic-building-blocks/CircleLoadingIndicator");
var react_beautiful_dnd_1 = require("react-beautiful-dnd");
var MediaEditor_1 = require("./components/MediaEditor");
// https://www.npmjs.com/package/react-image-video-lightbox -> Use this for the viewer ;)
var ComponentType;
(function (ComponentType) {
    ComponentType[ComponentType["Text"] = 0] = "Text";
    ComponentType[ComponentType["Media"] = 1] = "Media";
})(ComponentType = exports.ComponentType || (exports.ComponentType = {}));
var PageConfigurator = function (_a) {
    var material_id = _a.material_id, target_endpoint = _a.target_endpoint, media_resources_endpoint = _a.media_resources_endpoint, selected_page_id = _a.selected_page_id;
    var _b = (0, react_1.useState)(true), isLoading = _b[0], setIsLoading = _b[1];
    (0, react_1.useEffect)(function () {
        if (material_id === undefined || selected_page_id === undefined)
            return;
        loadPageDetails();
    }, [material_id, selected_page_id]);
    var loadPageDetails = function (delay) {
        if (delay === void 0) { delay = false; }
        setIsLoading(true);
        (0, DataProvider_1.get_ajax)("".concat(target_endpoint, "/GetPage?materialId=").concat(material_id, "&pageId=").concat(selected_page_id), function (response) {
            setTimeout(function () {
                setComponents(response);
                setIsLoading(false);
            }, !delay ? 1 : 100);
        });
    };
    var _c = (0, react_1.useState)(false), isNewComponentDialogOpen = _c[0], setIsNewComponentDialogOpen = _c[1];
    var _d = (0, react_1.useState)([]), components = _d[0], setComponents = _d[1];
    var _e = (0, react_1.useState)(-1), deleteSelectedComponentIndex = _e[0], setDeleteSelectedComponentIndex = _e[1];
    var addNewComponent = function (type) {
        (0, DataProvider_1.post)("".concat(target_endpoint, "/AddComponentToPage"), {
            materialId: material_id,
            pageId: selected_page_id,
            type: type
        }, function (new_compoent) {
            var cpy = __spreadArray([], components, true);
            cpy.push(new_compoent);
            setComponents(cpy);
            setIsNewComponentDialogOpen(false);
        });
    };
    var updateComponentValue = function (componentId, componentJSON) {
        (0, DataProvider_1.post)("".concat(target_endpoint, "/UpdateComponentContent"), {
            materialId: material_id,
            pageId: selected_page_id,
            componentId: componentId,
            componentJSON: componentJSON
        }, function (response) {
            (0, DataProvider_1.parseTrueOrErrorMessage)(response, function (response) { });
        });
    };
    var removeComponent = function (componentIndex) {
        (0, DataProvider_1.post)("".concat(target_endpoint, "/RemoveComponentFromPage"), {
            materialId: material_id,
            pageId: selected_page_id,
            componentId: components[componentIndex].id
        }, function (response) {
            (0, DataProvider_1.parseTrueOrErrorMessage)(response, function (response) {
                loadPageDetails(true);
            });
        });
    };
    var onDragEnd = function (result) {
        setIsLoading(true);
        if (!result.destination) {
            return;
        }
        (0, DataProvider_1.post)("".concat(target_endpoint, "/ReorderComponentInPage"), {
            'materialId': material_id,
            'pageId': selected_page_id,
            'sourceIndex': result.source.index,
            'destinationIndex': result.destination.index
        }, function (response) {
            (0, DataProvider_1.parseTrueOrErrorMessage)(response, function (response) {
                loadPageDetails(true);
            });
        });
    };
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        isLoading && (react_1["default"].createElement(CircleLoadingIndicator_1.LoadingCircleComponent, null)),
        !isLoading && (react_1["default"].createElement(react_1["default"].Fragment, null,
            react_1["default"].createElement(react_beautiful_dnd_1.DragDropContext, { onDragEnd: onDragEnd },
                react_1["default"].createElement(react_beautiful_dnd_1.Droppable, { droppableId: "page_components_viewer" }, function (provided) { return (react_1["default"].createElement("div", __assign({ ref: provided.innerRef }, provided.droppableProps),
                    components.map(function (component, index) { return (react_1["default"].createElement(react_beautiful_dnd_1.Draggable, { draggableId: "page_components_viewer_draggable_id_".concat(index), key: index, index: index }, function (provided) { return (react_1["default"].createElement("div", __assign({ ref: provided.innerRef }, provided.draggableProps),
                        react_1["default"].createElement("div", { className: 'learningMaterialPageComponentContainer' },
                            react_1["default"].createElement("div", __assign({}, provided.dragHandleProps),
                                react_1["default"].createElement(ai_1.AiOutlineClose, { className: 'float-right closeIcon', size: 24, onClick: function () { setDeleteSelectedComponentIndex(index); } }),
                                react_1["default"].createElement(ai_1.AiOutlineDrag, { className: 'dragIcon', size: 24 })),
                            react_1["default"].createElement("div", { className: 'editorContainer' },
                                component.type === 0 && (react_1["default"].createElement(TextEditor_1.TextEditor, { initialValue: JSON.parse(component.json), updateValue: function (e) {
                                        updateComponentValue(component.id, JSON.stringify(e));
                                    } })),
                                component.type === 1 && (react_1["default"].createElement(MediaEditor_1.MediaEditor, { componentId: component.id, initialValue: JSON.parse(component.json), updateValue: function (e) {
                                        updateComponentValue(component.id, JSON.stringify(e));
                                    }, media_resources_endpoint: media_resources_endpoint })))))); })); }),
                    provided.placeholder)); })),
            components.length === 0 && (react_1["default"].createElement("small", null, "Nu exista componente. Adaugati unul folosind butonul de mai jos.")))),
        react_1["default"].createElement("hr", null),
        react_1["default"].createElement("div", { className: 'row' },
            react_1["default"].createElement("div", { className: 'col-md-8' }),
            react_1["default"].createElement("div", { className: 'col-md-4' },
                react_1["default"].createElement(RoundButton_1.RoundButton, { className: "custom-btn-primary", text: "Adauga component", onClickHandler: function () { setIsNewComponentDialogOpen(true); } }))),
        react_1["default"].createElement(UserDialogs_1.DeleteConfirmationDialog, { isOpen: deleteSelectedComponentIndex !== -1, toggle: function () { setDeleteSelectedComponentIndex(-1); }, onConfirm: function () { removeComponent(deleteSelectedComponentIndex); } }),
        react_1["default"].createElement(reactstrap_1.Modal, { isOpen: isNewComponentDialogOpen, toggle: function () { setIsNewComponentDialogOpen(false); }, size: 'lg' },
            react_1["default"].createElement(reactstrap_1.ModalHeader, null, "Component nou"),
            react_1["default"].createElement(reactstrap_1.ModalBody, null,
                react_1["default"].createElement("div", { className: 'row' },
                    react_1["default"].createElement("div", { className: 'col-md-4' },
                        react_1["default"].createElement("div", { className: 'role_option' },
                            react_1["default"].createElement("div", { className: 'role_option_content', onClick: function () { addNewComponent(ComponentType.Text); } },
                                react_1["default"].createElement(io5_1.IoTextOutline, { size: 28 }),
                                react_1["default"].createElement("p", null, "Text")))),
                    react_1["default"].createElement("div", { className: 'col-md-4' },
                        react_1["default"].createElement("div", { className: 'role_option' },
                            react_1["default"].createElement("div", { className: 'role_option_content', onClick: function () { addNewComponent(ComponentType.Media); } },
                                react_1["default"].createElement(io_1.IoIosImages, { size: 28 }),
                                react_1["default"].createElement("p", null, "Media")))))),
            react_1["default"].createElement(reactstrap_1.ModalFooter, null,
                react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: function () { setIsNewComponentDialogOpen(false); } }, "Anuleaza")))));
};
exports.PageConfigurator = PageConfigurator;
