"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.PageDetailsEditor = void 0;
var react_1 = __importStar(require("react"));
var DataProvider_1 = require("../../utils/DataProvider");
var FormGroup_1 = require("../generic-building-blocks/FormGroup");
var RoundButton_1 = require("../generic-building-blocks/RoundButton");
var PageDetailsEditor = function (_a) {
    var material_id = _a.material_id, target_endpoint = _a.target_endpoint, selected_page_id = _a.selected_page_id, update_page_list = _a.update_page_list;
    (0, react_1.useEffect)(function () {
        if (material_id === undefined || selected_page_id === undefined)
            return;
        loadPages();
    }, [material_id, selected_page_id]);
    var originalPageName;
    var loadPages = function () {
        if (material_id === undefined)
            return;
        (0, DataProvider_1.get_ajax)("".concat(target_endpoint, "/GetPages?materialId=").concat(material_id), function (response) {
            originalPageName = response.filter(function (x) { return x.pageId === selected_page_id; })[0].pageName;
            setPageName(originalPageName);
        });
    };
    var _b = (0, react_1.useState)(undefined), pageName = _b[0], setPageName = _b[1];
    (0, react_1.useEffect)(function () {
        if (pageName === undefined || pageName === originalPageName)
            return;
        (0, DataProvider_1.post)("".concat(target_endpoint, "/SetPageName"), {
            'materialId': material_id,
            'pageId': selected_page_id,
            'newPageName': pageName
        }, function (response) {
            (0, DataProvider_1.parseTrueOrErrorMessage)(response, function (response) {
                update_page_list();
            });
        });
    }, [pageName]);
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        react_1["default"].createElement("div", { className: 'row' },
            react_1["default"].createElement("div", { className: 'col-md-6' },
                react_1["default"].createElement(FormGroup_1.FormGroup, { label: 'Nume pagina', value: pageName, onChange: function (e) { setPageName(e.target.value); } })),
            react_1["default"].createElement("div", { className: 'col-md-2' }),
            react_1["default"].createElement("div", { className: 'col-md-4' },
                react_1["default"].createElement(RoundButton_1.RoundButton, { className: 'btn-danger', text: 'Sterge pagina', onClickHandler: function () {
                        (0, DataProvider_1.post)("".concat(target_endpoint, "/DeletePage"), {
                            'materialId': material_id,
                            'pageId': selected_page_id
                        }, function (response) {
                            (0, DataProvider_1.parseTrueOrErrorMessage)(response, function (response) {
                                window.location.reload();
                            });
                        });
                    } })))));
};
exports.PageDetailsEditor = PageDetailsEditor;
