"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.displayToastNotification = exports.TOAST_NOTIFICATION = exports.useGlobalSync = exports.NotificationsProvider = exports.NotificationsContext = void 0;
var React = __importStar(require("react"));
var react_toastify_1 = require("react-toastify");
var signalr_1 = require("@microsoft/signalr");
var AuthService_1 = require("../components/api-authorization/AuthService");
var defaultApi = {
    connections: new Map(),
    getOrCreateConnection: function (hub_address, configuration) { return null; },
    notificationsCount: 0,
    setNotificationsCount: function (new_count) { return null; }
};
exports.NotificationsContext = React.createContext(defaultApi);
function NotificationsProvider(_a) {
    var children = _a.children;
    var _b = React.useState(defaultApi.connections), connections = _b[0], setConnections = _b[1];
    var getOrCreateConnection = React.useCallback(function (hub_address, configuration) {
        if (connections.has(hub_address)) {
            return connections.get(hub_address);
        }
        else {
            var token_1 = (0, AuthService_1.getAccessToken)().replace("Bearer ", "");
            console.log("Initialize new connection to ".concat(hub_address, ", using token ").concat(token_1));
            var new_connection = new signalr_1.HubConnectionBuilder()
                .withUrl(hub_address, { accessTokenFactory: function () { return token_1; } })
                .build();
            var map_cpy = connections;
            map_cpy.set(hub_address, new_connection);
            setConnections(map_cpy);
            new_connection.onreconnected(function () {
                (0, exports.displayToastNotification)("Client reconectat cu succes", TOAST_NOTIFICATION.SUCCESS);
            });
            configuration(new_connection);
            return new_connection;
        }
    }, [connections, setConnections]);
    var _c = React.useState(0), notificationsCount = _c[0], setNotificationsCount = _c[1];
    return (React.createElement(exports.NotificationsContext.Provider, { value: {
            connections: connections,
            getOrCreateConnection: getOrCreateConnection,
            notificationsCount: notificationsCount,
            setNotificationsCount: setNotificationsCount
        } }, children));
}
exports.NotificationsProvider = NotificationsProvider;
function useGlobalSync() {
    return React.useContext(exports.NotificationsContext);
}
exports.useGlobalSync = useGlobalSync;
var TOAST_NOTIFICATION;
(function (TOAST_NOTIFICATION) {
    TOAST_NOTIFICATION[TOAST_NOTIFICATION["GENERAL"] = 0] = "GENERAL";
    TOAST_NOTIFICATION[TOAST_NOTIFICATION["INFO"] = 1] = "INFO";
    TOAST_NOTIFICATION[TOAST_NOTIFICATION["SUCCESS"] = 2] = "SUCCESS";
    TOAST_NOTIFICATION[TOAST_NOTIFICATION["WARNING"] = 3] = "WARNING";
    TOAST_NOTIFICATION[TOAST_NOTIFICATION["ERROR"] = 4] = "ERROR";
})(TOAST_NOTIFICATION = exports.TOAST_NOTIFICATION || (exports.TOAST_NOTIFICATION = {}));
;
var displayToastNotification = function (message, type, closeCallback) {
    if (type === void 0) { type = TOAST_NOTIFICATION.GENERAL; }
    if (closeCallback === void 0) { closeCallback = function () { }; }
    switch (type) {
        case TOAST_NOTIFICATION.INFO:
            react_toastify_1.toast.info(message, { position: react_toastify_1.toast.POSITION.BOTTOM_RIGHT, onClose: closeCallback });
            break;
        case TOAST_NOTIFICATION.SUCCESS:
            react_toastify_1.toast.success(message, { position: react_toastify_1.toast.POSITION.BOTTOM_RIGHT, onClose: closeCallback });
            break;
        case TOAST_NOTIFICATION.WARNING:
            react_toastify_1.toast.warning(message, { position: react_toastify_1.toast.POSITION.BOTTOM_RIGHT, onClose: closeCallback });
            break;
        case TOAST_NOTIFICATION.ERROR:
            react_toastify_1.toast.error(message, { position: react_toastify_1.toast.POSITION.BOTTOM_RIGHT, onClose: closeCallback });
            break;
        default:
            (0, react_toastify_1.toast)(message, { position: react_toastify_1.toast.POSITION.BOTTOM_RIGHT, onClose: closeCallback });
            break;
    }
};
exports.displayToastNotification = displayToastNotification;
