"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.CdnResourceDownloadBtn = exports.RoundRedirectButton = exports.RoundButtonSmall = exports.RoundButton = void 0;
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var DataProvider_1 = require("../../utils/DataProvider");
var Utils_1 = require("../../utils/Utils");
var RoundButton = function (_a) {
    var className = _a.className, text = _a.text, onClickHandler = _a.onClickHandler, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    return (react_1["default"].createElement("button", { disabled: disabled, className: "no-outline btn btn-block rounded_input " + className, onClick: onClickHandler }, text));
};
exports.RoundButton = RoundButton;
var RoundButtonSmall = function (_a) {
    var className = _a.className, text = _a.text, onClickHandler = _a.onClickHandler, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    return (react_1["default"].createElement("button", { disabled: disabled, className: "no-outline btn rounded_input " + className, onClick: onClickHandler }, text));
};
exports.RoundButtonSmall = RoundButtonSmall;
var RoundRedirectButton = function (_a) {
    var className = _a.className, text = _a.text, redirectUrl = _a.redirectUrl, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var _c = (0, react_1.useState)(false), shouldRedirect = _c[0], setShouldRedirect = _c[1];
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        react_1["default"].createElement("button", { disabled: disabled, className: "no-outline btn btn-block rounded_input " + className, onClick: function () { setShouldRedirect(true); } }, text),
        shouldRedirect && react_1["default"].createElement(react_router_dom_1.Redirect, { to: redirectUrl })));
};
exports.RoundRedirectButton = RoundRedirectButton;
var CdnResourceDownloadBtn = function (_a) {
    var className = _a.className, text = _a.text, blobName = _a.blobName, originalName = _a.originalName, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        react_1["default"].createElement("button", { disabled: disabled, className: "no-outline btn btn-block rounded_input " + className, onClick: function () {
                var _cdn_address = window.localStorage.getItem("cdn_address");
                if (_cdn_address === null || _cdn_address === undefined || _cdn_address === "")
                    (0, DataProvider_1.get_ajax)('/configurator/get_cdn_address', function (address) {
                        window.localStorage.setItem("cdn_address", address);
                        (0, Utils_1.downloadURI)("".concat(address, "/").concat(blobName), originalName);
                    });
                else
                    (0, Utils_1.downloadURI)("".concat(_cdn_address, "/").concat(blobName), originalName);
            } }, text)));
};
exports.CdnResourceDownloadBtn = CdnResourceDownloadBtn;
